<div fxLayout="row" fxLayoutAlign="space-between">
  <h2 mat-dialog-title>Request New Payment Card</h2>
</div>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="requestForm" fxLayout="row wrap" fxLayoutGap="10px grid" style="height:130px">

    <mat-form-field fxFlex="33" appearance="outline">
      <mat-label>Card Amount</mat-label>
      <input id="CardAmount" currencyMask matInput formControlName="cardAmount" required [options]="{ precision: '2' }">
      <mat-error>Card amount is required.</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="33" appearance="outline">
      <mat-label>Card Available</mat-label>
      <input id="CardAvailableDate" matInput [matDatepicker]="toPicker" formControlName="cardAvailableDate" required
        [min]="minCardAvailableDate" (dateChange)="changeAvailableDate($event)" >
      <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
      <mat-datepicker #toPicker disabled="false"></mat-datepicker>
      <mat-error *ngIf="!invalidCardAvailableDate && requestForm.get('cardAvailableDate').hasError('required')">
        A card date of service is required.
      </mat-error>
      <mat-error *ngIf="invalidCardAvailableDate">Please enter a valid date.</mat-error>
      <mat-error *ngIf="!invalidCardAvailableDate && requestForm.get('cardAvailableDate').hasError('matDatepickerMin')">
        Card date of service must be equal to or greater than today.
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="33" appearance="outline">
      <mat-label>Expiration Date</mat-label>
      <input id="CardExpirationDate" matInput [matDatepicker]="toExpirationPicker" formControlName="cardExpirationDate"
        [min]="minCardExpirationDate" required>
      <mat-datepicker-toggle matSuffix [for]="toExpirationPicker"></mat-datepicker-toggle>
      <mat-datepicker #toExpirationPicker disabled="false"></mat-datepicker>
      <mat-error *ngIf="!invalidCardExpirationDate && requestForm.get('cardExpirationDate').hasError('required')">
        A card expiration date is required.
      </mat-error>
      <mat-error *ngIf="invalidCardExpirationDate">Please enter a valid date.</mat-error>
      <mat-error *ngIf="!invalidCardExpirationDate && requestForm.get('cardExpirationDate').hasError('matDatepickerMin')">
        Expiration date must be greater than the date of service.
      </mat-error>
    </mat-form-field>

    <div class="form-header-row" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
      <div class="form-header-checkbox-container" fxFlex="60" fxLayout="row" fxLayoutAlign="start center">
        <mat-checkbox style="margin-right:10px;" formControlName="visibleToMember"></mat-checkbox>
        <mat-label><strong>Allow member to view this card</strong></mat-label>
      </div>
    </div>

  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" style="margin-bottom: 0;">
  <div>
    <button mat-raised-button mat-dialog-close>{{cancelButtonText}}</button>
    <button mat-raised-button color="primary" (click)="submit()">{{saveButtonText}}</button>
  </div>
</mat-dialog-actions>
