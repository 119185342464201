import { HsrQuestionType } from '@enums';

export interface IHealthServiceRequestSettingsModel {
  questionnaireQuestions: IHsrQuestion[];
  eventNotificationsEnabled: boolean;
  webHookEndpointUri: string;
  authorizationToken: string;
  authorizationKey: string;
  partnerReviewRequiredEstimatedCostThreshold: number;
}

export interface IHsrQuestion {
  Id: number;
  Title: string;
  Html: string;
  Type: HsrQuestionType;
}
