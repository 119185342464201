/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPartnerResponseModel } from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as PartnerActions from './partner.actions';

export const partnerFeatureKey = 'partner';

export interface PartnerState {
  partners: IPartnerResponseModel[];
  groupPartners: IPartnerResponseModel[];
}

export const initialPartnerState: PartnerState = {
  partners: null,
  groupPartners: null
};

const partnerReducer = createReducer(
  initialPartnerState,
  on(PartnerActions.loadPartnersSuccess, (state, action) => ({ ...state, partners: action.partners })),
  on(PartnerActions.loadGroupPartnersSuccess, (state, action) => ({ ...state, groupPartners: action.groupPartners }))
);

export function reducer(state: PartnerState | undefined, action: Action) {
  return partnerReducer(state, action);
}
