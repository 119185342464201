import { ModuleType } from '@enums';

export interface IModuleConfigViewModel {
  id?: number;
  groupId: number;
  type: ModuleType;
  order?: number;
  name: string;
  isConfigurable?: boolean;
  settings?: object;
  hasConfigurableSettings?: boolean;
}
