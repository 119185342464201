<!-- <h1 mat-dialog-title>Submit Bill</h1>
<div #stepperTarget mat-dialog-content>
  <mat-vertical-stepper [formGroup]="superBillForm">
    <mat-step formGroupName="billForm">
      <ng-template matStepLabel>
        Bill Info
        <mat-icon
          *ngIf="superBillForm.get('billForm').invalid && superBillForm.get('billForm').touched"
          pTooltip="There are errors in this section of the form."
          tooltipPosition="bottom">
          error
        </mat-icon>
      </ng-template>
      <div fxLayout="row wrap" fxLayoutGap="15px grid">
        <div fxFlex="50" fxLayout="column">
          <div>Total Amount Charged *</div>
          <input formControlName="charged" type="text" pInputText currencyMask/>
          <small class="p-error"
            *ngIf="superBillForm.get('billForm.charged').invalid &&
                   superBillForm.get('billForm.charged').touched">
            Total Amount Charge is required.
          </small>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div>Total Amount Paid *</div>
          <input formControlName="paid" type="text" pInputText currencyMask/>
          <small class="p-error"
            *ngIf="superBillForm.get('billForm.paid').invalid &&
                   superBillForm.get('billForm.paid').touched">
            Total Amount Paid is required.
          </small>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div>Bill Date *</div>
          <p-calendar required formControlName="date" [style]="{'width': '100%'}" [appendTo]="stepperTarget" [showWeek]="false" [monthNavigator]="true" yearRange="1900:2021" [yearNavigator]="true"></p-calendar>
          <small class="p-error"
            *ngIf="superBillForm.get('billForm.date').invalid &&
                   superBillForm.get('billForm.date').toucaed">
            Bill Date is required.
          </small>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div style="margin-bottom: 3px;">Is this bill from a hospital? *</div>
          <div fxLayout="row" fxLayoutGap="15px" style="margin-top: 5px; margin-bottom: 10px;">
            <div fxLayout="row" fxLayoutGap="10px" >
              <p-radioButton [value]="true" formControlName="isHospitalBill"></p-radioButton>
              <label>Yes</label>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" style="margin-top: 3px;">
              <p-radioButton [value]="false" formControlName="isHospitalBill"></p-radioButton>
              <label>No</label>
            </div>
          </div>
          <small class="p-error"
            *ngIf="superBillForm.get('billForm.isHospitalBill').invalid &&
                   superBillForm.get('billForm.isHospitalBill').touched">
            Required.
          </small>
        </div>
      </div>
      <div style="margin-top: 15px;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button fxFlex="50" pButton pRipple label="Back" matStepperPrevious class="p-button-secondary"></button>
        <button fxFlex="50" pButton pRipple label="Next" matStepperNext></button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        Patient Info
      </ng-template>
      <div fxLayout="row wrap" fxLayoutGap="15px grid">
        <div fxFlex="100" fxLayout="column">
          <div>Patient</div>
          <mx-family-member-dropdown (changePatient)="onChangePatient($event)"></mx-family-member-dropdown>
        </div>
      </div>
      <div style="margin-top: 15px;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button fxFlex="50" pButton pRipple label="Back" matStepperPrevious class="p-button-secondary"></button>
        <button fxFlex="50" pButton pRipple label="Next" matStepperNext></button>
      </div>
    </mat-step>
    <mat-step formGroupName="medicalServicesForm">
      <ng-template matStepLabel>
        Medical Services
      </ng-template>
      <div fxLayout="column" style="margin-top: 15px;">
        <div style="padding: 15px;  border-radius: 15px; background-color: rgb(235, 235, 235)">
          <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 10px;">
            <div style="font-weight: bold;">#1.</div>

            <button pButton pRipple type="button"
              [style]="{'padding': '0px', 'margin-right': '-5px', 'margin-top': '-10px', 'margin-bottom': '-10px'}"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              pTooltip="Remove Medical Service"
              tooltipPosition="bottom">
            </button>
          </div>
          <div fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start center">

            <div fxFlex="50" fxLayout="column">
              <div>Procedure Code</div>
              <input formControlName="procedureCode" type="text" pInputText/>
            </div>
            <div fxFlex="50" fxLayout="column">
              <div>Diagnosis Code</div>
              <input formControlName="diagnosisCode" type="text" pInputText/>
            </div>
            <div fxFlex="50" fxLayout="column">
              <div>Service Date</div>
              <p-calendar required formControlName="serviceDate" [style]="{'width': '100%'}" [appendTo]="stepperTarget" [showWeek]="false" [monthNavigator]="true" yearRange="1900:2021" [yearNavigator]="true"></p-calendar>
            </div>
            <div fxFlex="50" fxLayout="column">
              <div>Units</div>
              <input formControlName="units" type="text" pInputText/>
            </div>
            <div fxFlex="100" fxLayout="column">
              <div>Charge Amount</div>
              <input formControlName="chargeAmount" type="text" pInputText currencyMask/>
            </div>
          </div>
        </div>

      </div>

      <div style="width: 100%" fxLayout="row" fxLayoutAlign="center">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-plus"
          class="p-button-rounded p-button-secondary"
          pTooltip="Add Medical Service"
          tooltipPosition="bottom">
        </button>
      </div>

      <div style="margin-top: 15px;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button fxFlex="50" pButton pRipple label="Back" matStepperPrevious class="p-button-secondary"></button>
        <button fxFlex="50" pButton pRipple label="Next" matStepperNext></button>
      </div>
    </mat-step>
    <mat-step formGroupName="providerForm">
      <ng-template matStepLabel>
        Provider Info
        <mat-icon
          *ngIf="superBillForm.get('providerForm').invalid && superBillForm.get('providerForm').touched"
          pTooltip="There are errors in this section of the form."
          tooltipPosition="bottom">
          error
        </mat-icon>
      </ng-template>
      <div fxLayout="row wrap" fxLayoutGap="15px grid">
        <div fxFlex="100" fxLayout="column">
          <div>Provider Name *</div>
          <input required formControlName="name" type="text" pInputText/>
          <small class="p-error"
            *ngIf="superBillForm.get('providerForm.name').invalid &&
                   superBillForm.get('providerForm.name').touched">
            Provider Name is required.
          </small>
        </div>

        <div fxFlex="100" fxLayout="column">
          <div>Address 1 *</div>
          <input required formControlName="address1" type="text" pInputText/>
          <small class="p-error"
            *ngIf="superBillForm.get('providerForm.address1').invalid &&
                   superBillForm.get('providerForm.address1').touched">
            Address Line 1 is required.
          </small>
        </div>

        <div fxFlex="100" fxLayout="column">
          <div>Address 2</div>
          <input formControlName="address2" type="text" pInputText/>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>City *</div>
          <input required formControlName="city" type="text" pInputText/>
          <small class="p-error"
            *ngIf="superBillForm.get('providerForm.city').invalid &&
                   superBillForm.get('providerForm.city').touched">
            Provider City is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>State *</div>
          <p-inputMask required [style]="{'width': '100%'}" formControlName="state" mask="aa" slotChar=""></p-inputMask>
          <small class="p-error"
            *ngIf="superBillForm.get('providerForm.state').invalid &&
                   superBillForm.get('providerForm.state').touched">
            Provider State is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>Zip/Postal Code *</div>
          <p-inputMask required [style]="{'width': '100%'}" formControlName="postalCode" mask="99999" [autoClear]="false" slotChar=""></p-inputMask>
          <small class="p-error"
            *ngIf="superBillForm.get('providerForm.postalCode').invalid &&
                  superBillForm.get('providerForm.postalCode').touched">
            Provider Postal Code is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>Country Code *</div>
          <p-inputMask required [style]="{'width': '100%'}" formControlName="countryCode" mask="aa" slotChar=""></p-inputMask>
          <small class="p-error"
            *ngIf="superBillForm.get('providerForm.countryCode').invalid &&
                   superBillForm.get('providerForm.countryCode').touched">
            Provider Country is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>Provider NPI</div>
          <p-inputMask [style]="{'width': '100%'}"  formControlName="npi" mask="9999999999" [autoClear]="false" slotChar=""></p-inputMask>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>Provider TIN</div>
          <p-inputMask [style]="{'width': '100%'}" formControlName="tin" mask="999-99-9999" [autoClear]="false" slotChar=""></p-inputMask>
        </div>
      </div>
      <div style="margin-top: 15px;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button fxFlex="50" pButton pRipple label="Back" matStepperPrevious class="p-button-secondary"></button>
        <button fxFlex="50" pButton pRipple label="Next" matStepperNext></button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        Upload Bills
      </ng-template>
      <div fxLayout="column" style="margin-top: 10px;">
        <div style="width:75px;">
          <input #fileChoose hidden="true" type="file" onclick="this.value=null" multiple="multiple" (change)="clickAddImage($event)" />
          <button mat-raised-button color="accent" (click)="fileChoose.click()">Choose Images</button>
        </div>
        <div>
          <mat-list>
            <mat-list-item *ngFor="let file of files;index as i">
              <span matLine> {{ file?.name }} </span>
              <button mat-icon-button color="warn" (click)="clickRemoveImage(i)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div style="margin-top: 15px;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button fxFlex="50" pButton pRipple label="Back" matStepperPrevious class="p-button-secondary"></button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; margin-top: 5px;">
  <span>* indicates a required field</span>
  <div>
    <button pButton pRipple [mat-dialog-close] class="p-button-secondary" style="margin-right: 10px" label="Cancel"></button>
    <button pButton pRipple style="margin-right: 10px" class="p-button-outlined" label="Save Draft"></button>
    <button pButton pRipple label="Submit" (click)="clickSubmit()"></button>
  </div>
</div>

<!-- <div mat-dialog-content fxLayout="column" style="width: 600px;" [@xsFadeAnimation]="'in'">
  <div fxLayout="row" fxLayoutAlign="space-between end" style="margin-bottom:30px;">
    <mat-card-title>Submit a {{ TerminologyType.Claim | terminology | async }}</mat-card-title>
  </div>

  <form [formGroup]="submitBillForm">
    <fieldset [disabled]="isSubmitting">
      <div fxLayout="column">
        <div fxLayout="row" class="form-row">
          <mat-form-field class="form-input left-input" id="memberFirstName">
            <input type="text" matInput placeholder="Member First Name" formControlName="memberFirstName">
            <mat-error>
              First name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-input right-input" id="memberLastName">
            <input type="text" matInput placeholder="Member Last Name" formControlName="memberLastName">
            <mat-error>
              Last name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" class="form-row">
          <mat-form-field class="form-input left-input" id="patientFirstName">
            <input type="text" matInput placeholder="Patient First Name" formControlName="patientFirstName">
            <mat-error>
              First name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-input right-input" id="patientLastName">
            <input type="text" matInput placeholder="Patient Last Name" formControlName="patientLastName">
            <mat-error>
              Last name is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="form-row" id="memberContactEmail">
          <input matInput placeholder="Email Address" formControlName="memberContactEmail">
          <mat-error>
            Email address is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-row" id="memberContactPhone">
          <input type="text" mask="(000) 000-0000" matInput placeholder="Phone Number" formControlName="memberContactPhone">
          <mat-error>
            Phone number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" style="margin-top: 10px;">
        <div style="width:75px;">
          <input #fileChoose hidden="true" type="file" onclick="this.value=null" multiple="multiple" (change)="clickAddImage($event)" />
          <button mat-raised-button color="accent" (click)="fileChoose.click()">Choose Images</button>
        </div>
        <div>
          <mat-list>
            <mat-list-item *ngFor="let file of files;index as i">
              <span matLine> {{ file?.name }} </span>
              <button mat-icon-button color="warn" (click)="clickRemoveImage(i)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
        </div>
      </div>

      <div fxLayout="row" style="margin: 15px 0">
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" style="margin-bottom: 10px; margin-right: 5px;">
        <div class="button-container">
          <button style="margin-right: 10px;" mat-raised-button mat-dialog-close class="form-button form-button-top">
            Cancel
          </button>
          <button mat-raised-button [disabled]="isSubmitting" color="primary" class="form-button form-button-top" (click)="clickSubmitBill()">
            Submit
          </button>
        </div>
      </div>
    </fieldset>
  </form>

</div>
 --> -->
