import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IPlanPostModel,
  IPlanPutModel,
  IPlanResponseModel,
  IProviderNetworkResponseModel
} from '@models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  public get(planId: number): Observable<IPlanResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}`;
    return this.http.get<IPlanResponseModel>(url);
  }

  public getByGroup(groupId: number): Observable<IPlanResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/plans`;
    return this.http.get<IPlanResponseModel[]>(url);
  }

  public getAll(userId: number): Observable<IPlanResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/partnerUser/${userId}/plans`;
    return this.http.get<IPlanResponseModel[]>(url);
  }

  public createPlan(payerId: number, plan: IPlanPostModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/payer/${payerId}/plan`;
    const body = plan;
    return this.http.post<IPlanResponseModel>(url, body, { observe: 'response' });
  }

  public put(planId: number, plan: IPlanPutModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}`;
    const body = plan;
    return this.http.put<IPlanResponseModel>(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 200) {
            this.snackBar.open(
              'Plan successfully updated!',
              'Close',
              { duration: 3000 }
            );
          }
        })
      );
  }

  public uploadSbcFile(planId: number, file: File): Observable<any> {
    if (file != null) {
      const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/sbc`;
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      return this.http.put<any>(url, formData);
    }
  }

  public uploadSpdFile(planId: number, file: File): Observable<any> {
    if (file != null) {
      const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/spd`;
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      return this.http.put<any>(url, formData);
    }
  }

  public uploadPlanImage(planId: number, file: File): Observable<any> {
    if (file != null) {
      const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/img`;
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      return this.http.put<any>(url, formData);
    }
  }

  public getPlanNetworks(planId: number): Observable<IProviderNetworkResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/networks`;
    return this.http.get<IProviderNetworkResponseModel[]>(url);
  }
}
