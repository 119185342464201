import { Pipe, PipeTransform } from '@angular/core';
import { PlanType } from '@enums';

@Pipe({
  name: 'planType'
})
export class PlanTypePipe implements PipeTransform {

  transform(planTypeCode: string): string {
    // TODO come back and adjust
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let mapping: string = PlanType[planTypeCode];

    if (!mapping) {
      switch (planTypeCode) {
        case 'med': {
          mapping = 'Medical';
          break;
        }
        case 'den': {
          mapping = 'Dental';
          break;
        }
        case 'vis': {
          mapping = 'Vision';
          break;
        }
      }
    }
    return mapping;
  }

}
