import { PhysicianType } from '@enums';
import { CostEstimateResponse, ICostLookupModel } from '@models';
import { createAction, props } from '@ngrx/store';

export const getProcedureCost = createAction(
  '[CostEstimator] Get Procedure Cost',
  props<{ cptCode: string; npi: string; planId: number; personId: number }>()
);

export const getProcedureCostSuccess = createAction(
  '[CostEstimator] Get Procedure Cost Success',
  props<{ result: ICostLookupModel }>()
);

export const getProcedureCostFailure = createAction(
  '[CostEstimator] Get Procedure Cost Failure',
  props<{ error: any }>()
);

export const getProcedureCostEstimate = createAction(
  '[CostEstimator] Get Procedure Cost Estimate',
  props<{
    personId: number; procedureName: string; procedureCost: number;
    cptCode: string; placeOfService: number; physicianType: PhysicianType;
  }>()
);

export const getProcedureCostEstimateSuccess = createAction(
  '[CostEstimator] Get Procedure Cost Estimate Success',
  props<{ result: CostEstimateResponse }>()
);

export const getProcedureCostEstimateFailure = createAction(
  '[CostEstimator] Get Procedure Cost Estimate Failure',
  props<{ error: any }>()
);

export const clearProcedureEstimate = createAction(
  '[CostEstimator] Clear Procedure Estimate'
);
