import { Component } from '@angular/core';

@Component({
  selector: 'mx-progress-spinner-modal',
  templateUrl: './progress-spinner-modal.component.html',
  styleUrls: ['./progress-spinner-modal.component.scss']
})
export class ProgressSpinnerModalComponent {

  constructor() { }
}
