import { Injectable } from '@angular/core';
import {
  IPaymentCardRequestPostModel,
  IPaymentCardRequestStatusPostModel
} from '@models';
import { Store } from '@ngrx/store';
import { PaymentCardRequestActions, PaymentCardRequestSelectors } from '@root-store';
import { AppState } from 'src/app/root-store/root.state';

@Injectable({
  providedIn: 'root'
})
export class PaymentCardFacade {
  public selectedPaymentCardRequest$ = this.store.select(PaymentCardRequestSelectors.selectSelectedPaymentCardRequest);
  public paymentCardRequests$ = this.store.select(PaymentCardRequestSelectors.selectPaymentCardRequests);
  public isSendingPaymentCardRequest$ = this.store.select(PaymentCardRequestSelectors.selectIsSendingPaymentCardRequest);
  public isLoading$ = this.store.select(PaymentCardRequestSelectors.selectIsLoading);

  constructor(
    private store: Store<AppState>
  ) {}

  getPaymentCardRequest(id: number): void {
    this.store.dispatch(PaymentCardRequestActions.loadPaymentCardRequest({ id }));
  }

  getPartnerPaymentCardRequests(userId: number): void {
    this.store.dispatch(PaymentCardRequestActions.loadPaymentCardRequestsByPartner({ userId }));
  }

  getMemberPaymentCardRequests(personId: number): void {
    this.store.dispatch(PaymentCardRequestActions.loadPaymentCardRequestsByMember({ personId }));
  }

  updatePaymentCardRequestStatus(model: IPaymentCardRequestStatusPostModel, paymentCardRequestId: number): void {
    this.store.dispatch(PaymentCardRequestActions.updatePaymentCardRequestStatus({ model, paymentCardRequestId }));
  }

  requestNewPaymentCard(request: IPaymentCardRequestPostModel): void {
    this.store.dispatch(PaymentCardRequestActions.sendPaymentCardRequest({ request }));
  }

  clearPaymentCardRequestStore(): void {
    this.store.dispatch(PaymentCardRequestActions.clearPaymentCardRequests());
  }
}
