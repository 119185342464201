import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpResourceStatus } from '@enums';
import {
  HttpResourcePending,
  IGroupMessagePostModel,
  IMemberMessagePostModel,
  IMessageResponseModel
} from '@models';
import { defer, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  public get(messageId: number): Observable<IMessageResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/message/${messageId}`;
    return this.http.get<IMessageResponseModel>(url);
  }

  public getByUser(userId: number): Observable<IMessageResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/messages`;
    return this.http.get<IMessageResponseModel[]>(url);
  }

  public sendToGroups(message: IGroupMessagePostModel): HttpResourcePending<any> {
    const url = `${environment.config.apiEndpoint}/v2/messages`;
    const body = message;
    const status = new ReplaySubject<HttpResourceStatus>();

    const request = this.http.post(url, body, { observe: 'response' })
      .pipe(
        catchError((error) => {
          status.next(HttpResourceStatus.ERROR);
          return throwError(error);
        }),
        tap((response) => {

          if (response.status === 201) {
            this.snackBar.open(
              'Message successfully sent!',
              'Close',
              { duration: 3000 }
            );
          }

          return status.next(HttpResourceStatus.SUCCESS);
        })
      );

    const data = defer(() => {
      status.next(HttpResourceStatus.LOADING);
      return request;
    });

    return { data, status };
  }

  public sendToMember(userId: number, message: IMemberMessagePostModel): Observable<boolean> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/message`;
    const body = message;

    return this.http.post<IMessageResponseModel>(url, body, { observe: 'response' })
      .pipe(
        map((response) => {
          if (response.status === 201) {
            return true;
          } else {
            return false;
          }
        })
      );
  }

}
