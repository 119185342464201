import { IThreadMemberModel } from './thread-member.model';
import { IThreadPartnerModel } from './thread-partner.model';

export interface IThreadResponseModel {
  id: number;
  assignee: IThreadPartnerModel;
  member: IThreadMemberModel;
  subject: string;
  messageCount: number;
  lastMessageSentDate: string;
  replyAllowed: boolean;
  isRead: boolean;
}
