import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PlanTierService } from '@backend';
import { IExternalNetworkMappingResponseModel, INetworkTierPutModel, IPlanTierPostModel, IPlanTierPutModel } from '@models';
import { PlanTierActions, PlanTierSelectors, PlanTierState } from '@root-store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanTierFacade {
  public planTiers$ = this.store.select(PlanTierSelectors.selectPlanTiers);
  public selectedPlanTierNetworks$ = this.store.select(PlanTierSelectors.selectSelectedPlanTierNetworks);
  public selectedPlanTierExternalNetworks$ = this.store.select(PlanTierSelectors.selectSelectedPlanTierExternalNetworks);
  public selectedPlanTierBenefits$ = this.store.select(PlanTierSelectors.selectSelectedPlanTierBenefits);
  public externalNetworkSuggestions$ = this.store.select(PlanTierSelectors.selectExternalNetworkSuggestions);
  public isCloningPlanTiers$ = this.store.select(PlanTierSelectors.selectIsCloningPlanTiers);

  constructor(
    private store: Store<PlanTierState>,
    private planTierService: PlanTierService
  ) {}

  public loadPlanTiers(planId: number) {
    this.store.dispatch(PlanTierActions.loadPlanTiers({ planId }));
  }

  public createPlanTier(planId: number, planTier: IPlanTierPostModel) {
    this.store.dispatch(PlanTierActions.createPlanTier({ planId, planTier }));
  }

  public updatePlanTier(planId: number, tier: number, planTier: IPlanTierPutModel) {
    this.store.dispatch(PlanTierActions.updatePlanTier({ planId, tier, planTier }));
  }

  public deletePlanTier(planId: number, tier: number) {
    this.store.dispatch(PlanTierActions.deletePlanTier({ planId, tier }));
  }

  public loadPlanTierNetworks(planId: number, tier: number) {
    this.store.dispatch(PlanTierActions.loadPlanTierNetworks({ planId, tier }));
  }

  public addPlanTierNetwork(planId: number, tier: number, networkId: number) {
    this.store.dispatch(PlanTierActions.addPlanTierNetwork({ planId, tier, networkId }));
  }

  public removePlanTierNetwork(planId: number, tier: number, networkId: number) {
    this.store.dispatch(PlanTierActions.removePlanTierNetwork({ planId, tier, networkId }));
  }

  public getExternalNetworkSuggestions(searchTerm: string) {
    this.store.dispatch(PlanTierActions.getExternalNetworkSuggestions({ searchTerm }));
  }

  public loadPlanTierExternalNetworks(planId: number, tier: number) {
    this.store.dispatch(PlanTierActions.loadPlanTierExternalNetworks({ planId, tier }));
  }

  public addPlanTierExternalNetwork(planId: number, tier: number, ribbonInsuranceId: string) {
    this.store.dispatch(PlanTierActions.addPlanTierExternalNetwork({ planId, tier, ribbonInsuranceId }));
  }

  public removePlanTierExternalNetwork(planId: number, tier: number, ribbonInsuranceId: string) {
    this.store.dispatch(PlanTierActions.removePlanTierExternalNetwork({ planId, tier, ribbonInsuranceId }));
  }

  public getPlanTierNetworkMappings(planId: number, tier: number): Observable<IExternalNetworkMappingResponseModel[]>  {
    return this.planTierService.getPlanTierExternalNetworkMappings(planId, tier);
  }

  public updatePlanTierBenefits(planId: number, tier: number, tierBenefits: INetworkTierPutModel) {
    this.store.dispatch(PlanTierActions.updatePlanTierBenefits({ planId, tier, planTierBenefits: tierBenefits }));
  }

  public getPlanTierBenefits(planId: number) {
    this.store.dispatch(PlanTierActions.getPlanTierBenefits({ planId }));
  }

  public clonePlanTiersFromPlan(planId: number, planIdToCloneFrom: number) {
    this.store.dispatch(PlanTierActions.clonePlanTiersFromPlan({ planId, planIdToCloneFrom }));
  }

  public clearPlanTiersState() {
    this.store.dispatch(PlanTierActions.clearPlanTiersState());
  }
}
