<h5 class="procedure-code">Procedure Code: {{ cptCode }}</h5>
<h2 class="title">{{ costEstimate.description }}</h2>
<div *ngIf="providerName" class="provider-title"><strong>{{ providerName }}</strong></div>
<hr class="divider">
<h3>Estimated Cost Using Your Insurance</h3>
<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="estimation-info">
    <span>Total Procedure Cost:</span>
    <span>{{ costEstimate.totalProcedureCost | currency }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="estimation-info">
    <span>Plan Responsibility:</span>
    <span>{{ costEstimate.planResponsibility | currency }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="estimation-info">
    <span>Your Responsibility:</span>
    <span>{{ costEstimate.memberResponsibility | currency }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="estimation-info">
    <span>Remaining Deductible:</span>
    <span>{{ costEstimate.remainingDeductible | currency }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="estimation-info">
    <span>Remaining Out Of Pocket Max:</span>
    <span>{{ costEstimate.remainingOutOfPocketMax | currency }}</span>
  </div>
  <div *ngIf="costEstimate.preAuthRequired" fxLayout="row" fxLayoutAlign="end center" class="prior-auth-required-warning">
    <span>Prior Authorization Required</span>
  </div>

  <hr class="divider">

  <div class="disclaimer">
    <div><strong>Disclaimer:</strong></div>
    Any information related to costs and pricing for healthcare services is strictly an estimate and needs to be verified with respective healthcare providers and insurance plans by the user of such information.
    <div>
      <a class="action-link more-link" (click)="fullDisclaimerVisible = !fullDisclaimerVisible">
        <span *ngIf="!fullDisclaimerVisible">Show full disclaimer</span>
        <span *ngIf="fullDisclaimerVisible">Hide disclaimer</span>
      </a>
    </div>
    <div *ngIf="fullDisclaimerVisible">
      Any information related to price estimates, insurance coverage or similar information provided is based upon data that is made available to Medxoom from various third parties that may include but are not limited to healthcare insurers and their business associates, insurance networks, third party administrators, healthcare providers, and health care facilities. The display of any information is not intended to be, nor shall it be construed as, an endorsement or recommendation of pricing at any particular health care provider or facility. Additionally, such information may also not be construed as an endorsement of any particular health care provider or facility. Medxoom has not undertaken any independent evaluation of any health care provider or facility or product or service or associated price estimates for any products or services whose information is provided. Medxoom disclaims all responsibility or liability for any price estimates, advice, care, treatment, products or services that may be provided by or through a health care provider or facility whose information is provided. Any user of the information is responsible for making its, his or her own decisions regarding health care providers and facilities. Medxoom is not responsible for any injury, illness, damages, losses or other harm caused by or related to the care and treatment provided by a health care provider or health care facility whose information is provided.
    </div>
    </div>
  <div *ngIf="showCloseButton" fxLayout="row" fxLayoutAlign="end center" class="close-button">
    <button pButton class="p-button-outlined" pRipple [mat-dialog-close]>
      Close
    </button>
  </div>
</div>
