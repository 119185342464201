import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTerminology from './terminology.reducer';
import { TerminologyEffects } from './terminology.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTerminology.terminologyFeatureKey, fromTerminology.reducer),
    EffectsModule.forFeature([TerminologyEffects])
  ],
  providers: [TerminologyEffects]
})
export class TerminologyStoreModule { }
