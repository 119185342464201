import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PartnerActions, PartnerSelectors, PartnerState } from '@root-store';
import { IPartnerResponseModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class PartnerFacade {
  // Collections
  public partners$: Observable<IPartnerResponseModel[]> = this.store.select(PartnerSelectors.selectPartners);
  public groupPartners$: Observable<IPartnerResponseModel[]> = this.store.select(PartnerSelectors.selectGroupPartners);

  constructor(private store: Store<PartnerState>) { }


  public loadPartners(): void {
    this.store.dispatch(PartnerActions.loadPartners());
  }

  public loadGroupPartners(groupId: number): void {
    this.store.dispatch(PartnerActions.loadGroupPartners({ groupId }));
  }

  public addGroupPartners(partnerIds: number[], groupId: number): void {
    this.store.dispatch(PartnerActions.addGroupPartners({ partnerIds, groupId }));
  }

  public removeGroupPartners(partnerIds: number[], groupId: number): void {
    this.store.dispatch(PartnerActions.removeGroupPartners({ partnerIds, groupId }));
  }

  public removeSingleGroupPartner(partnerId: number, groupId: number) {
    this.store.dispatch(PartnerActions.removeSingleGroupPartner({ partnerId, groupId }));
  }

  public clonePartnersFromGroup(groupId: number, groupToCloneFromId: number) {
    this.store.dispatch(PartnerActions.clonePartnersFromGroup({ groupId, groupToCloneFromId }));
  }

  public cloneDetailsBrandingFromGroup(groupId: number, groupName: string, brandConfigId: number, groupToCloneFromId: number) {
    this.store.dispatch(PartnerActions.cloneDetailsBrandingFromGroup({ groupId, groupName, brandConfigId, groupToCloneFromId }));
  }

}
