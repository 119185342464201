/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromModule from './message-center.reducer';

export const selectMessageCenterState = createFeatureSelector<fromModule.MessageCenterState>(
  fromModule.messageCenterFeatureKey
);

export const selectThreads = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.threads
);

export const selectSelectedThreadId = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.selectedThreadId
);

export const selectSelectedThread = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.threads?.find(summary => summary.id === state.selectedThreadId)
);

export const selectThreadMessages = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.threadMessages ?
    [...state.threadMessages].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) :
    null
);

export const selectCurrentViewState = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.currentViewState
);

export const selectReplyToThread = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.replyToThread
);

export const selectIsSendingMessage = createSelector(
  selectMessageCenterState,
  (state: fromModule.MessageCenterState) => state.isSendingMessage
);
