<div fxLayout="row" fxLayoutAlign="space-between">
  <h2 mat-dialog-title>{{title}}</h2>
</div>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="statusForm" fxLayout="row wrap">

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>New Status</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="1">Approval Required</mat-option>
        <mat-option [value]="2">Pending</mat-option>
        <mat-option [value]="3">Active</mat-option>
        <mat-option [value]="4">Canceled</mat-option>
        <mat-option [value]="5">Expired</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>Note</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        formControlName="note">
      </textarea>
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" style="margin-bottom: 0;">
  <div>
    <button mat-raised-button mat-dialog-close>{{cancelButtonText}}</button>
    <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="clickUpdateStatus()">{{saveButtonText}}</button>
  </div>
</mat-dialog-actions>
