import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITokenPutModel, ITokenResponseModel } from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  public refreshToken(): Observable<ITokenResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/token`;
    return this.http.get<ITokenResponseModel>(url);
  }

  public login(token: ITokenPutModel): Observable<ITokenResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/token`;
    const body = token;
    return this.http.post<ITokenResponseModel>(url, body);
  }
}
