import { YesNoPipe } from './pipes/yes-no.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { GridsterModule } from 'angular-gridster2';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { BalanceBillDetailsComponent } from './components/balance-bill-details/balance-bill-details.component';
import { BalanceBillImageModalComponent } from './components/balance-bill-image-modal/balance-bill-image-modal.component';
import { BalanceBillListComponent } from './components/balance-bill-list/balance-bill-list.component';
import { BalanceBillStatusModalComponent } from './components/balance-bill-status-modal/balance-bill-status-modal.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
// eslint-disable-next-line max-len
import { HealthServiceRequestStatusModalComponent } from './components/health-service-request-status-modal/health-service-request-status-modal.component';
import { HSRListComponent } from './components/hsr-list/hsr-list.component';
import { NewGroupModalComponent } from './components/new-group-modal/new-group-modal.component';
import { PaymentCardDetailsComponent } from './components/payment-card-details/payment-card-details.component';
import { PaymentCardRequestModalComponent } from './components/payment-card-request-modal/payment-card-request-modal.component';
// eslint-disable-next-line max-len
import { PaymentCardRequestStatusModalComponent } from './components/payment-card-request-status-modal/payment-card-request-status-modal.component';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { ProgressSpinnerModalComponent } from './components/progress-spinner-modal/progress-spinner-modal.component';
import { SelectComponent } from './components/select/select.component';
import { SessionTimeoutModalComponent } from './components/session-timeout-modal/session-timeout-modal.component';
import { SuperBillDetailsComponent } from './components/super-bill-details/super-bill-details.component';
import { SuperBillImageModalComponent } from './components/super-bill-image-modal/super-bill-image-modal.component';
import { SuperBillListComponent } from './components/super-bill-list/super-bill-list.component';
import { SuperBillStatusModalComponent } from './components/super-bill-status-modal/super-bill-status-modal.component';
import { UpdatePasswordModalComponent } from './components/update-password-modal/update-password-modal.component';
import { BaseDestroyComponentComponent } from './components/base-destroy-component/base-destroy-component.component';
import { PageContentComponent } from './containers/page-content/page-content.component';
import { MemberSearchComponent } from './components/member-search/member-search.component';
import { CostEstimatorSummaryComponent } from './components/cost-estimator-summary/cost-estimator-summary.component';
import { RatingComponent } from './components/rating/rating.component';
import { NetworksListComponent } from './components/networks-list/networks-list.component';
import { FeatureFlagDirective, LetDirective, MaterialElevationDirective } from './directives';
import { MaterialModule } from './modules/material.module';
import { PrimengModule } from './modules/primeng.module';
import { BillStatusPipe } from './pipes/bill-status.pipe';
import { ContactTypePipe } from './pipes/contact-type.pipe';
import { CoverageTypePipe } from './pipes/coverage-type.pipe';
import { HsrStatusPipe } from './pipes/hsr-status.pipe';
import { InsertSpacesPipe } from './pipes/insert-spaces.pipe';
import { PaymentCardRequestStatusPipe } from './pipes/payment-card-request-status.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PlanImagePipe } from './pipes/plan-image.pipe';
import { PlanNamePipe } from './pipes/plan-name.pipe';
import { PlanTypePipe } from './pipes/plan-type.pipe';
import { RelationshipPipe } from './pipes/relationship-pipe.pipe';
import { RemovewhitespacesPipe } from './pipes/removewhitespaces.pipe';
import { SuperBillStatusPipe } from './pipes/super-bill-status.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { PermissionFlagDirective } from './directives/permission-flag.directive';
import { TerminologyPipe } from './pipes/terminology.pipe';
import { ClaimStatusPipe } from './pipes/claim-status.pipe';
import { GroupNamePipe } from './pipes/group-name.pipe';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: false,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = ({
  validation: true
});

export const SHARED_COMPONENTS = [
  BalanceBillListComponent,
  BalanceBillDetailsComponent,
  ConfirmationDialogComponent,
  SessionTimeoutModalComponent,
  ProgressSpinnerModalComponent,
  UpdatePasswordModalComponent,
  NotFoundComponent,
  SelectComponent,
  PaymentCardRequestStatusModalComponent,
  PaymentCardRequestModalComponent,
  HealthServiceRequestStatusModalComponent,
  PageContentComponent,
  HSRListComponent,
  BalanceBillStatusModalComponent,
  BalanceBillImageModalComponent,
  PaymentCardDetailsComponent,
  PaymentCardComponent,
  SuperBillListComponent,
  SuperBillDetailsComponent,
  SuperBillStatusModalComponent,
  SuperBillImageModalComponent,
  NewGroupModalComponent,
  BaseDestroyComponentComponent,
  MemberSearchComponent,
  RatingComponent,
  CostEstimatorSummaryComponent,
  NetworksListComponent
];

export const SHARED_DIRECTIVES = [
  LetDirective,
  MaterialElevationDirective,
  FeatureFlagDirective,
  PermissionFlagDirective
];

export const SHARED_PIPES = [
  PlanNamePipe,
  PlanImagePipe,
  PhonePipe,
  ContactTypePipe,
  RemovewhitespacesPipe,
  CoverageTypePipe,
  PlanTypePipe,
  RelationshipPipe,
  BillStatusPipe,
  SuperBillStatusPipe,
  PaymentCardRequestStatusPipe,
  HsrStatusPipe,
  InsertSpacesPipe,
  TrimPipe,
  TerminologyPipe,
  ClaimStatusPipe,
  GroupNamePipe,
  YesNoPipe
];


@NgModule({
  declarations: [
    SHARED_COMPONENTS,
    SHARED_DIRECTIVES,
    SHARED_PIPES
  ],
  imports: [
    PrimengModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    GridsterModule,
    FontAwesomeModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(options),
    NgxMatSelectSearchModule,
    TextMaskModule,
    DragDropModule,
    TextFieldModule,
    DigitOnlyModule,
    RouterModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    PrimengModule,
    SHARED_COMPONENTS,
    SHARED_DIRECTIVES,
    SHARED_PIPES,
    NgxMaskModule,
    NgxCurrencyModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    GridsterModule,
    FontAwesomeModule,
    NgxCurrencyModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    DragDropModule,
    TextFieldModule,
    NgxSkeletonLoaderModule
  ]
})
export class SharedModule { }
