import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { IHSRPostModel } from 'src/app/backend/entities/health-service-request-post.model';
import { IHSRPutModel } from 'src/app/backend/entities/health-service-request-put.model';
import { IHSRStatusPostModel } from 'src/app/backend/entities/health-service-request-status-post.model';
import { DocumentsActions } from '.';
import { IHSRResponseModel } from '../../../backend/entities/health-service-request-response.model';
import { selectAll, selectAttachedSuperBills, selectCurrentRequest, selectUnmaskedCardNumber, State } from './documents.reducer';

@Injectable({
  providedIn: 'root'
})
export class DocumentsFacade {
  public requests$ = this.store.select(selectAll);
  public currentRequest$ = this.store.select(selectCurrentRequest);
  public attachedSuperBills$ = this.store.select(selectAttachedSuperBills);
  public unmaskedCardNumber$ = this.store.select(selectUnmaskedCardNumber);

  constructor(
    private store: Store<State>,
  ) {}

  public createHSR(hsr: IHSRPostModel, requestPaymentCard: boolean) {
    this.store.dispatch(DocumentsActions.createHSR({ hsr, requestPaymentCard }));
  }

  public requestNewCard(hsr: IHSRPutModel, hsrStatus: IHSRStatusPostModel) {
    this.store.dispatch(DocumentsActions.requestNewCard({ hsr, hsrStatus }));
  }

  public updateHSRStatus(hsrStatus: IHSRStatusPostModel, hsrId: number) {
    this.store.dispatch(DocumentsActions.updateHSRStatus({ hsrStatus, hsrId }));
  }

  public setSelectedHSR(id: number) {
    this.store.dispatch(DocumentsActions.setSelectedHSR({ id }));
  }

  public getMemberHSRs(personId: number): Observable<IHSRResponseModel[]> {
    this.store.dispatch(DocumentsActions.getMemberHSRs({ personId }));
    return this.requests$;
  }

  public getPartnerHSRs(userId: number): Observable<IHSRResponseModel[]> {
    this.store.dispatch(DocumentsActions.getPartnerHSRs({ userId }));
    return this.requests$;
  }

  public loadHSRDetails(id: number) {
    this.store.dispatch(DocumentsActions.getHSRDetails({ id }));
  }

  // Rehydrates HSR details data, sets it as selected, and returns an observable of it
  public getHSRDetails(hsrId: number) {
    this.loadHSRDetails(hsrId);
    this.setSelectedHSR(hsrId);
    return this.currentRequest$;
  }

  public loadAttachedSuperBills(id: number) {
    // this.store.dispatch(DocumentsActions.getAttachedSuperBills({id}));
  }

  // Rehydrates attached super bills
  public getAttachedSuperBills(id: number) {
    this.loadAttachedSuperBills(id);
    return this.attachedSuperBills$;
  }
}
