import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { BalanceBillStatus } from '@enums';
import { BalanceBillFacade } from '@facades';
// tslint:disable-next-line: max-line-length
import { IBalanceBillDetailResponseModel, IBalanceBillImageResponseModel, IBalanceBillStatusResponseModel } from '@models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mx-balance-bill-details',
  templateUrl: './balance-bill-details.component.html',
  styleUrls: ['./balance-bill-details.component.scss']
})
export class BalanceBillDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bill: IBalanceBillDetailResponseModel;
  @Input() billImages: IBalanceBillImageResponseModel[];
  @Output() changeStatus = new EventEmitter<BalanceBillStatus>();
  @Output() canDeactivate = new EventEmitter();

  status: number;
  public previousStatus: number;

  public dataSource: MatTableDataSource<IBalanceBillStatusResponseModel>;

  public displayedColumns: string[] = [
    'status',
    'note',
    'createdAt'
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private balanceBillFacade: BalanceBillFacade
  ) { }

  ngOnInit() {
    this.balanceBillFacade.cancelSave
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.status = this.previousStatus;
      });
  }

  ngOnChanges() {
    if (this.bill) {
      this.dataSource = new MatTableDataSource<IBalanceBillStatusResponseModel>(this.bill.statuses);
      this.status = this.bill.statuses[0].status;
      this.previousStatus = this.status;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public onStatusChanged(status: BalanceBillStatus) {
    this.changeStatus.next(status);
  }

  downloadImage(imageId: number, contentType: string, fileName: string) {
    this.balanceBillFacade.getBillImage(this.bill.id, imageId, contentType, fileName);
  }

}
