import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromPaymentCardRequest from './payment-card-request.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PaymentCardRequestEffects } from './payment-card-request.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPaymentCardRequest.paymentCardRequestFeatureKey, fromPaymentCardRequest.reducer),
    EffectsModule.forFeature([PaymentCardRequestEffects])
  ]
})
export class PaymentCardRequestStoreModule { }
