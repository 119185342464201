import { RelationshipType } from '@enums';
import { IPolicyResponseModel, IUserAccountResponseModel } from '@models';

export interface IMemberDetailsResponseModel {
  personId: number;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  relationship: RelationshipType;
  dependents: IMemberDetailsResponseModel[];
  policies: IPolicyResponseModel[];
  userIds: number[];

  accounts?: IUserAccountResponseModel[];
}
