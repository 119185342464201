<div fxLayout="row" fxLayoutAlign="space-between">
  <h2 mat-dialog-title>Claim Status Update</h2>
</div>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="statusForm" fxLayout="row wrap">

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>New Status</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="5">Received</mat-option>
        <mat-option [value]="2">In Progress</mat-option>
        <mat-option [value]="3">Declined</mat-option>
        <mat-option [value]="4">Completed</mat-option>
        <mat-option [value]="6">Resubmit</mat-option>
        <mat-option [value]="0">Unknown</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>Note</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        formControlName="note">
      </textarea>
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <div>
    <button mat-raised-button mat-dialog-close (click)="clickCancelUpdateStatus()">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="clickUpdateStatus()">Save</button>
  </div>
</mat-dialog-actions>
