import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, RouterState } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mx-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss']
})
export class PageContentComponent implements OnDestroy {
  public title: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router
  ) {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-') ;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private getTitle(routerState: RouterState, parent: ActivatedRoute): Array<string> {
    const data: Array<string> = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title as string);
    }

    if (routerState && parent) {
      data.push(... this.getTitle(routerState, parent.firstChild));
    }
    return data;
  }

}
