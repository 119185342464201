import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromModules from './analytics.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AnalyticsEffects } from './analytics.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromModules.analyticsFeatureKey, fromModules.reducer),
    EffectsModule.forFeature([AnalyticsEffects])
  ]
})
export class AnalyticsStoreModule { }
