import { ISuperBillMedicalServiceResponseModel } from './super-bill-medical-service-response.model';
import { ISuperBillStatusResponseModel } from './super-bill-status-response.model';

export interface ISuperBillDetailResponseModel {
  id: number;
  userId: number;
  memberFirstName: string;
  memberLastName: string;
  memberContactEmail: string;
  memberContactPhone: string;
  memberDateOfBirth: string;
  memberGroupNumber: string;
  memberMemberNumber: string;
  memberPolicyId: number;
  memberPersonId:  number;
  patientFirstName: string;
  patientLastName: string;
  patientDateOfBirth: string;
  patientGroupNumber: string;
  patientMemberNumber: string;
  patientPersonId: string;
  patientPolicyId: string;
  providerAddressLine1: string;
  providerAddressLine2: string;
  providerCity: string;
  providerPostalCode: string;
  providerState: string;
  providerName: string;
  providerNpi: string;
  providerTin: string;
  totalAmountCharged: number;
  totalAmountPaid: number;
  billDate: string;
  healthServiceRequestId: number;
  isHospitalBill: boolean;
  medicalServices: ISuperBillMedicalServiceResponseModel[];
  statuses: ISuperBillStatusResponseModel[];
  createdAt: string;
  updatedAt: string;
}
