<mat-card *ngIf="bill">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div id="PatientName" style="margin-bottom: 10px; font-size: 24px; font-weight: bold">
            {{ bill.patientFirstName }} {{ bill.patientLastName }}
          </div>

          <strong>Member Name</strong>
          <div id="MemberName" style="margin-bottom: 10px;">
            {{ bill.memberFirstName }} {{ bill.memberLastName }}
          </div>

          <strong>Submitted On</strong>
          <div id="CreatedAt" style="margin-bottom: 10px;">
            {{ bill.createdAt | date:'short' }}
          </div>

          <strong>Contact</strong>
          <div id="MemberEmail">
            <a [href]="'mailto:' + bill.memberContactEmail">{{ bill.memberContactEmail }}</a>
          </div>
          <div id="MemberPhone" style="margin-bottom: 10px;">
            <a [href]="'tel:' + bill.memberContactPhone">{{ bill.memberContactPhone | phone:'US' }}</a>
          </div>
        </div>

        <mat-card fxFlex="50" >
          <strong>Bill Images</strong>
          <div *ngIf="billImages.length > 0;else noImages">
            <div *ngFor="let image of billImages" fxLayout="row" fxLayoutAlign="space-between">
              <span>
                {{ image.fileName }}
              </span>
              <button mat-icon-button
                color="primary"
                (click)="downloadImage(image.id, image.fileType, image.fileName)"
                matTooltip="Download"
                matTooltipPosition="below">
                <mat-icon>get_app</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div fxFlex="100">
      <h2>Status History</h2>
      <div style="margin-bottom: -20px;">
        <form>
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>
              Current Status
            </mat-label>
            <mat-select [(value)]="status" (selectionChange)="onStatusChanged($event.value)">
              <mat-option [value]="1">New</mat-option>
              <mat-option [value]="5">Received</mat-option>
              <mat-option [value]="2">In Progress</mat-option>
              <mat-option [value]="3">Declined</mat-option>
              <mat-option [value]="4">Completed</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef width="25%"> Status </th>
          <td mat-cell *matCellDef="let row" width="25%">{{ row.status | billStatus }} </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef width="50%"> Note </th>
          <td mat-cell *matCellDef="let row" width="50%"> {{ row.note }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef width="25%"> Date </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{ row.createdAt | date:'short' }}</td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-card>


<ng-template #noImages>
  <div>
    No images submitted for this bill.
  </div>
</ng-template>
