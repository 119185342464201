import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromInsurance from './insurance.reducer';
import { EffectsModule } from '@ngrx/effects';
import { InsuranceEffects } from './insurance.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromInsurance.insuranceFeatureKey, fromInsurance.reducer),
    EffectsModule.forFeature([InsuranceEffects])
  ],
  providers: [InsuranceEffects]
})
export class InsuranceStoreModule { }
