import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { IHSRResponseModel } from 'src/app/backend/entities/health-service-request-response.model';
import { IHSRPutModel } from 'src/app/backend/entities/health-service-request-put.model';
import { DocumentsFacade } from 'src/app/modules/documents/documents-store/documents.facade';
import { IHSRStatusPostModel } from 'src/app/backend/entities/health-service-request-status-post.model';
import { HSRStatus } from '@enums';

@Component({
  selector: 'mx-payment-card-request-modal',
  templateUrl: './payment-card-request-modal.component.html',
  styleUrls: ['./payment-card-request-modal.component.scss']
})
export class PaymentCardRequestModalComponent implements OnInit {
  public requestForm: FormGroup;
  public title = 'Update Status';
  public cancelButtonText = 'Cancel';
  public saveButtonText = 'Save';
  public invalidCardExpirationDate = false as boolean;
  public invalidCardAvailableDate = false as boolean;
  public minCardExpirationDate: Date;
  public minCardAvailableDate: Date = moment().startOf('day').toDate();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      saveButtonText?: string;
      cancelButtonText?: string;
      hsr: IHSRResponseModel;
    },
    public dialogRef: MatDialogRef<PaymentCardRequestModalComponent>,
    private fb: FormBuilder,
    private documentsFacade: DocumentsFacade
  ) { }

  ngOnInit() {
    this.requestForm = this.fb.group({
      cardAmount: null,
      cardAvailableDate: moment(),
      cardExpirationDate: moment().endOf('month'),
      visibleToMember: true
    });

    if (this.data.cancelButtonText) {
      this.cancelButtonText = this.data.cancelButtonText;
    }

    if (this.data.saveButtonText) {
      this.saveButtonText = this.data.saveButtonText;
    }
  }

  public changeAvailableDate(event: MatDatepickerInputEvent<Date>) {
    if (this.requestForm.value.cardAvailableDate != null) {
      this.invalidCardAvailableDate = false;
      this.requestForm.patchValue({
        cardExpirationDate: moment((this.requestForm.value as IHSRPutModel).cardAvailableDate).endOf('month')
      });
      this.minCardExpirationDate = moment((this.requestForm.value as IHSRPutModel).cardAvailableDate).add(1, 'days').toDate();
    }
  }

  public submit() {
    const hsr = this.data.hsr;
    if (this.requestForm.valid) {
      const hsrPutModel: IHSRPutModel = {
        id: hsr.id,
        partnerUserId: hsr.partnerUserId,
        memberPersonId: hsr.memberPersonId,
        groupId: hsr.groupId,
        groupNumber: hsr.groupNumber,

        memberNumber: hsr.memberNumber,
        memberFirstName: hsr.memberFirstName,
        memberLastName: hsr.memberLastName,
        memberDateOfBirth: hsr.memberDateOfBirth,
        memberPolicyId: hsr.memberPolicyId,

        subscriberPersonId: hsr.subscriberPersonId,
        subscriberFirstName: hsr.subscriberFirstName,
        subscriberLastName: hsr.subscriberLastName,
        subscriberDateOfBirth: hsr.subscriberDateOfBirth,
        subscriberGroupNumber: hsr.subscriberGroupNumber,
        subscriberMemberNumber: hsr.subscriberMemberNumber,
        subscriberPolicyId: hsr.subscriberPolicyId,

        cardAmount: this.requestForm.get('cardAmount').value,
        cardAvailableDate: moment.utc(this.requestForm.get('cardAvailableDate').value as string).format('YYYY-MM-DD'),
        cardExpirationDate: moment.utc(this.requestForm.get('cardExpirationDate').value as string).format('YYYY-MM-DD'),

        procedureInfo: hsr.procedureInfo,
        cptCode: hsr.cptCode,

        providerName: hsr.providerName,
        providerAddress1: hsr.providerAddress1,
        providerAddress2: hsr.providerAddress2,
        providerCity: hsr.providerCity,
        providerState: hsr.providerState,
        providerPostalCode: hsr.providerPostalCode,
        providerCountryCode: hsr.providerCountryCode,

        visibleToMember: this.requestForm.get('visibleToMember').value,

        providerNPI: hsr.providerNPI,
        providerTIN: hsr.providerTIN,

        dateOfService: hsr.dateOfService,
        estimatedCost: hsr.estimatedCost,

        questionnaireResponses: hsr.questionnaireResponses,

        bundleListingPricingTier: hsr.bundleListingPricingTier,
        bundleListingId: hsr.bundleListingId,
      };

      const hsrStatus: IHSRStatusPostModel = {
        status: HSRStatus.CardRequested,
        note: ''
      };

      this.documentsFacade.requestNewCard(hsrPutModel, hsrStatus);
      this.dialogRef.close();
    }
  }

}
