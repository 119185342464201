import { Vendor } from '@enums';

export interface IBalanceBillSettingsModel {
  vendorId: Vendor;
  vendorName: string;
  logoUri: string;
  supportPhoneNumber: string;
  supportEmail: string;
  menuItemText: string;
  shortDescription: string;
  longDescription: string;
  balanceBillHealthServiceRequestAssociationRequired: boolean;
  partnerNotificationSubscribers: number[];
}
