import { RelationshipType } from '@enums';

export interface IMemberSearchResponseModel {
  personId: number;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  relationship: RelationshipType;
  groupId: number;
}
