import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BalanceBillStatus } from '@enums';
import { IBalanceBillResponseModel } from '@models';
import { BalanceBillImageModalComponent } from '../balance-bill-image-modal/balance-bill-image-modal.component';
// tslint:disable-next-line: max-line-length
import { BalanceBillStatusModalComponent } from '../balance-bill-status-modal/balance-bill-status-modal.component';

@Component({
  selector: 'mx-balance-bill-list',
  templateUrl: './balance-bill-list.component.html',
  styleUrls: ['./balance-bill-list.component.scss']
})
export class BalanceBillListComponent implements OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() groupId: number;
  @Input() bills: IBalanceBillResponseModel[];
  @Input() isLoading = true;

  public status = 'new';
  public displayedColumns: string[] = [
    'patientFirstName',
    'patientLastName',
    'memberContactPhone',
    'memberContactEmail',
    'status',
    'createdAt',
    'icon'
  ];

  public dataSource: MatTableDataSource<IBalanceBillResponseModel>;
  public hasBills = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnChanges() {
    if (this.bills) {
      this.bills = this.bills.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);
      this.dataSource = new MatTableDataSource<IBalanceBillResponseModel>(this.bills);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    this.hasBills = this.bills != null && this.bills.length > 0;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openImages(billId: number) {
    this.dialog.open(BalanceBillImageModalComponent, {
      data: {
        billId
      }
    });
  }

  public openBill(billId: number) {
    void this.router.navigate([`./${billId}`], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve'
    });
  }

  public onStatusChanged(e: MatSelectChange, id: number) {
    this.dialog.open(BalanceBillStatusModalComponent, {
      data: {
        billId: id,
        status: e.value as BalanceBillStatus
      }
    });
  }
}
