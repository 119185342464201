import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResourceStatus } from '@enums';
import { HttpResourcePending, IClaimResponseModel, IClaimTransaction } from '@models';
import { defer, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient
  ) { }

  public getClaims(userId: number): Observable<IClaimResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/claims`;
    return this.http.get<IClaimResponseModel[]>(url, this.httpOptions);
  }

  public getClaimsByPolicy(policyId: number): Observable<IClaimResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/policy/${policyId}/claims`;
    return this.http.get<IClaimResponseModel[]>(url, this.httpOptions);
  }

  public getClaim(claimId: number): HttpResourcePending<IClaimResponseModel> {
    const status = new ReplaySubject<HttpResourceStatus>();
    const url = `${environment.config.apiEndpoint}/v2/claim/${claimId}`;

    const request = this.http.get<IClaimResponseModel>(url, this.httpOptions)
      .pipe(
        catchError((error) => {
          status.next(HttpResourceStatus.ERROR);
          return throwError(error);
        }),
        tap(() => status.next(HttpResourceStatus.SUCCESS))
      );

    const data = defer(() => {
      status.next(HttpResourceStatus.LOADING);
      return request;
    });

    return { data, status };
  }

  public getClaimTransactions(userId: number): Observable<IClaimTransaction[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/transactions`;
    return this.http.get<IClaimTransaction[]>(url, this.httpOptions);
  }
}
