import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SubmitServiceRequestService {


  constructor(
    private fb: FormBuilder,
  ) { }

  public createRequestForm(): FormGroup {
    return this.fb.group({
      memberForm: this.fb.group({
        selectedPolicyId: null,
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        personId: null,
        groupNumber: ['', Validators.required],
        memberNumber: ['', Validators.required]
      }),
      patientForm: this.fb.group({
        selectedPolicyId: null,
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        personId: null,
        groupNumber: ['', Validators.required],
        memberNumber: ['', Validators.required]
      }),
      providerForm: this.fb.group({
        name: ['', Validators.required],
        address1: ['', Validators.required],
        address2: '',
        city: ['', Validators.required],
        state: ['', Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2)
        ])],
        postalCode: ['', Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])],
        npi: ''
      }),
      procedureForm: this.fb.group({
        estimatedCharge: [null, Validators.compose([
          Validators.required,
          Validators.maxLength(8)
        ])],
        cptCode: '',
        dateOfService: ['', Validators.required],
        procedureInfo: ['', Validators.required]
      }),
      paymentCardForm: this.fb.group({
        requestPaymentCard: false,
        cardAmount: null,
        cardAvailableDate: moment().toDate(),
        cardExpirationDate: moment().endOf('month').toDate(),
        visibleToMember: true
      })
    });
  }
}
