/* eslint-disable @typescript-eslint/no-unused-vars */

import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, switchMap, tap } from 'rxjs/operators';

import { BrandConfigService, GroupService, PartnerService } from '@backend';
import { InsuranceFacade } from '@facades';
import * as PartnerActions from './partner.actions';
import { AppState } from '../root.state';

@Injectable({
  providedIn: 'root'
})
export class PartnerEffects {

  loadPartners$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.loadPartners),
    switchMap(() => this.partnerService.getPartners()
      .pipe(
        map(partners => {
          const sortedPartners = partners.sort((a, b) => (a.name > b.name) ? 1 : -1);
          return PartnerActions.loadPartnersSuccess({ partners: sortedPartners });
        }),
        catchError(error => of(PartnerActions.loadPartnersFailure({ error })))
      ))
  ));

  loadGroupPartners$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.loadGroupPartners),
    switchMap(action => this.partnerService.getGroupPartners(action.groupId)
      .pipe(
        map(groupPartners => {
          const sortedGroupPartners = groupPartners.sort((a, b) => (a.name > b.name) ? 1 : -1);
          return PartnerActions.loadGroupPartnersSuccess({ groupPartners: sortedGroupPartners });
        }),
        catchError(error => of(PartnerActions.loadGroupPartnersFailure({ error })))
      ))
  ));

  addGroupPartners$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.addGroupPartners),
    switchMap(action => {
      const requests: Observable<any>[] = action.partnerIds.map(partnerId =>
        this.partnerService.addGroupPartner(partnerId, action.groupId));

      return forkJoin(requests).pipe(defaultIfEmpty(null), map(() => action.groupId));
    }),
    map(groupId => PartnerActions.addGroupPartnersSuccess({ groupId })),
    catchError(error => of(PartnerActions.addGroupPartnersFailure({ error })))
  ));

  removeGroupPartners$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.removeGroupPartners),
    switchMap(action => {
      const requests: Observable<any>[] = action.partnerIds.map(x =>
        this.partnerService.removeGroupPartner(x, action.groupId));

      return forkJoin(requests).pipe(defaultIfEmpty(null), map(() => action.groupId));
    }),
    map(groupId => PartnerActions.removeGroupPartnersSuccess({ groupId })),
    catchError(error => of(PartnerActions.removeGroupPartnersFailure({ error })))
  ));

  updatedGroupPartners$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.addGroupPartnersSuccess, PartnerActions.removeGroupPartnersSuccess),
    map(action => PartnerActions.loadGroupPartners({ groupId: action.groupId })),
    tap(() => {
      this.snackBar.open(
        'Group partners successfully updated!',
        'Close',
        { duration: 3000 }
      );
    })
  ));

  removeSingleGroupPartner$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.removeSingleGroupPartner),
    switchMap(action => this.partnerService.removeGroupPartner(action.partnerId, action.groupId)
      .pipe(
        map(() => PartnerActions.removeSingleGroupPartnerSuccess()),
        tap(() => {
          this.snackBar.open(
            'Group partners successfully updated!',
            'Close',
            { duration: 3000 }
          );
          this.store.dispatch(PartnerActions.loadGroupPartners({ groupId: action.groupId }));
        }),
        catchError(error => of(PartnerActions.removeSingleGroupPartnerFailure({ error })))
      ))
  ));

  clonePartnersFromGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.clonePartnersFromGroup),
    switchMap(action => this.partnerService.getGroupPartners(action.groupToCloneFromId)
      .pipe(
        map(groupPartners => {
          if (groupPartners?.length > 0) {
            const partnerIds = groupPartners.map(partner => partner.id);
            return PartnerActions.addGroupPartners({ groupId: action.groupId, partnerIds });
          } else {
            const errorMessage = 'The selected group has no partners to clone from.';
            this.showSnackBar(errorMessage);
            return PartnerActions.clonePartnersFromGroupFailure({ error: errorMessage });
          }
        }),
        catchError(error => of(PartnerActions.clonePartnersFromGroupFailure({ error })))
      ))
  ));

  cloneDetailsBrandingFromGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.cloneDetailsBrandingFromGroup),
    switchMap(action => forkJoin([
      this.pipeErrorAsUndefined(this.groupService.get(action.groupToCloneFromId)),
      this.pipeErrorAsUndefined(this.brandService.getByGroup(action.groupToCloneFromId)),
      of(action)
    ])),
    switchMap(([group, brandConfig, action]) => {
      const defaultErrorMessage = 'There was an error trying to clone the details and brand configuration from the group.';

      if (group && brandConfig) {
        const updatedGroup = {
          ...group,
          name: action.groupName,
          startDate: undefined,
          terminationDate: undefined,
          billingCycleDay: undefined
        };
        return forkJoin([
          this.pipeErrorAsUndefined(this.groupService.put(action.groupId, updatedGroup)),
          this.pipeErrorAsUndefined(this.brandService.update(action.brandConfigId, {
            primaryLogoUri: brandConfig.primaryLogoUri,
            primaryColorHex: brandConfig.primaryColorHex,
          }))
        ]).pipe(map(([groupResponse, brandConfigResponse]) => {
          if (groupResponse === undefined || brandConfigResponse === undefined) {
            return PartnerActions.cloneDetailsBrandingFromGroupFailure({ error: defaultErrorMessage });
          } else {
            return PartnerActions.cloneDetailsBrandingFromGroupSuccess({ groupId: action.groupId });
          }
        }));
      } else if (group === undefined || brandConfig === undefined) {
        return of(PartnerActions.cloneDetailsBrandingFromGroupFailure({ error: defaultErrorMessage }));
      } else {
        const errorMessage = 'The selected group has no details or brand configuration to clone from.';
        return of(PartnerActions.cloneDetailsBrandingFromGroupFailure({ error: errorMessage }));
      }
    })
  ));

  cloneDetailsBrandingFromGroupSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.cloneDetailsBrandingFromGroupSuccess),
    tap(action => {
      this.insuranceFacade.loadBrandingByGroup(action.groupId);
      this.insuranceFacade.loadGroup(action.groupId);
      this.showSnackBar('Group details and brand configuration successfully updated!');
    })
  ), { dispatch: false });

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private actions$: Actions,
    private partnerService: PartnerService,
    private groupService: GroupService,
    private brandService: BrandConfigService,
    private insuranceFacade: InsuranceFacade,
    private store: Store<AppState>
  ) { }

  private showSnackBar(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 10000 }
    );
  }

  private pipeErrorAsUndefined(request: Observable<any>) {
    return request.pipe(catchError(() => undefined));
  }

}
