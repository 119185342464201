import { Pipe, PipeTransform } from '@angular/core';
import { TerminologyFacade } from '@facades';
import { Observable } from 'rxjs';

@Pipe({
  name: 'terminology'
})
export class TerminologyPipe implements PipeTransform {

  constructor(
    private terminologyFacade: TerminologyFacade
  ) { }

  transform(value: number, ...args: unknown[]): Observable<string> {
    if (value) {
      return this.terminologyFacade.getTerminologyTerm(value);
    }
  }

}
