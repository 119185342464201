import { Pipe, PipeTransform } from '@angular/core';
import { RelationshipType } from '@enums';

@Pipe({
  name: 'relationshipPipe'
})
export class RelationshipPipe implements PipeTransform {

  transform(relationshipTypeCode: RelationshipType): any {
    switch (relationshipTypeCode) {
      case RelationshipType.Self:
        return 'Subscriber';
        break;
      case RelationshipType.Spouse:
        return 'Spouse';
        break;
      case RelationshipType.Child:
      case RelationshipType.ChildWhereInsuredHasNoFinancialResponsibility:
      case RelationshipType.StepsonOrStepdaughter:
      case RelationshipType.FosterChild:
        return 'Child';
        break;
      case RelationshipType.WardOfTheCourt:
      case RelationshipType.Employee:
      case RelationshipType.Unknown:
      case RelationshipType.HandicappedDependent:
      case RelationshipType.OrganDonor:
      case RelationshipType.CadaverDonor:
      case RelationshipType.GrandsonOrGrandaughter:
      case RelationshipType.NephewOrNiece:
      case RelationshipType.InjuredPlaintiff:
      case RelationshipType.SponsoredDependent:
      case RelationshipType.DependentOfMinorDependent:
      case RelationshipType.GrandfatherOrGrandmother:
      default:
        return 'Dependent';
    }
  }

}
