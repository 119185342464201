import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import {
  IMeMdConfigBaseModel, IMeMdConfigResponseModel,
  IMeMdConfigViewModel, IModuleConfigPostModel,
  IModuleConfigPutModel, IModuleConfigResponseModel,
  IModuleConfigViewModel,
  IModuleResponseModel
} from '@models';
import { MeMdConfigService, ModuleConfigService } from '@backend';
import { AppState } from '../root.state';
import * as ModuleConfigActions from './module-config.actions';
import { selectSelectedGroupId } from '../insurance-store/insurance.selectors';
import { selectAllModules } from './module-config.selectors';
import { ModuleType } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ModuleConfigEffects {

  loadAllModules = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.loadAllModules),
    switchMap(_ => this.moduleConfigService.getAllModules()),
    filter(allModules => !!allModules),
    map((allModules: IModuleResponseModel[]) => ModuleConfigActions.loadAllModulesSuccess({ allModules }))
  ));

  loadModulesConfigsByGroup = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.loadModulesConfigsByGroup),
    filter(data => !!data.groupId),
    switchMap((data) => this.moduleConfigService.getByGroup(data.groupId)),
    map((moduleConfigs: IModuleConfigResponseModel[]) => ModuleConfigActions.loadModulesConfigsByGroupSuccess({ moduleConfigs }))
  ));

  loadMeMdConfigs = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.loadMeMdConfigsByPlans),
    switchMap((data) => {
      const obsArray: Observable<IMeMdConfigResponseModel>[] = [];
      data.planIds.forEach(planId => {
        obsArray.push(
          this.meMdConfigService.getByPlan(planId).pipe(map(res => res), catchError(() => of(undefined)))
        );
      });
      return forkJoin(obsArray);
    }),
    map((meMdConfigs: IMeMdConfigResponseModel[]) => meMdConfigs.filter(meMdConfig => meMdConfig !== undefined)),
    map((meMdConfigs: IMeMdConfigResponseModel[]) => ModuleConfigActions.loadMeMdConfigsByPlansSuccess({ meMdConfigs }))
  ));

  createModuleConfig$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.createModuleConfig),
    switchMap((data) => this.moduleConfigService.post(data.moduleConfig)
      .pipe(
        map((moduleConfig) => {
          this.showSnackBar('Module enabled successfully!');
          this.store.dispatch(ModuleConfigActions.loadModulesConfigsByGroup({ groupId: moduleConfig.groupId }));
          return ModuleConfigActions.createModuleConfigSuccess({ moduleConfig });
        }),
        catchError(error => {
          const errorMessage = `An error ocurred trying to add the module.. ${ error as string }`;
          this.showSnackBar(errorMessage);
          return of(ModuleConfigActions.createModuleConfigFailure({ error }));
        })
      ))
  ));

  updateModuleConfig$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.updateModuleConfig),
    switchMap((data) => this.moduleConfigService.put(data.moduleConfig)
      .pipe(
        map((moduleConfig) => {
          this.showSnackBar('Module was updated successfully!');
          this.store.dispatch(ModuleConfigActions.loadModulesConfigsByGroup({ groupId: moduleConfig.groupId }));
          return ModuleConfigActions.updateModuleConfigSuccess({ moduleConfig });
        }),
        catchError(error => {
          const errorMessage = `An error ocurred trying to update the module. ${ error as string }`;
          this.showSnackBar(errorMessage);
          return of(ModuleConfigActions.updateModuleConfigFailure({ error }));
        })
      ))
  ));

  reorderModuleConfigs$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.reorderModuleConfigs),
    switchMap((data) => {
      const obsArray: Array<Observable<IModuleConfigResponseModel>> = [];

      data.moduleConfigs.forEach((module, index) => {
        obsArray.push(
          this.moduleConfigService.put(module)
        );
      });

      return forkJoin(obsArray).pipe(
        map(() => {
          this.showSnackBar('Modules reordered successfully!');
          this.store.dispatch(ModuleConfigActions.loadModulesConfigsByGroup({ groupId: data.groupId }));
          return ModuleConfigActions.reorderModuleConfigsSuccess();
        }),
        catchError(error => {
          const errorMessage = `An error ocurred trying to reorder modules. ${ error as string }`;
          this.showSnackBar(errorMessage);
          return of(ModuleConfigActions.reorderModuleConfigsFailure({ error }));
        })
      );
    })
  ));

  saveMeMdModuleAndConfigs$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.saveMeMdModuleAndConfigs),
    switchMap((data) => {
      if (data.meMdConfigs.length === 0) {
        this.createOrUpdateMeMdModule(data.moduleConfig);
        return of();
      } else {
        const obsArray: Observable<IMeMdConfigResponseModel>[] = [];

        data.meMdConfigs.forEach((meMdConfig: IMeMdConfigViewModel) => {
          const meMdConfigModel: IMeMdConfigBaseModel = {
            username: meMdConfig.username,
            password: meMdConfig.password,
            clientId: meMdConfig.clientId,
            clientSecret: meMdConfig.clientSecret,
            planCode: meMdConfig.planCode
          };

          if (meMdConfig.id === null) {
            obsArray.push(this.meMdConfigService.post(meMdConfig.planId, meMdConfigModel));
          } else {
            obsArray.push(this.meMdConfigService.put(meMdConfig.id, meMdConfigModel));
          }
        });

        return forkJoin(obsArray).pipe(
          map((resp: IMeMdConfigResponseModel[]) => {
            this.createOrUpdateMeMdModule(data.moduleConfig);
            return ModuleConfigActions.saveMeMdModuleAndConfigsSuccess({ meMdConfigs: resp });
          }),
          catchError(error => {
            const errorMessage = `An error ocurred trying to update one or more MeMD configs. ${ error as string }`;
            this.showSnackBar(errorMessage);
            return of(ModuleConfigActions.saveMeMdModuleAndConfigsFailure({ error }));
          })
        );
      }
    })
  ));

  public deleteMeMdConfig$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.deleteMeMdConfig),
    switchMap((data) => this.meMdConfigService.delete(data.meMdConfigId)
      .pipe(
        map(() => {
          this.showSnackBar('MeMD configuration removed from plan successfully!');
          return ModuleConfigActions.deleteMeMdConfigSuccess();
        }),
        catchError(error => {
          const errorMessage = `An error ocurred trying to remove MeMD configuration from plan. ${ error as string }`;
          this.showSnackBar(errorMessage);
          return of(ModuleConfigActions.deleteMeMdConfigFailure({ error }));
        })
      ))
  ));

  cloneModuleConfigsFromGroup$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.cloneModuleConfigsFromGroup),
    switchMap(action => this.moduleConfigService.getByGroup(action.groupId)),
    withLatestFrom(this.store.select(selectSelectedGroupId), this.store.select(selectAllModules)),
    switchMap(([moduleConfigs, groupId, allConfigs]) => {
      moduleConfigs = moduleConfigs.filter(moduleConfig => {
        const module = allConfigs.find(x => x.type === moduleConfig.type);
        const isExternalLinkModule = module?.type === ModuleType.ExternalLink;
        return module && (module.isConfigurable || isExternalLinkModule);
      });

      if (moduleConfigs.length > 0) {
        const requests: Observable<IModuleConfigResponseModel>[] = moduleConfigs.map(moduleConfig => this.moduleConfigService.post({
          type: moduleConfig.type,
          order: moduleConfig.order,
          settings: moduleConfig.settings,
          groupId
        }).pipe(
          catchError(() => of(undefined))
        ));

        return forkJoin(requests).pipe(
          map(responses => ModuleConfigActions.cloneModuleConfigsFromGroupSuccess({ responses, groupId }))
        );
      } else {
        this.showSnackBar('The selected group has no available modules to clone from.');
        return of(ModuleConfigActions.cloneModuleConfigsFromGroupFailure());
      }
    }),
    catchError(error => {
      const errorMessage = `An error ocurred trying to clone module configurations. ${ error as string }`;
      this.showSnackBar(errorMessage);
      return of(ModuleConfigActions.cloneModuleConfigsFromGroupFailure());
    })
  ));

  cloneModuleConfigsFromGroupSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleConfigActions.cloneModuleConfigsFromGroupSuccess),
    switchMap(action => {
      const failedResponses = action.responses.filter(response => response === undefined);
      if (failedResponses.length > 0) {
        this.showSnackBar(`${failedResponses.length} module configuration(s) could not be cloned.`);
      } else {
        this.showSnackBar('Module configurations cloned successfully.');
      }

      return of(ModuleConfigActions.loadModulesConfigsByGroup({ groupId: action.groupId }));
    })
  ));

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private actions$: Actions,
    private moduleConfigService: ModuleConfigService,
    private meMdConfigService: MeMdConfigService
  ) { }

  private createOrUpdateMeMdModule(moduleConfig: IModuleConfigViewModel) {
    if (moduleConfig.id != null) {
      const moduleConfigPutModel: IModuleConfigPutModel = {
        id: moduleConfig.id,
        order: moduleConfig.order,
        settings: moduleConfig.settings
      };
      this.store.dispatch(ModuleConfigActions.updateModuleConfig({ moduleConfig: moduleConfigPutModel }));
    } else {
      const moduleConfigPostModel: IModuleConfigPostModel = {
        groupId: moduleConfig.groupId,
        type: moduleConfig.type,
        order: moduleConfig.order,
        settings: moduleConfig.settings
      };
      this.store.dispatch(ModuleConfigActions.createModuleConfig({ moduleConfig: moduleConfigPostModel }));
    }
  }

  private showSnackBar(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 10000 }
    );
  }
}
