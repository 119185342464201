import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBenefits from './benefits.reducer';

export const selectBenefitsState = createFeatureSelector<fromBenefits.BenefitsState>(
  fromBenefits.benefitsFeatureKey
);

export const selectSelectedBenefitSummary = createSelector(
  selectBenefitsState,
  (state: fromBenefits.BenefitsState) => state.selectedBenefitSummary
);

export const selectIsLoadingBenefitSummary = createSelector(
  selectBenefitsState,
  (state: fromBenefits.BenefitsState) => state.isLoadingBenefitSummary
);

export const selectSelectedPolicy = createSelector(
  selectBenefitsState,
  (state: fromBenefits.BenefitsState) => state.selectedPolicy
);

export const selectIsSharingIdCard = createSelector(
  selectBenefitsState,
  (state: fromBenefits.BenefitsState) => state.isSharingCard
);
