import { Component, Input } from '@angular/core';
import { CostEstimateResponse } from '@models';

@Component({
  selector: 'mx-cost-estimator-summary',
  templateUrl: './cost-estimator-summary.component.html',
  styleUrls: ['./cost-estimator-summary.component.scss']
})
export class CostEstimatorSummaryComponent {
  @Input() cptCode: string;
  @Input() costEstimate: CostEstimateResponse;
  @Input() providerName: string;
  @Input() showCloseButton: boolean = true;

  public fullDisclaimerVisible: boolean = false;

  constructor() { }

}
