import { FormControl, Validators } from '@angular/forms';
import { IHsrQuestion } from './health-service-request-settings.model';

export class HealthServiceRequestQuestionFormModel {
  public Id = new FormControl();
  public Title = new FormControl();
  public Html = new FormControl();
  public Type = new FormControl();

  constructor(question: IHsrQuestion) {
    this.Id.setValue(question.Id);
    this.Id.setValidators([Validators.required]);

    this.Title.setValue(question.Title);
    this.Title.setValidators([Validators.required, Validators.maxLength(150)]);

    this.Html.setValue(question.Html);
    this.Html.setValidators([Validators.required]);

    this.Type.setValue(question.Type);
    this.Type.setValidators([Validators.required]);
  }
}
