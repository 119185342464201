import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mx-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnChanges {
  @Input() label = 'Mx Select';
  @Input() type = 'single';
  @Input() options: Array<{ label: string; val: any; selected: boolean }>;
  @Output() selectChange = new EventEmitter<any>();

  public selectCtrl = new FormControl();

  constructor() { }

  ngOnChanges() {
    if (this.options) {
      const initialOption = this.options.find(option => option.selected);
      const selectNewValue = initialOption?.val || this.options[0].val;
      this.selectCtrl.setValue(selectNewValue);
      this.valueChange(+selectNewValue);
    }
  }

  valueChange(valChange: number) {
    this.selectChange.emit(valChange);
  }

}
