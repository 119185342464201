import { IProfilePutModel, IProfileResponseModel } from '@models';
import { createAction, props } from '@ngrx/store';

export const loadProfile = createAction(
  '[User] Load Profile'
);

export const loadProfileSuccess = createAction(
  '[User] Load Profile Success',
  props<{ data: IProfileResponseModel }>()
);

export const loadProfileFailure = createAction(
  '[User] Load Profile Failure',
  props<{ error: any }>()
);

export const loadProfileWithProfileId = createAction(
  '[User] Load Profile with Profile ID',
  props<{ profileId: number }>()
);

export const loadGroupId = createAction(
  '[User] Load Group Id',
  props<{ planId: number }>()
);

export const loadGroupIdSuccess = createAction(
  '[User] Load Group Id Success',
  props<{ groupId: number }>()
);

export const loadGroupIdFailure = createAction(
  '[User] Load Group Id Failure'
);

export const resetPassword = createAction(
  '[User] Reset Password',
  props<{ username: string }>()
);

export const resetMemberPassword = createAction(
  '[User] Reset Member Password',
  props<{ username: string }>()
);

export const resetPasswordSuccess = createAction(
  '[User] Reset Password Success'
);

export const resetPasswordFailure = createAction(
  '[User] Reset Password Failure'
);

export const clearProfile = createAction(
  '[User] Clear Profile'
);

export const updateProfile = createAction(
  '[User] Update Profile',
  props<{ profileId: number; updatedProfile: IProfilePutModel }>()
);

export const updateProfileSuccess = createAction(
  '[User] Update Profile Success',
  props<{ updatedProfile: IProfileResponseModel }>()
);

export const updateProfileFailure = createAction(
  '[User] Update Profile Failure',
  props<{ error: any }>()
);

export const updatePassword = createAction(
  '[User] Update Password',
  props<{ oldPassword: string; newPassword: string }>()
);

export const updatePasswordSuccess = createAction(
  '[User] Update Password Success'
);

export const impersonateUser = createAction(
  '[User] Impersonate User',
  props<{ targetUserId: number; clientToken: string }>()
);

export const impersonateUserFailure = createAction(
  '[User] Impersonate User Failure'
);
