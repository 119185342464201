import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModuleConfigService } from '@backend';
import { ChatType, ClaimsProcessingVendor, ModuleType, PaymentGateway, Vendor } from '@enums';
import {
  HealthServiceRequestQuestionFormModel,
  IBalanceBillSettingsModel, IChatSettingsModel, IEzPaySettingsModel,
  IHealthServiceRequestSettingsModel, IHsrQuestion, IMeMdConfigResponseModel, IMeMdConfigViewModel,
  IModuleConfigPostModel, IModuleConfigPutModel, IExternalLinkSettingsModel,
  IModuleConfigResponseModel, IModuleConfigViewModel, IModuleResponseModel,
  IProviderSearchSettingsModel, ISpcSettingsModel, ISuperBillSettingsModel, MeMdConfigFormModel,
  IMagellanSettingsModel, IProviderSearchStandaloneModel
} from '@models';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take } from 'rxjs';
import * as ModuleConfigActions from 'src/app/root-store/module-config-store/module-config.actions';
import { ModuleConfigState } from 'src/app/root-store/module-config-store/module-config.reducer';
import * as ModuleConfigSelectors from 'src/app/root-store/module-config-store/module-config.selectors';

@Injectable({
  providedIn: 'root'
})
export class ModuleConfigFacade {

  public isSaving$: Observable<boolean> = this.store.select(ModuleConfigSelectors.selectIsSaving);
  public hasSavedChangesSuccessfully$: Observable<boolean> = this.store.select(ModuleConfigSelectors.selectHasSavedChangesSuccessfully);
  public hasDeletedMeMdConfigSuccessfully$: Observable<boolean> =
    this.store.select(ModuleConfigSelectors.selectHasDeletedMeMdConfigSuccessfully);

  public allModules$: Observable<IModuleResponseModel[]> = this.store.select(ModuleConfigSelectors.selectAllModules);
  public selectedGroupModuleConfigs$: Observable<IModuleConfigResponseModel[]> =
    this.store.select(ModuleConfigSelectors.selectSelectedGroupModuleConfigs);
  public groupMeMdConfigs$: Observable<IMeMdConfigResponseModel[]> =
    this.store.select(ModuleConfigSelectors.selectSelectedGroupMeMdConfigs);

  constructor(
    private store: Store<ModuleConfigState>,
    private fb: FormBuilder,
    private moduleConfigService: ModuleConfigService
  ) { }

  public loadAllModules(): void {
    this.store.dispatch(ModuleConfigActions.loadAllModules());
  }

  public loadModuleConfigsByGroup(groupId: number): void {
    this.store.dispatch(ModuleConfigActions.loadModulesConfigsByGroup({ groupId }));
  }

  public createModuleConfig(moduleConfig: IModuleConfigViewModel): void {
    const moduleConfigPostModel: IModuleConfigPostModel = {
      groupId: moduleConfig.groupId,
      type: moduleConfig.type,
      order: moduleConfig.order,
      settings: moduleConfig.settings
    };
    this.store.dispatch(ModuleConfigActions.createModuleConfig({ moduleConfig: moduleConfigPostModel }));
  }

  public updateModuleConfig(moduleConfig: IModuleConfigViewModel): void {
    const moduleConfigPutModel: IModuleConfigPutModel = {
      id: moduleConfig.id,
      order: moduleConfig.order,
      settings: moduleConfig.settings
    };
    this.store.dispatch(ModuleConfigActions.updateModuleConfig({ moduleConfig: moduleConfigPutModel }));
  }

  public reorderModuleConfigs(groupId: number, moduleConfigs: IModuleConfigViewModel[]): void {
    const moduleConfigPutModels: IModuleConfigPutModel[] = [];
    moduleConfigs.forEach((moduleConfig, index) => {
      moduleConfigPutModels.push({
        id: moduleConfig.id,
        order: index + 1,
        settings: moduleConfig.settings
      });
    });
    this.store.dispatch(ModuleConfigActions.reorderModuleConfigs({ groupId, moduleConfigs: moduleConfigPutModels }));
  }

  public deleteModuleConfig(groupId: number, moduleConfigId: number): void {
    this.moduleConfigService.delete(moduleConfigId)
      .subscribe(() => {
          this.loadModuleConfigsByGroup(groupId);
        }
      );
    this.store.dispatch(ModuleConfigActions.deleteModuleConfig({ moduleConfigId }));
  }

  public saveMeMdModuleAndConfigs(moduleConfig: IModuleConfigViewModel, meMdConfigFormArray: FormArray) {
    const meMdConfigs = meMdConfigFormArray.value as IMeMdConfigViewModel[];
    this.store.dispatch(ModuleConfigActions.saveMeMdModuleAndConfigs({ moduleConfig, meMdConfigs }));
  }

  public deleteMeMdConfig(meMdConfigId: number) {
    this.store.dispatch(ModuleConfigActions.deleteMeMdConfig({ meMdConfigId }));
  }

  public clearSavedSuccessfullyState(): void {
    this.store.dispatch(ModuleConfigActions.clearSavedSuccessfullyState());
  }

  public clearDeletedMeMdConfigSuccessfullyState(): void {
    this.store.dispatch(ModuleConfigActions.clearDeletedMeMdConfigSuccessfullyState());
  }

  public clearModuleConfigState(): void {
    this.store.dispatch(ModuleConfigActions.clearModuleConfigState());
  }


  public setupSpcForm(settings: ISpcSettingsModel): FormGroup {
    const commaSeparatedUserIdString = settings?.paymentCardRequestSummaryEmailRecipientUserIds != null ?
      settings.paymentCardRequestSummaryEmailRecipientUserIds.join(',') : '';

    return this.fb.group({
      conciergeNumber: [settings?.conciergeNumber ?? '', Validators.required],
      supportNumber: [settings?.supportNumber ?? '', Validators.required],
      paymentCardRequestSummaryEmailRecipientUserIds: [commaSeparatedUserIdString]
    });
  }

  public setupEzPayForm(settings: IEzPaySettingsModel): FormGroup {
    return this.fb.group({
      paymentGateway: [settings?.paymentGateway ?? PaymentGateway.StripeGateway]
    });
  }

  public setupBalanceBillForm(settings: IBalanceBillSettingsModel): FormGroup {
    const commaSeparatedUserIdString = settings?.partnerNotificationSubscribers != null ?
      settings.partnerNotificationSubscribers.join(',') : '';

    return this.fb.group({
      vendorId: [settings?.vendorId ?? Vendor.RbpDemo, Validators.required],
      vendorName: [settings?.vendorName ?? 'RbpDemo', [Validators.required, Validators.maxLength(64)]],
      logoUri: [settings?.logoUri ?? 'http://', [Validators.required, Validators.maxLength(1024)]],
      supportPhoneNumber: [settings?.supportPhoneNumber ?? '', Validators.maxLength(10)],
      supportEmail: [settings?.supportEmail ?? '', Validators.maxLength(128)],
      menuItemText: [settings?.menuItemText ?? 'Balance Bills', [Validators.required, Validators.maxLength(20)]],
      shortDescription: [settings?.shortDescription ?? '', Validators.maxLength(128)],
      longDescription: [settings?.longDescription ?? '', Validators.maxLength(512)],
      balanceBillHealthServiceRequestAssociationRequired:
        [settings?.balanceBillHealthServiceRequestAssociationRequired ?? false],
      partnerNotificationSubscribers: [commaSeparatedUserIdString]
    });
  }

  public setupSuperBillForm(settings: ISuperBillSettingsModel): FormGroup {
    const commaSeparatedUserIdString = settings?.superBillPartnerNotificationSubscribers != null ?
      settings.superBillPartnerNotificationSubscribers.join(',') : '';
    return this.fb.group({
      superBillHealthServiceRequestAssociationRequired: [settings?.superBillHealthServiceRequestAssociationRequired ?? false],
      enableClaimsProcessing: [settings?.enableClaimsProcessing ?? false],
      claimsProcessingVendor: [settings?.claimsProcessingVendor ?? ClaimsProcessingVendor.None],
      claimFormInstructions: [settings?.claimFormInstructions ?? ''],
      superBillPartnerNotificationSubscribers: [commaSeparatedUserIdString]
    });
  }

  public setupChatForm(settings: IChatSettingsModel): FormGroup {
    return this.fb.group({
      chatType: [settings?.chatType ?? ChatType.Unspecified],
      chatHtmlSnippet: [settings?.chatHtmlSnippet ?? ''],
      externalChatScript: [settings?.externalChatScript ?? ''],
      inlineChatScript: [settings?.inlineChatScript ?? ''],
      chatStyle: [settings?.chatStyle ?? '']
    });
  }

  public setupHealthServiceRequestForm(settings: IHealthServiceRequestSettingsModel): FormGroup {
    var settingsForm = this.fb.group({
      questionnaireQuestions: this.fb.array([]),
      eventNotificationsEnabled: [settings?.eventNotificationsEnabled ?? false],
      webHookEndpointUri: [settings?.webHookEndpointUri ?? '', [
        this.conditionalValidator(() => settingsForm.get('eventNotificationsEnabled').value === true, Validators.required)
      ]],
      authorizationToken: [settings?.authorizationToken ?? '', [
        this.conditionalValidator(() => settingsForm.get('eventNotificationsEnabled').value === true, Validators.required)
      ]],
      authorizationKey: [settings?.authorizationKey ?? '', [
        this.conditionalValidator(() => settingsForm.get('eventNotificationsEnabled').value === true, Validators.required)
      ]],
      partnerReviewRequiredEstimatedCostThreshold: [
        settings?.partnerReviewRequiredEstimatedCostThreshold ?? null,
        Validators.required
      ]
    });

    settings?.questionnaireQuestions?.forEach(question => {
      var questionnaireQuestions = settingsForm.get('questionnaireQuestions') as FormArray;
      this.addHsrQuestion(questionnaireQuestions, question);
    });

    return settingsForm;
  }

  public setupHealthServiceRequestFormCustomValidation(settingsForm: FormGroup) {
    settingsForm.get('eventNotificationsEnabled').valueChanges
      .subscribe(() => {
        settingsForm.get('webHookEndpointUri').updateValueAndValidity();
        settingsForm.get('authorizationToken').updateValueAndValidity();
        settingsForm.get('authorizationKey').updateValueAndValidity();
      });
  }

  public addHsrQuestion(questionnaireQuestions: FormArray, question: IHsrQuestion) {
    questionnaireQuestions.push(
      this.fb.group(new HealthServiceRequestQuestionFormModel(question))
    );
  }

  public setupProviderSearchForm(settings: IProviderSearchSettingsModel): FormGroup {
    return this.fb.group({
      legalMessage: [settings?.legalMessage ?? ''],
      enableProcedureSearch: [settings?.enableProcedureSearch ?? false]
    });
  }

  public enableMeMdConfig(meMdConfigs: FormArray, meMdConfig: IMeMdConfigResponseModel) {
    meMdConfigs.push(
      this.fb.group(new MeMdConfigFormModel(meMdConfig))
    );
  }

  public setupMeMdForm(meMdConfigModels: IMeMdConfigResponseModel[]): FormGroup {
    const settingsForm = this.fb.group({
      meMdConfigs: this.fb.array([])
    });

    var meMdConfigFormArray = settingsForm.get('meMdConfigs') as FormArray;

    meMdConfigModels.forEach(meMdConfig => {
      meMdConfigFormArray.push(
        this.fb.group(new MeMdConfigFormModel(meMdConfig))
      );
    });

    return settingsForm;
  }

  public setupProviderSearchStandalone(settings: IProviderSearchStandaloneModel): FormGroup {
    return this.fb.group({
      providerSearchStandaloneLegalMessage: [settings?.providerSearchStandaloneLegalMessage ?? ''],
      enableProcedureCostEstimation: [settings?.enableProcedureCostEstimation ?? false],
      enableProcedureFirstCaa: [settings?.enableProcedureFirstCaa ?? false]
    });
  }

  public loadMeMdConfiguration(planIds: number[]) {
    this.store.dispatch(ModuleConfigActions.loadMeMdConfigsByPlans({ planIds }));
  }

  public clearMeMdConfiguration() {
    this.store.dispatch(ModuleConfigActions.clearSelectedGroupMeMdConfigsState());
  }

  public setupExternalLinkForm(settings: IExternalLinkSettingsModel): FormGroup {
    return this.fb.group({
      menuName: [settings?.menuName ?? ''],
      uri: [settings?.uri ?? ''],
      externalLinkLogoUri: [settings?.externalLinkLogoUri ?? '', Validators.maxLength(512)],
      externalLinkDescription: [settings?.externalLinkDescription ?? '', Validators.maxLength(170)],
      externalLinkShortDescription: [settings?.externalLinkShortDescription ?? '', Validators.maxLength(64)],
      buttonCta: [settings?.buttonCta ?? '', Validators.maxLength(64)]
    });
  }

  public setupMagellanForm(settings: IMagellanSettingsModel): FormGroup {
    return this.fb.group({
      targetEligibilityPlanId: [settings?.targetEligibilityPlanId ?? 0]
    });
  }

  public cloneModuleConfigsFromGroup(groupId: number) {
    this.store.dispatch(ModuleConfigActions.cloneModuleConfigsFromGroup({ groupId }));
  }

  private conditionalValidator(predicate: any, validator: any): any {
    return ((formControl: FormControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl) as object;
      }
      return null;
    });
  }

}
