import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ISuperBillResponseModel } from '@models';
import { SuperBillImageModalComponent } from '../super-bill-image-modal/super-bill-image-modal.component';
import { SuperBillStatusModalComponent } from '../super-bill-status-modal/super-bill-status-modal.component';

@Component({
  selector: 'mx-super-bill-list',
  templateUrl: './super-bill-list.component.html',
  styleUrls: ['./super-bill-list.component.scss']
})
export class SuperBillListComponent implements OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() groupId: number;
  @Input() bills: ISuperBillResponseModel[];
  @Input() isLoading = true;

  public status = 'new';
  public displayedColumns: string[] = [
    'patientFirstName',
    'patientLastName',
    'memberContactPhone',
    'memberContactEmail',
    'status',
    'createdAt',
    'icon'
  ];

  public dataSource: MatTableDataSource<ISuperBillResponseModel>;
  public hasBills = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnChanges() {
    if (this.bills) {
      this.bills = this.bills.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);
      this.dataSource = new MatTableDataSource<ISuperBillResponseModel>(this.bills);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    this.hasBills = this.bills != null && this.bills.length > 0;
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openImages(billId: number): void {
    this.dialog.open(SuperBillImageModalComponent, {
      data: {
        billId
      }
    });
  }

  public openBill(billId: number): void {
    void this.router.navigate([`./${billId}`], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve'
    });
  }

  public onStatusChanged(e: MatSelectChange, id: number): void {
    this.dialog.open(SuperBillStatusModalComponent, {
      data: {
        billId: id,
        status: e.value as string
      }
    });
  }
}
