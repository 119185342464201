import { IAutoCompleteProcedureResponseModel } from '@models';
import { IAutoCompleteProviderResponseModel } from './auto-complete-provider-response.model';

export interface ISearchPostModel {
  searchTerm: string;
  planIds: number[];
  latitude: number;
  longitude: number;
  radius: number;
  size: number;
  from: number;
  autocompleteInput: string;
  autocompleteSelection: IAutoCompleteProviderResponseModel | IAutoCompleteProcedureResponseModel;
  targetPersonId: number;
  actingUserId?: number;
  portal?: string;
  device?: string;
  browser?: string;
  specialtyIds?: string[];
  facilityType?: string;
  minConfidence: number;
  minLocationConfidence: number;
}
