import { Injectable } from '@angular/core';
import { PaymentCardService } from '@backend';
import { IPaymentCardRequestPostModel } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectUserId } from 'src/app/root-store/auth-store/auth.selectors';
import { AppState } from '../root.state';
import * as PaymentCardRequestActions from './payment-card-request.actions';

@Injectable({
  providedIn: 'root'
})
export class PaymentCardRequestEffects {

  loadPaymentCardRequest$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCardRequestActions.loadPaymentCardRequest),
    switchMap((data) => this.paymentCardService.getPaymentCardRequest(data.id)),
    filter(paymentCardRequest => !!paymentCardRequest),
    map((paymentCardRequest) => PaymentCardRequestActions.loadPaymentCardRequestSuccess({ paymentCardRequest })),
    catchError(error => of(PaymentCardRequestActions.loadPaymentCardRequestFailure({ error })))
  ));

  loadPaymentCardRequestsByPartner$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCardRequestActions.loadPaymentCardRequestsByPartner),
    switchMap((data) => this.paymentCardService.getPaymentCardRequestsByPartner(data.userId)),
    map((paymentCardRequests) => PaymentCardRequestActions.loadPaymentCardRequestsByPartnerSuccess({ paymentCardRequests })),
    catchError(error => of(PaymentCardRequestActions.loadPaymentCardRequestsByPartnerFailure({ error })))
  ));

  loadPaymentCardRequestsByMember$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCardRequestActions.loadPaymentCardRequestsByMember),
    switchMap((data) => this.paymentCardService.getPaymentCardRequestsByMember(data.personId)),
    map((paymentCardRequests) => PaymentCardRequestActions.loadPaymentCardRequestsByMemberSuccess({ paymentCardRequests })),
    catchError(error => of(PaymentCardRequestActions.loadPaymentCardRequestsByMemberFailure({ error })))
  ));

  updatePaymentCardRequestStatus$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCardRequestActions.updatePaymentCardRequestStatus),
    switchMap((data) => this.paymentCardService.updatePaymentCardRequestStatus(data.model, data.paymentCardRequestId)
      .pipe(
        switchMap((paymentCardRequest) => of(PaymentCardRequestActions.updatePaymentCardRequestStatusSuccess({ paymentCardRequest }))),
        catchError(error => of(PaymentCardRequestActions.updatePaymentCardRequestStatusFailure({ error })))
      ))
  ));

  sendPaymentCardRequest$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCardRequestActions.sendPaymentCardRequest),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([data, userId]) => {
      const request = JSON.parse(JSON.stringify(data.request)) as IPaymentCardRequestPostModel;
      request.partnerUserId = +userId;
      return this.paymentCardService.requestPaymentCard(request)
        .pipe(
          switchMap(() => of(PaymentCardRequestActions.sendPaymentCardRequestSuccess())),
          catchError(error => of(PaymentCardRequestActions.sendPaymentCardRequestFailure({ error })))
        );
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private paymentCardService: PaymentCardService
  ) { }

}
