import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProfilePutModel, IProfileResponseModel } from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient
  ) { }

  public get(profileId: number): Observable<IProfileResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/profile/${profileId}`;
    return this.http.get<IProfileResponseModel>(url);
  }

  public getByUser(userId: number): Observable<IProfileResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/profile`;
    return this.http.get<IProfileResponseModel>(url);
  }

  public update(profileId: number, profile: IProfilePutModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/profile/${profileId}`;
    const body = profile;
    return this.http.put<IProfileResponseModel>(url, body);
  }

}
