<div class="modal-container">
  <h2>Update Password</h2>
  <form [formGroup]="passwordForm">
    <div fxLayout="column">
      <mat-form-field class="form-input" id="currentPassword">
        <input type="password" matInput placeholder="Current Password" formControlName="currentPassword">
      </mat-form-field>

      <mat-form-field class="form-input" id="newPassword">
        <input type="password" matInput placeholder="New Password" formControlName="newPassword">
      </mat-form-field>

      <mat-form-field class="form-input" id="confirmNewPassword">
        <input type="password" matInput placeholder="Confirm New Password" formControlName="confirmNewPassword">
      </mat-form-field>
    </div>

    <span *ngIf="!passwordForm.valid" class="error-text">
      <div *ngIf="passwordForm?.errors?.mismatch">
        Passwords don't match.
      </div>
    </span>

    <div fxLayout="row" fxLayoutAlign="end">
      <button mat-raised-button
        [disabled]="!passwordForm.valid"
        color="primary"
        class="form-button form-button-top"
        (click)="updatePassword()">
        Update Password
      </button>
    </div>
  </form>
</div>
