import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './root.state';

// feature store modules
import { ProfileStoreModule } from './profile-store/profile-store.module';
import { InsuranceStoreModule } from './insurance-store/insurance-store.module';
import { PersonStoreModule } from './person-store/person-store.module';
import { AuthStoreModule } from './auth-store/auth-store.module';
import { BenefitsStoreModule } from './benefits-store';
import { CostEstimatorStoreModule } from './cost-estimator-store';
import { AnalyticsStoreModule } from './analytics-store/analytics-store.module';
import { ReportingStoreModule } from './reporting-store/reporting-store.module';
import { PaymentCardRequestStoreModule } from './payment-card-request-store/payment-card-request-store.module';
import { ModuleConfigStoreModule } from './module-config-store/module-config-store.module';
import { TerminologyStoreModule } from './terminology-store';
import { PartnerStoreModule } from './partner-store';
import { MessageCenterStoreModule } from './message-center-store';
import { PlanTierStoreModule } from './plan-tier-store';


@NgModule({
  declarations: [],
  imports: [
    AnalyticsStoreModule,
    CommonModule,
    PlanTierStoreModule,
    ProfileStoreModule,
    InsuranceStoreModule,
    PersonStoreModule,
    AuthStoreModule,
    BenefitsStoreModule,
    CostEstimatorStoreModule,
    ReportingStoreModule,
    PaymentCardRequestStoreModule,
    ModuleConfigStoreModule,
    TerminologyStoreModule,
    PartnerStoreModule,
    MessageCenterStoreModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    EffectsModule.forRoot([])
  ]
})
export class RootStoreModule { }
