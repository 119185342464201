import { Pipe, PipeTransform } from '@angular/core';
import { CoverageType } from '@enums';

@Pipe({
  name: 'coverageType'
})
export class CoverageTypePipe implements PipeTransform {

  transform(coverageTypeCode: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return CoverageType[coverageTypeCode];
  }

}
