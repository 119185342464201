/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, createReducer, on } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import { IProfileResponseModel } from '@models';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  user: IProfileResponseModel;
  groupId: number;
  isUpdatingPassword: boolean;
}

export const initialProfileState: ProfileState = {
  user: null,
  groupId: null,
  isUpdatingPassword: false
};

export const profileReducer = createReducer(
  initialProfileState,

  on(ProfileActions.loadProfile, state => state),
  on(ProfileActions.loadProfileSuccess, (state, action) => ({ ...state, user: action.data })),
  on(ProfileActions.loadProfileFailure, (state, action) => state),
  on(ProfileActions.loadGroupId, state => state),
  on(ProfileActions.loadGroupIdSuccess, (state, action) => ({ ...state, groupId: action.groupId })),
  on(ProfileActions.loadGroupIdFailure, state => state),
  on(ProfileActions.clearProfile, state => Object.assign({}, initialProfileState)),
  on(ProfileActions.updateProfileSuccess, (state, action) => ({ ...state, user: action.updatedProfile })),
  on(ProfileActions.updateProfileFailure, state => state),
  on(ProfileActions.updatePassword, (state, action) => ({ ...state, isUpdatingPassword: true })),
  on(ProfileActions.updatePasswordSuccess, (state, action) => ({ ...state, isUpdatingPassword: false }))

);

export function reducer(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}
