import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/root-store/root.state';
import { SearchActions } from 'src/app/modules/search/search-store';
import {
  selectAutoCompleteSearchResults, selectAutoCompleteSearchResultsError,
  selectAutoCompleteSpecialties, selectCommonSpecialties, selectFacilityTypes,
  selectPlanTierFilterOptions, selectSearchResults, selectSelectedProviderOrFacilityDetails
} from 'src/app/modules/search/search-store/search.reducer';
import { IAutoCompletePostModel } from '../models/auto-complete-post.model';
import { ISearchPostModel } from '../models/search-post.model';
import { SearchType } from '@enums';
import { IProcedureSearchPostModel } from '../models/procedure-search-post.model';
import { CostEstimateResponse } from '../models/cost-estimate-response.model';

@Injectable({
  providedIn: 'root'
})
export class SearchFacade {

  public commonSpecialties$ = this.store.select(selectCommonSpecialties);
  public autoCompleteSpecialties$ = this.store.select(selectAutoCompleteSpecialties);
  public facilityTypes$ = this.store.select(selectFacilityTypes);
  public selectedProviderOrFacilityDetails$ = this.store.select(selectSelectedProviderOrFacilityDetails);
  public searchResults$ = this.store.select(selectSearchResults);
  public autoCompleteSearchResults$ = this.store.select(selectAutoCompleteSearchResults);
  public autoCompleteSearchResultsError$ = this.store.select(selectAutoCompleteSearchResultsError);
  public planTierFilterOptions$ = this.store.select(selectPlanTierFilterOptions);

  constructor(
    private store: Store<AppState>
  ) {}

  runProcedureAutoCompleteSearch(searchTerm: string) {
    this.store.dispatch(SearchActions.runProcedureAutoCompleteSearch({ searchTerm }));
  }

  runProviderAutoCompleteSearch(autoCompletePostModel: IAutoCompletePostModel) {
    this.store.dispatch(SearchActions.runProviderAutoCompleteSearch({ autoCompletePostModel }));
  }

  runFacilityAutoCompleteSearch(autoCompletePostModel: IAutoCompletePostModel) {
    this.store.dispatch(SearchActions.runFacilityAutoCompleteSearch({ autoCompletePostModel }));
  }

  runProviderFacilityProcedureSearch(searchCriteria: ISearchPostModel | IProcedureSearchPostModel, searchType: SearchType, firebaseEventBody: any) {
    this.store.dispatch(SearchActions.runProviderFacilityProcedureSearch({
      searchCriteria, searchType, firebaseEventBody
    }));
  }

  clearSearchResults() {
    this.store.dispatch(SearchActions.clearSearchResults());
  }

  getCommonSpecialties() {
    this.store.dispatch(SearchActions.getCommonSpecialties());
  }

  getAutoCompleteSpecialties(searchTerm: string) {
    this.store.dispatch(SearchActions.getAutoCompleteSpecialties({ searchTerm }));
  }

  getFacilityTypes() {
    this.store.dispatch(SearchActions.getFacilityTypes());
  }

  getSearchResultDetails(searchType: SearchType, facilityId: string, npi: string, searchTerm: string, procedureCostEstimate?: CostEstimateResponse) {
    this.store.dispatch(SearchActions.getSearchResultDetails({ searchType, facilityId, npi, searchTerm, procedureCostEstimate }));
  }

  clearSelectedProviderOrFacilityDetails() {
    this.store.dispatch(SearchActions.clearSelectedProviderOrFacilityDetails());
  }

  getPlanTierOptionsForSearch(groupId: number) {
    this.store.dispatch(SearchActions.getPlanTierOptionsForSearch({ groupId }));
  }

  providerHasMetrics(provider) {
    return provider?.npi && provider?.qualityMetrics?.overallQuality !== null && provider?.qualityMetrics?.overallQuality !== undefined;
  }

}
