import { MessageCenterViewState } from '@enums';
import {
  IThreadMessageResponseModel,
  IThreadResponseModel
} from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as MessageCenterActions from './message-center.actions';

export const messageCenterFeatureKey = 'messageCenter';

export interface MessageCenterState {
  threads: IThreadResponseModel[];
  selectedThreadId?: number;
  threadMessages: IThreadMessageResponseModel[];
  currentViewState: MessageCenterViewState;
  replyToThread: boolean;
  isSendingMessage: boolean;
}

export const initialMessageCenterState: MessageCenterState = {
  threads: null,
  selectedThreadId: null,
  threadMessages: null,
  currentViewState: MessageCenterViewState.ThreadsList,
  replyToThread: false,
  isSendingMessage: false
};

const messageCenterReducer = createReducer(
  initialMessageCenterState,

  on(MessageCenterActions.loadThreadsSuccess, (state, action) => {
    const updatedMessages = state.threads ? [...state.threads, ...action.threads] : action.threads;
    return {
      ...state,
      threads: updatedMessages
    };
  }),
  on(MessageCenterActions.setSelectedThreadId, (state, action) => ({ ...state, selectedThreadId: action.threadId })),
  on(MessageCenterActions.loadThreadMessages, (state) => ({ ...state, threadMessages: null })),
  on(MessageCenterActions.loadThreadMessagesSuccess, (state, action) => {
    const threadsCopy: IThreadResponseModel[] = JSON.parse(JSON.stringify(state.threads));
    const foundThreadIndex = threadsCopy.findIndex(thread => thread.id === action.threadId);
    if (foundThreadIndex >= 0) {
      threadsCopy[foundThreadIndex].isRead = true;
    }
    return {
      ...state,
      threads: threadsCopy,
      threadMessages: action.threadMessages
    };
  }),
  on(MessageCenterActions.clearThreads, (state) => ({
    ...state, threadMessages: null,
    selectedThreadId: null,
    threads: null
  })),
  on(MessageCenterActions.clearThreadMessages, (state) => ({
    ...state,
    threadMessages: null,
    selectedThreadId: null
  })),
  on(MessageCenterActions.setCurrentViewState, (state, action) => ({ ...state, currentViewState: action.viewState })),
  on(MessageCenterActions.toggleRepliesAllowedSuccess, (state, action) => {
    const threadsCopy: IThreadResponseModel[] = JSON.parse(JSON.stringify(state.threads));
    const foundThreadIndex = threadsCopy.findIndex(thread => thread.id === action.thread.id);
    if (foundThreadIndex >= 0) {
      threadsCopy[foundThreadIndex].replyAllowed = action.thread.replyAllowed;
    }
    return {
      ...state,
      threads: threadsCopy
    };
  }),
  on(MessageCenterActions.archiveThreadSuccess, (state, action) => {
    const threadsCopy: IThreadResponseModel[] = JSON.parse(JSON.stringify(state.threads));
    const foundThreadIndex = threadsCopy.findIndex(thread => thread.id === action.threadId);
    if (foundThreadIndex >= 0) {
      threadsCopy.splice(foundThreadIndex, 1);
    }
    return {
      ...state,
      threads: threadsCopy,
      threadMessages: []
    }
  }),
  on(MessageCenterActions.replyThreadMessageSuccess, (state, action) => {
    const threadsCopy: IThreadResponseModel[] = JSON.parse(JSON.stringify(state.threads));
    const foundThreadIndex = threadsCopy.findIndex(thread => thread.id === action.threadId);
    if (foundThreadIndex >= 0) {
      threadsCopy[foundThreadIndex].messageCount += 1;
    }
    return {
      ...state,
      threads: threadsCopy,
      replyToThread: false
    }
  }),
  on(MessageCenterActions.setReplyToThread, (state, action) => ({ ...state, replyToThread: action.replyToThread })),
  on(MessageCenterActions.replyThreadMessage, MessageCenterActions.sendNewMessage,
    (state) => ({ ...state, isSendingMessage: true })),
  on(MessageCenterActions.replyThreadMessageSuccess, MessageCenterActions.replyThreadMessageFailure,
    MessageCenterActions.sendNewMessageSuccess, MessageCenterActions.sendNewMessageFailure,
    (state) => ({ ...state, isSendingMessage: false })),
);

export function reducer(state: MessageCenterState | undefined, action: Action) {
  return messageCenterReducer(state, action);
}
