export interface CostEstimateResponse {
  memberResponsibility: number;
  planResponsibility: number;
  remainingDeductible: number;
  remainingOutOfPocketMax: number;
  totalProcedureCost: number;
  preAuthRequired: boolean;
  code?: string;
  description?: string;
}
