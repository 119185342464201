import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromPartner from './partner.reducer';
import { PartnerEffects } from './partner.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPartner.partnerFeatureKey, fromPartner.reducer),
    EffectsModule.forFeature([PartnerEffects])
  ],
  providers: [PartnerEffects]
})
export class PartnerStoreModule { }
