import { createFeatureSelector, createSelector, props } from '@ngrx/store';
import * as fromPlanTier from './plan-tier.reducer';

export const selectPlanTierState = createFeatureSelector<fromPlanTier.PlanTierState>(
  fromPlanTier.planTierFeatureKey
);

export const selectPlanTiers = createSelector(
  selectPlanTierState,
  (state: fromPlanTier.PlanTierState) => state.planTiers
);

export const selectSelectedPlanTierNetworks = createSelector(
  selectPlanTierState,
  (state: fromPlanTier.PlanTierState) => state.selectedPlanTierNetworks
);

export const selectExternalNetworkSuggestions = createSelector(
  selectPlanTierState,
  (state: fromPlanTier.PlanTierState) => state.externalNetworkSuggestions
);

export const selectSelectedPlanTierExternalNetworks = createSelector(
  selectPlanTierState,
  (state: fromPlanTier.PlanTierState) => state.selectedPlanTierExternalNetworks
);

export const selectSelectedPlanTierBenefits = createSelector(
  selectPlanTierState,
  (state: fromPlanTier.PlanTierState) => state.selectedPlanTierBenefits
);

export const selectIsCloningPlanTiers = createSelector(
  selectPlanTierState,
  (state: fromPlanTier.PlanTierState) => state.isCloningPlanTiers
);
