import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insertSpaces'
})
export class InsertSpacesPipe implements PipeTransform {

  // Inserts a space between every capitalized letter
  transform(value: string, ...args: unknown[]): unknown {
    return value ? value.replace(/([A-Z])/g, ' $1').trim() : value;
  }

}
