import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IProviderNetworkResponseModel } from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderSearchService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  public getProviderNetworks(): Observable<IProviderNetworkResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/networks`;
    return this.http.get<IProviderNetworkResponseModel[]>(url);
  }

}
