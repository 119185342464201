import { createAction, props } from '@ngrx/store';
import { RegistrationDateRange } from '@enums';
import {
  IMemberCountByGroupModel,
  IMemberCountsAggregatedByDateResponseModel,
  IUserLoginsByDateResponseModel
} from '@models';

export const loadRegistrationTrend = createAction(
  '[Reporting] Load Registration Trend',
  props<{ groupId: number; range: RegistrationDateRange }>()
);

export const loadRegistrationTrendSuccess = createAction(
  '[Reporting] Load Registration Trend Success',
  props<{ data: any }>()
);

export const loadRegistrationTrendFailure = createAction(
  '[Reporting] Load Registration Trend Failure',
  props<{ error: any }>()
);

export const loadAggregatedMemberCountsByDate = createAction(
  '[Reporting] Load Aggregated Member Counts',
  props<{ dateRange: RegistrationDateRange }>()
);

export const loadAggregatedMemberCountsByDateSuccess = createAction(
  '[Reporting] Load Aggregated Member Counts Success',
  props<{ data: IMemberCountsAggregatedByDateResponseModel[] }>()
);

export const loadAggregatedMemberCountsByDateFailure = createAction(
  '[Reporting] Load Aggregated Member Counts Failure',
  props<{ error: any }>()
);

export const loadGroupsMemberCount = createAction(
  '[Reporting] Load Groups Member Count'
);

export const loadGroupsMemberCountSuccess = createAction(
  '[Reporting] Load Groups Member Count Success',
  props<{ data: IMemberCountByGroupModel[] }>()
);

export const loadGroupsMemberCountFailure = createAction(
  '[Reporting] Load Groups Member Count Failure',
  props<{ error: any }>()
);

export const loadAggregatedUserLoginsByDate = createAction(
  '[Reporting] Load Aggregated User Logins By Date',
  props<{ dateRange: RegistrationDateRange }>()
);

export const loadAggregatedUserLoginsByDateSuccess = createAction(
  '[Reporting] Load Aggregated User Logins By Date Success',
  props<{ data: IUserLoginsByDateResponseModel[] }>()
);

export const loadAggregatedUserLoginsByDateFailure = createAction(
  '[Reporting] Load Aggregated User Logins By Date Failure',
  props<{ error: any }>()
);

export const loadGroupUserLoginsByDate = createAction(
  '[Reporting] Load Group User Logins By Date',
  props<{ dateRange: RegistrationDateRange; groupId: number }>()
);

export const loadGroupUserLoginsByDateSuccess = createAction(
  '[Reporting] Load Group User Logins By Date Success',
  props<{ data: IUserLoginsByDateResponseModel[] }>()
);

export const loadGroupUserLoginsByDateFailure = createAction(
  '[Reporting] Load Group User Logins By Date Failure',
  props<{ error: any }>()
);
