/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileFacade } from '@facades';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mx-update-password-modal',
  templateUrl: './update-password-modal.component.html',
  styleUrls: ['./update-password-modal.component.scss']
})
export class UpdatePasswordModalComponent implements OnInit, OnDestroy {
  public unsubscribe$ = new Subject();
  public passwordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private profileFacade: ProfileFacade,
    private dialogRef: MatDialogRef<UpdatePasswordModalComponent>
  ) { }

  ngOnInit() {
    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    }, { validators: this.matchValidator });

    this.profileFacade.isUpdatingPassword$
      .pipe(
        skip(1),
        takeUntil(this.unsubscribe$))
      .subscribe(isUpdating => {
        if (!isUpdating) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  matchValidator(group: FormGroup): { mismatch: boolean } {
    let valid = 1;

    const password = group.controls.newPassword.value as string;
    const confirmPassword = group.controls.confirmNewPassword.value as string;

    valid = password.localeCompare(confirmPassword);

    if ((valid === 0) || (password.localeCompare('') === 0 && confirmPassword.localeCompare('') === 0)) {
      return null;
    }

    return { mismatch: true };
  }

  updatePassword(): void {
    const oldPassword = this.passwordForm.controls.currentPassword.value as string;
    const newPassword = this.passwordForm.controls.newPassword.value as string;
    this.profileFacade.updatePassword(oldPassword, newPassword);
  }

}
