import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from 'ngx-currency';
import { SharedModule } from '../../shared/shared.module';
import { SubmitServiceRequestModalComponent } from './components/submit-service-request-modal/submit-service-request-modal.component';
import { SuperBillFormComponent } from './components/super-bill-form/super-bill-form.component';
import { DocumentsStoreModule } from './documents-store/documents-store.module';
import { SuperBillsStoreModule } from './super-bill-store/super-bills-store.module';
import { FamilyMemberDropdownComponent } from '../../shared/components/family-member-dropdown/family-member-dropdown.component';

const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: false,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true
};

@NgModule({
  declarations: [
    SuperBillFormComponent,
    SubmitServiceRequestModalComponent,
    FamilyMemberDropdownComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DocumentsStoreModule,
    SuperBillsStoreModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ]
})
export class DocumentsModule { }
