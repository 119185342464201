import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MxAlertService {
  private defaultButtonText: string;
  private defaultDuration: number;

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar
  ) {
    this.defaultButtonText = 'Close';
    this.defaultDuration = 10000;
  }

  public alert(message: string): void {
    this.snackBar.open(
      message,
      this.defaultButtonText,
      { duration: this.defaultDuration }
    );
  }

  public alertWithOptions(title: string, message: string, buttonText: string, duration?: number): void {
    this.snackBar.open(
      message,
      buttonText,
      { duration: (duration != null) ? duration : this.defaultDuration }
    );
  }

}
