import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { InsuranceFacade, PersonFacade } from '@facades';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteParamsService implements OnDestroy {

  public params: BehaviorSubject<Params>;
  public paramsSnapshot: Params;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private insuranceFacade: InsuranceFacade,
    private personFacade: PersonFacade
  ) {
    this.paramsSnapshot = {};
    this.params = new BehaviorSubject(this.paramsSnapshot);
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        distinctUntilChanged())
      .subscribe(() => {
        const snapshot = this.router.routerState.snapshot.root;
        const nextParams = this.collectParams(snapshot);
        if (this.paramsAreDifferent(this.paramsSnapshot, nextParams)) {
          this.params.next(this.paramsSnapshot = nextParams);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public doesGroupExist(groupId: number): Observable<boolean> {
    if (groupId) {
      return this.insuranceFacade.groups$
        .pipe(
          filter(groups => !!groups),
          take(1),
          switchMap(() =>
            this.insuranceFacade.selectGroup(groupId)
              .pipe(
                take(1),
                map(group => {
                  if (group) {
                    return true;
                  } else {
                    return false;
                  }
                })
              )
          )
        );
    }
  }

  private collectParams(root: ActivatedRouteSnapshot): Params {
    const params: Params = {};
    (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
      Object.assign(params, snapshot.params);
      snapshot.children.forEach(mergeParamsFromSnapshot);
    })(root);

    return (params);
  }

  private paramsAreDifferent(currentParams: Params, nextParams: Params): boolean {
    const currentKeys = Object.keys(currentParams);
    const nextKeys = Object.keys(nextParams);
    if (currentKeys.length !== nextKeys.length) {
      return (true);
    }
    for (let i = 0, length = currentKeys.length; i < length; i++) {
      const key = currentKeys[i];
      if (currentParams[key] !== nextParams[key]) {
        return (true);
      }
    }
    return (false);
  }

}
