import { Injectable } from '@angular/core';
import { TerminologyType } from '@enums';
import { Store } from '@ngrx/store';
import { TerminologyActions, TerminologySelectors, TerminologyState } from '@root-store';
import { filter, map, Observable, take } from 'rxjs';
import { ITerminologyConfigPostModel } from '../models/terminology-config-post.model';

@Injectable({
  providedIn: 'root'
})
export class TerminologyFacade {
  public terminology$ = this.store.select(TerminologySelectors.selectTerminology);
  public terminologyGroupConfigs$ = this.store.select(TerminologySelectors.selectTerminologyGroupConfigs);
  public isLoadingRequest$ = this.store.select(TerminologySelectors.selectIsLoadingRequest);

  constructor(
    private store: Store<TerminologyState>
  ) {}

  public loadTerminology(): void {
    this.store.dispatch(TerminologyActions.loadTerminology());
  }

  public loadTerminologyByGroup(groupId: number): void {
    this.store.dispatch(TerminologyActions.loadTerminologyConfigsByGroup({ groupId }));
  }

  public getTerminologyTerm(type: TerminologyType): Observable<string> {
    return this.terminologyGroupConfigs$
      .pipe(
        filter(terminologyConfigs => !!terminologyConfigs),
        map(terminologyConfigs => terminologyConfigs.find(term => term.type === type).value),
        take(1)
      );
  }

  public postTerminologyConfig(groupId: number, terminologyConfig: ITerminologyConfigPostModel): void {
    this.store.dispatch(TerminologyActions.postTerminologyConfig({ groupId, terminologyConfig }));
  }

  public putTerminologyConfig(terminologyConfigId: number, terminologyConfig: ITerminologyConfigPostModel): void {
    this.store.dispatch(TerminologyActions.putTerminologyConfig({ terminologyConfigId, terminologyConfig }));
  }

  public deleteTerminologyConfig(terminologyConfigId: number): void {
    this.store.dispatch(TerminologyActions.deleteTerminologyConfig({ terminologyConfigId }));
  }

}
