import { Pipe, PipeTransform } from '@angular/core';
import { SuperBillStatus } from '@enums';

@Pipe({
  name: 'superBillStatus'
})
export class SuperBillStatusPipe implements PipeTransform {

  transform(superBillStatusCode: number): any {
    return SuperBillStatus[superBillStatusCode];
  }

}
