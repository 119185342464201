<mat-card *ngIf="bill">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="65">
          <div id="PatientName" style="margin-bottom: 10px; font-size: 24px; font-weight: bold">
            {{ bill.patientFirstName }} {{ bill.patientLastName }}
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px grid">
            <div fxFlex="45" style="margin-bottom: 20px;">
              <h3>Subscriber</h3>
              <strong>Name</strong>
              <div id="MemberName" style="margin-bottom: 10px;">
                {{ bill.memberFirstName }} {{ bill.memberLastName }}
              </div>

              <strong>Group Number / Member Number</strong>
              <div id="GroupAndMemberNumber" style="margin-bottom: 10px;">
                {{ bill.memberGroupNumber }} / {{ bill.memberMemberNumber }}
              </div>

              <strong>Date of Birth</strong>
              <div id="MemberDateOfBirth" style="margin-bottom: 10px;">
                {{ bill.memberDateOfBirth }}
              </div>

              <strong>Contact</strong>
              <div id="MemberEmail">
                <a [href]="'mailto:' + bill.memberContactEmail">{{ bill.memberContactEmail }}</a>
              </div>
              <div id="MemberPhone" style="margin-bottom: 10px;">
                <a [href]="'tel:' + bill.memberContactPhone">{{ bill.memberContactPhone | phone:'US' }}</a>
              </div>
            </div>
            <div fxFlex="45">
              <h3>Patient</h3>
              <strong>Name</strong>
              <div id="PatientName" style="margin-bottom: 10px;">
                {{ bill.patientFirstName }} {{ bill.patientLastName }}
              </div>

              <strong>Group Number / Member Number</strong>
              <div id="PatientGroupAndMemberNumber" style="margin-bottom: 10px;">
                {{ bill.patientGroupNumber }} / {{ bill.patientMemberNumber }}
              </div>

              <strong>Date of Birth</strong>
              <div id="PatientDateOfBirth" style="margin-bottom: 10px;">
                {{ bill.patientDateOfBirth }}
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px grid">
            <div fxFlex="45">
              <h3>Provider</h3>
              <strong>Name</strong>
              <div id="ProviderName" style="margin-bottom: 10px;">
                {{ bill.providerName }}
              </div>
              <strong>Address</strong>
              <div id="ProviderAddressLine1">
                {{ bill.providerAddressLine1 }}
              </div>
              <div id="ProviderAddressLine2">
                {{ bill.providerAddressLine2 }}
              </div>
              <div id="ProviderCity" style="margin-bottom: 10px;">
                {{ bill.providerCity }}, {{ bill.providerState }} {{ bill.providerPostalCode }}
              </div>

              <strong>Provider NPI</strong>
              <div id="ProviderNPI" style="margin-bottom: 10px;">
                {{ bill.providerNpi }}
              </div>

              <strong>Provider TIN</strong>
              <div id="ProviderTIN" style="margin-bottom: 10px;">
                {{ bill.providerTin }}
              </div>

              <strong>Submitted On</strong>
              <div id="CreatedAt" style="margin-bottom: 10px;">
                {{ bill.createdAt | date:'short' }}
              </div>

            </div>
            <div fxFlex="45">
              <h3>Bill Info</h3>
              <strong>Total Amount Charged</strong>
              <div id="TotalAmountCharged" style="margin-bottom: 10px;">
                ${{ bill.totalAmountCharged }}
              </div>

              <strong>Total Amount Paid</strong>
              <div id="TotalAmountPaid" style="margin-bottom: 10px;">
                ${{ bill.totalAmountPaid }}
              </div>

              <strong>Bill Date</strong>
              <div id="BillDate" style="margin-bottom: 10px;">
                {{ bill.billDate }}
              </div>

              <strong>Is Hospital Bill?</strong>
              <div id="BillDate" style="margin-bottom: 10px;">
                {{ bill.isHospitalBill == true ? 'Yes' :
                    bill.isHospitalBill != null ? 'No' : '' }}
              </div>
            </div>
          </div>
        </div>
        <mat-card fxFlex="33">
          <strong>Bill Images</strong>
          <div *ngIf="billImages.length > 0;else noImages">
            <div *ngFor="let image of billImages" fxLayout="row" fxLayoutAlign="space-between">
              <span>
                {{ image.fileName }}
              </span>
              <button mat-icon-button color="primary" (click)="downloadImage(image.id, image.fileType, image.fileName)"
                matTooltip="Download" matTooltipPosition="below">
                <mat-icon>get_app</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div fxFlex="100" style="margin-bottom: 50px;">
      <h2>Medical Services</h2>
      <mat-table [dataSource]="medicalServicesDataSource">
        <ng-container matColumnDef="procedureCode">
          <th mat-header-cell *matHeaderCellDef width="25%"> Procedure Code </th>
          <td mat-cell *matCellDef="let row" width="25%">{{ row.procedureCode }} </td>
        </ng-container>

        <ng-container matColumnDef="diagnosisCode">
          <th mat-header-cell *matHeaderCellDef width="25%"> Diagnosis Code </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{ row.diagnosisCode }}</td>
        </ng-container>

        <ng-container matColumnDef="serviceDate">
          <th mat-header-cell *matHeaderCellDef width="25%"> Service Date </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{ row.serviceDate | date:'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="units">
          <th mat-header-cell *matHeaderCellDef width="10%"> Units </th>
          <td mat-cell *matCellDef="let row" width="10%"> {{ row.units }}</td>
        </ng-container>

        <ng-container matColumnDef="chargeAmount">
          <th mat-header-cell *matHeaderCellDef width="15%"> Charge Amount </th>
          <td mat-cell *matCellDef="let row" width="15%"> {{ row.chargeAmount }}</td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedMedicalServiceColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedMedicalServiceColumns;"></mat-row>
      </mat-table>
    </div>

    <div fxFlex="100">
      <h2>Status History</h2>
      <div style="margin-bottom: -20px;">
        <form [formGroup]="statusForm">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>
              Current Status
            </mat-label>
            <mat-select formControlName="status" (selectionChange)="onStatusChanged($event.value)">
              <mat-option *ngFor="let status of statuses" [value]="status.value"
                [disabled]="status.disabled">
                {{ status.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <mat-table #table [dataSource]="statusesDataSource">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef width="25%"> Status </th>
          <td mat-cell *matCellDef="let row" width="25%">{{ row.status | superBillStatus }} </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef width="50%"> Note </th>
          <td mat-cell *matCellDef="let row" width="50%"> {{ row.note }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef width="25%"> Date </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{ row.createdAt | date:'short' }}</td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedStatusColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedStatusColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-card>


<ng-template #noImages>
  <div>
    No images submitted for this bill.
  </div>
</ng-template>