import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DragDropModule } from 'primeng/dragdrop';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

const modules = [
  AutoCompleteModule,
  RadioButtonModule,
  DropdownModule,
  CalendarModule,
  SelectButtonModule,
  MultiSelectModule,
  InputTextModule,
  ToggleButtonModule,
  ChartModule,
  ScrollPanelModule,
  InputMaskModule,
  DialogModule,
  OverlayPanelModule,
  ButtonModule,
  TagModule,
  TooltipModule,
  RatingModule,
  CheckboxModule,
  ProgressBarModule,
  TableModule,
  InputSwitchModule,
  DragDropModule,
  ConfirmDialogModule
];

@NgModule({
  imports: [modules],
  exports: [modules]
})
export class PrimengModule { }
