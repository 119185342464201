/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPaymentCardRequestDetailResponseModel, IPaymentCardRequestResponseModel } from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as PaymentCardRequestActions from './payment-card-request.actions';

export const paymentCardRequestFeatureKey = 'paymentCardRequest';

export interface PaymentCardRequestState {
  isLoading: boolean;
  isSendingPaymentCardRequest: boolean;
  paymentCardRequests: IPaymentCardRequestResponseModel[];
  selectedPaymentCardRequest: IPaymentCardRequestDetailResponseModel;
}

export const initialPaymentCardRequestState: PaymentCardRequestState = {
  isLoading: false,
  isSendingPaymentCardRequest: false,
  paymentCardRequests: [],
  selectedPaymentCardRequest: null
};

const paymentCardRequestReducer = createReducer(
  initialPaymentCardRequestState,

  on(PaymentCardRequestActions.loadPaymentCardRequest, (state) => ({ ...state, isLoading: true })),
  on(PaymentCardRequestActions.loadPaymentCardRequestSuccess, (state, action) => (
    { ...state, isLoading: false, selectedPaymentCardRequest: action.paymentCardRequest }
  )),
  on(PaymentCardRequestActions.loadPaymentCardRequestFailure, (state) => ({ ...state, isLoading: false })),

  on(PaymentCardRequestActions.loadPaymentCardRequestsByPartner, (state) => ({ ...state, isLoading: true })),
  on(PaymentCardRequestActions.loadPaymentCardRequestsByPartnerSuccess, (state, action) => (
    { ...state, isLoading: false, paymentCardRequests: action.paymentCardRequests }
  )),
  on(PaymentCardRequestActions.loadPaymentCardRequestsByPartnerFailure, (state) => ({ ...state, isLoading: false })),

  on(PaymentCardRequestActions.loadPaymentCardRequestsByMember, (state) => ({ ...state, isLoading: true })),
  on(PaymentCardRequestActions.loadPaymentCardRequestsByMemberSuccess, (state, action) => (
    { ...state, isLoading: false, paymentCardRequests: action.paymentCardRequests }
  )),
  on(PaymentCardRequestActions.loadPaymentCardRequestsByMemberFailure, (state) => ({ ...state, isLoading: false })),

  on(PaymentCardRequestActions.updatePaymentCardRequestStatus, (state) => ({ ...state, isLoading: true })),
  on(PaymentCardRequestActions.updatePaymentCardRequestStatusSuccess, (state) => ({ ...state, isLoading: false })),
  on(PaymentCardRequestActions.updatePaymentCardRequestStatusFailure, (state, action) => ({ ...state, isLoading: false })),

  on(PaymentCardRequestActions.sendPaymentCardRequest, (state, action) => ({ ...state, isSendingPaymentCardRequest: true })),
  on(PaymentCardRequestActions.sendPaymentCardRequestSuccess, (state, action) => ({ ...state, isSendingPaymentCardRequest: false })),
  on(PaymentCardRequestActions.sendPaymentCardRequestFailure, (state, action) => ({ ...state, isSendingPaymentCardRequest: false })),

  on(PaymentCardRequestActions.clearPaymentCardRequests, (state, action) => Object.assign({}, initialPaymentCardRequestState))
);

export function reducer(state: PaymentCardRequestState | undefined, action: Action) {
  return paymentCardRequestReducer(state, action);
}
