import { createAction, props } from '@ngrx/store';
import { IHSRResponseModel } from '../../../backend/entities/health-service-request-response.model';
import { IHSRPostModel } from '../../../backend/entities/health-service-request-post.model';
import { IHSRStatusPostModel } from 'src/app/backend/entities/health-service-request-status-post.model';
import { IHSRPutModel } from 'src/app/backend/entities/health-service-request-put.model';

export const getMemberHSRs = createAction('[Documents Page] Get Member HSRs', props<{ personId: number }>());
// eslint-disable-next-line max-len
export const getMemberHSRsSuccess = createAction('[Documents Page] Get Member HSRs Success', props<{ requests: Array<IHSRResponseModel> }>());
export const getPartnerHSRs = createAction('[Documents Page] Get Partner HSRs', props<{ userId: number }>());
// eslint-disable-next-line max-len
export const getPartnerHSRsSuccess = createAction('[Documents Page] Get Partner HSRs Success', props<{ requests: Array<IHSRResponseModel> }>());
export const setSelectedHSR = createAction('[Documents Page - List] Set Selected HSR', props<{ id: number }>());
export const createHSR = createAction(
  '[Documents Page] Create HSR',
  props<{ hsr: IHSRPostModel; requestPaymentCard: boolean }>()
);
export const createHSRSuccess = createAction(
  '[Documents Page] Create HSR Success',
  props<{ hsr: IHSRResponseModel }>()
);
export const createHSRFailure = createAction(
  '[Documents Page] Create HSR Failure',
  props<{ error: any }>()
);
// eslint-disable-next-line max-len
export const requestNewCard = createAction('[Documents Page] Request New Card', props<{ hsr: IHSRPutModel; hsrStatus: IHSRStatusPostModel }>());
export const requestNewCardSuccess = createAction('[Documents Page] Request New Card Success', props<{ hsr: IHSRResponseModel }>());
// eslint-disable-next-line max-len
export const updateHSRStatus = createAction('[Documents Page] Update HSR Status', props<{ hsrStatus: IHSRStatusPostModel; hsrId: number }>());
export const updateHSRStatusSuccess = createAction('[Documents Page] Update HSR Status Success', props<{ hsr: IHSRResponseModel }>());
export const clearHSRs = createAction('[Documents Page - List] Clear HSRs');
export const getHSRDetails = createAction('[Documents Page] Get HSR Details', props<{ id: number }>());
// eslint-disable-next-line max-len
export const getHSRDetailsSuccess = createAction('[Documents Page] Get HSR Details Success', props<{ hsr: IHSRResponseModel; paymentCardNumber: string }>());
// export const getAttachedSuperBills = createAction('[Documents Page] Get Attached Super Bills', props<{ id: number; }>());
// eslint-disable-next-line max-len
// export const getAttachedSuperBillsSuccess = createAction('[Documents Page] Get Attached Super Bills Success', props<{ id: number; superBills: Array<ISuperBillDetailResponseModel> }>());

