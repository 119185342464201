import { Injectable } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthFacade } from '@facades';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedOutGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private authFacade: AuthFacade
  ) { }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.authToken$.pipe(
      take(1),
      switchMap(jwt => {
        if (!jwt) {
          return of(true);
        } else {
          void this.router.navigate(['./groups']);
          return of(true);
        }
      })
    );
  }

}
