export interface IPaymentCardResponseModel {
  id: number;
  personId: number;
  // eslint-disable-next-line id-blacklist
  number: string;
  expirationDate: string;
  zipCode: string;
  cvv: string;
  name: string;
}
