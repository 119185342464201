export const environment = {
  mode: 'env_placeholder',
  name: 'environment configuration',
  config: {
    apiEndpoint: 'api_endpoint_placeholder/api',
    memberPortalUrl: 'member_portal_url_placeholder',
    trackerId: 'tracker_id_placeholder',
    env: 'dev',
    googleMapsQueryAPI: 'https://www.google.com/maps/search/?api=1&query=',
    googleMapsMapId: 'google_maps_map_id_placeholder',
    googleMapsApiKey: 'google_maps_api_key_placeholder'
  },
  features: {
    search: true
  },
  firebaseConfig: {
    apiKey: "AIzaSyDEhLTFedry21flHosIi6xSgZO4EArHjpE",
    authDomain: "medxoom-dev.firebaseapp.com",
    databaseURL: "https://medxoom-dev.firebaseio.com",
    projectId: "medxoom-dev",
    storageBucket: "medxoom-dev.appspot.com",
    messagingSenderId: "699878714976",
    appId: "1:699878714976:web:0fc789d4159f50317d5d05",
    measurementId: "G-4PP73GYCJE"
  }
};
