import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IGroupPostModel, IGroupResponseModel, Pending, Status } from '@models';
import { Subject } from 'rxjs';
import { skip, takeUntil, take } from 'rxjs/operators';
import { GroupService } from '../../../backend/services/group.service';
import { InsuranceFacade } from '@facades';

@Component({
  selector: 'mx-new-group-modal',
  templateUrl: './new-group-modal.component.html',
  styleUrls: ['./new-group-modal.component.scss']
})
export class NewGroupModalComponent implements OnInit, OnDestroy {
  readonly Status = Status;
  public newGroupForm: FormGroup;
  public newGroupResponse$: Pending<IGroupResponseModel>;
  public status: Status;
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewGroupModalComponent>,
    private groupService: GroupService,
    private insuranceFacade: InsuranceFacade,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.newGroupForm = this.fb.group({
      name: '',
      phone: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: ''
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onClickCreate() {
    this.newGroupResponse$ = this.groupService.post(this.newGroupForm.value as IGroupPostModel);
    this.newGroupResponse$.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.insuranceFacade.loadGroups();
      });
    this.newGroupResponse$.status
      .pipe()
      .subscribe(status => {
        this.status = status;
        switch (status) {
          case Status.SUCCESS: {
            this.snackBar.open(
              'Group successfully created!',
              'Close',
              { duration: 3000 }
            );
            this.dialogRef.close();
            break;
          }
          case Status.LOADING: {
            break;
          }
          case Status.ERROR: {
            break;
          }
          default:
            break;
        }
      });
  }

  onClickCreateAndOpen() {
    this.newGroupResponse$ = this.groupService.post(this.newGroupForm.value as IGroupPostModel);
    this.newGroupResponse$.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(newGroup => {
        this.insuranceFacade.loadGroups();
        this.insuranceFacade.groups$
          .pipe(skip(1), take(1))
          .subscribe(() => void this.router.navigate([`groups/${newGroup.id}/details`]));
      });
    this.newGroupResponse$.status
      .pipe()
      .subscribe(status => {
        this.status = status;
        switch (status) {
          case Status.SUCCESS: {
            this.dialogRef.close();
            break;
          }
          case Status.LOADING: {
            break;
          }
          case Status.ERROR: {
            break;
          }
          default:
            break;
        }
      });
  }

}
