import { CostEstimateResponse, ICostLookupModel } from '@models';
import { createReducer, Action, on } from '@ngrx/store';

import * as CostEstimatorActions from './cost-estimator.actions';

export const analyticsFeatureKey = 'cost-estimator';

export interface CostEstimatorState {
  procedureCost: ICostLookupModel;
  procedureCostEstimate: CostEstimateResponse;
}

export const initialCostEstimatorState: CostEstimatorState = {
  procedureCost: null,
  procedureCostEstimate: null
};

export const costEstimatorReducer = createReducer(
  initialCostEstimatorState,
  on(CostEstimatorActions.getProcedureCostSuccess, (state, action) => ({
    ...state,
    procedureCost: action.result
  })),
  on(CostEstimatorActions.getProcedureCostFailure, (state, action) => ({
    ...state,
    procedureCost: null
  })),
  on(CostEstimatorActions.getProcedureCostEstimateSuccess, (state, action) => ({
    ...state,
    procedureCostEstimate: action.result
  })),
  on(CostEstimatorActions.clearProcedureEstimate, (state) => ({
    ...state,
    procedureCost: null,
    procedureCostEstimate: null
  }))
);

export function reducer(state: CostEstimatorState | undefined, action: Action) {
  return costEstimatorReducer(state, action);
}
