import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';

import { AppState } from '../root.state';
import { MessageCenterService } from '@backend';
import { IThreadResponseModel } from '@models';
import * as MessageCenterActions from './message-center.actions';
import { forkJoin, Observable, of } from 'rxjs';
import { MessageCenterFacade } from '@facades';
import { MessageCenterViewState, UserType, ThreadListType } from '@enums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthSelectors } from '../auth-store';
import { selectUserId } from '../auth-store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterEffects {
  loadThreads$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.loadThreads),
    switchMap(data => this.messageCenterService.loadThreads(data.loadThreadsRequest)
      .pipe(
        map((threads: IThreadResponseModel[]) => MessageCenterActions.loadThreadsSuccess({ threads: threads })),
        catchError(error => of(MessageCenterActions.loadThreadsFailure({ error })))
      ))
  ));

  loadThreadMessages$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.loadThreadMessages),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([action, partnerUserId]) => {
      const requests: Observable<any>[] = [this.messageCenterService.loadThreadMessages(action.threadId)];

      if (action.markAsRead) {
        const statusPostModel = {
          isRead: true,
          userId: +partnerUserId
        };
        requests.push(this.messageCenterService.updateThreadReadStatus(action.threadId, statusPostModel));
      }

      return forkJoin(requests).pipe(
        map(([threadMessages]) => MessageCenterActions.loadThreadMessagesSuccess({ threadMessages, threadId: action.threadId })),
        catchError(error => of(MessageCenterActions.loadThreadMessagesFailure({ error })))
      );
    })
  ));

  sendNewMessage$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.sendNewMessage),
    switchMap(action => this.messageCenterService.sendNewMessage(action.message, action.files)
      .pipe(
        map(() => MessageCenterActions.sendNewMessageSuccess()),
        catchError(error => {
          this.showSnackBar(
            'An application error occurred and your message was not sent. Please try again or contact support if the issue persists.'
          );
          return of(MessageCenterActions.sendNewMessageFailure({ error }));
        })
      ))
  ));

  sendNewMessageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.sendNewMessageSuccess),
    tap(() => {
      this.messageCenterFacade.setCurrentViewState(MessageCenterViewState.ThreadsList);
      this.showSnackBar('Message sent successfully!');
    })
  ), { dispatch: false });

  replyThreadMessage$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.replyThreadMessage),
    withLatestFrom(this.store.select(AuthSelectors.selectUserId)),
    switchMap(([action, userId]) => {
      const replyThreadBody = {
        body: action.body,
        senderType: UserType.Partner,
        senderUserId: +userId
      };

      return this.messageCenterService.replyThreadMessage(replyThreadBody, action.threadId, action.files)
        .pipe(
          map(() => MessageCenterActions.replyThreadMessageSuccess({ threadId: action.threadId })),
          catchError(error => {
            this.snackBar.open(
              'There was an error trying to send your message. Please try again later.',
              'Close',
              { duration: 3000 }
            );
            return of(MessageCenterActions.replyThreadMessageFailure({ error }));
          })
        );
    })
  ));

  replyTheadMessageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.replyThreadMessageSuccess),
    tap(action => {
      this.snackBar.open(
        'Your message was sent successfully!',
        'Close',
        { duration: 3000 }
      );
      this.messageCenterFacade.loadThreadMessages(action.threadId, false);
    })
  ), { dispatch: false });

  getMessageAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.getMessageAttachment),
    switchMap(action => this.messageCenterService.getMessageAttachment(action.messageId, action.attachmentId)
      .pipe(
        tap(file => {
          if (file) {
            saveAs(file, action.fileName, {
              type: file.type
            });
          }
        }),
        map(file => MessageCenterActions.getMessageAttachmentSuccess({ file })),
        catchError(error => of(MessageCenterActions.getMessageAttachmentFailure({ error }))),
      ))
  ));

  toggleRepliesAllowed$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.toggleRepliesAllowed),
    switchMap(action => this.messageCenterService.toggleRepliesAllowed(action.threadId, action.replyAllowed)
      .pipe(
        map((thread) => {
          this.snackBar.open('Successfully updated!', 'close', { duration: 5000 });
          return MessageCenterActions.toggleRepliesAllowedSuccess({ thread });
        }),
        catchError(error => {
          this.showSnackBar(
            'An application error occurred and updates were not saved. Please try again or contact support if the issue persists.'
          );
          return of(MessageCenterActions.toggleRepliesAllowedFailure({ error }));
        })
      ))
  ));

  archiveThread$ = createEffect(() => this.actions$.pipe(
    ofType(MessageCenterActions.archiveThread),
    withLatestFrom(this.store.select(AuthSelectors.selectUserId)),
    switchMap(([action, userId]) => this.messageCenterService.archiveThread(action.threadId, userId, action.isArchived)
      .pipe(
        map((response) => {
          this.snackBar.open('Message successfully archived.', 'close', { duration: 5000 });
          return MessageCenterActions.archiveThreadSuccess({ threadId: response.threadId });
        }),
        catchError(error => {
          this.showSnackBar(
            'An application error occurred and updates were not saved. Please try again or contact support if the issue persists.'
          );
          return of(MessageCenterActions.toggleRepliesAllowedFailure({ error }));
        })
      ))
  ));

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private messageCenterService: MessageCenterService,
    private messageCenterFacade: MessageCenterFacade,
    private snackBar: MatSnackBar
  ) { }

  private showSnackBar(message: string) {
    this.snackBar.open(
      message,
      'Close',
      { duration: 3000 }
    );
  }
}
