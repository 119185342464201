import { IBalanceBillStatusResponseModel } from './balance-bill-status-response.model';

export interface IBalanceBillDetailResponseModel {
  id: number;
  userId: number;
  memberFirstName: string;
  memberLastName: string;
  memberContactEmail: string;
  memberContactPhone: string;
  patientFirstName: string;
  patientLastName: string;
  statuses: IBalanceBillStatusResponseModel[];
  createdAt: string;
  updatedAt: string;
}
