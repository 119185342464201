import { Pipe, PipeTransform } from '@angular/core';

import { ClaimStatus } from '@enums';

@Pipe({
  name: 'claimStatus'
})
export class ClaimStatusPipe implements PipeTransform {

  transform(claimStatus: string): string {
    return ClaimStatus[+claimStatus];
  }

}
