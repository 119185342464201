export interface IAutoCompletePostModel {
  searchTerm: string;
  latitude: number;
  longitude: number;
  radius: number;
  facilityType?: string;
  planIds?: number[];
  size: number;
  minConfidence: number;
  minLocationConfidence: number;
}
