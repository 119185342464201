import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { SuperBillStatus } from '@enums';
import { SuperBillFacade } from '@facades';
// tslint:disable-next-line: max-line-length
import { ISuperBillDetailResponseModel, ISuperBillImageResponseModel, ISuperBillStatusResponseModel } from '@models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISuperBillMedicalServiceResponseModel } from '../../models/super-bill-medical-service-response.model';

@Component({
  selector: 'mx-super-bill-details',
  templateUrl: './super-bill-details.component.html',
  styleUrls: ['./super-bill-details.component.scss']
})
export class SuperBillDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bill: ISuperBillDetailResponseModel;
  @Input() billImages: ISuperBillImageResponseModel[];
  @Output() changeStatus = new EventEmitter<SuperBillStatus>();
  @Output() canDeactivate = new EventEmitter();

  status: number;
  public previousStatus: number;
  public statusForm: FormGroup;
  public superBillStatus = SuperBillStatus;

  public statusesDataSource: MatTableDataSource<ISuperBillStatusResponseModel>;
  public medicalServicesDataSource: MatTableDataSource<ISuperBillMedicalServiceResponseModel>;

  public displayedStatusColumns: string[] = [
    'status',
    'note',
    'createdAt'
  ];

  public displayedMedicalServiceColumns: string[] = [
    'procedureCode',
    'diagnosisCode',
    'serviceDate',
    'units',
    'chargeAmount'
  ];

  public statuses: any[] = [
    { value: SuperBillStatus.Draft, displayValue: SuperBillStatus[SuperBillStatus.Draft], disabled: true },
    { value: SuperBillStatus.New, displayValue: SuperBillStatus[SuperBillStatus.New], disabled: true },
    { value: SuperBillStatus.Received, displayValue: SuperBillStatus[SuperBillStatus.Received] },
    { value: SuperBillStatus['In Progress'], displayValue: SuperBillStatus[SuperBillStatus['In Progress']] },
    { value: SuperBillStatus.Declined, displayValue: SuperBillStatus[SuperBillStatus.Declined] },
    { value: SuperBillStatus.Completed, displayValue: SuperBillStatus[SuperBillStatus.Completed] },
    { value: SuperBillStatus.Resubmit, displayValue: SuperBillStatus[SuperBillStatus.Resubmit] },
    { value: SuperBillStatus.Unknown, displayValue: SuperBillStatus[SuperBillStatus.Unknown] },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private superBillFacade: SuperBillFacade,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.superBillFacade.cancelSave
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.status = this.previousStatus;
        this.statusForm.get('status').setValue(this.status);
      });

    this.statusForm = this.fb.group({
      status: [],
    });
  }

  ngOnChanges() {
    // TODO: Add unit tests
    if (this.bill) {
      this.statusesDataSource = new MatTableDataSource<ISuperBillStatusResponseModel>(this.bill.statuses);
      this.medicalServicesDataSource = new MatTableDataSource<ISuperBillMedicalServiceResponseModel>(this.bill.medicalServices);
      if (this.bill.statuses.length > 0) {
        this.status = this.bill.statuses[0].status;
        this.statusForm.get('status').setValue(this.status);
        this.previousStatus = this.status;
        if (this.status === SuperBillStatus.Draft) {
          this.statusForm.disable();
        }
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public onStatusChanged(status: SuperBillStatus) {
    this.changeStatus.next(status);
  }

  downloadImage(imageId: number, contentType: string, fileName: string) {
    this.superBillFacade.getSuperBillImage(this.bill.id, imageId, contentType, fileName);
  }

}
