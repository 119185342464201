<p-dropdown
  [(ngModel)]="selectedPatientPersonId"
  (onChange)="onChangePatient()"
  [options]="patients"
  optionLabel="label"
  optionValue="id"
  [style]="{'width': '100%'}">
</p-dropdown>

<!-- TODO: Need to come back here and clean this up styling wise, to be more reusable -->
