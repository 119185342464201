import { IAccountPostModel, IAccountResponseModel, ITokenPutModel, IUserPasswordPutModel } from '@models';
import { createAction, props } from '@ngrx/store';
import { IRole } from '../../shared/models/role.model';

export const clearStore = createAction(
  '[STORE_WIDE] Clear Store'
);

export const login = createAction(
  '[Auth] Login',
  props<{ loginCreds: ITokenPutModel }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ data: any; email: string }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const logout = createAction(
  '[Auth] Logout'
);

export const register = createAction(
  '[Auth] Register',
  props<{ data: IAccountPostModel}> ()
);

export const registerSuccess = createAction(
  '[Auth] Register Success',
  props<{ data: IAccountResponseModel}> ()
);

export const registerFailure = createAction(
  '[Auth] Register Failure',
  props<{ error: any }> ()
);

export const refreshAuthToken = createAction(
  '[Auth] Refresh Auth Token'
);

export const refreshAuthTokenSuccess = createAction(
  '[Auth] Refresh Auth Token Success',
  props<{ data: any }>()
);

export const refreshAuthTokenFailure = createAction(
  '[Auth] Refresh Auth Token Failure',
  props<{ error: any }>()
);

export const loadAuthCookie = createAction(
  '[Auth] Load Auth Cookie'
);

export const loadAuthCookieSuccess = createAction(
  '[Auth] Load Auth Cookie Success',
  props<{ token: string }>()
);

export const loadAuthCookieFailure = createAction(
  '[Auth] Load Auth Cookie Failure'
);

export const decodeAuthToken = createAction(
  '[Auth] Decode Auth Token',
  props<{ token: string }>()
);

export const decodeAuthTokenSuccess = createAction(
  '[Auth] Decode Auth Token Success',
  props<{ data: any }>()
);

export const decodeAuthTokenFailure = createAction(
  '[Auth] Decode Auth Token Failure',
  props<{ error: any }>()
);

export const updatePassword = createAction(
  '[Auth] Update Password',
  props<{ updateModel: IUserPasswordPutModel }>()
);

export const updatePasswordSuccess = createAction(
  '[Auth] Update Password Success'
);

export const setAuthExpTimer = createAction(
  '[Auth] Set Auth Expire Timer',
  props<{ exp: string }>()
);

export const setSessionTimeLeft = createAction(
  '[Auth] Set Session Time Left',
  props<{ time: number }>()
);

export const getRole = createAction(
  '[Auth] Get Role',
  props<{ userId: number }>()
);

export const getRoleSuccess = createAction(
  '[Auth] Get Role Success',
  props<{ role: IRole }>()
);
