import { Pipe, PipeTransform } from '@angular/core';
import { BalanceBillStatus } from '@enums';

@Pipe({
  name: 'billStatus'
})
export class BillStatusPipe implements PipeTransform {

  transform(billStatusCode: number): any {
    return BalanceBillStatus[billStatusCode];
  }

}
