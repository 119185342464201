import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { BenefitService, PlanTierService } from '@backend';
import * as BenefitsActions from './benefits.actions';
import { IBenefitSummary, INetworkTier } from '@models';
import { Store } from '@ngrx/store';
import { AppState } from '../root.state';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class BenefitsEffects {

  loadBenefitSummary$ = createEffect(() => this.actions$.pipe(
    ofType(BenefitsActions.loadBenefitSummary),
    switchMap(action => this.benefitService.getPolicyBenefitsSummary(action.policyId)
      .pipe(
        switchMap(benefitSummaries => {
          const requests = benefitSummaries.map(
            benefitSummary => this.planTierService.loadPlanTierNetworks(action.planId, benefitSummary.networkTier).pipe(
              map(providerNetworks => ({ ...benefitSummary, providerNetworks } as INetworkTier))
            )
          );
          return requests?.length > 0 ? forkJoin(requests) : of(benefitSummaries);
        }),
        map((benefitSummary) => {
          benefitSummary.forEach((networkTier: INetworkTier) => {
            networkTier.hasIndividualDeductibleAndOopMaxValues = this.hasDeductibleAndOopMaxValues(networkTier.individual);
            networkTier.hasFamilyDeductibleAndOopMaxValues = this.hasDeductibleAndOopMaxValues(networkTier.family);
            networkTier.hasIndividualCopayAndCoinsuranceValues = this.hasIndividualCopayAndCoinsuranceValues(networkTier);
          });
          const orderedBenefitSummary = benefitSummary.sort((a, b) => (a.networkTier > b.networkTier) ? 1 : -1);
          return BenefitsActions.loadBenefitSummarySuccess({ benefitSummary: orderedBenefitSummary });
        }),
        catchError(error => of(BenefitsActions.loadBenefitSummaryFailure({ error })))
      ))
  ));

  setSelectedPolicy$ = createEffect(() => this.actions$.pipe(
    ofType(BenefitsActions.setSelectedPolicy),
    map(action => {
      if (action.policy) {
        this.store.dispatch(BenefitsActions.loadBenefitSummary({ planId: action.policy.planId, policyId: action.policy.id }));
      }

      return BenefitsActions.setSelectedPolicySuccess({ policy: action.policy });
    }),
    catchError(error => of(BenefitsActions.setSelectedPolicyFailure({ error })))
  ));

  sharePolicyIdCard$ = createEffect(() => this.actions$.pipe(
    ofType(BenefitsActions.sharePolicyIdCard),
    switchMap(action => this.benefitService.sharePolicyIdCard(action.policyId, action.recipient, action.channelType)
      .pipe(
        map(result => BenefitsActions.sharePolicyIdCardSuccess({ response: result })),
        tap(() => {
          this.dialog.closeAll();
          this.snackBar.open('Card shared successfully.', 'close', { duration: 3000 });
        }),
        catchError(error => of(BenefitsActions.sharePolicyIdCardFailure({ error })))
      )),
  ));

  getPrintPolicyIdCard$ = createEffect(() => this.actions$.pipe(
    ofType(BenefitsActions.getPrintPolicyIdCard),
    switchMap(action => this.benefitService.getPolicyIdCard(action.policyId, 2)
      .pipe(
        tap(response => {
          const blobUrl = URL.createObjectURL(response);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          setTimeout(() => iframe.contentWindow.print(), 500);
        })
      )),
    map(idCard => BenefitsActions.getPolicyIdCardSuccess()),
    catchError(error => of(BenefitsActions.getPolicyIdCardFailure({ error })))
  ));

  getDownloadPolicyIdCard$ = createEffect(() => this.actions$.pipe(
    ofType(BenefitsActions.getDownloadPolicyIdCard),
    switchMap(action => this.benefitService.getPolicyIdCard(action.policyId, 1)
      .pipe(
        tap(response => {
          var fileURL = URL.createObjectURL(response);
          var linkElement = document.createElement('a');
          linkElement.href = fileURL;
          linkElement.target = '_blank';
          linkElement.download = `${action.planName}.pdf`;
          document.body.appendChild(linkElement);
          linkElement.click();
        })
      )),
    map(idCard => BenefitsActions.getPolicyIdCardSuccess()),
    catchError(error => of(BenefitsActions.getPolicyIdCardFailure({ error })))
  ));

  constructor(
    private actions$: Actions,
    private benefitService: BenefitService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private planTierService: PlanTierService
  ) {}

  private hasDeductibleAndOopMaxValues(benefitSummary: IBenefitSummary): boolean {
    return benefitSummary.deductible != null || benefitSummary.outOfPocketMax != null;
  }

  private hasIndividualCopayAndCoinsuranceValues(networkTier: INetworkTier): boolean {
    return networkTier.individual.emergencyRoom.copay != null ||
      networkTier.individual.emergencyRoom.coinsurance != null ||
      networkTier.individual.primaryCare.copay != null ||
      networkTier.individual.primaryCare.coinsurance != null ||
      networkTier.individual.specialist.copay != null ||
      networkTier.individual.specialist.coinsurance != null ||
      networkTier.individual.urgentCare.copay != null ||
      networkTier.individual.urgentCare.coinsurance != null;
  }

}
