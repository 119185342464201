export interface IClaimDetailResponseModel {
  id: number;
  claimHeaderId: number;
  claimNumber: string;
  lineNumber: number;
  serviceFromDate: Date;
  serviceToDate: Date;
  placeOfService: string;
  typeOfService: string;
  revenueCode: string;
  revenueCodeDescription?: string;
  procedureCode: string;
  procedureCodeDescription: string;
  reasonCode1: string;
  reasonCode1Description: string;
  reasonCode2: string;
  reasonCode2Description: string;
  procedureModifier1: string;
  procedureModifier2: string;
  procedureModifier3: string;
  procedureModifier4: string;
  primaryDiagnosisCode: string;
  otherDiagnosisCode1: string;
  otherDiagnosisCode2: string;
  otherDiagnosisCode3: string;
  drugNDC: string;
  drugQuantity: number;
  drugUnitOfMeasure: string;
  pharmacyPrescriptionNumber: string;
  renderingProviderNPI: string;
  renderingProviderEntityType: string;
  renderingProviderTaxonomyCode: string;
  renderingProviderLastNameOrOrgName: string;
  renderingProviderFirstName: string;
  renderingProviderMiddleName: string;
  units: number;
  days: number;
  anesthesiaMinutes: number;
  billedAmount: number;
  coveredAmount: number;
  cOBAmount: number;
  payerPaidAmount: number;
  coPayAmount: number;
  coInsuranceAmount: number;
  deductibleAmount: number;
  remainingMemberResponsibilityAmount: number;
  discountAmount: number;
  deniedAmount: number;
  otherAdjustmentAmount: number;
  otherAdjustmentReasonCode: string;
  priorAuthorizationCode: string;
  createdAt: Date;
  updatedAt: Date;
  notes: string;
}
