export interface IBenefitPostModel {
  benefitInformationCode: string;
  coverageLevel: string;
  serviceTypeCode: string;
  insuranceTypeCode: string;
  timePeriodQualifier: string;
  monetaryValue: number;
  percent: number;
  quantityQualifier: string;
  quantity: number;
  authorizationIndicator: string;
  inPlanNetworkIndicator: string;
}
