import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuperBillService } from '@backend';
import { ISuperBillImageResponseModel } from '@models';
import { Observable } from 'rxjs';

@Component({
  selector: 'mx-super-bill-image-modal',
  templateUrl: './super-bill-image-modal.component.html',
  styleUrls: ['./super-bill-image-modal.component.scss']
})
export class SuperBillImageModalComponent implements OnInit {
  public billImages$: Observable<ISuperBillImageResponseModel[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { billId: number },
    private superBillService: SuperBillService,
  ) { }

  ngOnInit() {
    this.billImages$ = this.superBillService.getSuperBillImages(this.data.billId);
  }

  downloadImage(imageId: number, contentType: string, fileName: string) {
    this.superBillService.getSuperBillImage(this.data.billId, imageId, contentType, fileName);
  }

}
