import { ThreadListType, ThreadSortOption } from "@enums";

export interface LoadThreadsRequest {
  threadListType: ThreadListType;
  skip: number;
  limit: number;
  sortBy: ThreadSortOption;
  searchTerm: string;
  sentToGroups: boolean;
}
