<mat-card>

  <div *ngIf="!isLoading;else loading">
    <div *ngIf="!hasPaymentCardRequests">No payment card requests found.</div>
  </div>

  <div [hidden]="isLoading || !hasPaymentCardRequests">

    <mat-form-field appearance="outline" class="search-box">
      <mat-label>Filter Payment Card Requests...</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. John Smith">
    </mat-form-field>

    <mat-table #table [dataSource]="dataSource" matSort matSortActive="spcId" matSortDirection="desc">

      <ng-container matColumnDef="spcId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> SPC ID </th>
        <td mat-cell *matCellDef="let row" width="15%" class="table-long-text"><strong>{{ row.spcId }}</strong></td>
      </ng-container>

      <ng-container matColumnDef="memberName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="20%"> Patient Name </th>
        <td mat-cell *matCellDef="let row" width="20%" class="table-long-text"> {{ row.memberFirstName }} {{ row.memberLastName }}</td>
      </ng-container>

      <ng-container matColumnDef="providerName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Provider Name </th>
        <td mat-cell *matCellDef="let row" width="15%"> {{ row.providerName }}</td>
      </ng-container>

      <ng-container matColumnDef="groupAndMemberNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="25%"> Group Number /<br/>Member Number </th>
        <td mat-cell *matCellDef="let row" width="25%">
          <div>{{ row.groupNumber }} /</div>
          <div>{{ row.memberNumber }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="cardAmount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Card Amount </th>
        <td mat-cell *matCellDef="let row" width="15%"> {{ row.cardAmount | currency }}</td>
      </ng-container>

      <ng-container matColumnDef="cardAvailableDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Card Available Date </th>
        <td mat-cell *matCellDef="let row" width="15%"> {{ (row.cardAvailableDate | date:'mediumDate') ?? 'n/a' }}</td>
      </ng-container>

      <ng-container matColumnDef="cardExpirationDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Exp. Date </th>
        <td mat-cell *matCellDef="let row" width="15%"> {{ (row.cardExpirationDate | date:'mediumDate') ?? 'n/a' }}</td>
      </ng-container>

      <ng-container matColumnDef="dateOfService">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Est. Date of Service </th>
        <td mat-cell *matCellDef="let row" width="15%"> {{ (row.dateOfService | date:'mediumDate') ?? 'n/a' }}</td>
      </ng-container>

      <ng-container matColumnDef="estimatedCost">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="10%"> Estimated Charge </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;" width="15%"> {{ (row.estimatedCost | currency) ?? 'n/a' }}</td>
      </ng-container>

      <ng-container matColumnDef="visibleToMember">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%">
          Visible to<br/>Member
        </th>
        <td mat-cell *matCellDef="let row" width="15%">
          <div [ngClass]="{ 'green': row.visibleToMember, 'red': !row.visibleToMember }">
            <div class="icon-container">
              <fa-icon [icon]="icons[ row.visibleToMember ? 'visibleToMember' : 'notVisibleToMember' ]"></fa-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Status </th>
        <td mat-cell *matCellDef="let row" width="15%">
          <ng-container *ngIf="row.status;else latestStatus">
            {{ row.status | hsrStatus | insertSpaces }}
          </ng-container>
          <ng-template #latestStatus>
            <ng-container *ngIf="row.statuses && row.statuses.length > 0">
              {{ row.statuses[0].status | hsrStatus | insertSpaces }}
            </ng-container>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="needsReview">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Needs Review </th>
        <td mat-cell *matCellDef="let row" width="15%" fxLayout="row" fxLayoutAlign="center">{{ row.needsReview ? 'Yes' : 'No' }}</td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="10%"> View Details </th>
        <td mat-cell *matCellDef="let row" width="10%">
          <div fxLayout="row">
            <button mat-icon-button color="primary" (click)="openPaymentCardRequest(row.id)" matTooltip="View Payment Card Request">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="[10]" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>

<ng-template #loading>
  <div class="sub-header">
    <mat-spinner style="margin:auto auto;" class="center-spinner"></mat-spinner>
  </div>
</ng-template>
