<h3>Bill Images</h3>

<div class="bill-images-modal-container">
  <mat-dialog-content *ngIf="billImages$ | async as billImages;else loading">
    <div *ngFor="let image of billImages" fxLayout="row" fxLayoutAlign="space-between">
      {{ image.fileName }}
      <button mat-icon-button
        color="primary"
        (click)="downloadImage(image.id, image.fileType, image.fileName)"
        matTooltip="Download"
        matTooltipPosition="below">
        <mat-icon>get_app</mat-icon>
      </button>
    </div>
  </mat-dialog-content>
</div>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>

<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
