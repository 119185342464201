import { IBenefitSummaryItemModel } from './benefit-summary-item.model';

export interface IBenefitSummary {
  deductible?: number;
  outOfPocketMax?: number;
  deductibleUsed?: number;
  outOfPocketUsed?: number;
  primaryCare: IBenefitSummaryItemModel;
  specialist: IBenefitSummaryItemModel;
  urgentCare: IBenefitSummaryItemModel;
  emergencyRoom: IBenefitSummaryItemModel;
  immunizations: IBenefitSummaryItemModel;
  mriScan: IBenefitSummaryItemModel;
  petScan: IBenefitSummaryItemModel;
  catScan: IBenefitSummaryItemModel;
  diagnosticLab: IBenefitSummaryItemModel;
  diagnosticXray: IBenefitSummaryItemModel;
  physicalTherapy: IBenefitSummaryItemModel;
  occupationalTherapy: IBenefitSummaryItemModel;
  speechTherapy: IBenefitSummaryItemModel;
  hospiceFacilityCharge: IBenefitSummaryItemModel;
  emergencyMedicalTransport: IBenefitSummaryItemModel;
  chiropractic: IBenefitSummaryItemModel;
  acupuncture: IBenefitSummaryItemModel;
  familyPlanning: IBenefitSummaryItemModel;
}
