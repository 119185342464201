import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BalanceBillStatus } from '@enums';
import { BalanceBillFacade } from '@facades';
import { IBalanceBillStatusPostModel } from '@models';

@Component({
  selector: 'mx-balance-bill-status-modal',
  templateUrl: './balance-bill-status-modal.component.html',
  styleUrls: ['./balance-bill-status-modal.component.scss']
})
export class BalanceBillStatusModalComponent implements OnInit {

  public statusForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { billId: number; status: BalanceBillStatus },
    private fb: FormBuilder,
    private balanceBillFacade: BalanceBillFacade
  ) { }

  ngOnInit() {
    this.statusForm = this.fb.group({
      status: this.data.status,
      note: ''
    });
  }

  clickUpdateStatus() {
    this.balanceBillFacade.updateBalanceBillStatus(this.statusForm.value as IBalanceBillStatusPostModel, this.data.billId);
  }

  clickCancelUpdateStatus() {
    this.balanceBillFacade.cancelSave.emit(null);
  }

}
