import { FormControl, Validators } from '@angular/forms';
import { IMeMdConfigResponseModel } from '@models';

export class MeMdConfigFormModel {
  public id = new FormControl();
  public planId = new FormControl();
  public username = new FormControl();
  public password = new FormControl();
  public clientId = new FormControl();
  public clientSecret = new FormControl();
  public planCode = new FormControl();

  constructor(meMdConfig: IMeMdConfigResponseModel) {
    this.id.setValue(meMdConfig.id);

    this.planId.setValue(meMdConfig.planId);

    this.username.setValue(meMdConfig.username);
    this.username.setValidators([Validators.required, Validators.maxLength(64)]);

    this.password.setValue(meMdConfig.password);
    this.password.setValidators([Validators.required, Validators.maxLength(64)]);

    this.clientId.setValue(meMdConfig.clientId);
    this.clientId.setValidators([Validators.required, Validators.maxLength(64)]);

    this.clientSecret.setValue(meMdConfig.clientSecret);
    this.clientSecret.setValidators([Validators.required, Validators.maxLength(64)]);

    this.planCode.setValue(meMdConfig.planCode);
    this.planCode.setValidators([Validators.required, Validators.maxLength(64)]);
  }
}
