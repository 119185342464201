import {
  IPaymentCardRequestDetailResponseModel,
  IPaymentCardRequestPostModel,
  IPaymentCardRequestResponseModel,
  IPaymentCardRequestStatusPostModel
} from '@models';
import { createAction, props } from '@ngrx/store';


export const loadPaymentCardRequest = createAction(
  '[Payment Card Request] Load Payment Card Request',
  props<{ id: number }>()
);

export const loadPaymentCardRequestSuccess = createAction(
  '[Payment Card Request] Load Payment Card Request Success',
  props<{ paymentCardRequest: IPaymentCardRequestDetailResponseModel }>()
);

export const loadPaymentCardRequestFailure = createAction(
  '[Payment Card Request] Load Payment Card Request Failure',
  props<{ error: any }>()
);

export const loadPaymentCardRequestsByPartner = createAction(
  '[Payment Card Request] Load Payment Card Requests By Partner',
  props<{ userId: number }>()
);

export const loadPaymentCardRequestsByPartnerSuccess = createAction(
  '[Payment Card Request] Load Payment Card Requests By Partner Success',
  props<{ paymentCardRequests: IPaymentCardRequestResponseModel[] }>()
);

export const loadPaymentCardRequestsByPartnerFailure = createAction(
  '[Payment Card Request] Load Payment Card Requests By Partner Failure',
  props<{ error: any }>()
);

export const loadPaymentCardRequestsByMember = createAction(
  '[Payment Card Request] Load Payment Card Requests By Member',
  props<{ personId: number }>()
);

export const loadPaymentCardRequestsByMemberSuccess = createAction(
  '[Payment Card Request] Load Payment Card Requests By Member Success',
  props<{ paymentCardRequests: IPaymentCardRequestResponseModel[] }>()
);

export const loadPaymentCardRequestsByMemberFailure = createAction(
  '[Payment Card Request] Load Payment Card Requests By Member Failure',
  props<{ error: any }>()
);

export const updatePaymentCardRequestStatus = createAction(
  '[Payment Card Request Status] Update Payment Card Request Status',
  props<{ model: IPaymentCardRequestStatusPostModel; paymentCardRequestId: number }>()
);

export const updatePaymentCardRequestStatusSuccess = createAction(
  '[Payment Card Request Status] Update Payment Card Request Status Success',
  props<{ paymentCardRequest: IPaymentCardRequestResponseModel }>()
);

export const updatePaymentCardRequestStatusFailure = createAction(
  '[Payment Card Request Status] Update Payment Card Request Status Failure',
  props<{ error: any }>()
);

export const sendPaymentCardRequest = createAction(
  '[Payment Card Form] Send Payment Card Request',
  props<{ request: IPaymentCardRequestPostModel }>()
);

export const sendPaymentCardRequestSuccess = createAction(
  '[Payment Card Form] Send Payment Card Request Success'
);

export const sendPaymentCardRequestFailure = createAction(
  '[Payment Card Form] Send Payment Card Request Failure',
  props<{ error: any }>()
);


export const clearPaymentCardRequests = createAction(
  '[Payment Card Request] Clear Payment Card Requests'
);
