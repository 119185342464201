import { AnalyticsActions } from '@root-store';
import { Injectable } from '@angular/core';
import { SuperBillService } from '@backend';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SuperBillActions } from '.';
import { AppState } from 'src/app/root-store/root.state';


@Injectable()
export class SuperBillsEffects {
  // getAllSuperBills$ = createEffect(() => this.actions$.pipe(
  //   ofType(SuperBillActions.getAllSuperBills),
  //   switchMap(() => this.store.pipe(
  //     select(selectPersonId),
  //     switchMap(personId => this.superBillService.getByPersonId(personId)))),
  //   map(superBills => SuperBillActions.getAllSuperBillsSuccess({ superBills }))
  // ));

  // createSuperBill$ = createEffect(() => this.actions$.pipe(
  //   ofType(SuperBillActions.createSuperBill),
  //   withLatestFrom(this.store.select(selectPersonId)),
  //   switchMap(([action, personId]) => this.superBillService.createBill(personId, action.superBill, action.images)),
  //   map((superBill) => {
  // this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.SuperBillSubmitted }));
  // this.store.dispatch(SuperBillActions.getAllSuperBills());
  // console.log('superBill :>> ', superBill);
  // this.store.dispatch(SuperBillActions.createSuperBillSuccess({ superBill }));

  // this.mxAlertService.alertWithOptions(
  //   'Success',
  //   'Your claim was submitted successfully.',
  //   'OK'
  // );

  // this.dialogRef.closeAll();
  // })
  // ), { dispatch: false });

  // createHSR$ = createEffect(() => this.actions$.pipe(
  // ofType(SuperBillActions.createHSR),
  //   switchMap(action => this.superBillService.postHSR(action.HSR, action.personId)),
  //   map((HSR) => SuperBillActions.createHSRSuccess({ HSR }))
  // ));



  // loadSuperBillDetails$ = createEffect(() => this.actions$.pipe(
  //   ofType(SuperBillActions.loadHSRDetails),
  //   switchMap(action =>
  //     forkJoin([
  //       this.superBillService.getSuperBillDetails(action.id),
  //       of(action.id)
  //     ])
  //   ),
  //   map(([hsr, id]) => SuperBillActions.loadHSRDetailsSuccess({ id, changes: hsr }))
  // ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private superBillService: SuperBillService
  ) { }
}
