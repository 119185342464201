import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReportingActions, ReportingSelectors } from '@root-store';
import { AppState } from 'src/app/root-store/root.state';
import { RegistrationDateRange } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ReportingFacade {
  public groupRegistrationData$ = this.store.select(ReportingSelectors.selectGroupRegistrationReportData);
  public aggregatedMemberCountsByDate$ = this.store.select(ReportingSelectors.selectAggregatedMemberCountsByDate);
  public isLoadingAggregatedMemberCountsByDate$ = this.store.select(ReportingSelectors.selectIsLoadingAggregatedMemberCountsByDate);
  public aggregatedMemberCountsByDateError$ = this.store.select(ReportingSelectors.selectAggregatedMemberCountsByDateError);
  public groupsMemberCount$ = this.store.select(ReportingSelectors.selectGroupsMemberCount);
  public isLoadingGroupsMemberCount$ = this.store.select(ReportingSelectors.selectIsLoadingGroupsMemberCount);
  public groupsMemberCountError$ = this.store.select(ReportingSelectors.selectGroupsMemberCountError);
  public aggregatedUserLoginsByDate$ = this.store.select(ReportingSelectors.selectAggregatedUserLoginsByDate);
  public isLoadingAggregatedUserLoginsByDate$ = this.store.select(ReportingSelectors.selectIsLoadingAggregatedUserLoginsByDate);
  public aggregatedUserLoginsByDateError$ = this.store.select(ReportingSelectors.selectAggregatedUserLoginsByDateError);
  public groupUserLoginsByDate$ = this.store.select(ReportingSelectors.selectGroupUserLoginsByDate);
  public isLoadingGroupUserLoginsByDate$ = this.store.select(ReportingSelectors.selectIsLoadingGroupUserLoginsByDate);
  public groupUserLoginsByDateError$ = this.store.select(ReportingSelectors.selectGroupUserLoginsByDateError);

  constructor(
    private store: Store<AppState>
  ) {}

  public loadRegistrationTrend(groupId: number, range: RegistrationDateRange): void {
    this.store.dispatch(ReportingActions.loadRegistrationTrend({ groupId, range }));
  }

  public loadAggregatedMemberCountsByDate(dateRange: RegistrationDateRange): void {
    this.store.dispatch(ReportingActions.loadAggregatedMemberCountsByDate({ dateRange }));
  }

  public loadGroupsMemberCount(): void {
    this.store.dispatch(ReportingActions.loadGroupsMemberCount());
  }

  public loadAggregatedUserLoginsByDate(dateRange: RegistrationDateRange): void {
    this.store.dispatch(ReportingActions.loadAggregatedUserLoginsByDate({ dateRange }));
  }

  public loadGroupUserLoginsByDate(dateRange: RegistrationDateRange, groupId: number): void {
    this.store.dispatch(ReportingActions.loadGroupUserLoginsByDate({ dateRange, groupId }));
  }
}
