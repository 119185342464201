import { Injectable } from '@angular/core';
import { HSRStatus } from '@enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DocumentsActions } from '.';
import { IHSRResponseModel } from '../../../backend/entities/health-service-request-response.model';
import { DocumentsService } from '../../../backend/services/documents.service';
import { PaymentCardService } from '@backend';
import { AppState } from '../../../root-store/root.state';

@Injectable()
export class DocumentsEffects {
  getAllServiceRequests$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getMemberHSRs),
    switchMap(action => this.documentsService.getMemberHSRs(action.personId)),
    map((requests: IHSRResponseModel[]) => DocumentsActions.getMemberHSRsSuccess({ requests }))
  ));

  getAllPartnerRequests$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getPartnerHSRs),
    switchMap(action => this.documentsService.getPartnerHSRs(action.userId)),
    map((requests: IHSRResponseModel[]) => DocumentsActions.getMemberHSRsSuccess({ requests }))
  ));

  createHSR$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.createHSR),
    switchMap(action => this.documentsService.postHSR(action.hsr)
      .pipe(
        switchMap(hsr => {
          if (action.requestPaymentCard) {
            const hsrStatusPostModel = {
              status: HSRStatus.CardRequested,
              note: ''
            };
            return this.documentsService.postHSRStatus(hsrStatusPostModel, hsr.id);
          }

          return of(hsr);
        }),
        map(hsr => DocumentsActions.createHSRSuccess({ hsr })),
        catchError(error => {
          this.documentsService.postFailure$.next(null);
          return of(DocumentsActions.createHSRFailure({ error }));
        })
      ))
  ));

  requestNewCard$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.requestNewCard),
    switchMap(action => combineLatest([of(action.hsrStatus), this.documentsService.putHSR(action.hsr)])
      .pipe(
        switchMap(([hsrStatus, hsr]) => this.documentsService.postHSRStatus(hsrStatus, hsr.id)),
        map(hsr => DocumentsActions.requestNewCardSuccess({ hsr }))
      )
    ),
  ));

  updateHSRStatus$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.updateHSRStatus),
    switchMap(action => this.documentsService.postHSRStatus(action.hsrStatus, action.hsrId)),
    map((hsr) => DocumentsActions.updateHSRStatusSuccess({ hsr }))
  ));

  loadHSRDetails$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getHSRDetails),
    switchMap(action => this.documentsService.getHSRDetails(action.id)),
    switchMap(hsr => {
      const results: Observable<any>[] = [of(hsr)];

      if (hsr.paymentCard) {
        results.push(this.paymentCardService.getPaymentCardNumber(hsr.paymentCardId).pipe(map(result => result?.number)));
      } else {
        results.push(of(null));
      }

      return forkJoin(results);
    }),
    map(([hsr, paymentCardNumber]) => DocumentsActions.getHSRDetailsSuccess({ hsr, paymentCardNumber }))
  ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private documentsService: DocumentsService,
    private paymentCardService: PaymentCardService
  ) { }
}
