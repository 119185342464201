import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public isDarkTheme: Observable<boolean>;
  private darkTheme = new Subject<boolean>();

  constructor() {
    this.isDarkTheme = this.darkTheme.asObservable();
  }

  setDarkTheme(isDarkTheme: boolean): void {
    this.darkTheme.next(isDarkTheme);
  }
}
