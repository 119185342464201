import {
  IExternalNetworkMappingResponseModel, IPlanTierPostModel, IPlanTierPutModel,
  IPlanTierResponseModel, IExternalNetworkAutocompleteResponseModel, INetworkTierPutModel,
  INetworkTier, IProviderNetworkResponseModel
} from '@models';
import { createAction, props } from '@ngrx/store';

export const loadPlanTiers = createAction(
  '[PlanTier] Load Plan Tiers',
  props<{ planId: number }>()
);

export const loadPlanTiersSuccess = createAction(
  '[PlanTier] Load Plan Tiers Success',
  props<{ planTiers: IPlanTierResponseModel[] }>()
);

export const loadPlanTiersFailure = createAction(
  '[PlanTier] Load Plan Tiers Failure',
  props<{ error: any }>()
);

export const createPlanTier = createAction(
  '[PlanTier] Create Plan Tier',
  props<{ planId: number; planTier: IPlanTierPostModel }>()
);

export const createPlanTierSuccess = createAction(
  '[PlanTier] Create Plan Tier Success',
  props<{ planTier: IPlanTierResponseModel }>()
);

export const createPlanTierFailure = createAction(
  '[PlanTier] Create Plan Tier Failure',
  props<{ error: any }>()
);

export const updatePlanTier = createAction(
  '[PlanTier] Update Plan Tier',
  props<{ planId: number; tier: number; planTier: IPlanTierPutModel }>()
);

export const updatePlanTierSuccess = createAction(
  '[PlanTier] Update Plan Tier Success',
  props<{ planTier: IPlanTierResponseModel }>()
);

export const updatePlanTierFailure = createAction(
  '[PlanTier] Update Plan Tier Failure',
  props<{ error: any }>()
);

export const deletePlanTier = createAction(
  '[PlanTier] Delete Plan Tier',
  props<{ planId: number; tier: number }>()
);

export const deletePlanTierSuccess = createAction(
  '[PlanTier] Delete Plan Tier Success',
  props<{ tier: number }>()
);

export const deletePlanTierFailure = createAction(
  '[PlanTier] Delete Plan Tier Failure',
  props<{ error: any }>()
);

export const loadPlanTierNetworks = createAction(
  '[PlanTier] Load Plan Tier Networks',
  props<{ planId: number; tier: number }>()
);

export const loadPlanTierNetworksSuccess = createAction(
  '[PlanTier] Load Plan Tier Networks Success',
  props<{ planTierNetworks: IProviderNetworkResponseModel[] }>()
);

export const loadPlanTierNetworksFailure = createAction(
  '[PlanTier] Load Plan Tier Networks Failure',
  props<{ error: any }>()
);

export const addPlanTierNetwork = createAction(
  '[PlanTier] Add Plan Tier Network',
  props<{ planId: number; tier: number, networkId: number }>()
);

export const addPlanTierNetworkSuccess = createAction(
  '[PlanTier] Add Plan Tier Network Success',
  props<{ planTierNetwork: IProviderNetworkResponseModel }>()
);

export const addPlanTierNetworkFailure = createAction(
  '[PlanTier] Add Plan Tier Network Failure',
  props<{ error: any }>()
);

export const removePlanTierNetwork = createAction(
  '[PlanTier] Remove Plan Tier Network',
  props<{ planId: number; tier: number, networkId: number }>()
);

export const removePlanTierNetworkSuccess = createAction(
  '[PlanTier] Remove Plan Tier Network Success'
);

export const removePlanTierNetworkFailure = createAction(
  '[PlanTier] Remove Plan Tier Network Failure',
  props<{ error: any }>()
);

export const getExternalNetworkSuggestions = createAction(
  '[PlanTier] Get External Network Suggestions',
  props<{ searchTerm: string }>()
);

export const getExternalNetworkSuggestionsSuccess = createAction(
  '[PlanTier] Get External Network Suggestions Success',
  props<{ externalNetworks: IExternalNetworkAutocompleteResponseModel[] }>()
);

export const getExternalNetworkSuggestionsFailure = createAction(
  '[PlanTier] Get External Network Suggestions Failure',
  props<{ error: any }>()
);

export const loadPlanTierExternalNetworks = createAction(
  '[PlanTier] Load Plan Tier External Networks',
  props<{ planId: number; tier: number }>()
);

export const loadPlanTierExternalNetworksSuccess = createAction(
  '[PlanTier] Load Plan Tier External Networks Success',
  props<{ externalNetworks: IExternalNetworkMappingResponseModel[] }>()
);

export const loadPlanTierExternalNetworksFailure = createAction(
  '[PlanTier] Load Plan Tier External Networks Failure',
  props<{ error: any }>()
);

export const addPlanTierExternalNetwork = createAction(
  '[PlanTier] Add Plan Tier External Network',
  props<{ planId: number; tier: number; ribbonInsuranceId: string }>()
);

export const addPlanTierExternalNetworkSuccess = createAction(
  '[PlanTier] Add Plan Tier External Network Success',
  props<{ externalNetwork: IExternalNetworkMappingResponseModel }>()
);

export const addPlanTierExternalNetworkFailure = createAction(
  '[PlanTier] Add Plan Tier External Network Failure',
  props<{ error: any }>()
);

export const removePlanTierExternalNetwork = createAction(
  '[PlanTier] Remove Plan Tier External Network',
  props<{ planId: number; tier: number; ribbonInsuranceId: string }>()
);

export const removePlanTierExternalNetworkSuccess = createAction(
  '[PlanTier] Remove Plan Tier External Network Success',
  props<{ ribbonInsuranceId: string }>()
);

export const removePlanTierExternalNetworkFailure = createAction(
  '[PlanTier] Remove Plan Tier External Network Failure',
  props<{ error: any }>()
);

export const updatePlanTierBenefits = createAction(
  '[PlanTier] Update Plan Tier Benefits',
  props<{ planId: number; tier: number; planTierBenefits: INetworkTierPutModel }>()
);

export const updatePlanTierBenefitsSuccess = createAction(
  '[PlanTier] Update Plan Tier Benefits Success',
  props<{ planTierBenefits: INetworkTier; tier: number }>()
);

export const updatePlanTierBenefitsFailure = createAction(
  '[PlanTier] Update Plan Tier Benefits Failure',
  props<{ error: any }>()
);

export const getPlanTierBenefits = createAction(
  '[PlanTier] Get Plan Tier Benefits',
  props<{ planId: number }>()
);

export const getPlanTierBenefitsSuccess = createAction(
  '[PlanTier] Get Plan Tier Benefits Success',
  props<{ planTierBenefits: INetworkTier[] }>()
);

export const getPlanTierBenefitsFailure = createAction(
  '[PlanTier] Get Plan Tier Benefits Failure',
  props<{ error: any }>()
);

export const clonePlanTiersFromPlan = createAction(
  '[PlanTier] Clone Plan Tiers From Plan',
  props<{ planId: number; planIdToCloneFrom: number }>()
);

export const clonePlanTiersFromPlanSuccess = createAction(
  '[PlanTier] Clone Plan Tiers From Plan Success'
);

export const clonePlanTiersFromPlanFailure = createAction(
  '[PlanTier] Clone Plan Tiers From Plan Failure',
  props<{ error: any }>()
);

export const clearPlanTiersState = createAction(
  '[PlanTier] Clear Plan Tier State'
);
