import { Component } from '@angular/core';

@Component({
  selector: 'mx-super-bill-form',
  templateUrl: './super-bill-form.component.html',
  styleUrls: ['./super-bill-form.component.scss']
})
export class SuperBillFormComponent {
  // @Input() isSubmitting: boolean;
  // @Input() superBillSettings: ISuperBillSettings;
  // @Output() submitSuperBill = new EventEmitter<{ bill: ISuperBillPostModel; files: File[] }>();
  // @Output() closeModal = new EventEmitter<boolean>();

  // // TODO: Move all of this somewhere else it is clogging up components....
  // public medicalPolicy$ = this.store.pipe(
  //   select(selectPolicies),
  //   map(policies => policies.find(policy => policy.plan.type === PlanType.Medical)),
  // );
  // public isSubscriber$ = this.medicalPolicy$.pipe(map(medicalPolicy => medicalPolicy.relationship === 1));
  // public memberPolicyId: number;
  // public memberData: any;
  // // TODO end

  // public files: File[];
  // public superBillForm: FormGroup;
  // public vendor: any;
  // public TerminologyType = TerminologyType;
  // public patientList$: Observable<any>;
  // public currentPatient: any;

  // constructor(
  //   @Inject(MAT_DIALOG_DATA) public data: { hsr: IHSRResponseModel, provider: Provider },
  //   private fb: FormBuilder,
  //   private mxAlertService: MxAlertService,
  //   private superBillFacade: SuperBillFacade,
  //   private policyService: PolicyService,
  //   private store: Store<AppState>
  // ) {}

  // ngOnInit() {
  //   // TODO: SET MEMBER FORM, clean this up later
  //   combineLatest([this.isSubscriber$, this.medicalPolicy$])
  //   .pipe(
  //     take(1),
  //     switchMap(([isSubscriber, medicalPolicy]) => isSubscriber
  //       ? of(medicalPolicy)
  //       : this.policyService.getPolicy(medicalPolicy.parentPolicyId)),
  //     // TODO: Hack, because the policy endpoint does not return the group number. Always use logged in user group number for now
  //     withLatestFrom(this.medicalPolicy$.pipe(map(policy => policy.plan.groupNumber))))
  //   .subscribe(
  //     (([policy, groupNumber]) => {
  //       this.memberData = {
  //         policyId: policy.id,
  //         firstName: policy.firstName,
  //         lastName: policy.lastName,
  //         dateOfBirth: policy.dateOfBirth,
  //         memberNumber: policy.memberNumber,
  //         groupNumber
  //       };
  //       console.log('this.memberData :>> ', this.memberData);
  //     })
  //   );

  //   this.files = [];
  //   // TODO: Remove initial values
  //   this.superBillForm = this.fb.group({
  //     billForm: this.fb.group({
  //       charged: [500, Validators.required],
  //       paid: [500, Validators.required],
  //       date: ['', Validators.required],
  //       isHospitalBill: [true, Validators.required]
  //     }),
  //     memberForm: this.fb.group({
  //       email: ['', Validators.required],
  //       phoneNumber: ['', Validators.required]
  //     }),
  //     medicalServicesForm: this.fb.group({
  //       procedureCode: ['', Validators.required],
  //       diagnosisCode: ['', Validators.required],
  //       serviceDate: ['', Validators.required],
  //       units: ['', Validators.required],
  //       chargeAmount: [null, Validators.required]
  //     }),
  //     providerForm: this.fb.group({
  //       name: ['Faux Provider Name', Validators.required],
  //       address1: ['123 West Street', Validators.required],
  //       address2: '',
  //       city: ['Atlanta', Validators.required],
  //       state: ['GA', Validators.compose([
  //         Validators.required,
  //         Validators.minLength(2),
  //         Validators.maxLength(2)
  //       ])],
  //       postalCode: ['12345', Validators.compose([
  //         Validators.required,
  //         Validators.minLength(5),
  //         Validators.maxLength(5)
  //       ])],
  //       countryCode: ['US', Validators.compose([
  //         Validators.required,
  //         Validators.maxLength(2)
  //       ])],
  //       npi: '',
  //       tin: ''
  //     })
  //   });
  //   this.vendor = Vendor;
  // }

  // public clickSubmit() {
  //   this.superBillForm.markAllAsTouched();

  //   const formValue = this.superBillForm.getRawValue();

  //   this.store.select(selectPersonId)
  //     .pipe(take(1))
  //     .subscribe((personId) => {
  //       // TODO: Fix hardcoded date
  //       const superBillPost: ISuperBillPostModel = {
  //         healthServiceRequestId: 110, // TODO: Replace with proper value
  //         memberFirstName: this.memberData.firstName,
  //         memberLastName: this.memberData.lastName,
  //         memberContactEmail: 'john@nothing.com',
  //         memberContactPhone: '123456789',
  //         memberPersonId: 1,
  //         memberGroupNumber: this.memberData.groupNumber,
  //         memberMemberNumber: this.memberData.memberNumber,
  //         memberDateOfBirth: this.memberData.groupNumber,
  //         memberPolicyId: this.memberData.policyId,
  //         totalAmountCharged: formValue.billForm.charged,
  //         totalAmountPaid: formValue.billForm.paid,
  //         billDate: this.formatDate(formValue.billForm.date),
  //         isHospitalBill: formValue.billForm.isHospitalBill,
  //         patientPersonId: 0,
  //         patientGroupNumber: this.memberData.groupNumber,
  //         patientFirstName: this.currentPatient.value.firstName,
  //         patientLastName: this.currentPatient.value.lastName,
  //         patientMemberNumber: this.currentPatient.value.memberNumber,
  //         patientDateOfBirth: this.currentPatient.value.dateOfBirth,
  //         patientPolicyId: this.currentPatient.id,
  //         providerName: formValue.providerForm.name,
  //         providerAddressLine1: formValue.providerForm.address1,
  //         providerAddressLine2: formValue.providerForm.address2,
  //         providerCity: formValue.providerForm.city,
  //         providerState: formValue.providerForm.state,
  //         providerPostalCode: formValue.providerForm.postalCode,
  //         providerNpi: formValue.providerForm.npi,
  //         providerTin: formValue.providerForm.tin,
  //         superBillImages: [],
  //         status: {
  //           status: 1,
  //           note: ''
  //         }
  //       };

  //       console.log('superBillPost :>> ', superBillPost);
  //       // if (!this.superBillForm.valid) {
  //       //   this.mxAlertService.alert('Please fix any errors on the form before continuing.');
  //       //   return;
  //       // }

  //       if (this.files.length === 0) {
  //         this.mxAlertService.alert('An image of your claim is required to proceed.');
  //         return;
  //       }
  //       this.superBillFacade.createSuperbill(superBillPost, this.files);

  //       // this.files = [];
  //     });



  // }

  // public clickAddImage(event: any) {
  //   const newFile = event.target.files[0];
  //   if (newFile != null && newFile.size < BILL_IMAGE_FILE_SIZE_LIMIT) {
  //     this.files = this.files.concat(Array.from<File>(event.target.files));
  //   } else {
  //     this.mxAlertService.alert('File must be less than 6MB.');
  //   }
  // }

  // public clickRemoveImage(index: number) {
  //   if (this.files.length > 0) {
  //     this.files.splice(index, 1);
  //   }
  // }

  // public onChangePatient(patient: any) {
  //   if (patient) {
  //     this.currentPatient = patient;
  //   }
  // }

  // private formatDate(date: any): string {
  //   if (date) {
  //     date = date.toLocaleDateString();
  //     const year = date.slice(-4);
  //     const month = date.substring(0, 2);
  //     let day = date.substring(
  //       date.indexOf('/') + 1,
  //       date.lastIndexOf('/')
  //     );

  //     if (day.length === 1) {
  //       day = '0' + day;
  //     }

  //     return year + '-' + month + '-' + day;
  //   } else {
  //     return null;
  //   }

  // }
}
