import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { RegistrationDateRange } from '@enums';
import { IMemberCountByGroupModel, IMemberCountsAggregatedByDateResponseModel, IUserLoginsByDateResponseModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private http: HttpClient
  ) { }

  public getRegistrationTrend(groupId: number, dateRange: RegistrationDateRange): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/memberReport/${groupId}/${dateRange}`;
    return this.http.get<any>(url);
  }

  public getAggregatedMemberCountsByDate(dateRange: RegistrationDateRange): Observable<IMemberCountsAggregatedByDateResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/report/memberCountsAggregated/${dateRange}`;
    return this.http.get<IMemberCountsAggregatedByDateResponseModel[]>(url);
  }

  public getGroupsMemberCount(): Observable<IMemberCountByGroupModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/report/memberCountsByGroup`;
    return this.http.get<IMemberCountByGroupModel[]>(url);
  }

  public getUserLoginsByDate(dateRange: RegistrationDateRange, groupId?: number): Observable<IUserLoginsByDateResponseModel[]> {
    let url = `${environment.config.apiEndpoint}/v2/report/userLoginsAggregated/${dateRange}`;

    if (groupId) {
      url =`${url}?groupId=${groupId}`;
    }

    return this.http.get<IUserLoginsByDateResponseModel[]>(url);
  }
}
