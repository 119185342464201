import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { AuthFacade } from '@facades';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private router: Router,
    private authFacade: AuthFacade
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.authToken$.pipe(
      take(1),
      switchMap(jwt => {
        if (jwt) {
          return of(true);
        } else {
          if (state.url !== '/' && state.url !== '') {
            void this.router.navigate(['./login'], {
              queryParams: {
                return: state.url
              }
            });
          } else {
            void this.router.navigate(['./login']);
          }
          return of(false);
        }
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.authToken$.pipe(
      take(1),
      switchMap(jwt => {
        if (jwt) {
          return of(true);
        } else {
          if (state.url !== '/' && state.url !== '') {
            void this.router.navigate(['./login'], {
              queryParams: {
                return: state.url
              }
            });
          } else {
            void this.router.navigate(['./login']);
          }
          return of(false);
        }
      })
    );
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
