/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { IRole } from '../../shared/models/role.model';
import { StandardRole } from '../../shared/roles';

export const authFeatureKey = 'auth';

export interface AuthState {
  jwtBearerToken: string;
  userId: number;
  email: string;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  sessionTimeLeft: number;
  role: IRole;
}

export const initialAuthState: AuthState = {
  jwtBearerToken: '',
  userId: null,
  email: '',
  isLoggingIn: false,
  isLoggedIn: false,
  sessionTimeLeft: null,
  role: StandardRole
};

export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.login, (state, action) => ({ ...state, isLoggingIn: true })),
  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    jwtBearerToken: action.data.token,
    email: action.email,
    isLoggingIn: false,
    isLoggedIn: true
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    isLoggingIn: false,
    isLoggedIn: false
  })),
  on(AuthActions.register, (state, action) => ({ ...state, isLoggingIn: true })),
  on(AuthActions.registerSuccess, (state, action) => ({
    ...state,
    jwtBearerToken: action.data.Token,
    isLoggingIn: false,
    isLoggedIn: true
  })),
  on(AuthActions.registerFailure, (state, action) => ({
    ...state,
    isLoggingIn: false,
    isLoggedIn: false
  })),
  on(AuthActions.logout, (state, action) => ({
    ...state,
    jwtBearerToken: initialAuthState.jwtBearerToken,
    isLoggingIn: false,
    isLoggedIn: false,
    role: StandardRole,
    userId: null
  })),
  on(AuthActions.decodeAuthTokenSuccess, (state, action) => ({
    ...state,
    userId: action.data.user_id,
    email: action.data.userName
  })),
  on(AuthActions.refreshAuthTokenSuccess, (state, action) => ({ ...state, jwtBearerToken: action.data.token })),
  on(AuthActions.loadAuthCookieSuccess, (state, action) => ({
    ...state, jwtBearerToken: action.token,
    isLoggingIn: false,
    isLoggedIn: true
  })),
  on(AuthActions.setSessionTimeLeft, (state, action) => ({ ...state, sessionTimeLeft: action.time })),
  on(AuthActions.getRoleSuccess, (state, action) => ({
    ...state,
    role: action.role
  }))

);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
