export interface IMemberContactResponseModel {
  id: number;
  groupId: number;
  type: number;
  name: string;
  value: Record<string, unknown>;
  description: string;
  createdAt: string;
  updatedAt: string;
}
