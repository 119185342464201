import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TerminologyConfigService } from '@backend';
import * as TerminologyActions from './terminology.actions';

@Injectable({
  providedIn: 'root'
})
export class TerminologyEffects {

  loadTerminology$ = createEffect(() => this.actions$.pipe(
    ofType(TerminologyActions.loadTerminology),
    switchMap(() => this.terminologyConfigService.getAll()
      .pipe(
        map(terminology => TerminologyActions.loadTerminologySuccess({ terminology })),
        catchError(error => of(TerminologyActions.loadTerminologyFailure({ error })))
      ))
  ));

  loadTerminologyConfigsByGroup$ = createEffect(() => this.actions$.pipe(
    ofType(TerminologyActions.loadTerminologyConfigsByGroup),
    switchMap(action => this.terminologyConfigService.getByGroup(action.groupId)
      .pipe(
        map(terminologyConfigs => TerminologyActions.loadTerminologyConfigsByGroupSuccess({ terminologyConfigs })),
        catchError(error => of(TerminologyActions.loadTerminologyConfigsByGroupFailure({ error })))
      ))
  ));

  postTerminologyConfig$ = createEffect(() => this.actions$.pipe(
    ofType(TerminologyActions.postTerminologyConfig),
    switchMap(action => this.terminologyConfigService.post(action.groupId, action.terminologyConfig)
      .pipe(
        map(() => TerminologyActions.updateTerminologyConfigSuccess()),
        catchError(error => of(TerminologyActions.updateTerminologyConfigFailure({ error })))
      ))
  ));

  putTerminologyConfig$ = createEffect(() => this.actions$.pipe(
    ofType(TerminologyActions.putTerminologyConfig),
    switchMap(action => this.terminologyConfigService.put(action.terminologyConfigId, action.terminologyConfig)
      .pipe(
        map(() => TerminologyActions.updateTerminologyConfigSuccess()),
        catchError(error => of(TerminologyActions.updateTerminologyConfigFailure({ error })))
      ))
  ));

  deleteTerminologyConfig$ = createEffect(() => this.actions$.pipe(
    ofType(TerminologyActions.deleteTerminologyConfig),
    switchMap(action => this.terminologyConfigService.delete(action.terminologyConfigId)
      .pipe(
        map(() => TerminologyActions.updateTerminologyConfigSuccess()),
        catchError(error => of(TerminologyActions.updateTerminologyConfigFailure({ error })))
      ))
  ));

  constructor(
    private actions$: Actions,
    private terminologyConfigService: TerminologyConfigService
  ) {}

}
