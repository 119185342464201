import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPartner from './partner.reducer';

export const selectPartnerState = createFeatureSelector<fromPartner.PartnerState>(
  fromPartner.partnerFeatureKey
);

export const selectPartners = createSelector(
  selectPartnerState,
  (state: fromPartner.PartnerState) => state.partners
);

export const selectGroupPartners = createSelector(
  selectPartnerState,
  (state: fromPartner.PartnerState) => state.groupPartners
);
