import { PaymentCardRequestState } from '@enums';

export interface IPaymentCardRequestStatusResponseModel {
  id: number;
  paymentCardRequestId: number;
  status: PaymentCardRequestState;
  note: string;
  createdAt: string;
  updatedAt: string;
}
