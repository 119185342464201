import { PaymentCardRequestState } from '@enums';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentCardRequestStatus'
})
export class PaymentCardRequestStatusPipe implements PipeTransform {

  transform(statusCode: number): any {
    return PaymentCardRequestState[statusCode];
  }

}
