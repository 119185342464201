import { UserType } from '@enums';
import { IThreadAttachmentModel, IThreadMemberModel, IThreadPartnerModel } from '@models';

export interface IThreadMessageResponseModel {
  id: number;
  subject: string;
  body: string;
  senderDisplayName: string;
  userType: UserType;
  assignee: IThreadPartnerModel;
  member: IThreadMemberModel;
  attachments: IThreadAttachmentModel[];
  createdAt: string;
  updatedAt: string;
}
