import { Pipe, PipeTransform } from '@angular/core';
import { HSRStatus } from '../enums';

@Pipe({
  name: 'hsrStatus'
})
export class HsrStatusPipe implements PipeTransform {

  transform(hsrStatusCode: any, ...args: unknown[]): string {
    return HSRStatus[hsrStatusCode];
  }

}
