import { createAction, props } from '@ngrx/store';
import {
  ITerminologyResponseModel,
  ITerminologyConfigResponseModel,
  ITerminologyConfigPostModel
} from '@models';


export const loadTerminology = createAction(
  '[Terminology] Load Terminology'
);

export const loadTerminologySuccess = createAction(
  '[Terminology] Load Terminology Success',
  props<{ terminology: ITerminologyResponseModel[] }>()
);

export const loadTerminologyFailure = createAction(
  '[Terminology] Load Terminology Failure',
  props<{ error: any }>()
);

export const loadTerminologyConfigsByGroup = createAction(
  '[Terminology] Load Terminology Configs By Group',
  props<{ groupId: number }>()
);

export const loadTerminologyConfigsByGroupSuccess = createAction(
  '[Terminology] Load Terminology Configs By Group Success',
  props<{ terminologyConfigs: ITerminologyConfigResponseModel[] }>()
);

export const loadTerminologyConfigsByGroupFailure = createAction(
  '[Terminology] Load Terminology Configs By Group Failure',
  props<{ error: any }>()
);

export const postTerminologyConfig = createAction(
  '[Terminology] Post Terminology Config',
  props<{ groupId: number; terminologyConfig: ITerminologyConfigPostModel }>()
);

export const putTerminologyConfig = createAction(
  '[Terminology] Put Terminology Config',
  props<{ terminologyConfigId: number; terminologyConfig: ITerminologyConfigPostModel }>()
);

export const deleteTerminologyConfig = createAction(
  '[Terminology] Delete Terminology Config',
  props<{ terminologyConfigId: number }>()
);

export const updateTerminologyConfigSuccess = createAction(
  '[Terminology] Update Terminology Config Success'
);

export const updateTerminologyConfigFailure = createAction(
  '[Terminology] Update Terminology Config Failure',
  props<{ error: any }>()
);
