/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { EventName, HsrFormStepName, PlanType, PricingTier } from '@enums';
import { AnalyticsFacade, InsuranceFacade, PersonFacade } from '@facades';
import { IPatientModel, IPlanResponseModel } from '@models';
import { RouteParamsService } from '@utilities';
import { BundleListingResponseModel, Provider } from 'projects/search/src/public-api';
import { Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { IHSRPostModel } from '../../../../backend/entities/health-service-request-post.model';
import { DocumentsService } from '../../../../backend/services/documents.service';
import { DocumentsFacade } from '../../documents-store/documents.facade';
import { SubmitServiceRequestService } from './submit-service-request.service';

@Component({
  selector: 'mx-submit-service-request-modal',
  templateUrl: './submit-service-request-modal.component.html',
  styleUrls: ['./submit-service-request-modal.component.scss']
})
export class SubmitServiceRequestModalComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;

  public patientList: IPatientModel[] = [];
  public selectedPatientPolicyId: number;
  public requestForm: FormGroup;
  public subscriberPersonId: number;
  public selectedMemberId: number; // The person selected in the breadcrumb family dropdown
  public yearRange = `${new Date().getFullYear() - 5}:${new Date().getFullYear() + 5}`;
  public minCardExpirationDate: Date = moment().add(1, 'days').toDate();
  public minCardAvailableDate = moment().toDate();
  public isLoadingRequest: boolean = false;

  private groupId: number;
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { provider: Provider; bundle: BundleListingResponseModel; preventNavigation: boolean },
    private dialogRef: MatDialogRef<SubmitServiceRequestModalComponent>,
    private service: SubmitServiceRequestService,
    private documentsService: DocumentsService,
    private snackBar: MatSnackBar,
    private personFacade: PersonFacade,
    private routeParams: RouteParamsService,
    private insuranceFacade: InsuranceFacade,
    private documentFacade: DocumentsFacade,
    private analyticsFacade: AnalyticsFacade,
    private router: Router
  ) { }

  public get isFirstStep(): boolean {
    return this.stepper?.selected?.label === 'patientInfo';
  }

  public get isLastStep(): boolean {
    return this.stepper?.selected?.label === 'submittedInfo';
  }

  public get isPaymentCardStep(): boolean {
    return this.stepper?.selected?.label === 'paymentCardInfo';
  }

  ngOnInit(): void {
    this.requestForm = this.service.createRequestForm();
    this.requestForm.get('procedureForm.estimatedCharge').valueChanges.subscribe(value => {
      this.requestForm.get('paymentCardForm').patchValue({ cardAmount: value });
    });

    const latestMemberAndGroupPlans$ = combineLatest([
      this.personFacade.selectedSubscriber$,
      this.insuranceFacade.groupPlans$,
      this.routeParams.params
    ]);
    latestMemberAndGroupPlans$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(([member, _, __]) => !!member)
      )
      .subscribe(([member, groupPlans, params]) => {
        if (this.subscriberPersonId !== +params.subscriberId) {
          this.subscriberPersonId = +params.subscriberId;
          this.selectedMemberId = +params.personId;
        }

        if (groupPlans) {
          let medicalPlan: IPlanResponseModel;

          const memberFormData: IPatientModel = {
            firstName: member.firstName,
            lastName: member.lastName,
            dateOfBirth: member.dateOfBirth,
            selectedPolicyId: 0,
            personId: this.subscriberPersonId,
            memberNumber: '',
            groupNumber: ''
          };

          this.requestForm
            .get('memberForm')
            .setValue(memberFormData);

          this.patientList = [];
          member.policies.forEach(policy => {
            const foundPlan = groupPlans.find(x => x.id === policy.planId);
            if (foundPlan && foundPlan.type === PlanType.Medical) {
              medicalPlan = foundPlan;
              memberFormData.memberNumber = policy.memberNumber;
              memberFormData.groupNumber = foundPlan.groupNumber ?? '';
              memberFormData.selectedPolicyId = policy.id;

              this.requestForm
                .get('memberForm')
                .setValue(memberFormData);
              this.requestForm
                .get('patientForm')
                .setValue(memberFormData);

              this.patientList.push(memberFormData);
              return false; // Stops iterating the loop
            }
          });

          member.dependents?.forEach(dependent => {
            const medicalPolicy = dependent.policies.find(x => x.planId === medicalPlan.id);
            const patient: IPatientModel = {
              firstName: dependent.firstName,
              lastName: dependent.lastName,
              dateOfBirth: dependent.dateOfBirth,
              selectedPolicyId: undefined,
              personId: dependent.personId,
              memberNumber: '',
              groupNumber: ''
            };
            if (medicalPolicy) {
              patient.selectedPolicyId = medicalPolicy.id;
              patient.memberNumber = medicalPolicy.memberNumber;
              patient.groupNumber = medicalPlan?.groupNumber ?? '';
            }
            this.patientList.push(patient);
          });
        } else {
          this.insuranceFacade.loadPlansByGroup(+params.groupId);
        }

      });

    this.insuranceFacade.selectedGroupId$
      .pipe(filter(groupId => !!groupId))
      .subscribe(groupId => {
        this.groupId = groupId;
        this.insuranceFacade.loadModulesByGroup(groupId);
      });

    this.documentsService.postSuccess$.pipe(take(1)).subscribe(hsrId => {
      this.isLoadingRequest = false;
      this.requestForm.enable();
      this.stepper.next();
      this.analyticsFacade.logEvent(EventName.SubmittedHealthServicerequest);
      this.dialogRef.afterClosed().subscribe(() => {
        if (!this.data.preventNavigation) {
          void this.router.navigate([`${this.router.url}/${hsrId}`]);
        }
      });
    });
    this.documentsService.postFailure$.subscribe(() => {
      this.isLoadingRequest = false;
      this.requestForm.enable();
    });

    this.requestForm.get('memberForm').disable({ emitEvent: false });
    this.requestForm.get('patientForm').disable({ emitEvent: false });
    this.requestForm.get('patientForm').get('selectedPolicyId').enable({ emitEvent: false });

    if (this.data?.provider) {
      const provider = this.data.provider;
      this.requestForm
        .get('providerForm')
        .patchValue({
          name: provider.name,
          address1: provider.addressLine1,
          address2: provider.addressLine2,
          city: provider.city,
          state: provider.state,
          postalCode: provider.zip,
          npi: provider.npi
        });

      this.requestForm.get('providerForm').disable({ emitEvent: false });
      this.requestForm.get('providerForm').get('npi').enable({ emitEvent: false });
    }

    if (this.data?.bundle) {
      const bundle = this.data.bundle;
      this.requestForm
        .get('procedureForm')
        .patchValue({
          estimatedCharge: bundle.pricingTier === PricingTier.Best ? bundle.price[1] : bundle.targetPrice[1],
          cptCode: bundle.bundle.indexCptCodes.join(', '),
          procedureInfo: bundle.bundle.description
        });

      this.requestForm.get('procedureForm').disable({ emitEvent: false });
      this.requestForm.get('procedureForm').get('dateOfService').enable({ emitEvent: false });

    }
  }

  public onChangePatient(patient: any) {
    if (patient) {
      this.requestForm.get('patientForm').patchValue({
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateOfBirth: patient.dateOfBirth,
        selectedPolicyId: patient.selectedPolicyId,
        personId: patient.personId,
        groupNumber: patient.groupNumber,
        memberNumber: patient.memberNumber
      });
    }
  }

  // Get the raw form value and then create the model and post
  public submitHSR() {
    this.requestForm.markAllAsTouched();

    if (this.requestForm.valid) {
      const formValue = this.requestForm.getRawValue();

      const dateOfService = moment.utc(formValue.procedureForm.dateOfService as string).format('yyyy-MM-DD');
      const requestPaymentCard = formValue.paymentCardForm.requestPaymentCard as boolean;

      const hsr: IHSRPostModel = {
        estimatedCost: +formValue.procedureForm.estimatedCharge,
        dateOfService,
        providerAddress1: formValue.providerForm.address1,
        providerAddress2: formValue.providerForm.address2,
        providerCity: formValue.providerForm.city,
        providerName: formValue.providerForm.name,
        providerPostalCode: formValue.providerForm.postalCode,
        providerState: formValue.providerForm.state,
        subscriberPolicyId: formValue.memberForm.selectedPolicyId,
        subscriberFirstName: formValue.memberForm.firstName,
        subscriberLastName: formValue.memberForm.lastName,
        subscriberDateOfBirth: formValue.memberForm.dateOfBirth,
        subscriberPersonId: this.subscriberPersonId,
        subscriberGroupNumber: formValue.memberForm.groupNumber,
        subscriberMemberNumber: formValue.memberForm.memberNumber,
        memberPolicyId: formValue.patientForm.selectedPolicyId,
        memberFirstName: formValue.patientForm.firstName,
        memberLastName: formValue.patientForm.lastName,
        memberDateOfBirth: formValue.patientForm.dateOfBirth,
        memberPersonId: formValue.patientForm.personId,
        groupNumber: formValue.patientForm.groupNumber,
        memberNumber: formValue.patientForm.memberNumber,
        providerNPI: formValue.providerForm.npi,
        groupId: this.groupId,
        cptCode: formValue.procedureForm.cptCode,
        procedureInfo: formValue.procedureForm.procedureInfo,
        visibleToMember: requestPaymentCard ? formValue.paymentCardForm.visibleToMember : true,
        bundleListingId: this.data?.bundle?.id ?? 0,
        bundleListingPricingTier: this.data?.bundle?.pricingTier ?? PricingTier.Unknown,
        cardAmount: requestPaymentCard ? formValue.paymentCardForm.cardAmount : undefined,
        cardAvailableDate: requestPaymentCard ?
          moment.utc(formValue.paymentCardForm.cardAvailableDate as string).format('yyyy-MM-DD') :
          undefined,
        cardExpirationDate: requestPaymentCard ?
          moment.utc(formValue.paymentCardForm.cardExpirationDate as string).format('yyyy-MM-DD') :
          undefined
      };

      this.isLoadingRequest = true;
      this.requestForm.disable();
      this.documentFacade.createHSR(hsr, requestPaymentCard);
    } else {
      this.snackBar.open('Please fill out all required fields before submitting.', 'close', { duration: 3000 });
      this.stepper.selectedIndex = this.getInvalidStep();
    }

  }

  public nextStep(): void {
    if (this.isPaymentCardStep) {
      this.submitHSR();
    } else {
      this.stepper.next();
    }
  }

  public previousStep(): void {
    this.stepper.previous();
  }

  public changeAvailableDate() {
    const cardAvailableDate = this.requestForm.get('paymentCardForm.cardAvailableDate').value as string;
    if (cardAvailableDate != null) {
      this.requestForm.get('paymentCardForm').patchValue({
        cardExpirationDate: moment(cardAvailableDate).endOf('month').toDate()
      });
      this.minCardExpirationDate = moment(cardAvailableDate).add(1, 'days').toDate();
    }
  }

  private getInvalidStep(): number {
    const controlKeys = Object.keys(this.requestForm.controls);
    const invalidStepName = controlKeys.find(key => this.requestForm.get(key).invalid);
    return HsrFormStepName[invalidStepName] as number;
  }

}
