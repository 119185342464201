import { ModuleType } from '@enums';

export interface IModuleConfigResponseModel {
  id: number;
  groupId: number;
  type: ModuleType;
  order: number;
  settings: object;
  createdAt: string;
  updatedAt: string;
}
