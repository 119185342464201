import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IPartnerResponseModel } from '@models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private http: HttpClient
  ) { }

  public getPartner(userId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/partner`;
    return this.http.get<any>(url);
  }

  public getPartners(): Observable<IPartnerResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/partners`;
    return this.http.get<IPartnerResponseModel[]>(url);
  }

  public getGroupPartners(groupId: number): Observable<IPartnerResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/partners`;
    return this.http.get<IPartnerResponseModel[]>(url);
  }

  public addGroupPartner(partnerId: number, groupId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/partner/${partnerId}/group/${groupId}`;
    return this.http.post<any>(url, null);
  }

  public removeGroupPartner(partnerId: number, groupId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/partner/${partnerId}/group/${groupId}`;
    return this.http.delete<any>(url);
  }

}
