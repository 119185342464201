import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { defer, Observable, ReplaySubject, throwError } from 'rxjs';
import { environment } from '@environment';
import { IGroupPostModel, IGroupPutModel, IGroupResponseModel, Pending, Status } from '@models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BrandConfigService } from './brand-config.service';
import { GROUP_LOGO_PLACEHOLDER } from '@constants';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient,
    private brandConfigService: BrandConfigService
  ) { }

  public get(groupId: number): Observable<IGroupResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}`;
    return this.http.get<IGroupResponseModel>(url);
  }

  public getAll(): Observable<IGroupResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/groups`;
    return this.http.get<IGroupResponseModel[]>(url);
  }

  public post(group: IGroupPostModel): Pending<IGroupResponseModel> {
    const status = new ReplaySubject<Status>();

    const url = `${environment.config.apiEndpoint}/v2/group`;
    const body = group;
    const request = this.http.post<IGroupResponseModel>(url, body)
      .pipe(
        catchError((error) => {
          status.next(Status.ERROR);
          return throwError(error);
        }),
        switchMap(newGroup => this.brandConfigService.createForGroupWithOutMessage(newGroup.id,
                                                                                   {
                                                                                     primaryColorHex: 'ffffff',
                                                                                     primaryLogoUri: GROUP_LOGO_PLACEHOLDER
                                                                                   })
          .pipe(
            catchError((error) => {
              status.next(Status.ERROR);
              return throwError(error);
            }),
            map(_ => {
              status.next(Status.SUCCESS);
              this.snackBar.open(
                'Group successfully created!',
                'Close',
                { duration: 3000 }
              );
              return newGroup;
            })
          ))
      );

    const data = defer(() => {
      status.next(Status.LOADING);
      return request;
    });

    return { data, status };
  }

  public put(groupId: number, group: IGroupPutModel): Observable<IGroupResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}`;
    const body = group;
    return this.http.put<IGroupResponseModel>(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 200) {
            this.snackBar.open(
              'Group successfully updated!',
              'Close',
              { duration: 3000 }
            );
          }
        }),
        map((response) => response.body)
      );
  }

}
