import { INetworkTier, IPolicyResponseModel } from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as BenefitsActions from './benefits.actions';

export const benefitsFeatureKey = 'benefits';

export interface BenefitsState {
  selectedBenefitSummary: INetworkTier[];
  isLoadingBenefitSummary: boolean;
  selectedPolicy: IPolicyResponseModel;
  isSharingCard: boolean;
}

export const initialBenefitsState: BenefitsState = {
  selectedBenefitSummary: null,
  isLoadingBenefitSummary: undefined,
  selectedPolicy: null,
  isSharingCard: false
};

const benefitsReducer = createReducer(
  initialBenefitsState,

  on(BenefitsActions.loadBenefitSummary, (state, action) => ({
    ...state,
    isLoadingBenefitSummary: true
  })),
  on(BenefitsActions.loadBenefitSummaryFailure, (state, action) => ({
    ...state,
    selectedBenefitSummary: null,
    isLoadingBenefitSummary: false
  })),
  on(BenefitsActions.loadBenefitSummarySuccess, (state, action) => ({
    ...state,
    selectedBenefitSummary: action.benefitSummary,
    isLoadingBenefitSummary: false
  })),
  on(BenefitsActions.sharePolicyIdCard, (state, action) => ({
    ...state,
    isSharingCard: true
  })),
  on(BenefitsActions.sharePolicyIdCardSuccess, (state, action) => ({
    ...state,
    isSharingCard: false
  })),
  on(BenefitsActions.sharePolicyIdCardFailure, (state, action) => ({
    ...state,
    isSharingCard: false
  })),
  on(BenefitsActions.clearSelectedBenefitSummary, state => ({ ...state, selectedBenefitSummary: null })),
  on(BenefitsActions.setSelectedPolicySuccess, (state, action) => ({ ...state, selectedPolicy: action.policy })),
);

export function reducer(state: BenefitsState | undefined, action: Action) {
  return benefitsReducer(state, action);
}
