/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IBrandConfigResponseModel,
  IGroupResponseModel,
  IMemberContactResponseModel,
  IModuleConfigResponseModel,
  IPayerResponseModel,
  IPlanResponseModel,
  IProviderNetworkResponseModel
} from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as InsuranceActions from './insurance.actions';

export const insuranceFeatureKey = 'insurance';

export interface InsuranceState {
  selectedGroupId: number;
  selectedPlan: IPlanResponseModel;
  selectedGroupPlans: IPlanResponseModel[];
  selectedGroupContacts: IMemberContactResponseModel[];
  selectedGroupModules: IModuleConfigResponseModel[];
  selectedGroupBranding: IBrandConfigResponseModel;
  selectedPayer: IPayerResponseModel;
  groups: IGroupResponseModel[];
  plans: IPlanResponseModel[];
  providerNetworks: IProviderNetworkResponseModel[];
  selectedPlanProviderNetworks: IProviderNetworkResponseModel[];
  isCloningPlan: boolean;
}

export const initialInsuranceState: InsuranceState = {
  selectedGroupId: null,
  selectedPlan: null,
  selectedGroupPlans: null,
  selectedGroupContacts: null,
  selectedGroupModules: null,
  selectedGroupBranding: null,
  selectedPayer: null,
  groups: null,
  plans: null,
  providerNetworks: null,
  selectedPlanProviderNetworks: null,
  isCloningPlan: false
};

const benefitsReducer = createReducer(
  initialInsuranceState,

  on(InsuranceActions.loadBenefits, state => state),
  on(InsuranceActions.loadBenefitsSuccess, (state, action) => state),
  on(InsuranceActions.loadBenefitsFailure, (state, action) => state),
  on(InsuranceActions.loadPlansByGroupSuccess, (state, action) => ({ ...state, selectedGroupPlans: action.plans })),
  on(InsuranceActions.loadProviderNetworksSuccess, (state, action) => ({ ...state, providerNetworks: action.providerNetworks })),
  on(InsuranceActions.loadContactsByGroupSuccess, (state, action) => ({ ...state, selectedGroupContacts: action.contacts })),
  on(InsuranceActions.loadModulesByGroupSuccess, (state, action) => ({ ...state, selectedGroupModules: action.modules })),
  on(InsuranceActions.loadBrandingByGroupSuccess, (state, action) => ({ ...state, selectedGroupBranding: action.brand })),
  on(InsuranceActions.loadBrandingByGroupFailure, (state, action) => ({ ...state, selectedGroupBranding: null })),
  on(InsuranceActions.loadAllPlansSuccess, (state, action) => ({ ...state, plans: action.plans })),
  on(InsuranceActions.loadPayerSuccess, (state, action) => ({ ...state, selectedPayer: action.payer })),
  on(InsuranceActions.loadAllGroupsSuccess, (state, action) => ({ ...state, groups: action.groups })),
  on(InsuranceActions.createPlanSuccess, (state, action) => ({ ...state, selectedPlan: action.plan })),
  on(InsuranceActions.selectGroupSuccess, (state, action) => ({ ...state, selectedGroupId: action.groupId })),
  on(InsuranceActions.clearInsuranceState, (state, action) => Object.assign({}, initialInsuranceState )),
  on(InsuranceActions.clearPlans, (state, action) => ({ ...state, selectedPlan: null, selectedGroupPlans: null })),
  on(InsuranceActions.clearBrand, (state, action) => ({ ...state, selectedGroupBranding: null })),
  on(InsuranceActions.clearContacts, (state, action) => ({ ...state, selectedGroupContacts: null })),
  on(InsuranceActions.saveGroupSuccess, (state, action) => ({ ...state, groups: action.groups })),
  on(InsuranceActions.loadSelectedPlanProviderNetworksSuccess, (state, action) =>
    ({ ...state, selectedPlanProviderNetworks: action.providerNetworks })),
  on(InsuranceActions.loadPlanSuccess, (state, action) => ({ ...state, selectedPlan: action.plan })),
  on(InsuranceActions.loadGroupSuccess, (state, action) => {
    const currentGroupIndex = state.groups.findIndex(group => group.id === action.group.id);
    const newGroups = [...state.groups];
    newGroups[currentGroupIndex] = action.group;
    return { ...state, groups: newGroups }
  }),
  on(InsuranceActions.setIsCloningPlan, (state, action) => ({ ...state, isCloningPlan: action.isCloningPlan }))
);

export function reducer(state: InsuranceState | undefined, action: Action) {
  return benefitsReducer(state, action);
}
