import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import * as fromRouter from '@ngrx/router-store';
import { AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  fromAuth.authFeatureKey
);

export const selectRole = createSelector(
  selectAuthState,
  (state: AuthState) => state.role
);

export const selectAuthToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.jwtBearerToken
);

export const selectUserId = createSelector(
  selectAuthState,
  (state: AuthState) => state.userId
);

export const selectUserEmail = createSelector(
  selectAuthState,
  (state: AuthState) => state.email
);

export const selectLoggingIn = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLoggingIn
);

export const selectLoggedIn = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLoggedIn
);

export const selectSessionTimeLeft = createSelector(
  selectAuthState,
  (state: AuthState) => state.sessionTimeLeft
);

export interface State {
  router: fromRouter.RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

export const {
  selectCurrentRoute,   // select the current route
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteGroupId = selectRouteParam('groupId');
