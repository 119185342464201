import { Injectable } from '@angular/core';
import {
  IBrandConfigResponseModel,
  IGroupPostModel,
  IGroupPutModel, IGroupResponseModel, IMemberContactResponseModel,
  IModuleConfigResponseModel, IPayerResponseModel, IPlanPostModel,
  IPlanPutModel, IPlanResponseModel, IProviderNetworkResponseModel
} from '@models';
import { select, Store } from '@ngrx/store';
import { InsuranceActions, InsuranceSelectors, InsuranceState, PersonSelectors } from '@root-store';
import { Observable } from 'rxjs';
import { PlanService, BenefitService } from '@backend';
import { filter, map, take } from 'rxjs/operators';
import { selectSelectedGroupId } from 'src/app/root-store/insurance-store/insurance.selectors';
import { ModuleType } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class InsuranceFacade {
  // Collections
  public groups$: Observable<IGroupResponseModel[]> = this.store.select(InsuranceSelectors.selectGroups);
  public plans$: Observable<IPlanResponseModel[]> = this.store.select(InsuranceSelectors.selectPlans);
  public groupContacts$: Observable<IMemberContactResponseModel[]> = this.store.select(InsuranceSelectors.selectSelectedGroupContacts);
  public groupPlans$: Observable<IPlanResponseModel[]> = this.store.select(InsuranceSelectors.selectSelectedGroupPlans);
  public providerNetworks$: Observable<IProviderNetworkResponseModel[]> = this.store.select(InsuranceSelectors.selectProviderNetworks);
  public allModules$: Observable<IModuleConfigResponseModel[]> = this.store.select(InsuranceSelectors.selectSelectedGroupModules);

  // Single
  public firstGroupId$: Observable<number> = this.store.select(InsuranceSelectors.selectFirstGroupId);
  public selectedGroupId$: Observable<number> = this.store.select(InsuranceSelectors.selectSelectedGroupId);
  public selectedMemberIds$: Observable<{ subscriberId: number; personId: number }> =
    this.store.pipe(select(PersonSelectors.selectSelectedMemberIds));
  public groupBranding$: Observable<IBrandConfigResponseModel> = this.store.pipe(select(InsuranceSelectors.selectSelectedGroupBranding));
  public selectedPlan$: Observable<IPlanResponseModel> = this.store.pipe(select(InsuranceSelectors.selectSelectedPlan));
  public selectedPayer$: Observable<IPayerResponseModel> = this.store.pipe(select(InsuranceSelectors.selectSelectedPayer));
  public selectedPlanProviderNetworks$: Observable<IProviderNetworkResponseModel[]> =
    this.store.pipe(select(InsuranceSelectors.selectSelectedPlanProviderNetworks));
  public isCloningPlan$: Observable<boolean> = this.store.select(InsuranceSelectors.selectIsCloningPlan);

  constructor(
    private store: Store<InsuranceState>,
    private planService: PlanService
  ) { }


  public getPlan(planId: number): Observable<IPlanResponseModel> {
    return this.planService.get(planId);
  }

  public loadGroup(groupId: number): void {
    this.store.dispatch(InsuranceActions.loadGroup({ groupId }));
  }

  public loadGroups(): void {
    this.store.dispatch(InsuranceActions.loadAllGroups());
  }

  public loadPlan(planId: number): void {
    this.store.dispatch(InsuranceActions.loadPlan({ planId }));
  }

  public loadPlansByGroup(groupId: number): void {
    this.store.dispatch(InsuranceActions.loadPlansByGroup({ groupId }));
  }

  public getGroupPlans(groupId: number): Observable<IPlanResponseModel[]> {
    return this.planService.getByGroup(groupId);
  }

  public loadAllPlans() {
    this.store.dispatch(InsuranceActions.loadAllPlans());
  }

  public loadBrandingByGroup(groupId: number): void {
    this.store.dispatch(InsuranceActions.loadBrandingByGroup({ groupId }));
  }

  public loadProviderNetworks(): void {
    this.store.dispatch(InsuranceActions.loadProviderNetworks());
  }

  public getProviderNetwork(): Observable<Array<IProviderNetworkResponseModel>> {
    this.loadProviderNetworks();
    return this.providerNetworks$;
  }

  public savePlan(planId: number, plan: IPlanPutModel, spdFile: File, sbcFile: File, planImage: File): void {
    this.store.dispatch(InsuranceActions.savePlan({ planId, plan, spdFile, sbcFile, planImage }));
  }

  public createPlan(payerId: number, plan: IPlanPostModel, spdFile: File,
                    sbcFile: File, planImage: File, planIdToCloneFrom: number): void {

    // TODO: Hack to get this out
    this.store.select(selectSelectedGroupId)
      .subscribe(groupId => {
        plan.groupId = groupId;
        this.store.dispatch(InsuranceActions.createPlan({ payerId, plan, spdFile, sbcFile, planImage, planIdToCloneFrom }));
      });
  }

  public saveBrand(groupId: number, brandConfigId: number, brand: any, logo: File): void {
    this.store.dispatch(InsuranceActions.saveBrand({ groupId, brandConfigId, brand, logo }));
  }

  public saveGroup(groupId: number, group: IGroupPutModel): void {
    this.store.dispatch(InsuranceActions.saveGroup({ groupId, group }));
  }

  public createGroup(group: IGroupPostModel): void {
    this.store.dispatch(InsuranceActions.createGroup({ group }));
  }

  public loadContactsByGroup(groupId: number): void {
    this.store.dispatch(InsuranceActions.loadContactsByGroup({ groupId }));
  }

  public savePhoneContact(contactId: number, contact: any): void {
    this.store.dispatch(InsuranceActions.savePhoneContact({ contactId, contact }));
  }

  public saveWebsiteContact(contactId: number, contact: any): void {
    this.store.dispatch(InsuranceActions.saveWebsiteContact({ contactId, contact }));
  }

  public saveAddressContact(contactId: number, contact: any): void {
    this.store.dispatch(InsuranceActions.saveAddressContact({ contactId, contact }));
  }

  public saveEmailContact(contactId: number, contact: any): void {
    this.store.dispatch(InsuranceActions.saveEmailContact({ contactId, contact }));
  }

  public deleteContact(contactId: number): void {
    this.store.dispatch(InsuranceActions.deleteContact({ contactId }));
  }

  public clearContacts(): void {
    this.store.dispatch(InsuranceActions.clearContacts());
  }

  public clearBrand(): void {
    this.store.dispatch(InsuranceActions.clearBrand());
  }

  public selectGroup(groupId: number): Observable<IGroupResponseModel> {
    return this.store.pipe(select(InsuranceSelectors.selectGroup, { groupId }));
  }

  public selectSortedGroups(excludedGroupId: number = undefined): Observable<IGroupResponseModel[]> {
    return this.store.pipe(select(InsuranceSelectors.selectSortedGroups, { excludedGroupId }));
  }

  public selectSortedGroupPlans(targetGroupId: number, excludePlanId? : number): Observable<IPlanResponseModel[]> {
    return this.store.pipe(select(InsuranceSelectors.selectSortedPlansForGroup(targetGroupId, excludePlanId)));
  }

  public setSelectedGroup(groupId: number, initialSubscriberId?: number, initialPersonId?: number): void {
    this.store.dispatch(InsuranceActions.selectGroup({ groupId, initialSubscriberId, initialPersonId }));
  }

  public saveFileSbc(planId: number, file: File): void {
    this.store.dispatch(InsuranceActions.saveFileSbc({ planId, file }));
  }

  public saveFileSpd(planId: number, file: File): void {
    this.store.dispatch(InsuranceActions.saveFileSpd({ planId, file }));
  }

  public loadModulesByGroup(groupId: number): void {
    this.store.dispatch(InsuranceActions.loadModulesByGroup({ groupId }));
  }

  public hasModule(type: ModuleType): Observable<boolean> {
    return this.allModules$.pipe(
      filter(modules => !!modules),
      map(modules => modules.some(module => module.type === type)),
      take(1)
    );
  }

  public getModuleSettings(type: ModuleType): Observable<any> {
    return this.allModules$.pipe(
      map(modules => {
        const foundModule = modules.find(module => module.type === type);
        return foundModule ? foundModule.settings : '';
      }),
      take(1)
    );
  }

  public cloneMemberContactsFromGroup(groupId: number) {
    this.store.dispatch(InsuranceActions.cloneMemberContactsFromGroup({ groupId }));
  }

  public loadPayer(payerId: number) {
    this.store.dispatch(InsuranceActions.loadPayer({ payerId }));
  }

  public loadSelectedPlanProviderNetworks(planId: number) {
    this.store.dispatch(InsuranceActions.loadSelectedPlanProviderNetworks({ planId }));
  }

}
