<mat-card class="member-search-container">
  <mat-card-title>Member Search</mat-card-title>

  <mat-card-content>
    <div>
      <mat-form-field appearance="outline">
        <input matInput (keyup)="search()" [(ngModel)]="searchText" class="search-input" placeholder="First Name, Last Name, Member Number">
      </mat-form-field>
    </div>

    <ng-container *ngIf="!(isLoading$ | async);else loading">
      <div class="results-container" *ngIf="hasMembers;else noResultsFound">
        <strong>{{ (searchResults$ | async)?.length }}</strong> result<span *ngIf="(searchResults$ | async)?.length !== 1">s</span> found.
      </div>
    </ng-container>

    <div [hidden]="(isLoading$ | async) || !hasMembers">
      <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef width="20%"> Last Name </th>
          <td mat-cell *matCellDef="let member" width="20%"> {{member.lastName}}</td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef width="20%"> First Name </th>
          <td mat-cell *matCellDef="let member" width="20%">{{member.firstName}} </td>
        </ng-container>

        <ng-container matColumnDef="dateOfBirth">
          <th mat-header-cell *matHeaderCellDef width="20%"> Date of Birth </th>
          <td mat-cell *matCellDef="let member" width="20%"> {{member.dateOfBirth | date:'mediumDate' }} </td>
        </ng-container>

        <ng-container matColumnDef="relationship">
          <th mat-header-cell *matHeaderCellDef width="20%"> Relationship </th>
          <td mat-cell *matCellDef="let member" width="20%"> {{member.relationship | relationshipPipe }} </td>
        </ng-container>

        <ng-container matColumnDef="groupName">
          <th mat-header-cell *matHeaderCellDef width="20%"> Group Name </th>
          <td mat-cell *matCellDef="let member" width="20%"> {{member.groupId | groupNamePipe | async }} </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let member; columns: displayedColumns;"
          (click)="selectPerson(member)"
          matRipple
          class="element-row">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSize]="[10]" showFirstLastButtons></mat-paginator>
    </div>

  </mat-card-content>
</mat-card>

<ng-template #noResultsFound>
  <div id="noResultsFound" class="results-container">No results found.</div>
</ng-template>

<ng-template #loading>
  <div>
    <mat-spinner [diameter]="50" class="spinner"></mat-spinner>
  </div>
</ng-template>