import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersonService } from '@backend';
import { DEFAULT_MEMBER_SEARCH_PAGE_LIMIT } from '@constants';
import { IMemberDetailsResponseModel, IMemberSearchPostModel, IPersonResponseModel } from '@models';
import { Store } from '@ngrx/store';
import { PersonActions, PersonSelectors } from '@root-store';
import moment from 'moment';
import { Observable, map } from 'rxjs';
import { selectSelectedMember, selectSelectedMemberPlanIds, selectSelectedSubscriber } from 'src/app/root-store/person-store/person.selectors';
import { AppState } from 'src/app/root-store/root.state';

@Injectable({
  providedIn: 'root'
})
export class PersonFacade {
  public people$ = this.store.select(PersonSelectors.selectPeople);
  public memberSearchResults$ = this.store.select(PersonSelectors.selectMemberSearchResults);
  public isLoading$ = this.store.select(PersonSelectors.selectIsLoading);
  public selectedMemberPlanIds$ = this.store.select(selectSelectedMemberPlanIds);
  public selectedMember$ = this.store.select(selectSelectedMember);
  public selectedSubscriber$ = this.store.select(selectSelectedSubscriber);

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private service: PersonService
  ) {}

  setSelectedPersonIds(subscriberId: number, personId: number): void {
    this.store.dispatch(PersonActions.setSelectedPersonIds({ subscriberId, personId }));
  }

  loadPeopleByGroup(groupId: number, initialSubscriberId?: number, initialPersonId?: number): void {
    this.store.dispatch(PersonActions.loadPeopleByGroup({ groupId, initialSubscriberId, initialPersonId }));
  }

  getOnlyActivePeopleByGroup(allSubscribers: IPersonResponseModel[]): IPersonResponseModel[] {
    var subscribers: Array<IPersonResponseModel> = JSON.parse(JSON.stringify(allSubscribers));

    subscribers = subscribers
      .filter(x => x.policies.some(y => this.isValidPolicy(y.effectiveFrom, y.effectiveTo)));

    subscribers.forEach(subscriber => {
      subscriber.dependents = subscriber.dependents != null ?
        subscriber.dependents = subscriber.dependents.filter(x =>
          x.policies.some(y => this.isValidPolicy(y.effectiveFrom, y.effectiveTo))) :
        [];
    });

    return subscribers;
  }

  searchMembers(searchText: string, pageLimit: number, groupId?: number) {
    const searchPostModel: IMemberSearchPostModel = {
      searchText,
      skip: 0,
      limit: pageLimit
    };

    if (groupId) {
      searchPostModel.targetGroupIds = [groupId];
    }

    this.store.dispatch(PersonActions.searchMembers({ searchPostModel }));
  }

  clearMemberSearchResults() {
    this.store.dispatch(PersonActions.clearMemberSearchResultsState());
  }

  navigateToMember(member: IMemberDetailsResponseModel) {
    const firstPolicy = member.policies?.length > 0 ? member.policies[0] : undefined;
    const subscriberId = firstPolicy?.parentPersonId ?? member.personId;
    if (firstPolicy && firstPolicy.groupId && subscriberId) {
      void this.router.navigate([`groups/${firstPolicy.groupId}/members/${subscriberId}/${member.personId}/summary`]) ;
    }
  }

  clearPersonState() {
    this.store.dispatch(PersonActions.clearPersonState());
  }  

  getSelectedMemberAndSubscriberDetails(personId: number) {
    this.store.dispatch(PersonActions.getSelectedMemberAndSubscriberDetails({ personId }));
  }  

  isValidPolicy(effectiveFrom: string, effectiveTo: string): boolean {
    const today = moment.utc();
    const policyEffectiveFromDate = moment.utc(effectiveFrom);
    const policyEffectiveToDate = effectiveTo ? moment.utc(effectiveTo) : moment.utc().add(1, 'd');

    var isValidEffectiveFrom = policyEffectiveFromDate.isSameOrBefore(today);
    var isValidEffectiveTo = (policyEffectiveToDate.isAfter(today));

    return isValidEffectiveFrom && isValidEffectiveTo;
  }

  getMemberUserIds(personId: number): Observable<number[]> {
    return this.service.getMember(personId).pipe(map(member => member.userIds));
  }
}
