import { EventEmitter, Injectable } from '@angular/core';
import { SuperBillService } from '@backend';
import { ISuperBillResponseModel, ISuperBillStatusPostModel } from '@models';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SuperBillFacade {
  public isLoading = new EventEmitter<boolean>();
  public doneSaving = new EventEmitter();
  public cancelSave = new EventEmitter();

  constructor(
    private superBillService: SuperBillService
  ) {}

  public updateSuperBillStatus(model: ISuperBillStatusPostModel, superBillId: number): void {
    this.superBillService.createStatus(model, superBillId).subscribe(() => this.doneSaving.emit());
  }

  public getSuperBill(superBillId: number): Observable<any> {
    return forkJoin([this.superBillService.getSuperBill(superBillId), this.superBillService.getSuperBillImages(superBillId)]);
  }

  public getSuperBillsByPartner(userId: number): Observable<ISuperBillResponseModel[]> {
    this.isLoading.next(true);
    return this.superBillService.getSuperBillsByPartner(userId).pipe(
      tap(() => this.isLoading.next(false))
    );
  }

  public getSuperBillImage(superBillId: number, imageId: number, contentType: string, fileName: string): void {
    this.superBillService.getSuperBillImage(superBillId, imageId, contentType, fileName);
  }

  public getSuperBillsByHSR(hsrId: number): Observable<Array<ISuperBillResponseModel>> {
    return this.superBillService.getSuperBillsByHSR(hsrId);
  }

}
