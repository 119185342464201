import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IHSRResponseModel } from '../entities/health-service-request-response.model';
import { IHSRPostModel } from '../entities/health-service-request-post.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { IHSRStatusPostModel } from '../entities/health-service-request-status-post.model';
import { IHSRPutModel } from '../entities/health-service-request-put.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  public postSuccess$ = new Subject<number>();
  public postFailure$ = new Subject<number>();
  private headers = {
    'Content-Type': 'application/json'
  };

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar // TODO: Remove, need an elegant solution for this. Don't like adding it directly
  ) { }

  public getMemberHSRs(personId: number): Observable<IHSRResponseModel[]> {
    if (personId) {
      const url = `${environment.config.apiEndpoint}/v2/person/${personId}/healthServiceRequests`;
      return this.http.get<IHSRResponseModel[]>(url);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return of([]); // TODO: Hack, I don't have time to find where this isn't getting called properly
    }
  }

  public getPartnerHSRs(partnerUserId: number): Observable<IHSRResponseModel[]> {
    if (partnerUserId) {
      const url = `${environment.config.apiEndpoint}/v2/partner/user/${partnerUserId}/healthServiceRequests`;
      return this.http.get<IHSRResponseModel[]>(url);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return of([]); // TODO: Hack, I don't have time to find where this isn't getting called properly
    }
  }

  public getHSRDetails(id: number): Observable<IHSRResponseModel> {
    const url = `${environment.config.apiEndpoint}/v3/healthServiceRequest/${id}`;
    return this.http.get<IHSRResponseModel>(url);
  }

  public postHSR(hsr: IHSRPostModel): Observable<IHSRResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/person/${hsr.memberPersonId}/healthServiceRequest`;
    const body = hsr;
    return this.http.post<IHSRResponseModel>(url, body, { 'headers': this.headers, observe: 'response' })
      .pipe(
        map(resp => {
          if (resp.status === 201) {
            this.postSuccess$.next(resp.body.id);
            this.snackBar.open('Successfully created!', 'close', { duration: 3000 });
          }
          return resp.body;
        })
      );
  }

  public putHSR(hsr: IHSRPutModel): Observable<IHSRResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/healthServiceRequest/${hsr.id}`;
    const body = hsr;
    return this.http.put<IHSRResponseModel>(url, body, { 'headers': this.headers, observe: 'response' })
      .pipe(
        map(resp => {
          if (resp.status === 201) {
            this.postSuccess$.next(resp.body.id);
            this.snackBar.open('Successfully updated!', 'close', { duration: 3000 });
          }
          return resp.body;
        })
      );
  }

  public postHSRStatus(hsrStatusPostModel: IHSRStatusPostModel, hsrId: number): Observable<IHSRResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/healthServiceRequest/${hsrId}/status`;
    const body = hsrStatusPostModel;
    return this.http.post<IHSRResponseModel>(url, body, { 'headers': this.headers, observe: 'response' })
      .pipe(
        map(resp => {
          if (resp.status === 201) {
            this.postSuccess$.next(resp.body.id);
            this.snackBar.open('Successfully updated status!', 'close', { duration: 3000 });
          }
          return resp.body;
        })
      );
  }

}
