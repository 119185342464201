import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of, Observable } from 'rxjs';
import { COOKIE_EXPIRATION_DAYS, AUTH_COOKIE_ID } from '@constants';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private cookieService: CookieService
  ) { }

  public getAuthToken(): string {
    return this.cookieService.get(AUTH_COOKIE_ID);
  }

  public checkAuthToken(): boolean {
    return this.cookieService.check(AUTH_COOKIE_ID);
  }

  public storeAuthToken(token: string): Observable<boolean> {
    this.cookieService.set(AUTH_COOKIE_ID, token, COOKIE_EXPIRATION_DAYS, '/', undefined, false, 'Lax');
    return of(this.checkAuthToken());
  }

  public unstoreAuthToken(): void {
    this.cookieService.delete(AUTH_COOKIE_ID, '/');

  }
}
