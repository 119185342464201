/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, createReducer, on } from '@ngrx/store';
import { ITerminologyResponseModel, ITerminologyConfigResponseModel } from '@models';
import * as TerminologyActions from './terminology.actions';

export const terminologyFeatureKey = 'terminology';

export interface TerminologyState {
  terminology: ITerminologyResponseModel[];
  terminologyGroupConfigs: ITerminologyConfigResponseModel[];
  isLoading: boolean;
  isLoadingRequest: boolean;
}

export const initialTerminologyState: TerminologyState = {
  terminology: null,
  terminologyGroupConfigs: null,
  isLoading: undefined,
  isLoadingRequest: undefined
};

const terminologyReducer = createReducer(
  initialTerminologyState,

  on(TerminologyActions.loadTerminology, state => ({ ...state, isLoading: true })),
  on(TerminologyActions.loadTerminologySuccess, (state, action) => (
    { ...state, terminology: action.terminology, isLoading: false }
  )),
  on(TerminologyActions.loadTerminologyFailure, state => ({ ...state, isLoading: false })),
  on(TerminologyActions.loadTerminologyConfigsByGroup, state => ({ ...state, isLoading: true })),
  on(TerminologyActions.loadTerminologyConfigsByGroupSuccess, (state, action) => (
    { ...state, terminologyGroupConfigs: action.terminologyConfigs, isLoading: false }
  )),
  on(TerminologyActions.loadTerminologyConfigsByGroupFailure, state => ({ ...state, isLoading: false })),
  on(TerminologyActions.postTerminologyConfig, state => ({ ...state, isLoadingRequest: true })),
  on(TerminologyActions.putTerminologyConfig, state => ({ ...state, isLoadingRequest: true })),
  on(TerminologyActions.deleteTerminologyConfig, state => ({ ...state, isLoadingRequest: true })),
  on(TerminologyActions.updateTerminologyConfigSuccess, state => ({ ...state, isLoadingRequest: false })),
  on(TerminologyActions.updateTerminologyConfigFailure, state => ({ ...state, isLoadingRequest: false })),
);

export function reducer(state: TerminologyState | undefined, action: Action) {
  return terminologyReducer(state, action);
}
