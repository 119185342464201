import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhysicianType } from '@enums';

import { environment } from '@environment';
import { CostEstimateResponse } from '@models';

@Injectable({
  providedIn: 'root'
})
export class CostEstimatorService {

  constructor(private http: HttpClient) { }

  public getProcedureCost(cptCode: string, npi: string, planId: number) {
    const params = `planId=${planId}`;
    const url = `${environment.config.apiEndpoint}/v2/provider/${npi}/procedure/${cptCode}/cost?${params}`;
    return this.http.get<any>(url);
  }

  public getProcedureCostEstimate(
    personId: number, procedureCost: number, cptCode: string, placeOfService: number, physicianType?: PhysicianType
  ) {
    // eslint-disable-next-line max-len
    let urlParams = `procedureCost=${procedureCost}&procedureCode=${cptCode}&placeOfService=${placeOfService}`;

    if (physicianType !== null && physicianType !== undefined) {
      urlParams += `&physicianType=${physicianType}`;
    }

    const url = `${environment.config.apiEndpoint}/v1/person/${personId}/procedureCostEstimate?${urlParams}`;
    return this.http.get<CostEstimateResponse>(url);
  }
}
