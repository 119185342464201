import { IClaimDetailResponseModel } from './claim-detail-response.model';
import { IClaimTransaction } from './claim-transaction.model';

export interface IClaimResponseModel {
  id: number;
  claimDetails: IClaimDetailResponseModel[];
  claimNumber: string;
  providerClaimNumber: string;
  providerAccountNumber: string;
  payerAdjustmentType: string;
  payerPriorClaimNumber: string;
  claimType: string;
  claimStatus: string;
  serviceFromDate: Date;
  serviceToDate: Date;
  placeOfService: string;
  billingProviderNPI: string;
  billingProviderEntityType: string;
  billingProviderTaxonomyCode: string;
  billingProviderLastNameOrOrgName: string;
  billingProviderFirstName: string;
  billingProviderMiddleName: string;
  billingProviderAddress1: string;
  billingProviderAddress2: string;
  billingProviderCity: string;
  billingProviderState: string;
  billingProviderPostalCode: string;
  billingProviderCountryCode: string;
  billingProviderPhoneNumber: string;
  renderingProviderNPI: string;
  renderingProviderEntityType: string;
  renderingProviderTaxonomyCode: string;
  renderingProviderLastNameOrOrgName: string;
  renderingProviderFirstName: string;
  renderingProviderMiddleName: string;
  renderingProviderAddress1: string;
  renderingProviderAddress2: string;
  renderingProviderCity: string;
  renderingProviderState: string;
  renderingProviderPostalCode: string;
  renderingProviderCountryCode: string;
  renderingProviderPhoneNumber: string;
  principalDiagnosisCode: string;
  principalProcedureCode: string;
  principalProcedureDate: Date;
  billedAmount: number;
  coveredAmount?: number;
  paidAmount: number;
  paidDate: number;
  remainingMemberResponsibilityAmount: number;
  payerName: string;
  groupNumber: string;
  groupName: string;
  memberNumber: string;
  ssn: string;
  lastName: string;
  firstName: string;
  middleName: string;
  dateOfBirth: Date;
  createdAt: Date;
  updatedAt: Date;
  isPayable: boolean;
  notes: string;

  // Doesn't come back from the API, but allows transactions to be attached to the claim
  transactions?: IClaimTransaction[];
  // For now, this is just used to indicate that a user has made at least one successful payment on the claim.
  isPaymentCompleted?: boolean;
}
