<div *ngIf="cardData;else noCardData">
  <div *ngIf="cardData;else noCardData">
    <div class="id-card embedded-button plain">
      <div fxLayout="column" fxLayoutAlign="space-between">
        <div fxFlex="50" fxLayout="row">
          <div fxFlex="50">
            <img src="./../../../../../assets/images/medxoom.png" style="max-width: 100%">
          </div>
        </div>

        <div id="card-number" fxLayout="row" fxLayoutAlign="space-between" class="id-card-small" style="margin-top: 35px;">
          <div *ngFor="let number of cardNumbers" fxFlex="25">{{ number }} </div>
        </div>

        <div class="fill-space"></div>
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="40px;" style="margin-top: 20px; font-size: 12px;">
          <div style="margin-right: 30px;">
            CVV: {{ cardData?.cvv }}
          </div>
          <div>
            EXP DATE: {{ cardData?.expirationDate }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: 5px; font-weight: bold; font-size: 18px;">
          <div fxFlex="60">
            {{ cardData?.name }}
          </div>
          <img fxFlex="15" src="./../../../../../assets/images/mastercard.png">
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noCardData>
  <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start">
    <div style="width: 300px; text-align: left;">
      <h3 style="margin-bottom:10px;">Card has not been created yet.</h3>
    </div>
  </div>
</ng-template>
