import {
  IModuleResponseModel,
  IModuleConfigResponseModel,
  IMeMdConfigResponseModel
} from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as ModuleConfigActions from './module-config.actions';

export const moduleConfigFeatureKey = 'moduleConfig';

export interface ModuleConfigState {
  selectedGroupModuleConfigs: IModuleConfigResponseModel[];
  allModules: IModuleResponseModel[];
  isSaving: boolean;
  hasSavedChangesSuccessfully: boolean;
  hasDeletedMeMdConfigSuccessfully: boolean;
  selectedGroupMeMdConfigs: IMeMdConfigResponseModel[];
}

export const initialModuleConfigState: ModuleConfigState = {
  selectedGroupModuleConfigs: null,
  allModules: null,
  isSaving: null,
  hasSavedChangesSuccessfully: null,
  hasDeletedMeMdConfigSuccessfully: null,
  selectedGroupMeMdConfigs: null
};

const moduleConfigReducer = createReducer(
  initialModuleConfigState,

  on(ModuleConfigActions.loadAllModulesSuccess, (state, action) => ({ ...state, allModules: action.allModules })),
  on(ModuleConfigActions.loadModulesConfigsByGroupSuccess, (state, action) => ({
    ...state, selectedGroupModuleConfigs: action.moduleConfigs
  })),

  on(ModuleConfigActions.createModuleConfig, (state, action) => ({
    ...state,
    isSaving: true,
    hasSavedChangesSuccessfully: null
  })),
  on(ModuleConfigActions.createModuleConfigSuccess, (state, action) => ({
    ...state,
    isSaving: false,
    hasSavedChangesSuccessfully: true
  })),
  on(ModuleConfigActions.createModuleConfigFailure, (state, action) => ({
    ...state,
    isSaving: false,
    hasSavedChangesSuccessfully: false
  })),

  on(ModuleConfigActions.updateModuleConfig, (state, action) => ({
    ...state,
    isSaving: true,
    hasSavedChangesSuccessfully: null
  })),
  on(ModuleConfigActions.updateModuleConfigSuccess, (state, action) => ({
    ...state,
    isSaving: false,
    hasSavedChangesSuccessfully: true
  })),
  on(ModuleConfigActions.updateModuleConfigFailure, (state, action) => ({
    ...state,
    isSaving: false,
    hasSavedChangesSuccessfully: false
  })),


  on(ModuleConfigActions.loadMeMdConfigsByPlansSuccess, (state, action) => ({
    ...state,
    selectedGroupMeMdConfigs: action.meMdConfigs
  })),

  on(ModuleConfigActions.saveMeMdModuleAndConfigs, (state, action) => ({
    ...state,
    isSaving: true,
    hasSavedChangesSuccessfully: null
  })),
  on(ModuleConfigActions.saveMeMdModuleAndConfigsSuccess, (state, action) => ({
    ...state,
    isSaving: false,
    hasSavedChangesSuccessfully: true
  })),
  on(ModuleConfigActions.saveMeMdModuleAndConfigsFailure, (state, action) => ({
    ...state,
    isSaving: false,
    hasSavedChangesSuccessfully: false
  })),

  on(ModuleConfigActions.deleteMeMdConfig, (state, action) => ({
    ...state,
    hasDeletedMeMdConfigSuccessfully: null
  })),
  on(ModuleConfigActions.deleteMeMdConfigSuccess, (state, action) => ({
    ...state,
    hasDeletedMeMdConfigSuccessfully: true
  })),
  on(ModuleConfigActions.deleteMeMdConfigFailure, (state, action) => ({
    ...state,
    hasDeletedMeMdConfigSuccessfully: false
  })),


  on(ModuleConfigActions.clearSelectedGroupMeMdConfigsState, (state, action) => ({ ...state, selectedGroupMeMdConfigs: null })),
  on(ModuleConfigActions.clearSavedSuccessfullyState, (state, action) => ({ ...state, hasSavedChangesSuccessfully: null })),
  on(ModuleConfigActions.clearDeletedMeMdConfigSuccessfullyState, (state, action) => ({
    ...state,
    hasDeletedMeMdConfigSuccessfully: null
  })),
  on(ModuleConfigActions.clearModuleConfigState, (state, action) => ({
    ...initialModuleConfigState,
    selectedGroupModuleConfigs: state.selectedGroupModuleConfigs
  })),
);

export function reducer(state: ModuleConfigState | undefined, action: Action) {
  return moduleConfigReducer(state, action);
}
