/* eslint-disable max-len */
import { createAction, props } from '@ngrx/store';
import {
  IAutoCompleteFacilityResponseModel, IAutoCompletePostModel,
  IAutoCompleteProviderResponseModel, IGeoCodeResponseModel,
  ISearchResponseModel, ISearchPostModel, ISpecialtyModel, IFacilityTypeModel,
  ISearchDetailsResponseModel, IPlanTierFilterModel, IProcedureSearchPostModel,
  CostEstimateResponse, IAutoCompleteProcedureResponseModel
} from '@models';
import {
  AutoCompleteResponseModel,
  Provider,
  ProviderDetails,
  SearchCriteria
} from 'projects/search/src/public-api';
import { SearchType } from '@enums';

export const runSearch = createAction('[Search Page] Run Search', props<{ searchCriteria: SearchCriteria }>());
export const runSearchSuccess = createAction('[Search Page] Run Search Success', props<{ providers: Provider[] }>());
export const getProviderDetails = createAction('[Search Page] Get Provider Details', props<{ id: number }>());
export const getProviderDetailsSuccess = createAction('[Search Page] Get Provider Details Success', props<{ id: string; changes: Partial<ProviderDetails> }>());
export const setSelectedResult = createAction('[Search Page - List] Set Selected Result', props<{ resultId: number }>());
export const clearSearchResults = createAction('[Search Page - List] Clear Search Results');
export const getAutoCompleteSuggestions = createAction('[Search Page] Get Auto Complete Suggestions', props<{ searchTerm: string; postalCode: string; providerSuggestionsOnly: boolean }>());
export const getAutoCompleteSuggestionsSuccess = createAction('[Search Page] Get Auto Complete Suggestions Success', props<{ autoCompleteResponse: AutoCompleteResponseModel }>());
export const getAutoCompleteSuggestionsFailure = createAction('[Search Page] Get Auto Complete Suggestions Failure', props<{ error: any }>());
export const runLocationSearch = createAction('[Search Page] Run Location Search', props<{ searchTerm: string; geoCoder: google.maps.Geocoder }>());
export const runLocationSearchSuccess = createAction('[Search Page] Run Location Search Success', props<{ result: IGeoCodeResponseModel }>());
export const runLocationSearchFailure = createAction('[Search Page] Run Location Search Failure', props<{ error: any }>());
export const runProcedureAutoCompleteSearch = createAction('[Search Page] Run Procedure Auto Complete Search', props<{ searchTerm: string }>());
export const runProcedureAutoCompleteSearchSuccess = createAction('[Search Page] Run Procedure Auto Complete Search Success', props<{ results: IAutoCompleteProcedureResponseModel[] }>());
export const runProcedureAutoCompleteSearchFailure = createAction('[Search Page] Run Procedure Auto Complete Search Failure', props<{ error: any }>());
export const runProviderAutoCompleteSearch = createAction('[Search Page] Run Provider Auto Complete Search', props<{ autoCompletePostModel: IAutoCompletePostModel }>());
export const runProviderAutoCompleteSearchSuccess = createAction('[Search Page] Run Provider Auto Complete Search Success', props<{ results: IAutoCompleteProviderResponseModel[] }>());
export const runProviderAutoCompleteSearchFailure = createAction('[Search Page] Run Provider Auto Complete Search Failure', props<{ error: any }>());
export const runFacilityAutoCompleteSearch = createAction('[Search Page] Run Facility Auto Complete Search', props<{ autoCompletePostModel: IAutoCompletePostModel }>());
export const runFacilityAutoCompleteSearchSuccess = createAction('[Search Page] Run Facility Auto Complete Search Success', props<{ results: IAutoCompleteFacilityResponseModel[] }>());
export const runFacilityAutoCompleteSearchFailure = createAction('[Search Page] Run Facility Auto Complete Search Failure', props<{ error: any }>());
export const runProviderFacilityProcedureSearch = createAction('[Search Page] Run Provider Facility Procedure Search', props<{ searchCriteria: ISearchPostModel | IProcedureSearchPostModel; searchType: SearchType; firebaseEventBody: any }>());
export const runProviderFacilityProcedureSearchSuccess = createAction('[Search Page] Run Provider Facility Procedure Search Success', props<{ results: ISearchResponseModel[] }>());
export const runProviderFacilityProcedureSearchFailure = createAction('[Search Page] Run Provider Facility Procedure Search Failure', props<{ error: any }>());
export const clearSelectedProviderOrFacilityDetails = createAction('[Search Page] Clear Selected Search Result Details');

export const getCommonSpecialties = createAction('[Search Page] Get Common Specialties');

export const getCommonSpecialtiesSuccess = createAction(
  '[Search Page] Get Common Specialties Success',
  props<{ commonSpecialties: ISpecialtyModel[] }>()
);
export const getCommonSpecialtiesFailure = createAction(
  '[Search Page] Get Common Specialties Failure',
  props<{ error: any }>()
);

export const getAutoCompleteSpecialties = createAction(
  '[Search Page] Get Auto Complete Specialties',
  props<{ searchTerm: string }>()
);
export const getAutoCompleteSpecialtiesSuccess = createAction(
  '[Search Page] Get Auto Complete Specialties Success',
  props<{ specialties: ISpecialtyModel[] }>()
);
export const getAutoCompleteSpecialtiesFailure = createAction(
  '[Search Page] Get Auto Complete Specialties Failure',
  props<{ error: any }>()
);

export const getFacilityTypes = createAction('[Search Page] Get Facility Types');
export const getFacilityTypesSuccess = createAction(
  '[Search Page] Get Facility Types Success',
  props<{ facilityTypes: IFacilityTypeModel[] }>()
);
export const getFacilityTypesFailure = createAction(
  '[Search Page] Get Facility Types Failure',
  props<{ error: any }>()
);

export const getSearchResultDetails = createAction(
  '[Search Page] Get Search Result Details',
  props<{ searchType: SearchType; facilityId: string; npi: string; searchTerm: string; procedureCostEstimate?: CostEstimateResponse }>()
);
export const getSearchResultDetailsSuccess = createAction(
  '[Search Page] Get Search Result Details Success',
  props<{ details: ISearchDetailsResponseModel }>()
);
export const getSearchResultDetailsFailure = createAction(
  '[Search Page] Get Search Result Details Failure',
  props<{ error: any }>()
);

export const getPlanTierOptionsForSearch = createAction(
  '[Search Page] Get Plan Tier Options For Search',
  props<{ groupId: number }>()
);

export const getPlanTierOptionsForSearchSuccess = createAction(
  '[Search Page] Get Plan Tier Options For Search Success',
  props<{ planTierFilterOptions: IPlanTierFilterModel[] }>()
);

export const getPlanTierOptionsForSearchFailure = createAction(
  '[Search Page] Get Plan Tier Options For Search Failure',
  props<{ error: any }>()
);

// TESTING ACTIONS
export const createResult = createAction('[Search Page] Create Result', props<{ provider: Provider }>());
export const deleteResult = createAction('[Search Page] Delete Result', props<{ id: string }>());
