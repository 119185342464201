import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { IMemberContactPostModel,
  IMemberContactPutModel,
  IMemberContactResponseModel
} from '@models';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { InsuranceActions } from '@root-store';
import { AppState } from 'src/app/root-store/root.state';

@Injectable({
  providedIn: 'root'
})
export class MemberContactService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  public get(memberContactId: number): Observable<IMemberContactResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/memberContact/${memberContactId}`;
    return this.http.get<IMemberContactResponseModel>(url);
  }

  public getByGroup(groupId: number): Observable<IMemberContactResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/memberContacts`;
    return this.http.get<IMemberContactResponseModel[]>(url);
  }

  public post(groupId: number, memberContact: IMemberContactPostModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/memberContact`;
    const body = memberContact;
    return this.http.post<IMemberContactResponseModel>(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 201) {
            this.store.dispatch(InsuranceActions.loadContactsByGroup({ groupId }));
            this.snackBar.open(
              'Contact created successfully!',
              'Close',
              { duration: 3000 }
            );
          }
        })
      );
  }

  public put(memberContactId: number, memberContact: IMemberContactPutModel, groupId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/memberContact/${memberContactId}`;
    const body = memberContact;
    return this.http.put<IMemberContactResponseModel>(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 200) {
            this.store.dispatch(InsuranceActions.loadContactsByGroup({ groupId }));
            this.snackBar.open(
              'Contact successfully updated!',
              'Close',
              { duration: 3000 }
            );
          }
        })
      );
  }

  public delete(memberContactId: number, groupId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/memberContact/${memberContactId}`;
    return this.http.delete<any>(url, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 204) {
            this.store.dispatch(InsuranceActions.loadContactsByGroup({ groupId }));
            this.snackBar.open(
              'Contact deleted.',
              'Close',
              { duration: 3000 }
            );
          }
        })
      );
  }
}
