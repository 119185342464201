import { QualityMetrics } from "projects/search/src/lib/models/quality-metrics.model";
import { CostEstimateResponse } from "./cost-estimate-response.model";

export interface ISearchResponseModel {
  npi?: string;
  specialty?: string;
  name: string;
  facilityId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  distance: number;
  networkIds: number[];
  plans?: ISearchResponsePlan[];
  procedureCostEstimate?: CostEstimateResponse;
}

export interface ISearchDetailsResponseModel extends ISearchResponseModel {
  ratingsCount: number;
  ratingsAvg: number;
  costEfficiencyIndex: number;
  qualityOutcomeIndex: number;
  gender: string;
  educations: string[];
  degrees: string[];
  specialties: string[];
  languages: string[];
  phone: string;
  facilityTypes: string[];
  qualityMetrics?: QualityMetrics;
}

export interface ISearchResponsePlan {
  planId: number;
  tiers: number[];
}
