export interface IEligibilityPutModel {
  id: number;
  ssn: string;
  subscriberSSN: string;
  lastName: string;
  firstName: string;
  middleName: string;
  suffix: string;
  dateOfBirth: Date;
  gender: string;
  relationship: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  email: string;
  homePhone: string;
  workPhone: string;
  cellPhone: string;
  groupNumber: string;
  groupName: string;
  groupAddress1: string;
  groupAddress2: string;
  groupCity: string;
  groupState: string;
  groupPostalCode: string;
  groupCountryCode: string;
  insuranceType: string;
  payerName: string;
  planId: string;
  planName: string;
  planCoverageTier: string;
  planYearStartDate: string;
  effectiveDate: Date;
  terminationDate: Date;
  memberNumber: string;
  personNumber: string;
}
