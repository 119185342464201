import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MessageCenterActions, MessageCenterSelectors, MessageCenterState } from '@root-store';
import { IThreadMessageResponseModel, IThreadMessagesPostModel, IThreadResponseModel, LoadThreadsRequest } from '@models';
import { MessageCenterViewState } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterFacade {
  // Collections
  public threads$: Observable<IThreadResponseModel[]> =
    this.store.select(MessageCenterSelectors.selectThreads);

  public selectedThreadId$: Observable<number> =
    this.store.select(MessageCenterSelectors.selectSelectedThreadId);

  public threadMessages$: Observable<IThreadMessageResponseModel[]> =
    this.store.select(MessageCenterSelectors.selectThreadMessages);

  public selectedThread$: Observable<IThreadResponseModel> =
    this.store.select(MessageCenterSelectors.selectSelectedThread);

  public selectCurrentViewState$ =
    this.store.select(MessageCenterSelectors.selectCurrentViewState);

  public selectReplyToThread$ =
    this.store.select(MessageCenterSelectors.selectReplyToThread);

  public selectIsSendingMessage$ =
    this.store.select(MessageCenterSelectors.selectIsSendingMessage);

  constructor(private store: Store<MessageCenterState>) { }

  public loadThreads(loadThreadsRequest: LoadThreadsRequest): void {
    this.store.dispatch(MessageCenterActions.loadThreads({ loadThreadsRequest }));
  }

  public setSelectedThreadId(threadId: number) {
    this.store.dispatch(MessageCenterActions.setSelectedThreadId({ threadId }));
  }

  public loadThreadMessages(threadId: number, markAsRead: boolean) {
    this.store.dispatch(MessageCenterActions.loadThreadMessages({ threadId, markAsRead }));
  }

  public clearThreads() {
    this.store.dispatch(MessageCenterActions.clearThreads());
  }

  public clearThreadMessages() {
    this.store.dispatch(MessageCenterActions.clearThreadMessages());
  }

  public sendNewMessage(message: IThreadMessagesPostModel, files: File[]) {
    this.store.dispatch(MessageCenterActions.sendNewMessage({ message, files }));
  }

  public setCurrentViewState(viewState: MessageCenterViewState) {
    this.store.dispatch(MessageCenterActions.setCurrentViewState({ viewState }));
  }

  public toggleRepliesAllowed(threadId: number, replyAllowed: boolean) {
    this.store.dispatch(MessageCenterActions.toggleRepliesAllowed({ threadId, replyAllowed }));
  }

  public archiveThread(threadId: number, isArchived: boolean) {
    this.store.dispatch(MessageCenterActions.archiveThread({ threadId, isArchived }))
  }

  public replyThreadMessage(body: string, threadId: number, files: File[]) {
    this.store.dispatch(MessageCenterActions.replyThreadMessage({ body, threadId, files }));
  }

  public setReplyToThread(replyToThread: boolean) {
    this.store.dispatch(MessageCenterActions.setReplyToThread({ replyToThread }));
  }

  public getMessageAttachment(messageId: number, attachmentId: number, fileName: string) {
    this.store.dispatch(MessageCenterActions.getMessageAttachment({ messageId, attachmentId, fileName }));
  }
}
