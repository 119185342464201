import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { PermissionType } from '@enums';
import { map, Observable, of } from 'rxjs';

import { AuthFacade } from '../facades/auth.facade';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authFacade: AuthFacade) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = route.data.permissionRedirect ?? '/';

    return this.checkValidity(route.data.permissionType)
      .pipe(map(hasPermission => hasPermission ? true : this.router.parseUrl(url)));
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  private checkValidity(permissionType: PermissionType): Observable<boolean> {
    if (typeof permissionType === 'undefined') {
      return of(true);
    }
    return this.authFacade.hasPermission(permissionType);
  }
}
