<mat-card *ngIf="request">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxLayout="row">
        <div fxFlex="50">

          <h1 id="SpcId" class="spc-id">
            ID {{ request.spcId }}
          </h1>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="status-container">
            <div>
              <h4>Current Status</h4>
              <h2>{{ status | hsrStatus | insertSpaces }}</h2>
            </div>
            <div>
              <button mat-raised-button color="primary" (click)="cancelRequest()" [disabled]="!canBeClosed()">Close this Request</button>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px grid">
            <div>
              <h3>Subscriber</h3>
              <strong>Name</strong>
              <div id="MemberName" style="margin-bottom: 10px;">
                {{ request.subscriberFirstName }} {{ request.subscriberLastName }}
              </div>

              <strong>Group Number / Member Number</strong>
              <div id="GroupAndMemberNumber" style="margin-bottom: 10px;">
                {{ request.groupNumber }} / {{ request.memberNumber }}
              </div>
            </div>
            <div style="margin-right:20px;">
              <h3>Patient</h3>
              <strong>Name</strong>
              <div id="MemberName" style="margin-bottom: 10px;">
                {{ request.memberFirstName }} {{ request.memberLastName }}
              </div>

              <strong>Group Number / Member Number</strong>
              <div id="GroupAndMemberNumber" style="margin-bottom: 10px;">
                {{ request.groupNumber }} / {{ request.memberNumber }}
              </div>
            </div>
          </div>


          <div>
            <h3>Procedure</h3>
            <ng-container *ngIf="request.procedureInfo != null">
              <strong>Procedure Info</strong>
              <div id="ProcedureInfo" style="margin-bottom: 10px;">
                {{ request.procedureInfo }}
              </div>
            </ng-container>

            <ng-container *ngIf="request.cptCode != null">
              <strong>Procedure Code(s)</strong>
              <div id="CptCode" style="margin-bottom: 10px;">
                {{ request.cptCode }}
              </div>
            </ng-container>

            <ng-container *ngIf="request.estimatedCost">
              <strong>Estimated Cost</strong>
              <div id="EstimatedCost" style="margin-bottom: 10px;">
                {{ request.estimatedCost | currency }}
              </div>
            </ng-container>

            <ng-container *ngIf="request.dateOfService">
              <strong>Date of Service</strong>
              <div id="EstimatedCost" style="margin-bottom: 10px;">
                {{ request.dateOfService | date:'mediumDate' }}
              </div>
            </ng-container>
          </div>

          <div>
            <h3>Provider</h3>
            <ng-container>
              <div fxLayout="row">
                <div fxFlex="100" id="ProviderInfo" style="margin-bottom: 10px;">
                  <strong>Name</strong>
                  <div>{{ request.providerName }}</div>
                </div>                
              </div>
              <div fxLayout="row">
                <div fxFlex="33" style="margin-bottom: 10px;">
                  <strong>Address</strong>
                  <div>{{ request.providerAddress1 }}</div>
                  <div *ngIf="request.providerAddress2 != null">{{ request.providerAddress2 }}</div>
                  <div>
                    {{ request.providerCity }} {{ request.providerState | uppercase }}, {{ request.providerPostalCode }}, {{ request.providerCountryCode }}
                  </div>
                </div>
                <div fxFlex="33" style="margin-bottom: 10px;">
                  <strong>NPI</strong>
                  <div id="ProviderNPI">
                    {{ request.providerNPI ?? 'None Provided' }}
                  </div>
                </div>
                <div fxFlex="33" style="margin-bottom: 10px;">
                  <strong>TIN</strong>
                  <div id="ProviderTIN">
                    {{ request.providerTIN ?? 'None Provided' }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <strong>Request Submitted On</strong>
          <div id="CreatedAt" style="margin-bottom: 10px;">
            {{ request.createdAt | date:'short' }}
          </div>
        </div>

        <div fxFlex="50">
          <mat-card style="background-color: rgb(238, 238, 238)">
            <strong>Payment Card</strong>

            <div *ngIf="request.paymentCard != null" class="container"
              [ngClass]="{ 'green': request.visibleToMember, 'red': !request.visibleToMember }">
              <div class="icon-container">
                <fa-icon [icon]="icons[ request.visibleToMember ? 'visibleToMember' : 'notVisibleToMember' ]"></fa-icon>
              </div>
              <div class="text-container">
                <span *ngIf="!request.visibleToMember">Not </span>
                Visible to Member
              </div>
            </div>

            <div class="card-details-section">
              <mx-payment-card [cardData]="request.paymentCard" [showFullCardNumber]=showFullCardNumber></mx-payment-card>
              <ng-container *ngIf="!request.paymentCard">
                <button mat-raised-button color="primary" (click)="requestCard()" [disabled]="!cardCanBeRequested()">Request a New Card</button>
              </ng-container>
            </div>

            <div *ngIf="request.paymentCard" class="card-details-section">
              <button mat-raised-button color="primary" (click)="toggleFullCardNumber()" [disabled]="showFullCardNumber">
                <mat-icon>visibility</mat-icon> View Full Card Number
              </button>
            </div>

            <div *ngIf="request.paymentCard" fxLayout="row" style="margin-top:25px;">
              <div fxFlex="33">
                <strong>Card Amount</strong>
                <div id="CardAmount" style="margin-bottom: 10px;">
                  {{ request.cardAmount | currency }}
                </div>
              </div>
              <div fxFlex="33">
                <strong>Available</strong>
                <div id="CardAvailableDate" style="margin-bottom: 10px;">
                  {{ request.cardAvailableDate | date:'shortDate' }}
                </div>
              </div>
              <div fxFlex="33">
                <strong>Expires</strong>
                <div id="CardExpirationDate" style="margin-bottom: 10px;">
                  {{ request.cardExpirationDate | date:'shortDate' }}
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div style="width: 100%;">
      <h3>Questionnaire</h3>
      <ng-container *ngIf="request.questionnaireResponses && request.questionnaireResponses.length > 0;else noQuestions">
        <div *ngFor="let question of request.questionnaireResponses" class="question-response">
          <h4 *ngIf="question.questionTitle">{{ question.questionTitle }}</h4>
          <div [innerHTML]="question.questionHtml"></div>
          <div class="question-answer">
            Answer: {{ question.answer }}
          </div>
        </div>
      </ng-container>
      <ng-template #noQuestions>No questionnaire questions found.</ng-template>
    </div>

    <div fxFlex="100" style="margin-top:20px;margin-bottom:30px;">
      <h2>Submitted Claims</h2>
      <ng-container *ngIf="superBillsDataSource && superBillsDataSource.data.length > 0;else noSuperBills">
        <mat-table #table [dataSource]="superBillsDataSource">
          <ng-container matColumnDef="patientName">
            <th mat-header-cell *matHeaderCellDef width="50%"> PatientName </th>
            <td mat-cell *matCellDef="let row" width="50%" (click)="navigateToSuperBill(row.id)" style="cursor:pointer;color:blue">
              {{ row.patientFirstName }} {{ row.patientLastName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef width="25%"> Status </th>
            <td mat-cell *matCellDef="let row" width="25%">{{ row.status | superBillStatus }} </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef width="50%"> Submitted Date </th>
            <td mat-cell *matCellDef="let row" width="50%"> {{ row.createdAt | date }}</td>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="superBillColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: superBillColumns;"></mat-row>
        </mat-table>
      </ng-container>
      <ng-template #noSuperBills>No submitted claims found.</ng-template>
    </div>

    <div fxFlex="100">
      <h2>Status History</h2>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef width="25%"> Status </th>
          <td mat-cell *matCellDef="let row" width="25%">{{ row.status | hsrStatus | insertSpaces }} </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef width="50%"> Note </th>
          <td mat-cell *matCellDef="let row" width="50%"> {{ row.note }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef width="25%"> Date </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{ row.createdAt | date:'short' }}</td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-card>
