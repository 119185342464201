import { SuperBillStatus } from '@enums';

export interface ISuperBillResponseModel {
  id: number;
  personId: number;
  memberFirstName: string;
  memberLastName: string;
  memberContactEmail: string;
  memberContactPhone: string;
  patientFirstName: string;
  patientLastName: string;
  status: SuperBillStatus;
  createdAt: string;
  updatedAt: string;
}
