import { INetworkTier, IPolicyResponseModel } from '@models';
import { createAction, props } from '@ngrx/store';


export const loadBenefitSummary = createAction(
  '[Benefits] Load Benefit Summary',
  props<{ planId: number; policyId: number }>()
);

export const loadBenefitSummarySuccess = createAction(
  '[Benefits] Load Benefit Summary Success',
  props<{ benefitSummary: INetworkTier[] }>()
);

export const loadBenefitSummaryFailure = createAction(
  '[Benefits] Load Benefit Summary Failure',
  props<{ error: any }>()
);

export const clearSelectedBenefitSummary = createAction(
  '[CLEAR_STORE] Clear Selected Benefit Summary'
);

export const clearBenefits = createAction(
  '[CLEAR_STORE] Clear Benefits'
);

export const setSelectedPolicy = createAction(
  '[Benefits] Set Selected Policy',
  props<{ policy: IPolicyResponseModel }>()
);

export const setSelectedPolicySuccess = createAction(
  '[Benefits] Set Selected Policy Success',
  props<{ policy: IPolicyResponseModel }>()
);

export const setSelectedPolicyFailure = createAction(
  '[Benefits] Set Selected Policy Failure',
  props<{ error: any }>()
);

export const sharePolicyIdCard = createAction(
  '[Benefits] Share Policy Id Card',
  props<{ policyId: number; recipient: string; channelType: number }>()
);

export const sharePolicyIdCardSuccess = createAction(
  '[Benefits] Share Policy Id Card Success',
  props<{ response: any }>()
);

export const sharePolicyIdCardFailure = createAction(
  '[Benefits] Share Policy Id Card Failure',
  props<{ error: any }>()
);

export const getPrintPolicyIdCard = createAction(
  '[Benefits] Get Print Policy Id Card',
  props<{ policyId: number }>()
);

export const getDownloadPolicyIdCard = createAction(
  '[Benefits] Get Download Policy Id Card',
  props<{ policyId: number; planName: string }>()
);

export const getPolicyIdCardSuccess = createAction(
  '[Benefits] Get Policy Id Card Success'
);

export const getPolicyIdCardFailure = createAction(
  '[Benefits] Get Policy Id Card Failure',
  props<{ error: any }>()
);
