export interface IPaymentCardRequestPostModel {
  groupNumber: string;
  memberNumber: string;
  memberFirstName: string;
  memberLastName: string;
  memberPersonId: number;
  partnerUserId: number;
  groupId: number;
  cardAmount: number;
  cardAvailableDate: Date;
  providerName: string;
  procedureInfo: string;
  cptCode: string;
  providerAddress1: string;
  providerAddress2: string;
  providerCity: string;
  providerState: string;
  providerPostalCode: string;
  providerCountryCode: string;
  visibleToMember: string;
  providerTIN: string;
  providerNPI: string;
}
