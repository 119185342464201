import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SuperBillsEffects } from './super-bills.effects';
import * as fromSuperBills from './super-bills.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSuperBills.superbillsFeatureKey, fromSuperBills.reducer),
    EffectsModule.forFeature([SuperBillsEffects])
  ]
})
export class SuperBillsStoreModule { }
