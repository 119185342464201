import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { PermissionType } from '../enums';
import { AuthFacade } from '../facades/auth.facade';

@Directive({
  selector: '[mxPermissionFlag]'
})
export class PermissionFlagDirective implements OnInit {
  @Input() permissionType: PermissionType;

  private isHidden = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authFacade: AuthFacade
  ) {}

  ngOnInit() {
    this.updateView();
  }

  private updateView() {
    this.checkValidity().subscribe(hasAccess => {
      if (hasAccess) {
        if (this.isHidden) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.isHidden = false;
        }
      } else {
        this.viewContainer.clear();
        this.isHidden = true;
      }
    });
  }

  private checkValidity(): Observable<boolean> {
    let isValid = of(true);

    if (this.permissionType !== undefined) {
      isValid = this.authFacade.role$.pipe(
        map(role => role?.permissions.includes(this.permissionType))
      );
    }

    return isValid;
  }
}
