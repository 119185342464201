import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';
import { LoggedInGuard, LoggedOutGuard } from '@guards';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes = [
  {
    path: '',
    canActivateChild: [LoggedOutGuard],
    loadChildren: () => import('./modules/login-layout/login-layout.module').then(m => m.LoginLayoutModule)
  },
  {
    path: '',
    canActivateChild: [LoggedInGuard],
    loadChildren: () => import('./modules/main-layout/main-layout.module').then(m => m.MainLayoutModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { title: 'Page Not Found' }
  },
  {
    path: '**',
    redirectTo: '/groups',
    pathMatch: 'full'
  }
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
