import { Injectable } from '@angular/core';
import { PhysicianType } from '@enums';
import { Store } from '@ngrx/store';
import { CostEstimatorActions } from 'src/app/root-store/cost-estimator-store';
import {
  selectProcedureCost,
  selectProcedureCostEstimate
} from 'src/app/root-store/cost-estimator-store/cost-estimator.selectors';
import { AppState } from 'src/app/root-store/root.state';

@Injectable({
  providedIn: 'root'
})
export class CostEstimatorFacade {

  public procedureCost$ = this.store.select(selectProcedureCost);
  public procedureCostEstimate$ = this.store.select(selectProcedureCostEstimate);

  constructor(
    private store: Store<AppState>
  ) {}

  getProcedureCost(cptCode: string, npi: string, planId: number, personId: number) {
    this.store.dispatch(CostEstimatorActions.getProcedureCost({ cptCode, npi, planId, personId }));
  }

  getProcedureCostEstimate(
    personId: number, procedureName: string, procedureCost: number,
    cptCode: string, placeOfService: number, physicianType: PhysicianType
  ) {
    this.store.dispatch(CostEstimatorActions.getProcedureCostEstimate({
      personId, procedureCost, cptCode, placeOfService, physicianType, procedureName
    }));
  }

  clearProcedureEstimate() {
    this.store.dispatch(CostEstimatorActions.clearProcedureEstimate());
  }
}
