export enum ModuleType {
  'IdCards' = 1,
  'BenefitSummary' = 2,
  'Claims' = 3, // (no nav)
  'MeMD' = 4,
  'Messages' = 6, // (no nav)
  'ExternalLink' = 7,
  'TouchCare' = 9,
  'ProviderSearch' = 10,
  'VidDocDemo' = 12,
  'EZPay' = 13, // (no nav)
  'Akos' = 14,
  'FrmConcierge' = 15,
  'Spc' = 17,
  'Ventegra' = 18,
  'BalanceBill' = 19, // (no nav)
  'SuperBill' = 20, // (no nav)
  'HMC' = 21,
  'HealthServiceRequest' = 22,
  'Documents' = 23,
  'MdLive' = 24,
  'Chat' = 25,
  'Magellan' = 30,
  'ProviderSearchStandalone' = 33
}

export enum PermissionType {
  'CreateGroup' = 0,
  'EditGroup',
  'EditBranding',
  'CreatePlan',
  'EditPlan',
  'EditModules',
  'EditTerminology',
  'EditPartners',
}

export enum PlanType {
  'Medical' = 1,
  'Dental' = 2,
  'Vision' = 3
}

export enum PlanTypeSmall {
  'Medical' = 'med'
}

export enum CoverageType {
  EE = 'Employee',
  ES = 'Employee & Spouse',
  EC = 'Employee & Children',
  FAM = 'Family'
}

export enum MemberContactValueType {
  'PhoneNumber' = 1,
  'Email' = 2,
  'Address' = 3,
  'Website' = 4
}

export enum RelationshipType {
  'Self' = 1,
  'Spouse' = 2,
  'Child' = 3,
  'ChildWhereInsuredHasNoFinancialResponsibility' = 4,
  'StepsonOrStepdaughter' = 5,
  'FosterChild' = 6,
  'WardOfTheCourt' = 7,
  'Employee' = 8,
  'Unknown' = 9,
  'HandicappedDependent' = 10,
  'OrganDonor' = 11,
  'CadaverDonor' = 12,
  'GrandsonOrGrandaughter' = 13,
  'NephewOrNiece' = 14,
  'InjuredPlaintiff' = 15,
  'SponsoredDependent' = 16,
  'DependentOfMinorDependent' = 17,
  'GrandfatherOrGrandmother' = 19
}

export enum TerminologyType {
  InNetwork = 1,
  OutOfNetwork = 2,
  Deductible = 3,
  OutOfPocketMax = 4,
  Copay = 5,
  Coinsurance = 6,
  Claim = 7,
  Claims = 8,
  HealthServiceRequest = 9,
  BenefitsSummary = 10,
  HealthPlan = 11,
  Plan = 12,
  Insurance = 13,
  ExplanationOfBenefits = 14,
  SubmittedClaims = 15,
  GroupNumber = 16,
  MemberNumber = 17,
  SubmittedBills = 33,
  PersonNumber = 34
}

export enum ClaimTransactionStatus {
  New = 1,
  Processing = 2,
  Completed = 3,
  Failed = 4,
  Cancelled = 5,
  PaidThroughOtherMeans = 6
}

export enum EventName {
  Login                           = 'partner_login',
  LoginFailed                     = 'partner_login_failed',
  SignUp                          = 'partner_sign_up',
  SignUpFailed                    = 'partner_sign_up_failed',
  ClaimOpened                     = 'partner_claim_opened',
  ClaimViewed                     = 'partner_claim_viewed',
  MeMdOpened                      = 'partner_memd_opened',
  MeMdOpenTnc                     = 'partner_memd_open_tnc',
  MeMdTransfer                    = 'partner_memd_transfer',
  MessageOpened                   = 'partner_message_opened',
  MessageViewed                   = 'partner_message_viewed',
  ResetPassword                   = 'partner_reset_password',
  ContactsOpened                  = 'partner_contacts_opened',
  BrandingOpened                  = 'partner_branding_opened',
  GroupsOpened                    = 'partner_groups_opened',
  MessagesOpened                  = 'partner_messages_opened',
  MembersOpened                   = 'partner_members_opened',
  ClaimsOpened                    = 'partner_claims_opened',
  MemberMessagesOpened            = 'partner_member_messages_opened',
  PlansOpened                     = 'partner_plans_opened',
  UpdateProfile                   = 'partner_update_profile',
  Logout                          = 'partner_logout',
  UpdatePassword                  = 'partner_update_password',
  UpdateGroup                     = 'partner_update_group',
  UpdatePlan                      = 'partner_update_plan',
  UpdateBranding                  = 'partner_update_branding',
  CreateBranding                  = 'partner_create_branding',
  UpdatePhoneContact              = 'partner_update_phone_contact',
  CreatePhoneContact              = 'partner_create_phone_contact',
  UpdateAddressContact            = 'partner_update_address_contact',
  CreateAddressContact            = 'partner_create_address_contact',
  UpdateEmailContact              = 'partner_update_email_contact',
  CreateEmailContact              = 'partner_create_email_contact',
  UpdateWebsiteContact            = 'partner_update_website_contact',
  CreateWebsiteContact            = 'partner_create_website_contact',
  DeleteContact                   = 'partner_delete_contact',
  CreatePlan                      = 'partner_create_plan',
  CreateGroup                     = 'partner_create_group',
  TypeAheadSelected               = 'partner_type_ahead_selected',
  VisitedProviderSearch           = 'partner_visited_provider_search',
  VisitedFacilitySearch           = 'partner_visited_facility_search',
  VisitedProcedureSearch          = 'partner_visited_procedure_search',
  ExecutedSearch                  = 'partner_executed_search',
  ViewedProviderDetails           = 'partner_viewed_provider_details',
  ViewedFacilityDetails           = 'partner_viewed_facility_details',
  ViewedProcedureDetails          = 'partner_viewed_procedure_details',
  StartedHealthServicerequest     = 'partner_started_health_service_request',
  SubmittedHealthServicerequest   = 'partner_submitted_health_service_request',
  ProcedureCostEstimatorVisited   = 'partner_procedure_cost_estimator_visited',
  ProcedureCostLookupApiCalled    = 'partner_procedure_cost_lookup_api_called',
  ProcedureCostEstimateApiCalled  = 'partner_procedure_cost_estimate_api_called',
  SpecialtySelected               = 'partner_specialty_selected',
  FacilityTypeSelected            = 'partner_facility_type_selected'
}

export enum BalanceBillStatus {
  'Unknown' = 0,
  'New' = 1,
  'In Progress' = 2,
  'Declined' = 3,
  'Completed' = 4,
  'Received' = 5
}

export enum SuperBillStatus {
  'Unknown' = 0,
  'New' = 1,
  'In Progress' = 2,
  'Declined' = 3,
  'Completed' = 4,
  'Received' = 5,
  'Resubmit' = 6,
  'Draft' = 7
}

export enum RegistrationDateRange {
  'Past7Days' = 1,
  'PastMonth' = 2,
  'Past6Months' = 3,
  'PastYear' = 4
}

export enum BreadcrumbType {
  Link,
  GroupList,
  MemberList,
  Details
}

export enum PaymentCardRequestState {
  'Approval Required' = 1,
  'Pending' = 2,
  'Active' = 3,
  'Canceled' = 4,
  'Expired' = 5
}

export enum HttpResourceStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum PricingTier {
  Best = 0,
  Average,
  Unknown,
  Expensive
}

export enum HSRStatus {
  Unknown = 0,
  ApprovalRequired,
  Pending,
  Active,
  Canceled,
  Expired,
  Authorized,
  Submitted,
  Closed,
  CardRequested,
  CardDenied,
  CardApproved,
  ReadyForReview
}

export enum HsrFormStepName {
  'patientForm' = 0,
  'providerForm',
  'procedureForm',
  'paymentCardForm'
}

export enum HsrQuestionType {
  Binary = 1,
  Text = 2
}

export enum ChatType {
  Unspecified = 0,
  Embedded = 1
}

export enum Vendor {
  ClaimDoc = 1,
  ClaimWatcher = 2,
  RbpDemo = 3,
  FRM = 4,
  Advanta = 5,
  HST = 6
}

export enum PaymentGateway {
  StripeGateway = 1,
  StripeTestingGateway = 2
}

export enum ClaimsProcessingVendor {
  None = 0,
  ChristianCareMinistry = 1
}

export enum ClaimStatus {
  'Voided' = 0,
  'Paid' = 1,
  'Denied' = 2,
  'Adjustment' = 3,
  'Sent for Pricing' = 4,
  'On Hold' = 5,
  'Duplicate' = 6,
  'Complete (not paid)' = 7,
  'Audit' = 8,
  'Pending Payment' = 9,
  'New' = 10,
  'In Progress' = 11,
  'Pended' = 12,
  'Pre Estimate' = 13,
  'Processed' = 14
}

export enum SearchType {
  Provider = 0,
  Procedure,
  Facility
}

export enum PhysicianType {
  PCP = 0,
  Specialist
}

export enum PlaceOfService {
  InOffice = 11,
  UrgentCare = 20,
  ER = 23
}

export enum UserType {
  Partner = 0
}

export enum MessageCenterViewState {
  ThreadsList = 0,
  NewMessage
}

export enum ThreadListType {
  Inbox = 0,
  Sent,
  Archived
}

export enum ThreadSortOption {
  DateDesc = 1,
  DateAsc = 2,
  AssigneeDesc = 3,
  AssigneeAsc = 4,
  MemberNameDesc = 5,
  MemberNameAsc = 6
}
