import { RelationshipType } from '@enums';
import { IMemberDetailsResponseModel, IMxInsuranceCard } from '@models';
import { IEligibilityResponseModel } from './eligibility-response.model';
import { IPlanResponseModel } from './plan-response.model';

export interface IPolicyResponseModel {
  id: number;
  userId: number;
  eligibilityId: number;
  planId: number;
  firstName: string;
  lastName: string;
  memberNumber: string;
  personNumber: string;
  dateOfBirth: string;
  gender: string;
  effectiveFrom: string;
  effectiveTo: string;
  coverage: string;
  personId: number;
  groupId: number;
  relationship: RelationshipType;
  parentPolicyId: number;
  parentPersonId: number;
  createdAt: string;
  updatedAt: string;

  dependents?: IEligibilityResponseModel[] | IMemberDetailsResponseModel[];
  plan?: IPlanResponseModel;
  groupNumber?: string;
  card?: IMxInsuranceCard;
}
