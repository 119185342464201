import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromPlanTier from './plan-tier.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PlanTierEffects } from './plan-tier.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPlanTier.planTierFeatureKey, fromPlanTier.reducer),
    EffectsModule.forFeature([PlanTierEffects])
  ],
  providers: [PlanTierEffects]
})
export class PlanTierStoreModule { }
