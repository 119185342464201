/* eslint-disable @typescript-eslint/no-unsafe-return */ // TODO Discuss what registration data model should be
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReporting from './reporting.reducer';

export const selectReportingState = createFeatureSelector<fromReporting.ReportingState>(
  fromReporting.reportingFeatureKey
);

export const selectGroupRegistrationReportData = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.registrationData
);

export const selectAggregatedMemberCountsByDate = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.aggregatedMemberCountsByDate
);

export const selectIsLoadingAggregatedMemberCountsByDate = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.isLoadingAggregatedMemberCountsByDate
);

export const selectAggregatedMemberCountsByDateError = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.aggregatedMemberCountsByDateError
);

export const selectGroupsMemberCount = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.groupsMemberCount
);

export const selectIsLoadingGroupsMemberCount = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.isLoadingGroupsMemberCount
);

export const selectGroupsMemberCountError = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.groupsMemberCountError
);

export const selectAggregatedUserLoginsByDate = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.aggregatedUserLoginsByDate
);

export const selectIsLoadingAggregatedUserLoginsByDate = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.isLoadingAggregatedUserLoginsByDate
);

export const selectAggregatedUserLoginsByDateError = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.aggregatedUserLoginsByDateError
);

export const selectGroupUserLoginsByDate = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.groupUserLoginsByDate
);

export const selectIsLoadingGroupUserLoginsByDate = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.isLoadingGroupUserLoginsByDate
);

export const selectGroupUserLoginsByDateError = createSelector(
  selectReportingState,
  (state: fromReporting.ReportingState) => state.groupUserLoginsByDateError
);
