export function getRandomStringFromCharacters(characters: string, minLength: number, maxLength: number) {
  let result = '';
  const charactersLength = characters.length;
  const randomLength = Math.floor(Math.random() * (maxLength - minLength + 1) + minLength);

  for ( let i = 0; i < randomLength; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
