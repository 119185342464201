import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { ITokenResponseModel } from '@models';
import { Store } from '@ngrx/store';
import { MxAlertService, StorageService } from '@utilities';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap } from 'rxjs/operators';
import { decodeAuthToken, logout, refreshAuthTokenSuccess } from 'src/app/root-store/auth-store/auth.actions';
import { AppState } from 'src/app/root-store/root.state';
import { environment } from '@environment';
import { AuthFacade } from '@facades';
import { AuthService } from '../services/token.service';

@Injectable()
export class MxHttpInterceptor implements HttpInterceptor {
  public isRefreshingToken = false;
  public authToken = '';
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private mxAlertService: MxAlertService,
    private storageService: StorageService,
    private authFacade: AuthFacade
  ) {
    this.authFacade.authToken$
      .pipe(filter(authToken => !!authToken))
      .subscribe(authToken => {
        this.authToken = authToken;
      });
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request, this.authToken))
      .pipe(
        catchError((err: HttpErrorResponse) => {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const errorMessage = (err.error != null && err.error?.title) ? `${err.error.title}` : GENERIC_ERROR_MESSAGE;
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 404: {
                break;
              }
              case 401: { // 1. Token is expired. Start here.
                return this.handle401Error(request, next, errorMessage);
              }
              default: {
                this.mxAlertService.alertWithOptions('Error', errorMessage, 'Close');
                break;
              }
            }
          }
          return throwError(() => errorMessage);
        })
      );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, errorMessage: string) {
    // 2a. This means a token refresh was requested but the token was expired. User needs to login again.
    if (req.url === `${environment.config.apiEndpoint}/v2/token`) {
      this.mxAlertService.alertWithOptions('Error', errorMessage, 'Close');
      this.authFacade.logout();
      return throwError(() => errorMessage);
    }

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // 2b. Token expired but still within the window to request a new token.
      return this.authService.refreshToken()
        .pipe(
          switchMap((newToken: ITokenResponseModel) => {
            if (newToken) {
              this.authToken = newToken.token;
              // 3a. Emit new token for tokenSubject listeners
              this.tokenSubject.next(newToken.token);

              // 4. Decode, store and update store with new token
              this.store.dispatch(decodeAuthToken({ token: newToken.token }));
              this.storageService.storeAuthToken(newToken.token);
              this.store.dispatch(refreshAuthTokenSuccess({ data: { token: newToken.token } }));

              // 5. Pass the request on
              return next.handle(this.addToken(req, newToken.token));
            }
            return this.logoutUser();
          }),
          catchError(error => this.logoutUser() ),
          finalize(() => { this.isRefreshingToken = false; })
        );
    } else {
      // 3b. Pass the request on with the given token
      return from(this.tokenSubject.pipe(
        filter(token => token != null),
        switchMap((token) => next.handle(this.addToken(req, token)))
      ));
    }
  }

  logoutUser() {
    this.store.dispatch(logout());

    return throwError(() => '');
  }
}
