import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IBenefitSummaryPutModel,
  IBenefitSummaryResponseModel,
  INetworkTier,
  INetworkTierPutModel
} from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';


@Injectable({
  providedIn: 'root'
})
export class BenefitService {

  constructor(
    private http: HttpClient
  ) { }

  public getPlanBenefitsSummary(planId: number): Observable<INetworkTier[]> {
    const url = `${environment.config.apiEndpoint}/v4/plan/${planId}/benefits/summary`;
    return this.http.get<INetworkTier[]>(url);
  }

  public put(planId: number, benefitTier: number, benefits: INetworkTierPutModel): Observable<INetworkTier> {
    const url = `${environment.config.apiEndpoint}/v4/plan/${planId}/benefitTier/${benefitTier}/benefits/summary`;
    const body = benefits;
    return this.http.put<INetworkTier>(url, body);
  }

  public getPolicyBenefitsSummary(policyId: number): Observable<INetworkTier[]> {
    const url = `${environment.config.apiEndpoint}/v4/policy/${policyId}/benefits/summary`;
    return this.http.get<INetworkTier[]>(url);
  }

  public sharePolicyIdCard(policyId: number, recipient: string, channelType: number) {
    const url = `${environment.config.apiEndpoint}/v2/policy/${policyId}/idCard/shareRequest`;
    return this.http.post<any[]>(url, { recipient, channelType });
  }

  public getPolicyIdCard(policyId: number, size: number) {
    const url = `${environment.config.apiEndpoint}/v2/policy/${policyId}/idCard?size=${size}`;
    return this.http.get(url, { headers: { Accept: 'application/pdf' }, responseType: 'blob' });
  }

}
