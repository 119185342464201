import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import * as ReportingActions from './reporting.actions';
import { ReportingService } from '@backend';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportingEffects {

  loadRegistrationTrend$ = createEffect(() => this.actions$.pipe(
    ofType(ReportingActions.loadRegistrationTrend),
    filter(data => !!data.groupId),
    switchMap((data) => this.reportingService.getRegistrationTrend(data.groupId, data.range)
      .pipe(
        map(reportingData => ReportingActions.loadRegistrationTrendSuccess({ data: reportingData })),
        catchError(error => of(ReportingActions.loadRegistrationTrendFailure({ error })))
      ))
  ));

  loadAggregatedMemberCountsByDate$ = createEffect(() => this.actions$.pipe(
    ofType(ReportingActions.loadAggregatedMemberCountsByDate),
    switchMap((data) => this.reportingService.getAggregatedMemberCountsByDate(data.dateRange)
      .pipe(
        map(reportingData => ReportingActions.loadAggregatedMemberCountsByDateSuccess({ data: reportingData })),
        catchError(error => of(ReportingActions.loadAggregatedMemberCountsByDateFailure({ error })))
      ))
  ));

  loadGroupsMemberCount$ = createEffect(() => this.actions$.pipe(
    ofType(ReportingActions.loadGroupsMemberCount),
    switchMap(() => this.reportingService.getGroupsMemberCount()
      .pipe(
        map(data => ReportingActions.loadGroupsMemberCountSuccess({ data })),
        catchError(error => of(ReportingActions.loadGroupsMemberCountFailure({ error })))
      ))
  ));

  loadAggregatedUserLoginsByDate$ = createEffect(() => this.actions$.pipe(
    ofType(ReportingActions.loadAggregatedUserLoginsByDate),
    switchMap(action => this.reportingService.getUserLoginsByDate(action.dateRange)
      .pipe(
        map(data => ReportingActions.loadAggregatedUserLoginsByDateSuccess({ data })),
        catchError(error => of(ReportingActions.loadAggregatedUserLoginsByDateFailure({ error })))
      ))
  ));

  loadGroupUserLoginsByDate$ = createEffect(() => this.actions$.pipe(
    ofType(ReportingActions.loadGroupUserLoginsByDate),
    switchMap(action => this.reportingService.getUserLoginsByDate(action.dateRange, action.groupId)
      .pipe(
        map(data => ReportingActions.loadGroupUserLoginsByDateSuccess({ data })),
        catchError(error => of(ReportingActions.loadGroupUserLoginsByDateFailure({ error })))
      ))
  ));

  constructor(
    private actions$: Actions,
    private reportingService: ReportingService
  ) { }

}
