import { Injectable } from '@angular/core';
import { PermissionType } from '@enums';
import { select, Store } from '@ngrx/store';
import { AuthActions, AuthSelectors, AuthState } from '@root-store';
import { map, Observable, take } from 'rxjs';
import { selectUserId } from 'src/app/root-store/auth-store/auth.selectors';
import { resetPassword } from 'src/app/root-store/profile-store/profile.actions';
import { login } from '../../root-store/auth-store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  public userId$ = this.store.pipe(select(selectUserId));
  public sessionTimeLeft$ = this.store.pipe(select(AuthSelectors.selectSessionTimeLeft));
  public authToken$ = this.store.pipe(select(AuthSelectors.selectAuthToken));
  public isLoggedIn$ = this.store.pipe(select(AuthSelectors.selectLoggedIn));
  public isLoggingIn$ = this.store.pipe(select(AuthSelectors.selectLoggingIn));
  public role$ = this.store.pipe(select(AuthSelectors.selectRole));

  constructor(
    private store: Store<AuthState>
  ) {}

  public login(username: string, password: string): void {
    this.store.dispatch(
      login({
        loginCreds: {
          username,
          password
        }
      })
    );
  }

  public resetPassword(username: string): void {
    // TODO - Move this action to the auth store?
    this.store.dispatch(resetPassword({ username }));
  }

  public logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  public loadAuthCookie(): void {
    this.store.dispatch(AuthActions.loadAuthCookie());
  }

  public hasPermission(permission: PermissionType): Observable<boolean> {
    return this.role$.pipe(
      map(role => role.permissions),
      map(permissions => permissions.some(v => v === permission)),
      take(1)
    );
  }

}
