import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceFacade } from '@facades';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Pipe({
  name: 'groupNamePipe'
})
export class GroupNamePipe implements PipeTransform {
  constructor(private insuranceFacade: InsuranceFacade) {}

  transform(groupId?: number): Observable<string> {
    return this.insuranceFacade.groups$.pipe(
      filter(groups => !!groups),
      map(groups => {
        if (groups && groupId) {
          const foundGroup = groups.find(group => group.id === groupId);
          if (foundGroup) {
            return foundGroup.name;
          } else {
            return '';
          }
        } else {
          return '';
        }
      })
    );
  }
}
