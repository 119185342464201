import { IPersonResponseModel } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPersons from './person.reducer';

export const selectPersonState = createFeatureSelector<fromPersons.PersonState>(
  fromPersons.personFeatureKey
);

export const selectPeople = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => state.members
);

export const selectSelectedMember = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => state.selectedMember
)

export const selectSelectedSubscriber = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => state.selectedSubscriber
)

export const selectFirstPersonId = createSelector(
  selectPeople,
  (people: IPersonResponseModel[]) => {
    if (people && people.length > 0) {
      return people[0].id;
    }
  }
);

export const selectIsLoading = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => state.isLoading
);

export const selectSelectedMemberIds = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => ({
    subscriberId: state.selectedSubscriber?.personId,
    personId: state.selectedMember?.personId
  })
);

export const selectSelectedMemberPlanIds = createSelector(
  selectSelectedMember,
  (selectedMember) => {
    return selectedMember?.policies?.map(policy => policy.planId);
  }
);

export const selectMembers = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => ({
    members: state.members
  })
);

export const selectMemberSearchResults = createSelector(
  selectPersonState,
  (state: fromPersons.PersonState) => (state.memberSearchResults)
);
