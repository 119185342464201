import { BalanceBillStatus } from '@enums';

export interface IBalanceBillResponseModel {
  id: number;
  userId: number;
  memberFirstName: string;
  memberLastName: string;
  memberContactEmail: string;
  memberContactPhone: string;
  patientFirstName: string;
  patientLastName: string;
  status: BalanceBillStatus;
  createdAt: string;
  updatedAt: string;
}
