import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromModule from './module-config.reducer';
import { ModuleType } from '@enums';

export const selectModuleConfigState = createFeatureSelector<fromModule.ModuleConfigState>(
  fromModule.moduleConfigFeatureKey
);

export const selectAllModules = createSelector(
  selectModuleConfigState,
  (state: fromModule.ModuleConfigState) => state.allModules
);

export const selectSelectedGroupModuleConfigs = createSelector(
  selectModuleConfigState,
  (state: fromModule.ModuleConfigState) => state.selectedGroupModuleConfigs
);

export const selectSelectedGroupMeMdConfigs = createSelector(
  selectModuleConfigState,
  (state: fromModule.ModuleConfigState) => state.selectedGroupMeMdConfigs
);

export const selectIsSaving = createSelector(
  selectModuleConfigState,
  (state: fromModule.ModuleConfigState) => state.isSaving
);

export const selectHasSavedChangesSuccessfully = createSelector(
  selectModuleConfigState,
  (state: fromModule.ModuleConfigState) => state.hasSavedChangesSuccessfully
);

export const selectHasDeletedMeMdConfigSuccessfully = createSelector(
  selectModuleConfigState,
  (state: fromModule.ModuleConfigState) => state.hasDeletedMeMdConfigSuccessfully
);
