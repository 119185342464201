import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import {
  ITerminologyResponseModel,
  ITerminologyConfigResponseModel,
  ITerminologyConfigPostModel
} from '@models';

@Injectable({
  providedIn: 'root'
})
export class TerminologyConfigService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<ITerminologyResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/terminologyConfigs`;
    return this.http.get<ITerminologyResponseModel[]>(url);
  }

  public getByGroup(groupId: number): Observable<ITerminologyConfigResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/terminologyConfigs`;
    return this.http.get<ITerminologyConfigResponseModel[]>(url);
  }

  public post(groupId: number, terminologyConfig: ITerminologyConfigPostModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/terminologyConfig`;
    const body = terminologyConfig;
    return this.http.post<any>(url, body);
  }

  public put(terminologyConfigId: number, terminologyConfig: ITerminologyConfigPostModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/terminologyConfig/${terminologyConfigId}`;
    const body = terminologyConfig;
    return this.http.put<any>(url, body);
  }

  public delete(terminologyConfigId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/terminologyConfig/${terminologyConfigId}`;
    return this.http.delete<any>(url);
  }

}
