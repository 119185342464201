import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { of } from 'rxjs';

import { ClientEventService } from '@backend';
import * as AnalyticsActions from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  logEvent$ = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.logEvent),
    tap(action => void this.analytics.logEvent(action.eventName, action.data as object)),
    switchMap(action => {
      const eventData = { ...action.data, action: action.eventName };
      return this.clientEventService.logEvent(eventData).pipe(
        map(() => AnalyticsActions.logEventSuccess()),
        catchError(() => of(AnalyticsActions.logEventFailure()))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private analytics: AngularFireAnalytics,
    private clientEventService: ClientEventService
  ) { }
}
