import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IHSRResponseModel } from 'src/app/backend/entities/health-service-request-response.model';

@Component({
  selector: 'mx-hsr-list',
  templateUrl: './hsr-list.component.html',
  styleUrls: ['./hsr-list.component.scss']
})
export class HSRListComponent implements OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() paymentCardRequests: IHSRResponseModel[];
  @Input() isLoading = true;

  public dataSource: MatTableDataSource<IHSRResponseModel>;
  public hasPaymentCardRequests = false;
  public displayedColumns: string[] = [
    'spcId',
    'memberName',
    'providerName',
    'groupAndMemberNumber',
    'cardAmount',
    'cardAvailableDate',
    'cardExpirationDate',
    'dateOfService',
    'estimatedCost',
    'visibleToMember',
    'status',
    'needsReview',
    'details'
  ];
  public icons = {
    visibleToMember: faEye,
    notVisibleToMember: faEyeSlash
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnChanges() {
    if (this.paymentCardRequests) {
      this.dataSource = new MatTableDataSource<IHSRResponseModel>(this.paymentCardRequests);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    this.hasPaymentCardRequests = this.paymentCardRequests != null && this.paymentCardRequests.length > 0;
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openPaymentCardRequest(paymentCardRequestId: number): void {
    void this.router.navigate([`./${paymentCardRequestId}`], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve'
    });
  }

}
