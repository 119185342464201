import { Pipe, PipeTransform } from '@angular/core';
import { MemberContactValueType } from '@enums';

@Pipe({
  name: 'contactType'
})
export class ContactTypePipe implements PipeTransform {

  transform(contactTypeCode: number): any {
    return MemberContactValueType[contactTypeCode];
  }

}
