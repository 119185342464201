import { selectUserId } from 'src/app/root-store/auth-store/auth.selectors';
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BenefitService,
  BrandConfigService,
  GroupService,
  MemberContactService,
  ModuleConfigService,
  PayerService,
  PlanService,
  PlanTierService,
  ProviderSearchService
} from '@backend';
import { GROUP_LOGO_PLACEHOLDER, PAYER_ID } from '@constants';
import { EventName, MemberContactValueType } from '@enums';
import {
  IBrandConfigResponseModel,
  IGroupResponseModel,
  IMemberContactPostModel,
  IMemberContactResponseModel,
  IModuleConfigResponseModel,
  IPayerResponseModel,
  IPlanResponseModel,
  IPlanTierResponseModel,
  IProviderNetworkResponseModel
} from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsActions } from '../analytics-store';
import { PersonActions } from '../person-store';
import { AppState } from '../root.state';
import * as InsuranceActions from './insurance.actions';
import { ModuleConfigActions } from '@root-store';
import { selectGroups, selectSelectedGroupId } from './insurance.selectors';

@Injectable({
  providedIn: 'root'
})
export class InsuranceEffects {

  selectGroup$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.selectGroup),
    filter(data => !!data.groupId),
    tap((data) => {
      this.store.dispatch(PersonActions.loadPeopleByGroup({
        groupId: data.groupId,
        initialSubscriberId: data.initialSubscriberId,
        initialPersonId: data.initialPersonId })
      );
      this.store.dispatch(ModuleConfigActions.loadModulesConfigsByGroup({ groupId: data.groupId }));
    }),
    map((data) => InsuranceActions.selectGroupSuccess({ groupId: data.groupId }))
  ));

  loadBenefits$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadBenefits),
    concatMap(() =>
    // TODO: Replace this, what the... why is this even working in the grand scheme of things
    /** An EMPTY observable only emits completion. Replace with your own observable API request */
      EMPTY.pipe(
        map(data => InsuranceActions.loadBenefitsSuccess({ data })),
        catchError(error => of(InsuranceActions.loadBenefitsFailure({ error }))))
    )
  ));

  loadPlan$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadPlan),
    switchMap((data) => this.planService.get(data.planId)),
    map((plan: IPlanResponseModel) => InsuranceActions.loadPlanSuccess({ plan }))
  ));

  loadGroup$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadGroup),
    switchMap(action => this.groupService.get(action.groupId)
      .pipe(
        map(group => InsuranceActions.loadGroupSuccess({ group })),
        catchError(error => of(InsuranceActions.loadGroupFailure({ error })))
      ))
  ));

  loadAllGroups$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadAllGroups),
    switchMap(() => this.groupService.getAll()),
    map(groups => InsuranceActions.loadAllGroupsSuccess({ groups }))
  ));

  loadProviderNetworks$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadProviderNetworks),
    switchMap((data) => this.providerSearchService.getProviderNetworks()
      .pipe(
        map((providerNetworks: IProviderNetworkResponseModel[]) => {
          let items = JSON.parse(JSON.stringify(providerNetworks)) as Array<IProviderNetworkResponseModel>;
          items = items.sort((a, b) => {
            if (a.name.toLowerCase().trim() === 'no network') { return -1; }
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          });
          items.unshift({ id: 0, name: 'Network Unknown', imageUri: null, portalUri: null, contactPhoneNumber: null });
          return items;
        }),
        map((providerNetworks: IProviderNetworkResponseModel[]) => InsuranceActions.loadProviderNetworksSuccess({ providerNetworks })),
        catchError(error => of(InsuranceActions.loadProviderNetworksFailure({ error })))
      ))
  ));

  loadPlansByGroup$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadPlansByGroup),
    filter(data => !!data.groupId),
    switchMap((data) => this.planService.getByGroup(data.groupId)),
    map((plans: IPlanResponseModel[]) => InsuranceActions.loadPlansByGroupSuccess({ plans }))
  ));

  loadAllPlans$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadAllPlans),
    switchMap(() => this.store.select(selectUserId)),
    filter(userId => !!userId),
    switchMap(userId => this.planService.getAll(userId)),
    map(plans => InsuranceActions.loadAllPlansSuccess({ plans }))
  ));



  loadContactsByGroup = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadContactsByGroup),
    filter(data => !!data.groupId),
    switchMap((data) => this.contactService.getByGroup(data.groupId)),
    map((contacts: IMemberContactResponseModel[]) => InsuranceActions.loadContactsByGroupSuccess({ contacts }))
  ));

  loadModulesByGroup = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadModulesByGroup),
    filter(data => !!data.groupId),
    switchMap((data) => this.moduleService.getByGroup(data.groupId)),
    map((modules: IModuleConfigResponseModel[]) => InsuranceActions.loadModulesByGroupSuccess({ modules }))
  ));

  loadBrandingByGroup = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadBrandingByGroup),
    filter(data => !!data.groupId),
    switchMap((data) => this.brandService.getByGroup(data.groupId)
      .pipe(
        map((brand: IBrandConfigResponseModel) => InsuranceActions.loadBrandingByGroupSuccess({ brand })),
        catchError(error => of(InsuranceActions.loadBrandingByGroupFailure({ error })))
      ))
  ));

  saveGroup = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.saveGroup),
    filter(data => !!data.groupId),
    switchMap((data) => {
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdateGroup }));
      return combineLatest(
        this.groupService.put(data.groupId, data.group),
        this.store.pipe(select(selectGroups), take(1))
      );
    }),
    map(([updatedGroup, groups]) => {
      const index = groups.indexOf(groups.find(group => group.id === updatedGroup.id));
      const groupsCopy = JSON.parse(JSON.stringify(groups)) as IGroupResponseModel[];

      groupsCopy.splice(index, 1, updatedGroup);
      return InsuranceActions.saveGroupSuccess({ groups: groupsCopy });
    })
  ));

  savePlan$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.savePlan),
    switchMap((data) => {
      const obsArray: Observable<any>[] = [];

      // Always add these to save
      obsArray.push(
        this.planService.put(data.planId, data.plan),
      );
      
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdatePlan }));

      if (data.sbcFile != null && data.sbcFile instanceof(File)) {
        obsArray.push(this.planService.uploadSbcFile(data.planId, data.sbcFile));
      }
      if (data.spdFile != null && data.spdFile instanceof(File)) {
        obsArray.push(this.planService.uploadSpdFile(data.planId, data.spdFile));
      }
      if (data.planImage != null && data.planImage instanceof(File)) {
        obsArray.push(this.planService.uploadPlanImage(data.planId, data.planImage));
      }
      return forkJoin(obsArray);
    }),
    switchMap(() => this.store.select(selectSelectedGroupId)),
    map((groupId) => InsuranceActions.loadPlansByGroup({ groupId }))
  ));

  createPlan$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.createPlan),
    withLatestFrom(this.store.pipe(select(selectSelectedGroupId))),
    tap(() => this.store.dispatch(InsuranceActions.setIsCloningPlan({ isCloningPlan: true }))),
    switchMap(([action, groupId]) => this.planService.createPlan(PAYER_ID, action.plan)
      .pipe(
        switchMap(response => {
          const plan: IPlanResponseModel = response.body;
          const obsArray: Observable<any>[] = [];
          if (action.planImage != null && action.planImage instanceof(File)) {
            obsArray.push(this.planService.uploadPlanImage(plan.id, action.planImage));
          }
          if (action.sbcFile != null && action.sbcFile instanceof(File)) {
            obsArray.push(this.planService.uploadSbcFile(plan.id, action.sbcFile));
          }
          if (action.spdFile != null && action.spdFile instanceof(File)) {
            obsArray.push(this.planService.uploadSpdFile(plan.id, action.spdFile));
          }

          return forkJoin([of(plan.id), ...obsArray]);
        }),
        switchMap(([planId]) => {
          const loadPlanTiersObservable = action.planIdToCloneFrom
            ? this.planTierService.loadPlanTiers(action.planIdToCloneFrom)
            : of(null);
          return forkJoin([of(planId), loadPlanTiersObservable]);
        }),
        switchMap(([planId, planTiersToCloneFrom]) => {
          if (planTiersToCloneFrom?.length > 0) {
            const planTierPostRequests: Observable<IPlanTierResponseModel>[] = planTiersToCloneFrom.map(planTier => {
              const planTierPostModel = {
                tier: planTier.tier,
                tierName: planTier.tierName
              };
              return this.planTierService.createPlanTier(planId, planTierPostModel);
            });

            return forkJoin([of(planId), ...planTierPostRequests]);
          } else {
            void this.router.navigate([], {
              queryParams: {
                id: null
              },
              queryParamsHandling: 'merge'
            });

            this.completePlanCreation();
            this.store.dispatch(InsuranceActions.loadPlansByGroup({ groupId }));

            return EMPTY;
          }
        }),
        switchMap(([planId, ...planTiers]) => {
          const planTierNetworkRequests: Observable<any[]>[] = planTiers.map(planTier => this.planTierService.loadPlanTierNetworks(action.planIdToCloneFrom, planTier.tier)
            .pipe(
              switchMap(planTierNetworks => {
                if (planTierNetworks?.length > 0) {
                  const newPlanTierNetworkRequests: Observable<any>[] = planTierNetworks.map(planTierNetwork => this.planTierService.addPlanTierNetwork(planId, planTier.tier, planTierNetwork.id));
                  return forkJoin(newPlanTierNetworkRequests);
                } else {
                  return of(null);
                }
              })
            ));
          const planTierExternalNetworkMappingsRequests: Observable<any[]>[] = planTiers.map(planTier => this.planTierService.getPlanTierExternalNetworkMappings(action.planIdToCloneFrom, planTier.tier)
            .pipe(
              switchMap(planTierExternalNetworkMappings => {
                if (planTierExternalNetworkMappings?.length > 0) {
                  const newPlanTierExternalNetworkMappingsRequests: Observable<any>[] = planTierExternalNetworkMappings.map(planTierExternalNetworkMapping => this.planTierService.createPlanTierExternalNetworkMapping(planId, planTier.tier, planTierExternalNetworkMapping.ribbonInsuranceId));
                  return forkJoin(newPlanTierExternalNetworkMappingsRequests);
                } else {
                  return of(null);
                }
              })
            ));
          const planTierBenefitsRequest: Observable<any> = this.planTierService.getPlanTierBenefits(action.planIdToCloneFrom)
            .pipe(
              switchMap(planTierBenefits => {
                if (planTierBenefits?.length > 0) {
                  const newPlanTierBenefitsRequests: Observable<any>[] = planTierBenefits.map(planTierBenefits => {
                    const planTierPostModel = {
                      networkTier: planTierBenefits.networkTier,
                      individual: planTierBenefits.individual,
                      family: planTierBenefits.family
                    };
                    return this.planTierService.updatePlanTierBenefits(planId, planTierBenefits.networkTier, planTierPostModel);
                  });
                  return forkJoin(newPlanTierBenefitsRequests)
                } else {
                  return of(null);
                }
              })
            );
          return forkJoin([...planTierNetworkRequests, ...planTierExternalNetworkMappingsRequests, planTierBenefitsRequest]);
        }),
        switchMap(() => {
          void this.router.navigate([], {
            queryParams: {
              id: null
            },
            queryParamsHandling: 'merge'
          });

          this.completePlanCreation();

          return of(InsuranceActions.loadPlansByGroup({ groupId }));
        }),
        catchError(error => {
          this.showSnackBar(error);
          this.store.dispatch(InsuranceActions.setIsCloningPlan({ isCloningPlan: false }))

          return of(InsuranceActions.createPlanFailure({ error }));
        })
      ))
  ));

  saveBrand = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.saveBrand),
    filter(data => !!data.groupId),
    switchMap((data) => {
      const brand = {
        primaryLogoUri: data.brand.logoUri,
        primaryColorHex: data.brand.color
      };

      if (data.brandConfigId != null) {
        this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdateBranding }));
        if (data.logo != null) {
          this.brandService.uploadLogo(data.brandConfigId, data.logo).subscribe();
        }
        return this.brandService.update(data.brandConfigId, brand);
      } else if (data.logo != null) {
        this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.CreateBranding }));
        brand.primaryLogoUri = GROUP_LOGO_PLACEHOLDER;
        this.brandService.createForGroupWithOutMessage(data.groupId, brand)
          .subscribe(newBrandConfig => {
            if (newBrandConfig != null) {
              this.brandService.uploadLogo(newBrandConfig.id, data.logo).subscribe(response => of(response));
            }
          });
      } else {
        this.showSnackBar('Please choose a file to use for the brand logo.');
      }
      return of({});
    }),
    switchMap(() =>
      this.store.pipe(
        delay(1500),
        select(selectSelectedGroupId),
        map(groupId =>
          InsuranceActions.loadBrandingByGroup({ groupId })
        )
      )
    )
  ));

  savePhoneContact = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.savePhoneContact),
    withLatestFrom(this.store.select(selectSelectedGroupId)),
    filter(([data, groupId]) => !!groupId),
    switchMap(([data, groupId]) => {
      const contact: IMemberContactPostModel = {
        groupId,
        type: MemberContactValueType.PhoneNumber,
        name: data.contact.name,
        description: data.contact.description,
        value: {
          phoneNumber: data.contact.phone
        }
      };
      if (data.contactId) {
        this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdatePhoneContact }));
        return this.contactService.put(data.contactId, contact, groupId);
      }
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.CreatePhoneContact }));
      return this.contactService.post(groupId, contact);
    })
  ), { dispatch: false });

  saveAddressContact = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.saveAddressContact),
    withLatestFrom(this.store.select(selectSelectedGroupId)),
    filter(([data, groupId]) => !!groupId),
    switchMap(([data, groupId]) => {
      const contact: IMemberContactPostModel = {
        groupId,
        type: MemberContactValueType.Address,
        name: data.contact.name,
        description: data.contact.description,
        value: {
          addressLine1: data.contact.addressLine1,
          addressLine2: data.contact.addressLine2,
          city: data.contact.city,
          state: data.contact.state,
          postalCode: data.contact.postalCode,
          country: data.contact.country
        }
      };
      if (data.contactId) {
        this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdateAddressContact }));
        return this.contactService.put(data.contactId, contact, groupId);
      }
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.CreateAddressContact }));
      return this.contactService.post(groupId, contact);
    })
  ), { dispatch: false });

  saveEmailContact = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.saveEmailContact),
    withLatestFrom(this.store.select(selectSelectedGroupId)),
    filter(([data, groupId]) => !!groupId),
    switchMap(([data, groupId]) => {
      const contact: IMemberContactPostModel = {
        groupId,
        type: MemberContactValueType.Email,
        name: data.contact.name,
        description: data.contact.description,
        value: {
          emailAddress: data.contact.email
        }
      };

      if (data.contactId) {
        this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdateEmailContact }));
        return this.contactService.put(data.contactId, contact, groupId);
      }
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.CreateEmailContact }));
      return this.contactService.post(groupId, contact);
    })
  ), { dispatch: false });

  saveWebsiteContact = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.saveWebsiteContact),
    withLatestFrom(this.store.select(selectSelectedGroupId)),
    filter(([data, groupId]) => !!groupId),
    switchMap(([data, groupId]) => {
      const contact: IMemberContactPostModel = {
        groupId,
        type: MemberContactValueType.Website,
        name: data.contact.name,
        description: data.contact.description,
        value: {
          displayName: data.contact.displayName,
          uri: data.contact.url
        }
      };
      if (data.contactId) {
        this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdateWebsiteContact }));
        return this.contactService.put(data.contactId, contact, groupId);
      }
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.CreateWebsiteContact }));
      return this.contactService.post(groupId, contact);
    })
  ), { dispatch: false });

  deleteContact = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.deleteContact),
    withLatestFrom(this.store.select(selectSelectedGroupId)),
    switchMap(([data, groupId]) => {
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.DeleteContact }));
      return this.contactService.delete(data.contactId, groupId);
    }),
    withLatestFrom(this.store.pipe(select(selectSelectedGroupId))),
    map(([_, groupId]) => InsuranceActions.loadContactsByGroup({ groupId }))
  ), { dispatch: false });

  cloneMemberContactsFromGroup$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.cloneMemberContactsFromGroup),
    switchMap(action => this.contactService.getByGroup(action.groupId)),
    withLatestFrom(this.store.select(selectSelectedGroupId)),
    switchMap(([memberContacts, selectedGroupId]) => {
      if (memberContacts.length > 0) {
        memberContacts.sort((a, b) => a.createdAt <= b.createdAt ? -1 : 1);
        memberContacts.forEach(memberContact => {
          this.store.dispatch(InsuranceActions.cloneMemberContact({ memberContact, selectedGroupId }));
        });
        return of(InsuranceActions.cloneMemberContactsFromGroupComplete({ groupId: selectedGroupId }));
      } else {
        const error = 'The selected group has no available member contacts to clone from.';
        this.showSnackBar(error);
        return of(InsuranceActions.cloneMemberContactsFromGroupFailure({ error }));
      }
    }),
    catchError(error => of(InsuranceActions.cloneMemberContactsFromGroupFailure({ error })))
  ));

  cloneMemberContact$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.cloneMemberContact),
    concatMap((action) => this.contactService.post(action.selectedGroupId, {
      groupId: action.selectedGroupId,
      type: action.memberContact.type,
      name: action.memberContact.name,
      value: action.memberContact.value,
      description: action.memberContact.description
    }).pipe(
      catchError((error) => {
        const errorMessage = `An error ocurred trying to clone member contact from group. ${ error as string }`;
        this.showSnackBar(errorMessage);
        return of(InsuranceActions.cloneMemberContactFailure({ error }));
      })
    )),
    map(memberContact => InsuranceActions.cloneMemberContactSuccess({ memberContact })),
  ));

  loadPayer$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadPayer),
    switchMap((data) => this.payerService.get(data.payerId)),
    map((payer: IPayerResponseModel) => InsuranceActions.loadPayerSuccess({ payer }))
  ));

  loadSelectedPlanProviderNetworks$ = createEffect(() => this.actions$.pipe(
    ofType(InsuranceActions.loadSelectedPlanProviderNetworks),
    switchMap(action => this.planService.getPlanNetworks(action.planId)),
    map(providerNetworks => InsuranceActions.loadSelectedPlanProviderNetworksSuccess({ providerNetworks })),
    catchError(error => of(InsuranceActions.loadSelectedPlanProviderNetworksFailure({ error })))
  ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private planService: PlanService,
    private providerSearchService: ProviderSearchService,
    private groupService: GroupService,
    private contactService: MemberContactService,
    private moduleService: ModuleConfigService,
    private brandService: BrandConfigService,
    private benefitService: BenefitService,
    private payerService: PayerService,
    private planTierService: PlanTierService,
    private router: Router,
    @Inject(MatSnackBar) private snackBar: MatSnackBar
  ) { }

  private showSnackBar(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 10000 }
    );
  }

  private completePlanCreation() {
    this.showSnackBar('Plan created successfully!');
    this.store.dispatch(InsuranceActions.setIsCloningPlan({ isCloningPlan: false }))
  }

}
