import { PlanType } from '@enums';

export interface IPlanResponseModel {
  id: number;
  groupName: string;
  groupId: number;
  groupNumber: string;
  payerId: number;
  name: string;
  externalId: string;
  type: PlanType;
  planImageUri: string;
  networkId: number;
  providerNetworkName: string;
  providerNetworkImageUri: string;
  rxPcn: string;
  rxBin: string;
  rxGroup: string;
  claimSubmittalAddressLine1: string;
  claimSubmittalAddressLine2: string;
  claimSubmittalCity: string;
  claimSubmittalState: string;
  claimSubmittalPostalCode: string;
  ediPayerId: string;
  memberSupportPhoneNumber: string;
  providerSupportPhoneNumber: string;
  createdAt: string;
  updatedAt: string;
  sbcUri: string;
  spdUri: string;
  sbcNameFile: string;
  spdNameFile: string;
  familyAccumulatorsMustBeMetForFamilies: boolean;
  hidePolicyIdentifiers?: boolean;
  maskMemberNumbers?: boolean;
  planImageNameFile?: string;
  suffixNote: string;
  prefixNote: string;
}
