import { createAction, props } from '@ngrx/store';

export const logEvent = createAction(
  '[Analytics] Log Event',
  props<{ eventName: string; data?: any }>()
);

export const logEventSuccess = createAction(
  '[Analytics] Log Event Success'
);

export const logEventFailure = createAction(
  '[Analytics] Log Event Failure'
);

export const setSearchResponseStartTime = createAction(
  '[Analytics] Set Search Response Start Time'
);
