import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  IUSerImpersonateResponseModel,
  IUserAccountResponseModel,
  IUserImpersonatePostModel,
  IUserPasswordPutModel,
  IUserPasswordResetPostModel,
  IUserPasswordResetPutModel
} from '@models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { updatePasswordSuccess } from 'src/app/root-store/auth-store/auth.actions';
import { AppState } from 'src/app/root-store/root.state';
import { MxAlertService } from 'src/app/shared/utilities/alert.service';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private mxAlertService: MxAlertService,
    private store: Store<AppState>
  ) { }

  public getUser(userId: number): Observable<IUserAccountResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}`;
    return this.http.get<IUserAccountResponseModel>(url, this.httpOptions);
  }

  public putPassword(updateModel: IUserPasswordPutModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/user/${updateModel.id}/password`;
    const body = updateModel;
    return this.http.put<any>(url, body);
  }

  public requestResetPassword(username: string) {
    const url = `${environment.config.apiEndpoint}/v2/user/password`;
    const body: IUserPasswordResetPostModel = { username, callbackUri: '' };
    return this.http.post(url, body)
      .subscribe(() => {
        this.mxAlertService.alertWithOptions('', 'Request Sent! Check your email for a link to reset your password.', 'Close');
      });
  }

  public requestResetPasswordForMember(username: string) {
    const url = `${environment.config.apiEndpoint}/v2/user/password?type=member`;
    const body: IUserPasswordResetPostModel = { username, callbackUri: '' };
    return this.http.post(url, body)
      .subscribe(() => {
        this.mxAlertService.alertWithOptions(
          '',
          'An email was sent to that email address with a link to reset the password for that account.',
          'Close'
        );
      });
  }

  public resetPassword(resetModel: IUserPasswordResetPutModel) {
    const url = `${environment.config.apiEndpoint}/v2/user/password`;
    const body = resetModel;
    return this.http.put(url, body, { observe: 'response' })
      .subscribe((response) => {
        if (response.status === 204) {
          this.mxAlertService.alert('Password successfully reset!');
          void this.router.navigate(['/login']);
        }
      });
  }

  public updatePassword(updateModel: IUserPasswordPutModel) {
    const url = `${environment.config.apiEndpoint}/v2/user/${updateModel.id}/password`;
    const body = updateModel;
    return this.http.put(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 204) {
            this.mxAlertService.alertWithOptions('Success', 'Password successfully updated!', 'Close');
            this.store.dispatch(updatePasswordSuccess());
          }
        }));
  }

  public impersonateUser(targetUserId: number, impersonatorUserId: number, clientToken: string) {
    const url = `${environment.config.apiEndpoint}/v1/impersonation/init`;
    const body: IUserImpersonatePostModel = { targetUserId, impersonatorUserId, clientToken };
    return this.http.post<IUSerImpersonateResponseModel>(url, body);
  }
}
