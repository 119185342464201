import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IEligibilityPutModel,
  IEligibilityResponseModel,
  IMemberDetailsResponseModel,
  IMemberSearchPostModel,
  IMemberSearchResponseModel,
  IPersonResponseModel
} from '@models';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  public getRecord(eligibilityId: number): Observable<IEligibilityResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/eligibility/${eligibilityId}`;
    return this.http.get<IEligibilityResponseModel>(url);
  }

  public getByGroup(groupId: number): Observable<IPersonResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/members`;
    return this.http.get<IPersonResponseModel[]>(url, { observe: 'response' })
      .pipe(
        map(response => response.body || [])
      );
  }

  public updateRecord(eligibilityRecord: IEligibilityPutModel): Observable<IEligibilityResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/eligibility/${eligibilityRecord.id}`;
    const body = eligibilityRecord;
    return this.http.put<IEligibilityResponseModel>(url, body)
      .pipe(
        tap((record) => {
          if (record) {
            this.snackBar.open(
              'Eligibility record successfully updated!',
              'Close',
              { duration: 3000 }
            );
          }
        })
      );
  }

  public searchMembers(searchPostModel: IMemberSearchPostModel): Observable<IMemberSearchResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v3/members/search`;
    const body = searchPostModel;
    
    return this.http.post<IMemberSearchResponseModel[]>(url, body).pipe(
      catchError(error => {
        this.snackBar.open(
          `An application error occurred and the members could not be retrieved. Please contact support if this issue persists.`,
          'Close',
          { duration: 10000 }
        );

        return throwError(() => new Error(error));
      })
    );
  }

  public getMember(personId: number): Observable<IMemberDetailsResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/member/${personId}`;
    return this.http.get<IMemberDetailsResponseModel>(url)
      .pipe(
        catchError((error) => {
          this.snackBar.open(
            `An application error occurred and the member could not be retrieved. Please contact support if this issue persists.`,
            'Close',
            { duration: 10000 }
          );
          return throwError(() => new Error(error));
      })
    );
  }

}
