import { IPolicyResponseModel } from './policy-response.model';
import { IUserAccountResponseModel } from './user-account-response.model';

export interface IPersonResponseModel {
  id: number;
  firstName: string;
  lastName: string;
  relationship: number;
  dependents?: IPersonResponseModel[];
  policies: IPolicyResponseModel[];
  createdAt: string;
  updatedAt: string;
  userIds: number[];

  accounts?: IUserAccountResponseModel[];
}

export interface IPersonResponseModel {
  id: number;
  ssn: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  gender: string;
  suffix: string;
  title: string;
  sex: string;
  createdAt: string;
  updatedAt: string;
  dependents?: IPersonResponseModel[];
  subscriberId?: number;
}
