import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import {
  IReplyThreadMPostModel,
  IThreadMessageResponseModel,
  IThreadMessagesPostModel,
  IThreadReadStatusPostModel,
  IThreadResponseModel,
  LoadThreadsRequest
} from '@models';
import { ThreadListType } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService {

  constructor(
    private http: HttpClient
  ) { }

  public loadThreads(loadThreadsRequest: LoadThreadsRequest): Observable<IThreadResponseModel[]> {
    var url = `${environment.config.apiEndpoint}/v2/threads?skip=${loadThreadsRequest.skip}&limit=${loadThreadsRequest.limit}&sentToGroups=${loadThreadsRequest.sentToGroups}`;

    if (loadThreadsRequest.threadListType === ThreadListType.Inbox) {
      url = url.concat('&withMemberReply=true&archived=false');
    } else if (loadThreadsRequest.threadListType === ThreadListType.Archived) {
      url = url.concat('&withMemberReply=true&archived=true');
    } else if (loadThreadsRequest.threadListType === ThreadListType.Sent) {
      url = url.concat('&withMemberReply=false&archived=false');
    }
    if (loadThreadsRequest.sortBy) {
      url = url.concat(`&sortBy=${loadThreadsRequest.sortBy}`);
    }
    if (loadThreadsRequest.searchTerm) {
      url = url.concat(`&searchTerm=${loadThreadsRequest.searchTerm}`);
    }

    return this.http.get<IThreadResponseModel[]>(url);
  }

  public loadThreadMessages(threadId: number) {
    const url = `${environment.config.apiEndpoint}/v2/thread/${threadId}/messages`;
    return this.http.get<IThreadMessageResponseModel[]>(url);
  }

  public sendNewMessage(message: IThreadMessagesPostModel, files: File[]) {
    const url = `${environment.config.apiEndpoint}/v3/messages`;
    const formData = this.buildMessageFormData(message, files);
    return this.http.post<any>(url, formData);
  }

  public toggleRepliesAllowed(threadId: number, replyAllowed: boolean) {
    const url = `${environment.config.apiEndpoint}/v2/thread/${threadId}/replyAllowedStatus`;
    const body = { replyAllowed };
    return this.http.put<any>(url, body);
  }

  public archiveThread(threadId: number, userId: number, isArchived: boolean) {
    const url = `${environment.config.apiEndpoint}/v2/thread/${threadId}/archiveStatus`;
    const body = { userId, isArchived };
    return this.http.post<any>(url, body);
  }

  public replyThreadMessage(message: IReplyThreadMPostModel, threadId: number, files: File[]) {
    const url = `${environment.config.apiEndpoint}/v2/thread/${threadId}/message`;
    const formData = this.buildMessageFormData(message, files);
    return this.http.post<any>(url, formData);
  }

  public getMessageAttachment(messageId: number, attachmentId: number) {
    const url = `${environment.config.apiEndpoint}/v2/message/${messageId}/attachment/${attachmentId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  public updateThreadReadStatus(threadId: number, threadStatus: IThreadReadStatusPostModel) {
    const url = `${environment.config.apiEndpoint}/v2/thread/${threadId}/readStatus`;
    return this.http.post<any>(url, threadStatus);
  }

  private buildMessageFormData(message: any, files: File[]) {
    const formData = new FormData();
    formData.append('message', JSON.stringify(message));
    files?.forEach(file => {
      formData.append('files', file)
    });
    return formData;
  }

}
