/* eslint-disable @typescript-eslint/no-unused-vars */
import { IMemberDetailsResponseModel, IMemberSearchResponseModel, IPersonResponseModel } from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as PersonActions from './person.actions';

export const personFeatureKey = 'person';

export interface PersonState {
  members: IPersonResponseModel[];
  memberSearchResults: IMemberSearchResponseModel[];
  selectedSubscriberId: number;
  selectedSubscriber: IMemberDetailsResponseModel;
  selectedMemberId: number;
  selectedMember: IMemberDetailsResponseModel;
  isLoading: boolean;
}

export const initialPersonState: PersonState = {
  members: null,
  memberSearchResults: null,
  selectedSubscriberId: null,
  selectedSubscriber: undefined,
  selectedMemberId: null,
  selectedMember: undefined,
  isLoading: false,
};

const personReducer = createReducer(
  initialPersonState,

  on(PersonActions.loadPeopleByGroup, (state, action) => ({ ...state, isLoading: true })),
  on(PersonActions.loadPeopleByGroupSuccess, (state, action) => ({
    ...state,
    members: action.members,
    isLoading: false
  })),
  on(PersonActions.loadPeopleFailure, (state, action) => ({ ...state, isLoading: false })),

  on(PersonActions.searchMembers, (state, action) => ({ ...state, isLoading: true })),
  on(PersonActions.searchMembersSuccess, (state, action) => ({
    ...state,
    memberSearchResults: action.memberSearchResults,
    isLoading: false,
  })),
  on(PersonActions.searchMembersFailure, (state, action) => ({ ...state, isLoading: false })),


  on(PersonActions.getSelectedMemberAndSubscriberDetails, (state) => ({ ...state, isLoading: true })),
  on(PersonActions.getSelectedMemberAndSubscriberDetailsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    selectedSubscriber: action.subscriber,
    selectedMember: action.member
  })),
  on(PersonActions.getSelectedMemberAndSubscriberDetailsFailure, (state) => ({
    ...state,
    isLoading: false,
    selectedSubscriber: null,
    selectedMember: null
  })),

  on(PersonActions.setSelectedPersonIds, (state, action) => ({
    ...state,
    selectedSubscriberId: action.subscriberId,
    selectedMemberId: action.personId
  })),

  on(PersonActions.clearMemberSearchResultsState, (state, action) => ({ ...state, memberSearchResults: null })),
  on(PersonActions.clearPersonState, (state, action) => Object.assign({}, initialPersonState))
);

export function reducer(state: PersonState | undefined, action: Action) {
  return personReducer(state, action);
}
