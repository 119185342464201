/* eslint-disable max-len */
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, on, createSelector } from '@ngrx/store';
import { DocumentsActions } from '.';
import { IHSRResponseModel } from '../../../backend/entities/health-service-request-response.model';
import { ISuperBillDetailResponseModel } from '../../../backend/entities/super-bill-detail-response.model';

export const documentsFeatureKey = 'documents';
// Documents should be sorted by last created first
export const documentsAdapter = createEntityAdapter<IHSRResponseModel>({
  sortComparer: (a: IHSRResponseModel, b: IHSRResponseModel) => b.createdAt.localeCompare(a.createdAt)
});
export interface State extends EntityState<IHSRResponseModel> {
  selectedRequestId: number | null;
  attachedSuperBills: Array<ISuperBillDetailResponseModel>;
  paymentCardNumber: string;
}

const defaultDocuments = {
  selectedRequestId: null,
  attachedSuperBills: null,
  paymentCardNumber: null
};

export const initialState: State = documentsAdapter.getInitialState(defaultDocuments);

export const documentsReducer = createReducer(
  initialState,
  on(DocumentsActions.setSelectedHSR, (state, { id }) => ({ ...state, selectedRequestId: id })),
  on(DocumentsActions.getMemberHSRsSuccess, (state, action) => documentsAdapter.setAll(action.requests, state)),
  on(DocumentsActions.clearHSRs, state => documentsAdapter.removeAll({ ...state, selectedResultId: null })),
  on(DocumentsActions.createHSRSuccess, (state, action) => documentsAdapter.addOne(action.hsr, state)),
  on(DocumentsActions.requestNewCardSuccess, (state, { hsr }) => documentsAdapter.upsertOne(hsr, state)),
  on(DocumentsActions.updateHSRStatusSuccess, (state, { hsr }) => documentsAdapter.upsertOne(hsr, state)),
  on(DocumentsActions.getHSRDetailsSuccess, (state, { hsr, paymentCardNumber }) =>
    documentsAdapter.upsertOne(hsr, { ... state, paymentCardNumber })),
);

export const reducer = (state: State | undefined, action: Action) => documentsReducer(state, action);

// Selectors
export const getDocumentsState = createFeatureSelector<State>('documents');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = documentsAdapter.getSelectors(getDocumentsState);

export const getSelectedRequestId = (state: State) => state.selectedRequestId;
export const selectAttachedSuperBills = (state: State) => state.attachedSuperBills;

export const selectUnmaskedCardNumber = createSelector(
  getDocumentsState,
  (state) => state.paymentCardNumber
);

export const selectCurrentRequestId = createSelector(
  getDocumentsState,
  getSelectedRequestId
);

export const selectCurrentRequest = createSelector(
  selectEntities,
  selectCurrentRequestId,
  (entities, id) => entities[id]
);
