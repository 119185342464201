export interface IEligibilityResponseModel {
  // EDIT
  memberNumber: string; //
  personNumber: string; //
  ssn: string; //
  dateOfBirth: string; //
  gender: string; //
  firstName: string; //
  middleName: string; //
  lastName: string; //
  address1: string; //
  address2: string; //
  city: string; //
  state: string; //
  postalCode: string; //
  countryCode: string; //
  homePhone: string; //
  workPhone: string; //
  cellPhone: string; //
  planCoverageTier: string; //
  effectiveDate: string; //
  terminationDate: string; //
  suffix: string; //
  title: string; //
  sex: string; //
  email: string; //

  // NO EDIT
  id: number;
  relationship: number; //
  subscriberSSN: string;
  createdAt: string;
  updatedAt: string;
  planName: string;
  planId: string;
  insuranceType: string;
  groupName: string;

  // NO DISPLAY
  payerName: string;
  groupNumber: string;
  groupAddress1: string;
  groupAddress2: string;
  groupCity: string;
  groupState: string;
  groupPostalCode: string;
  groupCountryCode: string;
  planYearStartDate: string;

  dependents?: any[];
}
