<mat-card>

  <div *ngIf="!isLoading;else loading">
    <div *ngIf="!hasBills">No balance bills found.</div>
  </div>

  <div [hidden]="isLoading || !hasBills">

    <mat-form-field appearance="outline" class="member-search">
      <mat-label>Filter Balance Bills...</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. John Smith">
    </mat-form-field>

    <mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="patientFirstName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> First Name </th>
        <td mat-cell *matCellDef="let row" width="15%" class="table-long-text">{{ row.patientFirstName }} </td>
      </ng-container>

      <ng-container matColumnDef="patientLastName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Last Name </th>
        <td mat-cell *matCellDef="let row" width="15%" class="table-long-text"> {{ row.patientLastName }}</td>
      </ng-container>

      <ng-container matColumnDef="memberContactPhone">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Phone </th>
        <td mat-cell *matCellDef="let row" width="15%"> {{ row.memberContactPhone | phone:'US' }}</td>
      </ng-container>

      <ng-container matColumnDef="memberContactEmail">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="20%"> Email </th>
        <td mat-cell *matCellDef="let row" width="20%" class="table-long-text">
          <a href="mailto:{{ row.memberContactEmail }}">{{ row.memberContactEmail }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="15%"> Status </th>
        <td mat-cell *matCellDef="let row" width="15%">
          {{ row.status | billStatus }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="10%"> Created At</th>
        <td mat-cell *matCellDef="let row" width="10%"> {{ row.createdAt | date }}</td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th mat-header-cell mat-sort-header *matHeaderCellDef width="10%"> View Images </th>
        <td mat-cell *matCellDef="let row" width="10%">
          <div fxLayout="row">
            <button mat-icon-button color="primary" (click)="openImages(row.id)" matTooltip="Open Images">
              <mat-icon>perm_media</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="openBill(row.id)" matTooltip="View Details">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="[10]" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>

<ng-template #loading>
  <mat-spinner class="center-spinner"></mat-spinner>
</ng-template>
