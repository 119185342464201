import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { IPaymentCardResponseModel } from '@models';
import { BaseDestroyComponentComponent } from '@shared-components/base-destroy-component/base-destroy-component.component';
import { takeUntil } from 'rxjs';
import { DocumentsFacade } from 'src/app/modules/documents/documents-store/documents.facade';

@Component({
  selector: 'mx-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent extends BaseDestroyComponentComponent implements OnInit, OnChanges {
  @Input() cardData: IPaymentCardResponseModel;
  @Input() showFullCardNumber = false;

  public cardPadding: number;
  public cardHeight: number;
  public maskedCardNumbers: string[] = [];
  public unmaskedCardNumbers: string[] = [];

  constructor(private documentsFacade: DocumentsFacade) {
    super();
  }

  get cardNumbers(): string[] {
    return this.showFullCardNumber ? this.unmaskedCardNumbers : this.maskedCardNumbers;
  }

  ngOnInit() {
    this.documentsFacade.unmaskedCardNumber$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(unmaskedCardNumber => {
        if (unmaskedCardNumber) {
          this.unmaskedCardNumbers = this.splitCardNumbers(unmaskedCardNumber);
        }
      });
  }

  ngOnChanges() {
    if (this.cardData && this.maskedCardNumbers.length === 0) {
      this.maskedCardNumbers = this.splitCardNumbers(this.cardData.number);
    }
  }

  private splitCardNumbers(cardNumber: string) {
    return cardNumber.match(/.{1,4}/g);
  }

}
