import { IProviderNetworkResponseModel } from '@models';
import { IBenefitSummary } from './benefit-summary.model';

export interface INetworkTier {
  networkTier: number;
  networkTierName: string;
  individual: IBenefitSummary;
  family: IBenefitSummary;

  providerNetworks?: IProviderNetworkResponseModel[];
  hasIndividualDeductibleAndOopMaxValues?: boolean;
  hasFamilyDeductibleAndOopMaxValues?: boolean;
  hasIndividualCopayAndCoinsuranceValues?: boolean;
}
