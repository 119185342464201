import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DEFAULT_MEMBER_SEARCH_PAGE_LIMIT } from '@constants';
import { PersonFacade } from '@facades';
import { IMemberSearchResponseModel } from '@models';
import { GroupNamePipe } from '@pipes';
import { BaseDestroyComponentComponent } from '@shared-components/base-destroy-component/base-destroy-component.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mx-member-search',
  templateUrl: './member-search.component.html',
  providers: [GroupNamePipe],
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent extends BaseDestroyComponentComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() searchText: string;
  @Input() showGroupColumn = true;
  @Input() defaultPageLimit = DEFAULT_MEMBER_SEARCH_PAGE_LIMIT;
  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() memberSelected = new EventEmitter<IMemberSearchResponseModel>();

  public textInputChanged$ = new Subject<string>();
  public isLoading$ = this.personFacade.isLoading$;
  public searchResults$ = this.personFacade.memberSearchResults$;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<IMemberSearchResponseModel>;
  public hasMembers: boolean;

  constructor(
    private personFacade: PersonFacade
  ) {
    super();
  }

  ngOnInit() {
    this.personFacade.clearMemberSearchResults();

    this.displayedColumns = ['lastName', 'firstName', 'dateOfBirth', 'relationship'];
    if (this.showGroupColumn) this.displayedColumns.push('groupName');

    this.textInputChanged$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchText: string) => {
        this.searchTextChanged.next(searchText);
      });

    this.searchResults$
      .subscribe(results => {
        this.hasMembers = results?.length > 0;
        if (this.hasMembers) {
          this.dataSource = new MatTableDataSource<IMemberSearchResponseModel>(results);
          setTimeout(() => this.dataSource.paginator = this.paginator);
        }
      })
  }

  public search(): void {
    this.textInputChanged$.next(this.searchText);
  }

  public selectPerson(member: IMemberSearchResponseModel) {
    this.memberSelected.next(member);
  }
}
