import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  // Strip HTML tags from a string
  public stripHtml(text: string): string {
    return (text != null) ? text.replace(/(<([^>]+)>)/ig, '') : text;
  }

  // Remove UTC designation (Z) from ISO 8601 date string
  // This is useful for dates that you don't want automatically converted to local datetime when displayed, such as birth dates
  public stripUTCFromDateString(date: string): string {
    return date != null ? date.replace('Z', '') : null;
  }

  // Given an array of suffixes, checks if the string has one
  public endsWithAny(suffixes: string[], text: string): boolean {
    return suffixes.some((suffix) => text.endsWith(suffix));
  }

  public getFileName(url: string): string {
    if (url !== null && url !== '') {
      const arrayUrl = url.split('/');
      const arrayLength = arrayUrl.length;
      if (arrayUrl.length > 0) {
        return arrayUrl[arrayLength - 1];
      }
    }
    return '';
  }
}
