import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuperBillFacade } from '@facades';
import { ISuperBillStatusPostModel } from '@models';

@Component({
  selector: 'mx-super-bill-status-modal',
  templateUrl: './super-bill-status-modal.component.html',
  styleUrls: ['./super-bill-status-modal.component.scss']
})
export class SuperBillStatusModalComponent implements OnInit {

  public statusForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { status: string; billId: number },
    private fb: FormBuilder,
    private superBillFacade: SuperBillFacade
  ) { }

  ngOnInit() {
    this.statusForm = this.fb.group({
      status: this.data.status,
      note: ''
    });
  }

  clickUpdateStatus(): void {
    this.superBillFacade.updateSuperBillStatus(this.statusForm.value as ISuperBillStatusPostModel, this.data.billId);
  }

  clickCancelUpdateStatus(): void {
    this.superBillFacade.cancelSave.emit();
  }

}
