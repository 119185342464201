import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IBalanceBillImageResponseModel,
  IPersonResponseModel,
  IPolicyResponseModel,
  IPolicySearchModel
} from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient
  ) { }

  public getClaimsByUser(userId: number): Observable<IBalanceBillImageResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/claims`;
    return this.http.get<IBalanceBillImageResponseModel[]>(url);
  }

  public getPolicy(policyId: number): Observable<IPolicyResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/policy/${policyId}`;
    return this.http.get<IPolicyResponseModel>(url, this.httpOptions);
  }

  public getPolicyByUserId(userId: number): Observable<IPolicyResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/policies`;
    return this.http.get<IPolicyResponseModel[]>(url, this.httpOptions);
  }

  public policySearch(userId: number, searchCriteria: IPolicySearchModel): Observable<IPolicyResponseModel[]> {
    const body = JSON.stringify(searchCriteria);
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/policy/eligibility/search`;
    return this.http.post<IPolicyResponseModel[]>(url, body, this.httpOptions);
  }

  public getDependents(policyId: number): Observable<IPersonResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/policy/${policyId}/dependents`;
    return this.http.get<IPersonResponseModel[]>(url, this.httpOptions);
  }

}
