export interface IBenefitSummaryFlattenedModel {
    primaryCarePreAuthRequired?: boolean,
    primaryCareCopay?: number,
    primaryCareDeductibleApplies?: boolean,
    primaryCareCoinsurance?: number,
    specialistPreAuthRequired?: boolean,
    specialistCopay?: number,
    specialistDeductibleApplies?: boolean,
    specialistCoinsurance?: number,
    urgentCarePreAuthRequired?: boolean,
    urgentCareCopay?: number,
    urgentCareDeductibleApplies?: boolean,
    urgentCareCoinsurance?: number,
    emergencyRoomPreAuthRequired?: boolean,
    emergencyRoomCopay?: number,
    emergencyRoomDeductibleApplies?: boolean,
    emergencyRoomCoinsurance?: number,
    immunizationsPreAuthRequired?: boolean,
    immunizationsCopay?: number,
    immunizationsDeductibleApplies?: boolean,
    immunizationsCoinsurance?: number,
    mriScanPreAuthRequired?: boolean,
    mriScanCopay?: number,
    mriScanDeductibleApplies?: boolean,
    mriScanCoinsurance?: number,
    diagnosticLabPreAuthRequired?: boolean,
    diagnosticLabCopay?: number,
    diagnosticLabDeductibleApplies?: boolean,
    diagnosticLabCoinsurance?: number,
    diagnosticXrayPreAuthRequired?: boolean,
    diagnosticXrayCopay?: number,
    diagnosticXrayDeductibleApplies?: boolean,
    diagnosticXrayCoinsurance?: number,
    petScanPreAuthRequired?: boolean,
    petScanCopay?: number,
    petScanDeductibleApplies?: boolean,
    petScanCoinsurance?: number,
    catScanPreAuthRequired?: boolean,
    catScanCopay?: number,
    catScanDeductibleApplies?: boolean,
    catScanCoinsurance?: number,
    physicalTherapyPreAuthRequired?: boolean,
    physicalTherapyCopay?: number,
    physicalTherapyDeductibleApplies?: boolean,
    physicalTherapyCoinsurance?: number,
    occupationalTherapyPreAuthRequired?: boolean,
    occupationalTherapyCopay?: number,
    occupationalTherapyDeductibleApplies?: boolean,
    occupationalTherapyCoinsurance?: number,
    speechTherapyPreAuthRequired?: boolean,
    speechTherapyCopay?: number,
    speechTherapyDeductibleApplies?: boolean,
    speechTherapyCoinsurance?: number,
    hospiceFacilityChargePreAuthRequired?: boolean,
    hospiceFacilityChargeCopay?: number,
    hospiceFacilityChargeDeductibleApplies?: boolean,
    hospiceFacilityChargeCoinsurance?: number,
    emergencyMedicalTransportPreAuthRequired?: boolean,
    emergencyMedicalTransportCopay?: number,
    emergencyMedicalTransportDeductibleApplies?: boolean,
    emergencyMedicalTransportCoinsurance?: number,
    chiropracticPreAuthRequired?: boolean,
    chiropracticCopay?: number,
    chiropracticDeductibleApplies?: boolean,
    chiropracticCoinsurance?: number,
    acupuncturePreAuthRequired?: boolean,
    acupunctureCopay?: number,
    acupunctureDeductibleApplies?: boolean,
    acupunctureCoinsurance?: number,
    familyPlanningPreAuthRequired?: boolean,
    familyPlanningCopay?: number,
    familyPlanningDeductibleApplies?: boolean,
    familyPlanningCoinsurance?: number
  }