import {
  IModuleResponseModel,
  IModuleConfigResponseModel,
  IModuleConfigPostModel,
  IModuleConfigPutModel,
  IMeMdConfigResponseModel,
  IModuleConfigViewModel,
  IMeMdConfigViewModel,
} from '@models';
import { createAction, props } from '@ngrx/store';


export const loadAllModules = createAction(
  '[ModuleConfig] Load All Modules'
);
export const loadAllModulesSuccess = createAction(
  '[ModuleConfig] Load All Modules Success',
  props<{ allModules: IModuleResponseModel[] }>()
);
export const loadAllModulesFailure = createAction(
  '[ModuleConfig] Load All Modules Failure',
  props<{ error: any }>()
);


export const loadModulesConfigsByGroup = createAction(
  '[ModuleConfig] Load Modules By Group',
  props<{ groupId: number }>()
);
export const loadModulesConfigsByGroupSuccess = createAction(
  '[ModuleConfig] Load Modules By Group Success',
  props<{ moduleConfigs: IModuleConfigResponseModel[] }>()
);
export const loadModulesConfigsByGroupFailure = createAction(
  '[ModuleConfig] Load Modules By Group Failure',
  props<{ error: any }>()
);


export const createModuleConfig = createAction(
  '[ModuleConfig] Create Module Config',
  props<{ moduleConfig: IModuleConfigPostModel }>()
);
export const createModuleConfigSuccess = createAction(
  '[ModuleConfig] Create Module Config Success',
  props<{ moduleConfig: IModuleConfigResponseModel }>()
);
export const createModuleConfigFailure = createAction(
  '[ModuleConfig] Create Module Config Failure',
  props<{ error: any }>()
);


export const updateModuleConfig = createAction(
  '[ModuleConfig] Update Module Config',
  props<{ moduleConfig: IModuleConfigPutModel }>()
);
export const updateModuleConfigSuccess = createAction(
  '[ModuleConfig] Update Module Config Success',
  props<{ moduleConfig: IModuleConfigResponseModel }>()
);
export const updateModuleConfigFailure = createAction(
  '[ModuleConfig] Update Module Config Failure',
  props<{ error: any }>()
);


export const reorderModuleConfigs = createAction(
  '[ModuleConfig] Reorder Module Configs',
  props<{ groupId: number; moduleConfigs: IModuleConfigPutModel[] }>()
);
export const reorderModuleConfigsSuccess = createAction(
  '[ModuleConfig] Reorder Module Configs Success'
);
export const reorderModuleConfigsFailure = createAction(
  '[ModuleConfig] Reorder Module Configs Failure',
  props<{ error: any }>()
);


export const deleteModuleConfig = createAction(
  '[ModuleConfig] Delete Module Config',
  props<{ moduleConfigId: number }>()
);
export const deleteModuleConfigSuccess = createAction(
  '[ModuleConfig] Delete Module Config Success'
);
export const deleteModuleConfigFailure = createAction(
  '[ModuleConfig] Delete Module Config Failure',
  props<{ error: any }>()
);




export const loadMeMdConfigsByPlans = createAction(
  '[ModuleConfig] Load MeMD Configs By Plans',
  props<{ planIds: number[] }>()
);
export const loadMeMdConfigsByPlansSuccess = createAction(
  '[ModuleConfig] Load MeMD Configs By Plans Success',
  props<{ meMdConfigs: IMeMdConfigResponseModel[] }>()
);
export const loadMeMdConfigsFailure = createAction(
  '[ModuleConfig] Load MeMD Configs By Plans Failure',
  props<{ error: any }>()
);




export const saveMeMdModuleAndConfigs = createAction(
  '[ModuleConfig] Save MeMD Module and Configs',
  props<{ moduleConfig: IModuleConfigViewModel; meMdConfigs: IMeMdConfigViewModel[] }>()
);
export const saveMeMdModuleAndConfigsSuccess = createAction(
  '[ModuleConfig] Save MeMD Module and Configs Success',
  props<{ meMdConfigs: IMeMdConfigResponseModel[] }>()
);
export const saveMeMdModuleAndConfigsFailure = createAction(
  '[ModuleConfig] Save MeMD Module and Configs Failure',
  props<{ error: any }>()
);


export const deleteMeMdConfig = createAction(
  '[ModuleConfig] Delete MeMD Config',
  props<{ meMdConfigId: number }>()
);
export const deleteMeMdConfigSuccess = createAction(
  '[ModuleConfig] Delete MeMD Config Success'
);
export const deleteMeMdConfigFailure = createAction(
  '[ModuleConfig] Delete MeMD Config Failure',
  props<{ error: any }>()
);




export const clearSelectedGroupMeMdConfigsState = createAction(
  '[ModuleConfig] Clear Selected Group MeMD Configs State'
);

export const clearSavedSuccessfullyState = createAction(
  '[ModuleConfig] Clear Saved Successfully State'
);

export const clearDeletedMeMdConfigSuccessfullyState = createAction(
  '[ModuleConfig] Clear Deleted MeMD Config Successfully State'
);

export const clearModuleConfigState = createAction(
  '[ModuleConfig] Clear Module Config State'
);

export const cloneModuleConfigsFromGroup = createAction(
  '[ModuleConfig] Clone Module Configs From Group',
  props<{ groupId: number }>()
);

export const cloneModuleConfigsFromGroupSuccess = createAction(
  '[ModuleConfig] Clone Module Configs From Group Success',
  props<{ responses: IModuleConfigResponseModel[]; groupId: number }>()
);

export const cloneModuleConfigsFromGroupFailure = createAction(
  '[ModuleConfig] Clone Module Configs From Group Failure'
);
