import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BalanceBillService } from '@backend';
import { IBalanceBillImageResponseModel } from '@models';
import { Observable } from 'rxjs';

@Component({
  selector: 'mx-balance-bill-image-modal',
  templateUrl: './balance-bill-image-modal.component.html',
  styleUrls: ['./balance-bill-image-modal.component.scss']
})
export class BalanceBillImageModalComponent implements OnInit {
  public billImages$: Observable<IBalanceBillImageResponseModel[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { billId: number },
    private balanceBillService: BalanceBillService,
  ) { }

  ngOnInit() {
    this.billImages$ = this.balanceBillService.getBillImages(this.data.billId);
  }

  downloadImage(imageId: number, contentType: string, fileName: string) {
    this.balanceBillService.getBillImage(this.data.billId, imageId, contentType, fileName);
  }

}
