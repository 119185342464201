import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, defaultIfEmpty, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PlanTierService } from '@backend';

import * as PlanTierActions from './plan-tier.actions';
import { AppState } from '../root.state';
import { Store } from '@ngrx/store';
import { INetworkTierPutModel, IPlanTierResponseModel } from '@models';
import { PlanTierSelectors } from '.';

@Injectable({
  providedIn: 'root'
})
export class PlanTierEffects {
  loadPlanTiers$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.loadPlanTiers),
    switchMap(action => this.planTierService.loadPlanTiers(action.planId)),
    map((planTiers: any) => PlanTierActions.loadPlanTiersSuccess({ planTiers }))
  ));

  createPlanTier$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.createPlanTier),
    switchMap(action => this.planTierService.createPlanTier(action.planId, action.planTier)
      .pipe(
        map(planTier => PlanTierActions.createPlanTierSuccess({ planTier })),
        tap(() => this.showSnackBar('Tier saved successfully.')),
        catchError(error => {
          this.showSnackBar('');
          return of(PlanTierActions.createPlanTierFailure({ error }));
        })
      ))
  ));

  updatePlanTier$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.updatePlanTier),
    switchMap(action => this.planTierService.updatePlanTier(action.planId, action.tier, action.planTier)
      .pipe(
        map(planTier => PlanTierActions.updatePlanTierSuccess({ planTier })),
        tap(() => this.showSnackBar('Tier saved successfully.')),
        catchError(error => of(PlanTierActions.updatePlanTierFailure({ error })))
      ))
  ));

  deletePlanTier$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.deletePlanTier),
    withLatestFrom(
      this.store.select(PlanTierSelectors.selectSelectedPlanTierNetworks),
      this.store.select(PlanTierSelectors.selectSelectedPlanTierExternalNetworks)
    ),
    switchMap(([action, planTierNetworks, planTierExternalNetworks]) => {
      const requests: Observable<any>[] = [];

      planTierNetworks.forEach(network => {
        requests.push(this.planTierService.removePlanTierNetwork(action.planId, action.tier, network.id));
      })

      planTierExternalNetworks.forEach(externalNetwork => {
        requests.push(this.planTierService.removePlanTierExternalNetwork(action.planId, action.tier, externalNetwork.ribbonInsuranceId));
      });

      return forkJoin(requests).pipe(
        defaultIfEmpty(null),
        switchMap(() => this.planTierService.deletePlanTier(action.planId, action.tier)),
        map(() => PlanTierActions.deletePlanTierSuccess({ tier: action.tier })),
        tap(() => {
          this.store.dispatch(PlanTierActions.getPlanTierBenefits({ planId: action.planId }));
          this.showSnackBar('Tier deleted successfully.');
        }),
        catchError(error => {
          this.showSnackBar('There was a problem while deleting the tier.');
          return of(PlanTierActions.deletePlanTierFailure({ error }));
        })
      );
    })
  ));

  loadPlanTierNetworks$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.loadPlanTierNetworks),
    switchMap(action => this.planTierService.loadPlanTierNetworks(action.planId, action.tier)
      .pipe(
        map(planTierNetworks => PlanTierActions.loadPlanTierNetworksSuccess({ planTierNetworks })),
        catchError(error => of(PlanTierActions.loadPlanTierNetworksFailure({ error })))
      ))
  ));

  addPlanTierNetwork$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.addPlanTierNetwork),
    switchMap(action => this.planTierService.addPlanTierNetwork(action.planId, action.tier, action.networkId)
      .pipe(
        map(planTierNetwork => PlanTierActions.addPlanTierNetworkSuccess({ planTierNetwork })),
        tap(() => {
          this.store.dispatch(PlanTierActions.loadPlanTierNetworks({ planId: action.planId, tier: action.tier }));
          this.showSnackBar('Network added successfully.');
        }),
        catchError(error => of(PlanTierActions.addPlanTierNetworkFailure({ error })))
      ))
  ));

  removePlanTierNetwork$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.removePlanTierNetwork),
    switchMap(action => this.planTierService.removePlanTierNetwork(action.planId, action.tier, action.networkId)
      .pipe(
        map(() => PlanTierActions.removePlanTierNetworkSuccess()),
        tap(() => {
          this.store.dispatch(PlanTierActions.loadPlanTierNetworks({ planId: action.planId, tier: action.tier }));
          this.showSnackBar('Network removed successfully.');
        }),
        catchError(error => of(PlanTierActions.removePlanTierNetworkFailure({ error })))
      ))
  ));

  getExternalNetworkSuggestions$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.getExternalNetworkSuggestions),
    switchMap(action => this.planTierService.getExternalNetworkSuggestions(action.searchTerm)
      .pipe(
        map(externalNetworks => PlanTierActions.getExternalNetworkSuggestionsSuccess({ externalNetworks })),
        catchError(error => of(PlanTierActions.getExternalNetworkSuggestionsFailure({ error })))
      )),
  ));

  loadPlanTierExternalNetworks$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.loadPlanTierExternalNetworks),
    switchMap(action => this.planTierService.getPlanTierExternalNetworkMappings(action.planId, action.tier)
      .pipe(
        map(externalNetworks => PlanTierActions.loadPlanTierExternalNetworksSuccess({ externalNetworks })),
        catchError(error => of(PlanTierActions.loadPlanTierExternalNetworksFailure({ error })))
      )),
  ));

  addPlanTierExternalNetwork$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.addPlanTierExternalNetwork),
    switchMap(action => this.planTierService.createPlanTierExternalNetworkMapping(action.planId, action.tier, action.ribbonInsuranceId)
      .pipe(
        map(externalNetwork => PlanTierActions.addPlanTierExternalNetworkSuccess({ externalNetwork })),
        tap(() => this.showSnackBar('Network mapping added successfully')),
        catchError(error => of(PlanTierActions.addPlanTierExternalNetworkFailure({ error })))
      )),
  ));

  removePlanTierExternalNetwork$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.removePlanTierExternalNetwork),
    switchMap(action => this.planTierService.removePlanTierExternalNetwork(action.planId, action.tier, action.ribbonInsuranceId)
      .pipe(
        map(() => PlanTierActions.removePlanTierExternalNetworkSuccess({ ribbonInsuranceId: action.ribbonInsuranceId })),
        tap(() => this.showSnackBar('Network mapping removed successfully')),
        catchError(error => of(PlanTierActions.removePlanTierExternalNetworkFailure({ error })))
      )),
  ));

  updatePlanTierBenefits$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.updatePlanTierBenefits),
    switchMap(action => this.planTierService.updatePlanTierBenefits(action.planId, action.tier, action.planTierBenefits)
      .pipe(
        map(planTierBenefits => PlanTierActions.updatePlanTierBenefitsSuccess({ planTierBenefits: planTierBenefits, tier: action.tier })),
        tap(() => this.showSnackBar('Benefits saved successfully.')),
        catchError(error => of(PlanTierActions.updatePlanTierBenefitsFailure({ error })))
      ))
  ));

  getPlanTierBenefits$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.getPlanTierBenefits),
    switchMap(action => this.planTierService.getPlanTierBenefits(action.planId)
      .pipe(
        map(planTierBenefits => PlanTierActions.getPlanTierBenefitsSuccess({ planTierBenefits })),
        catchError(error => of(PlanTierActions.getPlanTierBenefitsFailure({ error })))
      ))
  ));

  clonePlanTiersFromPlan$ = createEffect(() => this.actions$.pipe(
    ofType(PlanTierActions.clonePlanTiersFromPlan),
    switchMap(action => this.planTierService.loadPlanTiers(action.planIdToCloneFrom)
      .pipe(
        switchMap(planTiersToCloneFrom => {
          if (planTiersToCloneFrom?.length > 0) {
            const planTierPostRequests: Observable<IPlanTierResponseModel>[] = planTiersToCloneFrom.map(planTier => {
              const planTierPostModel = {
                tier: planTier.tier,
                tierName: planTier.tierName
              };
              return this.planTierService.createPlanTier(action.planId, planTierPostModel);
            });

            return forkJoin(planTierPostRequests);
          } else {
            this.showSnackBar('The selected plan does not have any tiers to clone from.');
            this.store.dispatch(PlanTierActions.clonePlanTiersFromPlanSuccess());
            return EMPTY;
          }
        }),
        switchMap(planTiers => {
          const planTierNetworkRequests: Observable<any[]>[] = planTiers.map(planTier => this.planTierService.loadPlanTierNetworks(action.planIdToCloneFrom, planTier.tier)
            .pipe(
              switchMap(planTierNetworks => {
                if (planTierNetworks?.length > 0) {
                  const newPlanTierNetworkRequests: Observable<any>[] = planTierNetworks.map(planTierNetwork => this.planTierService.addPlanTierNetwork(action.planId, planTier.tier, planTierNetwork.id));
                  return forkJoin(newPlanTierNetworkRequests);
                } else {
                  return of(null);
                }
              })
            ));
          const planTierExternalNetworkRequests: Observable<any[]>[] = planTiers.map(planTier => this.planTierService.getPlanTierExternalNetworkMappings(action.planIdToCloneFrom, planTier.tier)
            .pipe(
              switchMap(planTierExternalNetworks => {
                if (planTierExternalNetworks?.length > 0) {
                  const newPlanTierExternalNetworkRequests: Observable<any>[] = planTierExternalNetworks.map(planTierExternalNetwork => this.planTierService.createPlanTierExternalNetworkMapping(action.planId, planTier.tier, planTierExternalNetwork.ribbonInsuranceId));
                  return forkJoin(newPlanTierExternalNetworkRequests);
                } else {
                  return of(null);
                }
              })
            ));
          const planTierBenefitsRequest: Observable<any> = this.planTierService.getPlanTierBenefits(action.planIdToCloneFrom)
            .pipe(
              switchMap(planTierBenefits => {
                if (planTierBenefits?.length > 0) {
                  const newPlanTierBenefitsRequests: Observable<any>[] = planTierBenefits.map(planTierBenefits => {
                    const planTierPostModel = {
                      networkTier: planTierBenefits.networkTier,
                      individual: planTierBenefits.individual,
                      family: planTierBenefits.family
                    };
                    return this.planTierService.updatePlanTierBenefits(action.planId, planTierBenefits.networkTier, planTierPostModel);
                  });
                  return forkJoin(newPlanTierBenefitsRequests)
                } else {
                  return of(null);
                }
              })
            );
          return forkJoin([...planTierNetworkRequests, ...planTierExternalNetworkRequests, planTierBenefitsRequest]);
        }),
        map(() => {
          this.showSnackBar('Tiers info cloned successfully')
          this.store.dispatch(PlanTierActions.loadPlanTiers({ planId: action.planId }));
          this.store.dispatch(PlanTierActions.getPlanTierBenefits({ planId: action.planId }));
          return PlanTierActions.clonePlanTiersFromPlanSuccess();
        }),
        catchError(() => {
          const errorMessage = 'There was an error while clonning the tiers info and the cloning process was not completed.';
          this.showSnackBar(errorMessage);
          this.store.dispatch(PlanTierActions.loadPlanTiers({ planId: action.planId }));
          return of(PlanTierActions.clonePlanTiersFromPlanFailure({ error: errorMessage }));
        })
      ))
  ));

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private planTierService: PlanTierService,
    @Inject(MatSnackBar) private snackBar: MatSnackBar
  ) {}

  private showSnackBar(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 3000 }
    );
  }

  private generateEmptyBenefitsPutModel(networkTier: number): INetworkTierPutModel {
    return {
      networkTier,
      individual: {
        deductible: null,
        outOfPocketMax: null,
        primaryCare: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        specialist: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        urgentCare: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        emergencyRoom: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        immunizations: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        mriScan: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        diagnosticLab: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        diagnosticXray: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        petScan: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        catScan: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        physicalTherapy: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        occupationalTherapy: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        speechTherapy: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        hospiceFacilityCharge: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        emergencyMedicalTransport: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        chiropractic: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        acupuncture: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        familyPlanning: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        }
      },
      family: {
        deductible: null,
        outOfPocketMax: null,
        primaryCare: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        specialist: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        urgentCare: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        emergencyRoom: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        immunizations: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        mriScan: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        diagnosticLab: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        diagnosticXray: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        petScan: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        catScan: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        physicalTherapy: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        occupationalTherapy: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        speechTherapy: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        hospiceFacilityCharge: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        emergencyMedicalTransport: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        chiropractic: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        acupuncture: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        },
        familyPlanning: {
          copay: null,
          deductibleApplies: false,
          coinsurance: null,
          preAuthRequired: false
        }
      }
    };
  }
}
