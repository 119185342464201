import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BenefitsState } from '../../root-store/benefits-store/benefits.reducer';
import { BenefitsActions, BenefitsSelectors } from '@root-store';
import { Observable } from 'rxjs';
import { INetworkTier, IPolicyResponseModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class BenefitsFacade {
  public selectedBenefitSummary$: Observable<INetworkTier[]>;
  public selectedPolicy$: Observable<IPolicyResponseModel> = this.store.select(BenefitsSelectors.selectSelectedPolicy);
  public isSharingIdCard$: Observable<boolean> = this.store.select(BenefitsSelectors.selectIsSharingIdCard);
  public isLoadingBenefitSummary$: Observable<boolean> = this.store.select(BenefitsSelectors.selectIsLoadingBenefitSummary);

  constructor(
    private store: Store<BenefitsState>
  ) {
    this.selectedBenefitSummary$ = this.store.select(BenefitsSelectors.selectSelectedBenefitSummary);
  }

  public loadBenefitSummary(planId: number, policyId: number) {
    this.store.dispatch(BenefitsActions.loadBenefitSummary({ planId, policyId }));
  }

  public clearSelectedBenefitSummary() {
    this.store.dispatch(BenefitsActions.clearSelectedBenefitSummary());
  }

  public setSelectedPolicy(policy: IPolicyResponseModel) {
    this.store.dispatch(BenefitsActions.setSelectedPolicy({ policy }));
  }

  public sharePolicyIdCard(policyId: number, recipient: string, channelType: number) {
    this.store.dispatch(BenefitsActions.sharePolicyIdCard({ policyId, recipient, channelType }));
  }

  public getPrintPolicyIdCard(policyId: number) {
    this.store.dispatch(BenefitsActions.getPrintPolicyIdCard({ policyId }));
  }

  public getDownloadPolicyIdCard(policyId: number, planName: string) {
    this.store.dispatch(BenefitsActions.getDownloadPolicyIdCard({ policyId, planName }));
  }
}
