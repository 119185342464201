import { StorageService } from './../../shared/utilities/storage.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CostEstimatorEffects } from './cost-estimator.effects';
import * as fromCostEstimator from './cost-estimator.reducer';

@NgModule({
  declarations: [],
  providers: [StorageService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCostEstimator.analyticsFeatureKey, fromCostEstimator.reducer),
    EffectsModule.forFeature([CostEstimatorEffects])
  ]
})
export class CostEstimatorStoreModule { }
