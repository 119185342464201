import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HSRStatus } from '@enums';
import { IHSRStatusPostModel } from 'src/app/backend/entities/health-service-request-status-post.model';
import { DocumentsFacade } from 'src/app/modules/documents/documents-store/documents.facade';

@Component({
  selector: 'mx-health-service-request-status-modal',
  templateUrl: './health-service-request-status-modal.component.html',
  styleUrls: ['./health-service-request-status-modal.component.scss']
})
export class HealthServiceRequestStatusModalComponent implements OnInit {
  public statusForm: FormGroup;
  public title = 'Update Status';
  public cancelButtonText = 'Cancel';
  public saveButtonText = 'Save';
  public HSRStatus = HSRStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
    { statusBlocked?: boolean;
      title?: string;
      saveButtonText?: string;
      cancelButtonText?: string;
      requestId: number;
      status: HSRStatus;
    },
    private fb: FormBuilder,
    private documentsFacade: DocumentsFacade
  ) { }

  ngOnInit() {
    let statusBlocked = false;
    if (this.data.statusBlocked) {
      statusBlocked = this.data.statusBlocked;
    }

    this.statusForm = this.fb.group({
      status: new FormControl({ value: this.data.status, disabled: statusBlocked }),
      note: ''
    });

    if (this.data.title) {
      this.title = this.data.title;
    }

    if (this.data.cancelButtonText) {
      this.cancelButtonText = this.data.cancelButtonText;
    }

    if (this.data.saveButtonText) {
      this.saveButtonText = this.data.saveButtonText;
    }
  }

  clickUpdateStatus() {
    const hsrStatus: IHSRStatusPostModel = {
      status: this.statusForm.controls.status.value as HSRStatus,
      note: this.statusForm.controls.note.value as string
    };
    this.documentsFacade.updateHSRStatus(hsrStatus, this.data.requestId);
  }

}
