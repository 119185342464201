/* eslint-disable max-len */
import { createAction, props } from '@ngrx/store';
import { ISuperBillPostModel } from 'src/app/backend/entities/super-bill-post.model';
import { ISuperBillResponseModel } from '../../../backend/entities/super-bill-response.model';
import { ISuperBillDetailResponseModel } from '../../../backend/entities/super-bill-detail-response.model';

export const getAllSuperBills = createAction('[Documents Page] Get All Super Bills');
export const getAllSuperBillsSuccess = createAction('[Documents Page] Get All Super Bills Success', props<{ superBills: Array<ISuperBillResponseModel> }>());
export const setSelectedSuperBill = createAction('[Documents Page - List] Set Selected Super Bill', props<{ id: number }>());
export const createSuperBill = createAction('[Documents Page] Create Super Bill', props<{ superBill: ISuperBillPostModel; images: File[] }>());
export const createSuperBillSuccess = createAction('[Documents Page] Create Super Bill Success', props<{ superBill: ISuperBillDetailResponseModel }>());
export const clearSuperBills = createAction('[Documents Page - List] Clear Super Bills');

