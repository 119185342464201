import { IProfileResponseModel } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfile from './profile.reducer';

export const selectProfileState = createFeatureSelector<fromProfile.ProfileState>(
  fromProfile.profileFeatureKey
);

export const selectUserData = createSelector(
  selectProfileState,
  (state: fromProfile.ProfileState) => state.user
);

export const selectUserId = createSelector(
  selectUserData,
  (user: IProfileResponseModel) => {
    if (user) {
      return user.userId;
    }
  }
);

export const selectIsUpdatingPassword = createSelector(
  selectProfileState,
  (state: fromProfile.ProfileState) => state.isUpdatingPassword
);
