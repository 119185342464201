export interface IGroupResponseModel {
  id: number;
  name: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  startDate: string;
  billingCycleDay: number;
  terminationDate: string;
}
