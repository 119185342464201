import {
  IBrandConfigResponseModel,
  IGroupPostModel,
  IGroupPutModel,
  IGroupResponseModel,
  IMemberContactResponseModel,
  IModuleConfigResponseModel,
  IPayerResponseModel,
  IPlanPostModel,
  IPlanPutModel,
  IPlanResponseModel,
  IProviderNetworkResponseModel
} from '@models';
import { createAction, props } from '@ngrx/store';

export const loadBenefits = createAction(
  '[Benefits] Load Benefits'
);

export const loadBenefitsSuccess = createAction(
  '[Benefits] Load Benefits Success',
  props<{ data: any }>()
);

export const loadBenefitsFailure = createAction(
  '[Benefits] Load Benefits Failure',
  props<{ error: any }>()
);

export const loadPlan = createAction(
  '[Benefits] Load Plan',
  props<{ planId: number }>()
);

export const loadPlanSuccess = createAction(
  '[Benefits] Load Plan Success',
  props<{ plan: IPlanResponseModel }>()
);

export const loadPayer = createAction(
  '[Benefits] Load Payer',
  props<{ payerId: number }>()
);

export const loadPayerSuccess = createAction(
  '[Benefits] Load Payer Success',
  props<{ payer: IPayerResponseModel }>()
);

export const loadGroup = createAction(
  '[Insurance] Load Group',
  props<{ groupId: number }>()
);

export const loadGroupSuccess = createAction(
  '[Insurance] Load Group Success',
  props<{ group: IGroupResponseModel }>()
);

export const loadGroupFailure = createAction(
  '[Insurance] Load Group Failure',
  props<{ error: any }>()
);

export const loadAllGroups = createAction(
  '[Insurance] Load All Groups'
);

export const loadAllGroupsSuccess = createAction(
  '[Insurance] Load All Groups Success',
  props<{ groups: IGroupResponseModel[] }>()
);

export const loadPlansByGroup = createAction(
  '[Insurance] Load Plans by Group',
  props<{ groupId: number }>()
);

export const loadPlansByGroupSuccess = createAction(
  '[Insurance] Load Plans by Group Success',
  props<{ plans: IPlanResponseModel[] }>()
);

export const loadAllPlans = createAction(
  '[Insurance] Load All Plans'
);

export const loadAllPlansSuccess = createAction(
  '[Insurance] Load All Plans Success',
  props<{ plans: IPlanResponseModel[] }>()
);

export const createPlan = createAction(
  '[Insurance] Create Plan',
  props<{
    payerId: number;
    plan: IPlanPostModel;
    spdFile?: File;
    sbcFile?: File;
    planImage?: File;
    planIdToCloneFrom: number;
  }>()
);

export const createPlanSuccess = createAction(
  '[Insurance] Create Plan Success',
  props<{ plan: IPlanResponseModel }>()
);

export const createPlanFailure = createAction(
  '[Insurance] Create Plan Failure',
  props<{ error: any }>()
);

export const loadContactsByGroup = createAction(
  '[Insurance] Load Contacts by Group',
  props<{ groupId: number }>()
);

export const loadContactsByGroupSuccess = createAction(
  '[Insurance] Load Contacts by Group Success',
  props<{ contacts: IMemberContactResponseModel[] }>()
);

export const loadModulesByGroup = createAction(
  '[Insurance] Load Modules by Group',
  props<{ groupId: number }>()
);

export const loadModulesByGroupSuccess = createAction(
  '[Insurance] Load Modules by Group Success',
  props<{ modules: IModuleConfigResponseModel[] }>()
);

export const loadBrandingByGroup = createAction(
  '[Insurance] Load Branding by Group',
  props<{ groupId: number }>()
);

export const loadBrandingByGroupSuccess = createAction(
  '[Insurance] Load Branding by Group Success',
  props<{ brand: IBrandConfigResponseModel }>()
);

export const loadBrandingByGroupFailure = createAction(
  '[Insurance] Load Branding by Group Failure',
  props<{ error: any }>()
);

export const loadProviderNetworks = createAction(
  '[Insurance] Load Provider Networks'
);

export const loadProviderNetworksSuccess = createAction(
  '[Insurance] Load Provider Networks Success',
  props<{ providerNetworks: IProviderNetworkResponseModel[] }>()
);

export const loadProviderNetworksFailure = createAction(
  '[Insurance] Load Provider Networks Failure',
  props<{ error: any }>()
);

export const selectGroup = createAction(
  '[Insurance] Select Group',
  props<{
    groupId: number;
    initialSubscriberId?: number;
    initialPersonId?: number;
  }>()
);

export const selectGroupSuccess = createAction(
  '[Insurance] Select Group Success',
  props<{ groupId: number }>()
);

export const clearInsuranceState = createAction(
  '[Insurance] Clear Insurance State'
);

export const saveGroup = createAction(
  '[Insurance] Save Group',
  props<{ groupId: number; group: IGroupPutModel }>()
);

export const createGroup = createAction(
  '[Insurance] Create Group',
  props<{ group: IGroupPostModel }>()
);

export const saveGroupSuccess = createAction(
  '[Insurance] Save Group Success',
  props<{ groups: IGroupResponseModel[] }>()
);

export const savePlan = createAction(
  '[Insurance] Save Plan',
  props<{ planId: number; plan: IPlanPutModel; spdFile: File; sbcFile: File; planImage: File }>()
);

export const savePlanSuccess = createAction(
  '[Insurance] Save Plan Success',
  props<{ plans: IPlanResponseModel[] }>()
);

export const saveFileSbc = createAction(
  '[Insurance] Save Sbc File',
  props<{ planId: number; file: File }>()
);

export const saveFileSbcSuccess = createAction(
  '[Insurance] Save File Sbc Success',
  props<{ plan: number; file: File }> ()
);

export const saveFileSbcFailure = createAction(
  '[Insurance] Save File Sbc Failure',
  props<{ error: any }>()
);

export const saveFileSpd = createAction(
  '[Insurance] Save Spd File',
  props<{ planId: number; file: File }>()
);

export const saveFileSpdSuccess = createAction(
  '[Insurance] Save File Spd Success',
  props<{ plan: number; file: File }> ()
);

export const saveFileSpdFailure = createAction(
  '[Insurance] Save File Spd Failure'
);

export const saveBrand = createAction(
  '[Insurance] Save Brand',
  props<{ groupId: number; brandConfigId: number; brand: any; logo: File }>()
);

export const savePhoneContact = createAction(
  '[Insurance] Save Phone Contact',
  props<{ contactId: number; contact: any }>()
);

export const saveEmailContact = createAction(
  '[Insurance] Save Email Contact',
  props<{ contactId: number; contact: any }>()
);

export const saveAddressContact = createAction(
  '[Insurance] Save Address Contact',
  props<{ contactId: number; contact: any }>()
);

export const saveWebsiteContact = createAction(
  '[Insurance] Save Website Contact',
  props<{ contactId: number; contact: any }>()
);

export const deleteContact = createAction(
  '[Insurance] Delete Contact',
  props<{ contactId: number }>()
);

export const clearBrand = createAction(
  '[Insurance] Clear Brand'
);

export const clearPlans = createAction(
  '[Insurance] Clear Plans'
);

export const clearContacts = createAction(
  '[Insurance] Clear Contacts'
);

export const cloneMemberContactsFromGroup = createAction(
  '[Insurance] Clone Member Contacts From Group',
  props<{ groupId: number }>()
);

export const cloneMemberContactsFromGroupComplete = createAction(
  '[Insurance] Clone Member Contacts From Group Complete',
  props<{ groupId: number }>()
);

export const cloneMemberContactsFromGroupFailure = createAction(
  '[Insurance] Clone Member Contacts From Group Failure',
  props<{ error: string }>()
);

export const cloneMemberContact = createAction(
  '[Insurance] Clone Member Contact',
  props<{ memberContact: IMemberContactResponseModel; selectedGroupId: number }>()
);

export const cloneMemberContactSuccess = createAction(
  '[Insurance] Clone Member Contact Success',
  props<{ memberContact: IMemberContactResponseModel }>()
);

export const cloneMemberContactFailure = createAction(
  '[Insurance] Clone Member Contact Failure',
  props<{ error: string }>()
);

export const loadSelectedPlanProviderNetworks = createAction(
  '[Insurance] Load Selected Plan Provider Networks',
  props<{ planId: number }>()
);

export const loadSelectedPlanProviderNetworksSuccess = createAction(
  '[Insurance] Load Selected Plan Provider Networks Success',
  props<{ providerNetworks: IProviderNetworkResponseModel[] }>()
);

export const loadSelectedPlanProviderNetworksFailure = createAction(
  '[Insurance] Load Selected Plan Provider Networks Failure',
  props<{ error: string }>()
);

export const clonePlanBenefitTiers = createAction(
  '[Insurance] Clone Plan Benefit Tiers',
  props<{ fromPlanId: number, toPlanId: number }>()
);

export const clonePlanBenefitTiersSuccess = createAction(
  '[Insurance] Clone Plan Benefit Tier Success'
);

export const clonePlanBenefitTiersFailure = createAction(
  '[Insurance] Clone Plan Benefit Tier Failure',
  props<{ error: any }>()
);

export const setIsCloningPlan = createAction(
  '[PlanTier] Set IsCloningPlan',
  props<{ isCloningPlan: boolean }>()
);
