<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="primary">close</mat-icon>
</button>

<h1 mat-dialog-title>
  What is the name of the new group?
</h1>
<mat-dialog-content>
  <mat-progress-bar *ngIf="status === Status.LOADING" mode="buffer"></mat-progress-bar>

  <form [formGroup]="newGroupForm">
    <mat-form-field fxFlex="100" appearance="outline">
      <input id="NewGroupName" autofocus matInput formControlName="name">
    </mat-form-field>
  </form>

</mat-dialog-content>

<div mat-dialog-actions style="margin-bottom: 0px;" fxLayout="row" fxLayoutAlign="space-between">
  <button fxFlex="48" [disabled]="status === Status.LOADING || status === Status.SUCCESS" mat-raised-button (click)="onClickCreate()">Create</button>
  <button fxFlex="48" [disabled]="status === Status.LOADING || status === Status.SUCCESS" mat-raised-button color="primary" (click)="onClickCreateAndOpen()">Create and Open</button>
</div>
