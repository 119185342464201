import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  public decodeJwt(token: string): Observable<any> {
    if (token) {
      return of(jwt_decode(token));
    }

    return of(null);
  }
}
