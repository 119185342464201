<div *ngIf="providerNetworks?.length > 0; else noNetworks" id="providerNetworks">
  <div fxLayout="row wrap" fxLayoutGap="5px grid">
    <mat-card *ngFor="let network of providerNetworks; let i = index" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="25" class="provider-network-card">
      <p-button *ngIf="showDeleteButton" id="removeNetworkButton" icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-text"
        (click)="removeNetworkClicked(network.id)">
      </p-button>
      <mat-card-content class="provider-network-card-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <img *ngIf="network.imageUri" [id]="'networkImageUri' + i"
          src="{{ network.imageUri }}" alt="Provider Image"
          class="provider-network-image" (click)="openPortalUri(network.portalUri)" />
        <a *ngIf="network.portalUri; else noPortalUri" [id]="'networkNameWithLink' + i" [href]="network.portalUri" class="provider-name-alignment" target="_blank">
          {{ network.name }}
        </a>
        <ng-template #noPortalUri>
          <div  [id]="'networkName' + i" class="provider-name-alignment">{{ network.name }}</div>
        </ng-template>
        <a *ngIf="network.contactPhoneNumber" [id]="'providerPhoneNumber' + i" [href]="'tel:'+ network.contactPhoneNumber" target="_blank">
          {{ network.contactPhoneNumber | phone:'US' }}
        </a>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #noNetworks>None</ng-template>
