import {
  IExternalNetworkMappingResponseModel, IPlanTierResponseModel,
  IExternalNetworkAutocompleteResponseModel, INetworkTier,
  IProviderNetworkResponseModel
} from '@models';
import { Action, createReducer, on } from '@ngrx/store';

import { PlanTierActions } from './index';

export const planTierFeatureKey = 'plan-tier';

export interface PlanTierState {
  planTiers: IPlanTierResponseModel[];
  selectedPlanTierNetworks: IProviderNetworkResponseModel[];
  selectedPlanTierExternalNetworks: IExternalNetworkMappingResponseModel[];
  selectedPlanTierBenefits: INetworkTier[];
  externalNetworkSuggestions: IExternalNetworkAutocompleteResponseModel[];
  isCloningPlanTiers: boolean;
}

export const initialPlanTierState: PlanTierState = {
  planTiers: null,
  selectedPlanTierNetworks: null,
  selectedPlanTierExternalNetworks: null,
  selectedPlanTierBenefits: null,
  externalNetworkSuggestions: null,
  isCloningPlanTiers: false
};

const planTierReducer = createReducer(
  initialPlanTierState,

  on(PlanTierActions.loadPlanTiersSuccess, (state, action) => ({ ...state, planTiers: action.planTiers })),
  on(PlanTierActions.updatePlanTierSuccess, PlanTierActions.createPlanTierSuccess, (state, action) => {
    const existingPlanTierIndex = state.planTiers.findIndex(x => x.tier === action.planTier.tier);
    const updatedPlanTiers = [...state.planTiers];
    if (existingPlanTierIndex >= 0) {
      updatedPlanTiers[existingPlanTierIndex] = action.planTier;
    } else {
      updatedPlanTiers.push(action.planTier);
    }
    return { ...state, planTiers: updatedPlanTiers };
  }),
  on(PlanTierActions.deletePlanTierSuccess, (state) => {
    const updatedPlanTiers = [...state.planTiers];
    updatedPlanTiers.pop();
    return { ...state, planTiers: updatedPlanTiers };
  }),
  on(PlanTierActions.loadPlanTierNetworksSuccess, (state, action) => ({ ...state, selectedPlanTierNetworks: action.planTierNetworks })),
  on(PlanTierActions.getExternalNetworkSuggestionsSuccess, (state, action) => {
    const suggestions = [...action.externalNetworks].filter(suggestion => !state.selectedPlanTierExternalNetworks.some(x => x.ribbonInsuranceId === suggestion.networkId));
    return { ...state, externalNetworkSuggestions: suggestions };
  }),
  on(PlanTierActions.getExternalNetworkSuggestionsFailure, (state) => ({ ...state, externalNetworkSuggestions: [] })),
  on(PlanTierActions.loadPlanTierExternalNetworksSuccess, (state, action) => ({ ...state, selectedPlanTierExternalNetworks: action.externalNetworks })),
  on(PlanTierActions.loadPlanTierExternalNetworksFailure, (state) => ({ ...state, selectedPlanTierExternalNetworks: [] })),
  on(PlanTierActions.addPlanTierExternalNetworkSuccess, (state, action) => {
    const updatedPlanTierExternalNetworks = [...state.selectedPlanTierExternalNetworks];
    updatedPlanTierExternalNetworks.push(action.externalNetwork);
    return { ...state, selectedPlanTierExternalNetworks: updatedPlanTierExternalNetworks };
  }),
  on(PlanTierActions.removePlanTierExternalNetworkSuccess, (state, action) => {
    const updatedPlanTierExternalNetworks = [...state.selectedPlanTierExternalNetworks].filter(x => x.ribbonInsuranceId !== action.ribbonInsuranceId);
    return { ...state, selectedPlanTierExternalNetworks: updatedPlanTierExternalNetworks };
  }),
  on(PlanTierActions.updatePlanTierBenefitsSuccess, (state, action) => {
    const updatedPlanTierBenefits = [...state.selectedPlanTierBenefits].filter(x => x.networkTier !== action.tier);
    updatedPlanTierBenefits.push(action.planTierBenefits);
    return { ...state, selectedPlanTierBenefits: updatedPlanTierBenefits };
  }),
  on(PlanTierActions.getPlanTierBenefitsSuccess, (state, action) => ({ ...state, selectedPlanTierBenefits: action.planTierBenefits })),
  on(PlanTierActions.clonePlanTiersFromPlan, (state) => ({ ...state, isCloningPlanTiers: true })),
  on(PlanTierActions.clonePlanTiersFromPlanSuccess, PlanTierActions.clonePlanTiersFromPlanFailure, (state) => ({
    ...state,
    isCloningPlanTiers: false
  })),
  on(PlanTierActions.clearPlanTiersState, () => initialPlanTierState)
);

export function reducer(state: PlanTierState | undefined, action: Action) {
  return planTierReducer(state, action);
}
