export interface IMxInsuranceCard {
  policyId: number;
  type?: string;
  name?: string;
  dob: string;
  imageUrl?: string;
  firstName: string;
  lastName: string;
  groupId?: string;
  groupNumber: string;
  memberNumber: string;
  planImageUri: string;
  memberId?: string;
  coverage?: string;
  coverageType?: string;
  effectiveDate?: string;
  provider?: string;
}
