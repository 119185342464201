import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IHSRResponseModel } from '../../../backend/entities/health-service-request-response.model';
// eslint-disable-next-line max-len
import { HealthServiceRequestStatusModalComponent } from '../health-service-request-status-modal/health-service-request-status-modal.component';
import { HSRStatus } from '@enums';
import { DocumentsFacade } from 'src/app/modules/documents/documents-store/documents.facade';
import { IHSRStatusResponseModel } from 'src/app/backend/entities/health-service-request-status-response.model';
import { PaymentCardRequestModalComponent } from '../payment-card-request-modal/payment-card-request-modal.component';
import { ISuperBillResponseModel } from '@models';
import { SuperBillFacade } from '@facades';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'mx-payment-card-details',
  templateUrl: './payment-card-details.component.html',
  styleUrls: ['./payment-card-details.component.scss']
})
export class PaymentCardDetailsComponent implements OnChanges {
  @Input() request: IHSRResponseModel;
  @Output() changeStatus = new EventEmitter<IHSRStatusResponseModel>();

  public status: number;
  public showFullCardNumber = false;
  public dataSource: MatTableDataSource<IHSRStatusResponseModel>;
  public displayedColumns: string[] = [
    'status',
    'note',
    'createdAt'
  ];

  public superBillsDataSource: MatTableDataSource<ISuperBillResponseModel>;
  public superBillColumns: string[] = [
    'patientName',
    'status',
    'createdAt'
  ];

  public icons = {
    visibleToMember: faEye,
    notVisibleToMember: faEyeSlash
  };

  constructor(
    private documentsFacade: DocumentsFacade,
    private superBillFacade: SuperBillFacade,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnChanges() {
    if (this.request) {
      this.dataSource = new MatTableDataSource<IHSRStatusResponseModel>(this.request.statuses);
      if (this.request.statuses && this.request.statuses.length > 0) {
        const statuses = JSON.parse(JSON.stringify(this.request.statuses));
        statuses.sort((a: IHSRStatusResponseModel, b: IHSRStatusResponseModel) => b.id - a.id);
        this.status = statuses[0].status;
      }

      this.superBillFacade.getSuperBillsByHSR(this.request.id)
        .subscribe(bills => {
          this.superBillsDataSource = new MatTableDataSource<ISuperBillResponseModel>(bills);
        });
    }
  }

  public canBeClosed(): boolean {
    return this.status === HSRStatus.ReadyForReview ||
           this.status === HSRStatus.CardDenied ||
           this.status === HSRStatus.CardRequested;
  }

  public cardCanBeRequested(): boolean {
    return this.status === HSRStatus.ReadyForReview ||
           this.status === HSRStatus.CardDenied;
  }

  public onStatusChanged(e: MatSelectChange): void {
    this.changeStatus.next(e.value as IHSRStatusResponseModel);
  }

  public requestCard(): void {
    const dialogRef = this.dialog.open(PaymentCardRequestModalComponent, {
      data: {
        saveButtonText: 'Request Card',
        cancelButtonText: 'Go Back',
        hsr: this.request
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.documentsFacade.getHSRDetails(this.request.id);
    });
  }

  public navigateToSuperBill(id: number) {
    void this.router.navigate([`./../../super-bills/${id}`], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve'
    });
  }

  public cancelRequest(): void {
    const hasCard = this.request.paymentCardId && this.request.paymentCardId > 0;
    const dialogTitle = 'Close Health Service Request';

    const dialogRef = this.dialog.open(HealthServiceRequestStatusModalComponent, {
      data: {
        requestId: this.request.id,
        status: HSRStatus.Closed,
        statusBlocked: true,
        title: dialogTitle,
        cancelButtonText: 'Go Back',
        saveButtonText: 'Confirm'
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.documentsFacade.getHSRDetails(this.request.id);
    });
  }

  public toggleFullCardNumber() {
    this.showFullCardNumber = true;
  }

}
