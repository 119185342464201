import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IBalanceBillDetailResponseModel,
  IBalanceBillImageResponseModel,
  IBalanceBillResponseModel,
  IBalanceBillStatusPostModel
} from '@models';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class BalanceBillService {

  constructor(
    private http: HttpClient
  ) { }

  public getBill(billId: number): Observable<IBalanceBillDetailResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/balanceBill/${billId}`;
    return this.http.get<IBalanceBillDetailResponseModel>(url);
  }

  public getBills(userId: number): Observable<IBalanceBillResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/balanceBills`;
    return this.http.get<IBalanceBillResponseModel[]>(url);
  }

  public getBillImages(billId: number): Observable<IBalanceBillImageResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/balanceBill/${billId}/images`;
    return this.http.get<IBalanceBillImageResponseModel[]>(url);
  }

  public getBillImage(billId: number, imageId: number, contentType: string, fileName: string) {
    const url = `${environment.config.apiEndpoint}/v2/balanceBill/${billId}/image/${imageId}`;
    if (!contentType.includes('/')) {
      if (contentType.includes('pdf')) {
        contentType = 'application/'.concat(contentType);
      } else {
        contentType = 'image/'.concat(contentType);
      }
    }

    this.http.get(url, { headers: { Accept: contentType }, responseType: 'blob' })
      .subscribe(blob => {
        saveAs(blob, fileName, {
          type: contentType
        });
      });
  }

  public createStatus(model: IBalanceBillStatusPostModel, billId: number) {
    const url = `${environment.config.apiEndpoint}/v2/balanceBill/${billId}/status`;
    const body = model;
    return this.http.post<IBalanceBillDetailResponseModel>(url, body);
  }

  public getBillsByPartner(userId: number): Observable<IBalanceBillResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/partner/balanceBills`;
    return this.http.get<IBalanceBillResponseModel[]>(url);
  }
}

