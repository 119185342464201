import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromBenefits from './benefits.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BenefitsEffects } from './benefits.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBenefits.benefitsFeatureKey, fromBenefits.reducer),
    EffectsModule.forFeature([BenefitsEffects])
  ],
  providers: [BenefitsEffects]
})
export class BenefitsStoreModule { }
