<button mat-icon-button class="close-button" [mat-dialog-close] [disabled]="isLoadingRequest">
  <mat-icon class="close-icon" color="primary">close</mat-icon>
</button>

<h1 mat-dialog-title>Submit a Health Service Request</h1>

<div #stepperTarget mat-dialog-content>
  <mat-stepper [formGroup]="requestForm" linear>
    <mat-step formGroupName="patientForm" label="patientInfo">
      <div fxLayout="row wrap" fxLayoutGap="15px grid">
        <span>Who are you requesting a medical service for?</span>
        <div fxFlex="100" fxLayout="column" fxLayoutGap="5px">
          <div>Patient *</div>
          <mx-family-member-dropdown [patientList]="patientList" [selectedMemberPersonId]="this.selectedMemberId" (changePatient)="onChangePatient($event)"></mx-family-member-dropdown>
        </div>
      </div>
    </mat-step>
    <mat-step formGroupName="providerForm" label="providerInfo">
      <div fxLayout="row wrap" fxLayoutGap="15px grid">
        <span>Which provider would you like to visit?</span>
        <div fxFlex="100" fxLayout="column">
          <div>Provider Name *</div>
          <input required formControlName="name" type="text" pInputText/>
          <small class="p-error"
            *ngIf="requestForm.get('providerForm').get('name').invalid &&
              requestForm.get('providerForm').get('name').touched">
            Provider Name is required.
          </small>
        </div>

        <div fxFlex="100" fxLayout="column">
          <div>Address 1 *</div>
          <input required formControlName="address1" type="text" pInputText/>
          <small class="p-error"
            *ngIf="requestForm.get('providerForm').get('address1').invalid &&
              requestForm.get('providerForm').get('address1').touched">
            Address Line 1 is required.
          </small>
        </div>

        <div fxFlex="100" fxLayout="column">
          <div>Address 2</div>
          <input formControlName="address2" type="text" pInputText/>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>City *</div>
          <input required formControlName="city" type="text" pInputText/>
          <small class="p-error"
            *ngIf="requestForm.get('providerForm').get('city').invalid &&
              requestForm.get('providerForm').get('city').touched">
            Provider City is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>State *</div>
          <p-inputMask required class="full-width" formControlName="state" mask="aa" slotChar=""></p-inputMask>
          <small class="p-error"
            *ngIf="requestForm.get('providerForm').get('state').invalid &&
              requestForm.get('providerForm').get('state').touched">
            Provider State is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>ZIP Code *</div>
          <p-inputMask required class="full-width" formControlName="postalCode" mask="99999" [autoClear]="false" slotChar=""></p-inputMask>
          <small class="p-error"
            *ngIf="requestForm.get('providerForm').get('postalCode').invalid &&
              requestForm.get('providerForm').get('postalCode').touched">
            Provider ZIP Code is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>Provider NPI</div>
          <p-inputMask class="full-width" formControlName="npi" mask="9999999999" [autoClear]="false" slotChar=""></p-inputMask>
        </div>
      </div>
    </mat-step>
    <mat-step formGroupName="procedureForm" label="procedureInfo">
      <div fxLayout="row wrap" fxLayoutGap="15px grid">
        <span>
          Please select the service/procedure you are looking to receive from
          <strong>{{requestForm.get('providerForm.name').value}}</strong>.
        </span>
        <div fxFlex="100" fxLayout="column">
          <div>Estimated Charge *</div>
          <input formControlName="estimatedCharge" maxlength="8" type="text" currencyMask pInputText/>
          <small class="p-error"
            *ngIf="requestForm.get('procedureForm').get('estimatedCharge').invalid &&
              requestForm.get('procedureForm').get('estimatedCharge').touched">
            Estimated Charge is required.
          </small>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>CPT Code</div>
          <input formControlName="cptCode" type="text" pInputText/>
        </div>

        <div fxFlex="50" fxLayout="column">
          <div>Estimated Date of Service *</div>
          <p-calendar required formControlName="dateOfService" class="full-width"
            [appendTo]="stepperTarget" [showWeek]="false" [monthNavigator]="true"
            [yearRange]="yearRange" [yearNavigator]="true">
          </p-calendar>
          <small class="p-error"
            *ngIf="requestForm.get('procedureForm').get('dateOfService').invalid &&
              requestForm.get('procedureForm').get('dateOfService').touched">
            Date of Service is required.
          </small>
        </div>

        <div fxFlex="100" fxLayout="column">
          <div>Procedure Info *</div>
          <textarea required formControlName="procedureInfo" pInputTextarea autoResize="true"></textarea>
          <small class="p-error"
            *ngIf="requestForm.get('procedureForm').get('procedureInfo').invalid &&
              requestForm.get('procedureForm').get('procedureInfo').touched">
            Procedure Info is required.
          </small>
        </div>
      </div>
    </mat-step>
    <mat-step formGroupName="paymentCardForm" label="paymentCardInfo">
      <div fxLayout="column" fxLayoutGap="15px">
        <span>Generate Payment Card?</span>
        <div fxLayout="row" fxLayoutGap="10px">
          <p-radioButton [value]="true" formControlName="requestPaymentCard"></p-radioButton>
          <label>Yes</label>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <p-radioButton [value]="false" formControlName="requestPaymentCard"></p-radioButton>
          <label>No</label>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="15px grid" *ngIf="requestForm.get('paymentCardForm.requestPaymentCard').value">
          <div fxFlex="100" fxLayout="column">
            <div>Card Amount *</div>
            <input formControlName="cardAmount" type="text" currencyMask pInputText required/>
            <small class="p-error"
              *ngIf="requestForm.get('paymentCardForm').get('cardAmount').invalid &&
                requestForm.get('paymentCardForm').get('cardAmount').touched">
              Card Amount is required.
            </small>
          </div>
          <div fxFlex="50" fxLayout="column">
            <div>Card Available Date *</div>
            <p-calendar
              required formControlName="cardAvailableDate" class="full-width"
              [appendTo]="stepperTarget" [showWeek]="false" [monthNavigator]="true"
              [yearRange]="yearRange" [yearNavigator]="true" (onSelect)="changeAvailableDate()"
              [minDate]="minCardAvailableDate">
            </p-calendar>
            <small class="p-error"
              *ngIf="requestForm.get('paymentCardForm').get('cardAvailableDate').invalid &&
                requestForm.get('paymentCardForm').get('cardAvailableDate').touched">
              Card Available Date is required.
            </small>
          </div>
          <div fxFlex="50" fxLayout="column">
            <div>Card Expiration Date *</div>
            <p-calendar
              required formControlName="cardExpirationDate" class="full-width"
              [appendTo]="stepperTarget" [showWeek]="false" [monthNavigator]="true"
              [yearRange]="yearRange" [yearNavigator]="true" [minDate]="minCardExpirationDate">
            </p-calendar>
            <small class="p-error"
              *ngIf="requestForm.get('paymentCardForm').get('cardExpirationDate').invalid &&
                requestForm.get('paymentCardForm').get('cardExpirationDate').touched">
              Card Expiration Date is required.
            </small>
          </div>
          <div class="form-header-row" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
            <div class="form-header-checkbox-container" fxFlex="60" fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox formControlName="visibleToMember"></mat-checkbox>
              <mat-label>Allow member to view this card</mat-label>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="submittedInfo">
      <div fxLayout="column" fxLayoutAlign="center center" class="submitted-step" fxLayoutGap="15px">
        <span class="title"><strong>Thank you</strong></span>
        <span>
          Your request has been submitted. You can track the status of your request from the Service Requests page.
        </span>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div *ngIf="!isLastStep" class="full-width footer" fxLayout="row" fxLayoutAlign="space-between center">
  <span>
    Have questions? Give us a call at <a href="tel:877-633-9666">877-633-9666</a>.
  </span>
  <div>
    <button
      pButton pRipple class="p-button-secondary" style="margin-right: 10px"
      (click)="previousStep()" label="Back" *ngIf="!isFirstStep" [disabled]="isLoadingRequest">
    </button>
    <button pButton pRipple (click)="nextStep()" [disabled]="isLoadingRequest">
      <span *ngIf="!isLoadingRequest">{{ isPaymentCardStep ? 'Submit' : 'Next' }}</span>
      <mat-spinner *ngIf="isLoadingRequest" diameter="20" class="submit-button-spinner"></mat-spinner>
    </button>
  </div>
</div>
