import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[mxFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag;
  private isHidden = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this.updateView();
  }

  private updateView() {
    if (this.checkValidity()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.viewContainer.clear();
      this.isHidden = true;
    }
  }

  private checkValidity() {
    return (
      !this.featureFlag
      || (this.featureFlag && environment.features[this.featureFlag] as boolean)
    );
  }
}
