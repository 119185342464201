import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '@environment';
import {
  AutoCompleteResponseModel,
  Provider,
  ProviderDetails,
  SearchCriteria
} from 'projects/search/src/public-api';
import {
  IAutoCompleteFacilityResponseModel, IAutoCompletePostModel,
  IAutoCompleteProviderResponseModel, ISpecialtyModel,
  ISearchPostModel, ISearchResponseModel, IFacilityTypeModel,
  ISearchDetailsResponseModel, IProcedureSearchPostModel,
  IAutoCompleteProcedureResponseModel
} from '@models';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public isSearching = false;
  public hasSearched = false;

  constructor(
    private http: HttpClient
  ) { }

  public runSearch(searchCriteria: SearchCriteria): Observable<Provider[]> {
    this.isSearching = true;
    const body = searchCriteria;
    const url = `${environment.config.apiEndpoint}/v3/providers/search`;

    return this.handleSearchResults(this.http.post<Provider[]>(url, body));
  }

  public getProviderDetails(id: number): Observable<ProviderDetails> {
    const url = `${environment.config.apiEndpoint}/v3/provider/providerLocation/${id}`;
    return this.http.get<ProviderDetails>(url);
  }

  public getPricePointAutoCompleteSuggestions(searchTerm: string, postalCode: string) {
    const url = `${environment.config.apiEndpoint}/v2/pricepoint/autocomplete`;
    return this.http.post<AutoCompleteResponseModel>(url, { searchTerm, postalCode });
  }

  public getProvidersAutoCompleteSuggestions(searchTerm: string, postalCode: string) {
    const url = `${environment.config.apiEndpoint}/v2/providers/autocomplete`;
    return this.http.post<AutoCompleteResponseModel>(url, { searchTerm, postalCode });
  }

  public getProcedureAutoCompleteSuggestionsV3(searchTerm: string, updateIsSearchingState = false) {
    if (updateIsSearchingState) {
      this.isSearching = true;
    }

    const url = `${environment.config.apiEndpoint}/v3/pricepoint/autocomplete`;
    return this.http.post<IAutoCompleteProcedureResponseModel[]>(url, { searchTerm, tags: ['caa'] }).pipe(
      tap(() => {
        if (updateIsSearchingState) {
          this.isSearching = false;
        }
      })
    );
  }

  public getProvidersAutoCompleteSuggestionsV3(searchTerm: string, latitude?: number, longitude?: number, radius?: number) {
    const url = `${environment.config.apiEndpoint}/v3/providers/autocomplete`;
    return this.http.post<IAutoCompleteProviderResponseModel[]>(url, { searchTerm, latitude, longitude, radius });
  }

  public getFacilitiesAutoCompleteSuggestions(autoCompletePostModel: IAutoCompletePostModel) {
    const url = `${environment.config.apiEndpoint}/v2/facilities/autocomplete`;
    return this.http.post<IAutoCompleteFacilityResponseModel[]>(url, autoCompletePostModel);
  }

  public getProvidersLocationAutoCompleteSuggestions(autoCompletePostModel: IAutoCompletePostModel) {
    const url = `${environment.config.apiEndpoint}/v2/provider/locations/autocomplete`;
    return this.http.post<IAutoCompleteProviderResponseModel[]>(url, autoCompletePostModel);
  }

  public getNetworkIds(personId: number): Observable<number[]> {
    const url = `${environment.config.apiEndpoint}/v2/person/${personId}/networks`;
    return this.http.get<number[]>(url);
  }

  public runProviderSearch(searchCriteria: ISearchPostModel): Observable<ISearchResponseModel[]> {
    this.isSearching = true;
    const url = `${environment.config.apiEndpoint}/v3/provider/locations/search`;
    return this.handleSearchResults(this.http.post<ISearchResponseModel[]>(url, searchCriteria));
  }

  public runFacilitySearch(searchCriteria: ISearchPostModel): Observable<ISearchResponseModel[]> {
    this.isSearching = true;
    const url = `${environment.config.apiEndpoint}/v2/facilities/search`;
    return this.handleSearchResults(this.http.post<ISearchResponseModel[]>(url, searchCriteria));
  }

  public runProcedureSearch(searchCriteria: IProcedureSearchPostModel): Observable<ISearchResponseModel[]> {
    this.isSearching = true;
    const url = `${environment.config.apiEndpoint}/v2/procedure/provider/locations/search`;

    return this.handleSearchResults(this.http.post<ISearchResponseModel[]>(url, searchCriteria));
  }

  public getCommonSpecialties() {
    const url = `${environment.config.apiEndpoint}/v1/provider/specialties/common`;
    return this.http.get<ISpecialtyModel[]>(url);
  }

  public getAutoCompleteSpecialties(searchTerm: string) {
    const url = `${environment.config.apiEndpoint}/v1/provider/specialties/autocomplete?searchTerm=${searchTerm}`;
    return this.http.get<ISpecialtyModel[]>(url);
  }

  public getFacilityTypes() {
    const url = `${environment.config.apiEndpoint}/v1/facility/types`;
    return this.http.get<IFacilityTypeModel[]>(url);
  }

  public getProviderLocationDetails(facilityId: string, npi: string) {
    this.isSearching = true;
    const url = `${environment.config.apiEndpoint}/v1/provider/${npi}/location/${facilityId}`;
    return this.handleSearchResults(this.http.get<ISearchDetailsResponseModel>(url));
  }

  public getFacilityDetails(facilityId: string) {
    this.isSearching = true;
    const url = `${environment.config.apiEndpoint}/v1/facility/${facilityId}`;
    return this.handleSearchResults(this.http.get<ISearchDetailsResponseModel>(url));
  }

  private handleSearchResults<T>(request: Observable<T>): Observable<T> {
    return request.pipe(
      catchError(error => {
        this.isSearching = false;
        this.hasSearched = true;
        return throwError(error);
      }),
      tap(() => {
        this.isSearching = false;
        this.hasSearched = true;
      })
    );
  }

}
