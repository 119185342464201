export interface IProfileResponseModel {
  id?: number;
  userId?: number;
  socialSecurityNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  gender?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string;
  homePhone?: string;
  workPhone?: string;
  cellPhone?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
