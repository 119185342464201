import { IMemberCountByGroupModel, IMemberCountsAggregatedByDateResponseModel, IUserLoginsByDateResponseModel } from '@models';
import { Action, createReducer, on } from '@ngrx/store';
import * as ReportingActions from './reporting.actions';

export const reportingFeatureKey = 'reporting';

export interface ReportingState {
  registrationData: any;
  aggregatedMemberCountsByDate: IMemberCountsAggregatedByDateResponseModel[];
  isLoadingAggregatedMemberCountsByDate: boolean;
  aggregatedMemberCountsByDateError: string;
  groupsMemberCount: IMemberCountByGroupModel[];
  isLoadingGroupsMemberCount: boolean;
  groupsMemberCountError: string;
  aggregatedUserLoginsByDate: IUserLoginsByDateResponseModel[];
  isLoadingAggregatedUserLoginsByDate: boolean;
  aggregatedUserLoginsByDateError: string;
  groupUserLoginsByDate: IUserLoginsByDateResponseModel[];
  isLoadingGroupUserLoginsByDate: boolean;
  groupUserLoginsByDateError: string;
}

export const initialReportingState: ReportingState = {
  registrationData: null,
  aggregatedMemberCountsByDate: null,
  isLoadingAggregatedMemberCountsByDate: false,
  aggregatedMemberCountsByDateError: null,
  groupsMemberCount: null,
  isLoadingGroupsMemberCount: false,
  groupsMemberCountError: null,
  aggregatedUserLoginsByDate: null,
  isLoadingAggregatedUserLoginsByDate: false,
  aggregatedUserLoginsByDateError: null,
  groupUserLoginsByDate: null,
  isLoadingGroupUserLoginsByDate: false,
  groupUserLoginsByDateError: null
};

export const reportingReducer = createReducer(
  initialReportingState,

  on(ReportingActions.loadRegistrationTrendSuccess, (state, action) => ({ ...state, registrationData: action.data })),
  on(ReportingActions.loadAggregatedMemberCountsByDate, (state, action) => ({
    ...state,
    isLoadingAggregatedMemberCountsByDate: true,
    aggregatedMemberCountsByDateError: null
  })),
  on(ReportingActions.loadAggregatedMemberCountsByDateSuccess, (state, action) => ({
    ...state,
    aggregatedMemberCountsByDate: action.data,
    isLoadingAggregatedMemberCountsByDate: false,
    aggregatedMemberCountsByDateError: null
  })),
  on(ReportingActions.loadAggregatedMemberCountsByDateFailure, (state, action) => ({
    ...state,
    aggregatedMemberCountsByDate: null,
    isLoadingAggregatedMemberCountsByDate: false,
    aggregatedMemberCountsByDateError: 'Error loading chart data.'
  })),
  on(ReportingActions.loadGroupsMemberCount, (state, action) => ({
    ...state,
    isLoadingGroupsMemberCount: true,
    groupsMemberCountError: null
  })),
  on(ReportingActions.loadGroupsMemberCountSuccess, (state, action) => ({
    ...state,
    groupsMemberCount: action.data,
    isLoadingGroupsMemberCount: false,
    groupsMemberCountError: null
  })),
  on(ReportingActions.loadGroupsMemberCountFailure, (state, action) => ({
    ...state,
    groupsMemberCount: null,
    isLoadingGroupsMemberCount: false,
    groupsMemberCountError: action.error
  })),
  on(ReportingActions.loadAggregatedUserLoginsByDate, (state, action) => ({
    ...state,
    isLoadingAggregatedUserLoginsByDate: true,
    aggregatedUserLoginsByDateError: null
  })),
  on(ReportingActions.loadAggregatedUserLoginsByDateSuccess, (state, action) => ({
    ...state,
    aggregatedUserLoginsByDate: action.data,
    isLoadingAggregatedUserLoginsByDate: false,
    aggregatedUserLoginsByDateError: null
  })),
  on(ReportingActions.loadAggregatedUserLoginsByDateFailure, (state, action) => ({
    ...state,
    aggregatedUserLoginsByDate: null,
    isLoadingAggregatedUserLoginsByDate: false,
    aggregatedUserLoginsByDateError: action.error
  })),
  on(ReportingActions.loadGroupUserLoginsByDate, (state, action) => ({
    ...state,
    isLoadingGroupUserLoginsByDate: true,
    groupUserLoginsByDateError: null
  })),
  on(ReportingActions.loadGroupUserLoginsByDateSuccess, (state, action) => ({
    ...state,
    groupUserLoginsByDate: action.data,
    isLoadingGroupUserLoginsByDate: false,
    aggregatedUserLoginsByDateError: null
  })),
  on(ReportingActions.loadGroupUserLoginsByDateFailure, (state, action) => ({
    ...state,
    groupUserLoginsByDate: null,
    isLoadingGroupUserLoginsByDate: false,
    groupUserLoginsByDateError: action.error
  }))
);

export function reducer(state: ReportingState | undefined, action: Action) {
  return reportingReducer(state, action);
}
