import { MessageCenterViewState, ThreadListType, ThreadSortOption } from '@enums';
import { IThreadMessagesPostModel, IThreadMessageResponseModel, IThreadResponseModel, LoadThreadsRequest } from '@models';
import { createAction, props } from '@ngrx/store';

export const loadThreads = createAction(
  '[Message Center] Load Threads',
  props<{ loadThreadsRequest: LoadThreadsRequest }>()
);
export const loadThreadsSuccess = createAction(
  '[Message Center] Load Threads Success',
  props<{ threads: IThreadResponseModel[] }>()
);
export const loadThreadsFailure = createAction(
  '[Message Center] Load Threads Failure',
  props<{ error: any }>()
);

export const setSelectedThreadId = createAction(
  '[Message Center] Set Selected Thread Id',
  props<{ threadId: number }>()
);

export const loadThreadMessages = createAction(
  '[Message Center] Load Thread Messages',
  props<{ threadId: number; markAsRead: boolean }>()
);

export const loadThreadMessagesSuccess = createAction(
  '[Message Center] Load Thread Messages Success',
  props<{ threadId: number; threadMessages: IThreadMessageResponseModel[] }>()
);

export const loadThreadMessagesFailure = createAction(
  '[Message Center] Load Thread Messages Failure',
  props<{ error: any }>()
);

export const sendNewMessage = createAction(
  '[Message Center] Send New Message',
  props<{ message: IThreadMessagesPostModel; files: File[] }>()
);

export const sendNewMessageSuccess = createAction(
  '[Message Center] Send New Message Success'
);

export const sendNewMessageFailure = createAction(
  '[Message Center] Send New Message Failure',
  props<{ error: any }>()
);

export const setCurrentViewState = createAction(
  '[Message Center] Set Current View State',
  props<{ viewState: MessageCenterViewState }>()
);

export const replyThreadMessage = createAction(
  '[Message Center] Reply Thread Message',
  props<{ body: string; threadId: number; files: File[] }>()
);

export const replyThreadMessageSuccess = createAction(
  '[Message Center] Reply Thread Message Success',
  props<{ threadId: number }>()
);

export const replyThreadMessageFailure = createAction(
  '[Message Center] Reply Thread Message Failure',
  props<{ error: any }>()
);

export const setReplyToThread = createAction(
  '[Message Center] Set Reply to Thread',
  props<{ replyToThread: boolean }>()
);

export const getMessageAttachment = createAction(
  '[Message Center] Get Message Attachment',
  props<{ messageId: number; attachmentId: number; fileName: string }>()
);

export const getMessageAttachmentSuccess = createAction(
  '[Message Center] Get Message Attachment Success',
  props<{ file: Blob }>()
);

export const getMessageAttachmentFailure = createAction(
  '[Message Center] Get Message Attachment Failure',
  props<{ error: any }>()
);

export const clearThreads = createAction('[Message Center] Clear Threads');

export const clearThreadMessages = createAction('[Message Center] Clear Thread Messages');

export const toggleRepliesAllowed = createAction(
  '[Message Center] Toggle Replies Allowed',
  props<{ threadId: number; replyAllowed: boolean }>()
);

export const toggleRepliesAllowedSuccess = createAction(
  '[Message Center] Toggle Replies Allowed Success',
  props<{ thread: IThreadResponseModel }>()
);

export const toggleRepliesAllowedFailure = createAction(
  '[Message Center] Toggle Replies Allowed Failure',
  props<{ error: any }>()
);

export const archiveThread = createAction(
  '[Message Center] Archive Thread',
  props<{ threadId: number; isArchived: boolean }>()
);

export const archiveThreadSuccess = createAction(
  '[Message Center] Archive Thread Success',
  props<{ threadId: number }>()
);

export const archiveThreadFailure = createAction(
  '[Message Center] Archive Thread Failure',
  props<{ error: any }>()
);
