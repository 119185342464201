export interface IBenefitResponseModel {
  id: number;
  planId: number;
  benefitInformationCode: string;
  coverageLevel: string;
  serviceTypeCode: string;
  insuranceTypeCode: string;
  timePeriodQualifier: string;
  monetaryValue?: number;
  percent?: number;
  quantityQualifier: string;
  quantity?: number;
  authorizationIndicator: string;
  inPlanNetworkIndicator: string;
  createdAt: string;
  updatedAt: string;
}
