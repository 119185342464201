import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ClientEventService {

  constructor(
    private http: HttpClient
  ) { }

  public logEvent(event: any): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v3/clientEvent`;
    const body = event;
    return this.http.post(url, body);
  }
}
