import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPaymentCardRequests from './payment-card-request.reducer';

export const selectPaymentCardRequestState = createFeatureSelector<fromPaymentCardRequests.PaymentCardRequestState>(
  fromPaymentCardRequests.paymentCardRequestFeatureKey
);

export const selectIsLoading = createSelector(
  selectPaymentCardRequestState,
  (state: fromPaymentCardRequests.PaymentCardRequestState) => state.isLoading
);

export const selectSelectedPaymentCardRequest = createSelector(
  selectPaymentCardRequestState,
  (state: fromPaymentCardRequests.PaymentCardRequestState) => state.selectedPaymentCardRequest
);

export const selectPaymentCardRequests = createSelector(
  selectPaymentCardRequestState,
  (state: fromPaymentCardRequests.PaymentCardRequestState) => state.paymentCardRequests
);

export const selectIsSendingPaymentCardRequest = createSelector(
  selectPaymentCardRequestState,
  (state: fromPaymentCardRequests.PaymentCardRequestState) => state.isSendingPaymentCardRequest
);
