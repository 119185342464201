import { IPlanResponseModel } from './../../shared/models/plan-response.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInsurance from './insurance.reducer';
import { IGroupResponseModel } from '@models';

export const selectInsuranceState = createFeatureSelector<fromInsurance.InsuranceState>(
  fromInsurance.insuranceFeatureKey
);

export const selectSelectedPlan = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedPlan
);

export const selectProviderNetworks = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.providerNetworks
);

export const selectSelectedGroupPlans = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedGroupPlans
);

export const selectSelectedGroupContacts = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedGroupContacts
);

export const selectSelectedGroupModules = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedGroupModules
);

export const selectSelectedGroupBranding = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedGroupBranding
);

export const selectSelectedPayer = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedPayer
);

export const selectGroups = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.groups
);

export const selectPlans = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.plans
);

export const selectGroup = createSelector(
  selectGroups,
  (groups: IGroupResponseModel[], props) => {
    if (groups) {
      return groups.find(group => group.id === props.groupId);
    }
  }
);

export const selectSelectedGroupId = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedGroupId
);

export const selectFirstGroupId = createSelector(
  selectGroups,
  (groups: IGroupResponseModel[], ) => {
    if (groups) {
      return groups[0].id;
    }
  }
);

export const selectSortedGroups = createSelector(
  selectGroups,
  (groups: IGroupResponseModel[], props) => {
    if (groups) {
      let sortedGroups = groups.slice().sort((a, b) => (a.name > b.name) ? 1 : -1);

      if (props?.excludedGroupId) {
        sortedGroups = sortedGroups.filter(group => group.id !== props.excludedGroupId);
      }

      return sortedGroups;
    }
  }
);

export const selectSortedPlansForGroup = (targetGroupId: number, excludePlanId?:  number) => createSelector(
  selectPlans,
  (plans: IPlanResponseModel[],) => {
    if (plans) {
      let sortedPlans = plans.filter(plan => plan.groupId === targetGroupId).slice().sort((a, b) => (a.name > b.name) ? 1 : -1);

      if (excludePlanId) {
        sortedPlans = sortedPlans.filter(plan => plan.id !== excludePlanId);
      }

      return sortedPlans;
    }
  }
);

export const selectSelectedPlanProviderNetworks = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.selectedPlanProviderNetworks
);

export const selectIsCloningPlan = createSelector(
  selectInsuranceState,
  (state: fromInsurance.InsuranceState) => state.isCloningPlan
);
