import { EventEmitter, Injectable } from '@angular/core';
import { BalanceBillService } from '@backend';
import { IBalanceBillResponseModel, IBalanceBillStatusPostModel } from '@models';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BalanceBillFacade {
  public isLoading = new EventEmitter<boolean>();
  public doneSaving = new EventEmitter();
  public cancelSave = new EventEmitter();

  constructor(
    private balanceBillService: BalanceBillService,
  ) {}

  updateBalanceBillStatus(model: IBalanceBillStatusPostModel, billId: number) {
    this.balanceBillService.createStatus(model, billId).subscribe(() => this.doneSaving.emit());
  }

  getBill(billId: number): Observable<any> {
    return forkJoin(this.balanceBillService.getBill(billId), this.balanceBillService.getBillImages(billId));
  }

  getBillsByPartner(userId: number): Observable<IBalanceBillResponseModel[]> {
    this.isLoading.next(true);
    return this.balanceBillService.getBillsByPartner(userId).pipe(
      tap(() => this.isLoading.next(false))
    );
  }

  getBillImage(billId: number, imageId: number, contentType: string, fileName: string) {
    this.balanceBillService.getBillImage(billId, imageId, contentType, fileName);
  }
}
