export interface IAccumulatorHelperDetail {
  limitMet: boolean;
  individualLimitNotMet: boolean;
  familyLimitNotMet: boolean;
  useIndividualLimitValue: boolean;
  type: string;
  progress: number;

  individualUsed: number;
  individualLimit: number;
  individualRemaining: number;
  familyUsed: number;
  familyLimit: number;
  familyRemaining: number;
}
