import { IMemberDetailsResponseModel, IMemberSearchPostModel, IMemberSearchResponseModel, IPersonResponseModel } from '@models';
import { createAction, props } from '@ngrx/store';

export const loadPeopleByGroup = createAction(
  '[Person] Load People By Group',
  props<{
    groupId: number;
    initialSubscriberId?: number;
    initialPersonId?: number;
  }>()
);

export const loadPeopleByGroupSuccess = createAction(
  '[Person] Load People By Group Success',
  props<{ members: IPersonResponseModel[] }>()
);

export const loadPeopleFailure = createAction(
  '[Person] Load People Failure',
  props<{ error: any }>()
);


export const searchMembers = createAction(
  '[Person] Search Members',
  props<{ searchPostModel: IMemberSearchPostModel }>()
);

export const searchMembersSuccess = createAction(
  '[Person] Search Members Success',
  props<{ memberSearchResults: IMemberSearchResponseModel[] }>()
);

export const searchMembersFailure = createAction(
  '[Person] Search Members Failure',
  props<{ error: any }>()
);


export const getSelectedMemberAndSubscriberDetails = createAction(
  '[Person] Get Selected Member and Subscriber Details',
  props<{ personId: number }>()
);

export const getSelectedMemberAndSubscriberDetailsSuccess = createAction(
  '[Person] Get Selected Member and Subscriber Details Success',
  props<{ subscriber: IMemberDetailsResponseModel; member: IMemberDetailsResponseModel }>()
  );

export const getSelectedMemberAndSubscriberDetailsFailure = createAction(
  '[Person] Get Selected Member and Subscriber Details Failure',
  props<{ error: any }>()
);


export const clearPersonState = createAction(
  '[Person] Clear Person State'
);

export const clearMemberSearchResultsState = createAction(
  '[Person] Clear Member Search Results State'
);

export const setSelectedPersonIds = createAction(
  '[Person] Set Selected Person Ids',
  props<{ subscriberId: number; personId: number}>()
);
