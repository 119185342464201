import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IPatientModel } from '@models';

@Component({
  selector: 'mx-family-member-dropdown',
  templateUrl: './family-member-dropdown.component.html',
  styleUrls: ['./family-member-dropdown.component.scss']
})
export class FamilyMemberDropdownComponent implements OnChanges {
  @Input() patientList: IPatientModel[];
  @Input() selectedMemberPersonId: number;
  @Output() changePatient = new EventEmitter<IPatientModel>();

  public patients: any[] = [];
  public selectedPatientPersonId: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.patients = [];
    const patientList: IPatientModel[] = changes.patientList.currentValue;
    patientList.forEach(patient => {
      this.patients.push({
        id: patient.personId,
        label: `${patient.firstName} ${patient.lastName}`,
        value: {
          firstName: patient.firstName,
          lastName: patient.lastName,
          dateOfBirth: patient.dateOfBirth,
          selectedPolicyId: patient.selectedPolicyId,
          personId: patient.personId,
          memberNumber: patient.memberNumber,
          groupNumber: patient.groupNumber
        }
      });
    });

    // Default select the person that is selected in the breadcrumb family selector
    this.selectedPatientPersonId = this.selectedMemberPersonId;
    this.onChangePatient();
  }

  public onChangePatient() {
    const patient = this.patientList.find(x => x.personId === this.selectedPatientPersonId);
    if (patient) {
      this.changePatient.emit(patient);
    }
  }

}
