import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'mx-base-destroy-component',
  template: ''
})
export class BaseDestroyComponentComponent implements OnDestroy {

  public unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
