import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventName } from '@enums';
import { AnalyticsFacade } from '@facades';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { CostEstimatorService } from '../../backend/services/cost-estimator.service';
import { selectUserId } from '../auth-store/auth.selectors';
import { AppState } from '../root.state';
import * as CostEstimatorActions from './cost-estimator.actions';

@Injectable()
export class CostEstimatorEffects {
  getProcedureCost$ = createEffect(() => this.actions$.pipe(
    ofType(CostEstimatorActions.getProcedureCost),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([action, partnerUserId]) => {
      this.analyticsFacade.logEvent(EventName.ProcedureCostLookupApiCalled, {
        procedureCode: action.cptCode,
        providerNpi: action.npi,
        planId: action.planId?.toString(),
        memberPersonId: action.personId.toString(),
        partnerUserId
      });
      return this.costEstimatorService.getProcedureCost(action.cptCode, action.npi, action.planId)
        .pipe(
          map(result => CostEstimatorActions.getProcedureCostSuccess({ result })),
          catchError(error => {
            this.snackBar.open(
              'Costs are not available for this procedure at the selected provider.',
              'Close',
              { duration: 3000 }
            );
            return of(CostEstimatorActions.getProcedureCostFailure({ error }));
          }));
    })
  ));

  getProcedureCostEstimate$ = createEffect(() => this.actions$.pipe(
    ofType(CostEstimatorActions.getProcedureCostEstimate),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([action, partnerUserId]) => {
      this.analyticsFacade.logEvent(EventName.ProcedureCostEstimateApiCalled, {
        memberPersonId: action.personId.toString(),
        procedureCost: action.procedureCost.toString(),
        procedure: `${action.cptCode} - ${action.procedureName}`,
        placeOfService: action.placeOfService.toString(),
        physicianType: action.physicianType?.toString(),
        partnerUserId
      });
      return this.costEstimatorService.getProcedureCostEstimate(
        action.personId, action.procedureCost, action.cptCode,
        action.placeOfService, action.physicianType
      ).pipe(
        map(result => CostEstimatorActions.getProcedureCostEstimateSuccess({ result })),
        catchError(error => {
          this.snackBar.open(
            'Costs are not available for this procedure at the selected provider.',
            'Close',
            { duration: 3000 }
          );
          return of(CostEstimatorActions.getProcedureCostEstimateFailure({ error }));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private costEstimatorService: CostEstimatorService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private analyticsFacade: AnalyticsFacade
  ) { }
}
