import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceFacade } from '@facades';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Pipe({
  name: 'planNamePipe'
})
export class PlanNamePipe implements PipeTransform {
  constructor(private insuranceFacade: InsuranceFacade) {}

  transform(planId: number): Observable<string> {
    return this.insuranceFacade.groupPlans$.pipe(
      filter(plans => !!plans),
      map(plans => {
        if (plans) {
          const foundPlan = plans.find(plan => plan.id === planId);
          if (foundPlan) {
            return foundPlan.name;
          } else {
            return '';
          }
        } else {
          return '';
        }
      })
    );
  }

}
