import { PaymentCardRequestState } from '@enums';

export interface IPaymentCardRequestResponseModel {
  id: number;
  partnerUserId: number;
  memberPersonId: number;
  groupId: number;
  groupNumber: string;
  memberNumber: string;
  memberFirstName: string;
  memberLastName: string;
  cardAmount: number;
  cardAvailableDate: string;
  cardExpirationDate: string;
  procedureInfo: string;
  cptCode: string;
  providerName: string;
  providerAddress1: string;
  providerAddress2: string;
  providerCity: string;
  providerState: string;
  providerPostalCode: string;
  providerCountryCode: string;
  spcId: string;
  visibleToMember: boolean;
  status: PaymentCardRequestState;
  statusNote: string;
  providerTIN: string;
  providerNPI: string;
}
