import { Injectable } from '@angular/core';
import { PersonService, UserService } from '@backend';
import { IMemberSearchResponseModel, IUserAccountResponseModel } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { map, switchMap, tap, filter, catchError, startWith, withLatestFrom, take } from 'rxjs/operators';
import { AppState } from '../root.state';
import * as PersonActions from './person.actions';
import { selectSelectedMember, selectSelectedSubscriber } from './person.selectors';
import { InsuranceFacade } from '@facades';

@Injectable({
  providedIn: 'root'
})
export class PersonEffects {

  loadPeopleByGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PersonActions.loadPeopleByGroup),
    filter(data => !!data.groupId),
    tap(() => PersonActions.clearPersonState()),
    switchMap((data) => forkJoin([this.personService.getByGroup(data.groupId), of(data)])),
    map(([members, data]) => {
      if (members?.length > 0) {
        var subscriberId = (data?.initialSubscriberId) ? data.initialSubscriberId : members[0].id;
        var personId = (data?.initialPersonId) ? data.initialPersonId : members[0].id;
        this.store.dispatch(PersonActions.setSelectedPersonIds({ subscriberId, personId }));
      }

      return PersonActions.loadPeopleByGroupSuccess({ members });
    })
  ));

  searchMembers$ = createEffect(() => this.actions$.pipe(
    ofType(PersonActions.searchMembers),
    tap(() => PersonActions.clearMemberSearchResultsState()),
    switchMap((data) => this.personService.searchMembers(data.searchPostModel)),
    map((memberSearchResults: IMemberSearchResponseModel[]) => PersonActions.searchMembersSuccess({ memberSearchResults })),
    catchError(error => of(PersonActions.searchMembersFailure({ error })))
  ));

  getSelectedMemberAndSubscriberDetails$ = createEffect(() => this.actions$.pipe(
    ofType(PersonActions.getSelectedMemberAndSubscriberDetails),
    withLatestFrom(this.store.select(selectSelectedMember).pipe(take(1))),
    switchMap(([data, selectedMember]) => {
      if (selectedMember && selectedMember.personId === data.personId) {
        return combineLatest([
          this.insuranceFacade.selectedGroupId$.pipe(take(1)),
          of(selectedMember)
        ]);
      }
      return combineLatest([
        this.insuranceFacade.selectedGroupId$.pipe(take(1)),
        this.personService.getMember(data.personId)
      ]);
    }),
    switchMap(([selectedGroupId, member]) => {
      if (selectedGroupId != null) {
        const memberWithFilteredPolicies = JSON.parse(JSON.stringify(member));
        memberWithFilteredPolicies.policies = member.policies.filter(x => x.groupId === selectedGroupId);
        return of(memberWithFilteredPolicies);
      }
      return of(member);
    }),
    switchMap(member => {
      const obsArray: Observable<IUserAccountResponseModel>[] = member.userIds.map(userId =>
        this.userService.getUser(userId).pipe(catchError(() => of(null))) // Ignore inactive userIds that would return an error here
      );
      return (obsArray.length > 0) ?
        combineLatest([forkJoin(obsArray), of(member), this.store.select(selectSelectedSubscriber).pipe(take(1))]) :
        combineLatest([of([]), of(member), this.store.select(selectSelectedSubscriber).pipe(take(1))]);
    }),
    switchMap(([accounts, member, selectedSubscriber]) => {
      var parentPersonId = member.policies[0]?.parentPersonId;
      var memberIsADependent = (parentPersonId !== null);
      var subscriberPersonId = memberIsADependent ? parentPersonId : member.personId;
      var memberWithAccounts = JSON.parse(JSON.stringify(member));

      memberWithAccounts.accounts = accounts.filter(account => !!account) as Array<IUserAccountResponseModel>;
      
      if (selectedSubscriber && selectedSubscriber.personId === subscriberPersonId) {
        return memberIsADependent ?
          combineLatest([of(selectedSubscriber), of(memberWithAccounts)]) :
          combineLatest([of(memberWithAccounts), of(memberWithAccounts)]);
      }
      return combineLatest([this.personService.getMember(subscriberPersonId), of(memberWithAccounts)]);
    }),
    map(([subscriber, member]) => PersonActions.getSelectedMemberAndSubscriberDetailsSuccess({ subscriber, member })),
    catchError((error, source) => source.pipe(startWith(PersonActions.getSelectedMemberAndSubscriberDetailsFailure({ error }))))
  ));

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private insuranceFacade: InsuranceFacade,
    private personService: PersonService,
    private userService: UserService
  ) { }

}
