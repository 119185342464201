import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { environment } from '@environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MxHttpInterceptor } from './backend/interceptors/mx-http.interceptor';
import { RootStoreModule } from './root-store/root-store.module';
import { SharedModule } from './shared/shared.module';
import { DocumentsModule } from './modules/documents/documents.module';
import { FirebaseOptions } from '@angular/fire/app';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = ({
  validation: true
});

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    RootStoreModule,
    DocumentsModule,
    NgxMaskModule.forRoot(options),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.mode === 'Production'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig as FirebaseOptions),
    AngularFireAnalyticsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MxHttpInterceptor,
      multi: true
    },
    CookieService
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
