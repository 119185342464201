import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { logout } from 'src/app/root-store/auth-store/auth.actions';
import { AuthState } from 'src/app/root-store/auth-store/auth.reducer';
import { selectSessionTimeLeft } from 'src/app/root-store/auth-store/auth.selectors';
import { Observable, Subject } from 'rxjs';
import { refreshAuthToken } from '../../../root-store/auth-store/auth.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mx-session-timout-modal',
  templateUrl: './session-timeout-modal.component.html'
})
export class SessionTimeoutModalComponent implements OnInit, OnDestroy {
  public unsubscribe$ = new Subject();
  public timeLeft$: Observable<number> = this.store.pipe(select(selectSessionTimeLeft));

  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutModalComponent>,
    private store: Store<AuthState>
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.timeLeft$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(timeLeft => {
        if (timeLeft <= 0) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  logout() {
    this.dialogRef.close();
    this.store.dispatch(logout());
  }

  refreshToken() {
    this.dialogRef.close();
    this.store.dispatch(refreshAuthToken());
  }
}
