
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(
    private dialog: MatDialog
  ) { }

  canDeactivateWarning(canDeactivate: boolean): Observable<any> | boolean {
    if (!canDeactivate) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Unsaved Changes',
          message: `
            You have unsaved changes on this page.<br>
            Are you sure you want to navigate away?`,
          buttonText: {
            ok: 'Discard Changes',
            cancel: 'Return'
          }
        }
      });

      return dialogRef.afterClosed();
    }

    return canDeactivate;
  }
}
