/* eslint-disable max-len */
import {
  IAutoCompleteFacilityResponseModel, IAutoCompleteProviderResponseModel,
  ISpecialtyModel, IGeoCodeResponseModel, ISearchResponseModel, IFacilityTypeModel,
  ISearchDetailsResponseModel, IPlanTierFilterModel, IAutoCompleteProcedureResponseModel
} from '@models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, on, createSelector } from '@ngrx/store';
import { AutoCompleteResponseModel, Provider } from 'projects/search/src/public-api';
import { SearchActions } from '.';

export const searchFeatureKey = 'search';
export const searchAdapter = createEntityAdapter<Provider>({
  selectId: (provider: Provider) => provider.providerLocationId
});
export interface State extends EntityState<Provider> {
  // OBSOLETE - Used by old provider search (keep around until that module is sunsetted)
  selectedResultId: number | null;
  autoCompleteSuggestions: AutoCompleteResponseModel;

  // New procedure/provider/facility search page properties
  locationSearchResult: IGeoCodeResponseModel[];
  locationSearchResultError: string;
  autoCompleteSearchResults: IAutoCompleteProviderResponseModel[] | IAutoCompleteFacilityResponseModel[] | IAutoCompleteProcedureResponseModel[];
  autoCompleteSearchResultsError: string;
  commonSpecialties: ISpecialtyModel[];
  autoCompleteSpecialties: ISpecialtyModel[];
  facilityTypes: IFacilityTypeModel[];
  planTierFilterOptions: IPlanTierFilterModel[];
  searchResults: ISearchResponseModel[];
  selectedProviderOrFacilityDetails: ISearchDetailsResponseModel;
}

const defaultSearch = {
  selectedResultId: null, // OBSOLETE - Used by old provider search (keep around until that module is sunsetted)
  selectedProviderOrFacilityDetails: null,
  autoCompleteSuggestions: null,
  locationSearchResult: null,
  locationSearchResultError: null,
  autoCompleteSearchResults: null,
  autoCompleteSearchResultsError: null,
  searchResults: null,
  commonSpecialties: [],
  autoCompleteSpecialties: [],
  facilityTypes: [],
  planTierFilterOptions: []
};

export const initialState: State = searchAdapter.getInitialState(defaultSearch);

export const searchReducer = createReducer(
  initialState,
  on(SearchActions.setSelectedResult, (state, { resultId }) => ({ ...state, selectedResultId: resultId })),
  on(SearchActions.runSearchSuccess, (state, action) => searchAdapter.setAll(action.providers, state)),
  on(SearchActions.clearSearchResults, state => searchAdapter.removeAll({
    ...state,
    selectedResultId: null,
    selectedResultFacilityId: null,
    searchResults: null
  })),
  on(SearchActions.createResult, (state, action) => searchAdapter.addOne(action.provider, state)),
  on(SearchActions.getProviderDetailsSuccess, (state, action) => searchAdapter.updateOne({ id: action.id, changes: action.changes }, state)),
  on(SearchActions.deleteResult, (state, action) => searchAdapter.removeOne(action.id, state)),
  on(SearchActions.getAutoCompleteSuggestionsSuccess, (state, action) => ({
    ...state,
    autoCompleteSuggestions: action.autoCompleteResponse
  })),
  on(SearchActions.getAutoCompleteSuggestionsFailure, (state, action) => ({
    ...state,
    autoCompleteSuggestions: { procedures: [], providers: [] }
  })),
  on(SearchActions.runLocationSearchSuccess, (state, action) => ({
    ...state,
    locationSearchResult: [action.result],
    locationSearchResultError: null
  })),
  on(SearchActions.runLocationSearchFailure, (state, action) => ({
    ...state,
    locationSearchResult: [],
    locationSearchResultError: action.error
  })),
  on(
    SearchActions.runProviderAutoCompleteSearchSuccess,
    SearchActions.runFacilityAutoCompleteSearchSuccess,
    SearchActions.runProcedureAutoCompleteSearchSuccess,
    (state, action) => ({
      ...state,
      autoCompleteSearchResults: action.results,
      autoCompleteSearchResultsError: null
    })
  ),
  on(
    SearchActions.runProviderAutoCompleteSearchFailure,
    SearchActions.runFacilityAutoCompleteSearchFailure,
    SearchActions.runProcedureAutoCompleteSearchFailure,
    (state, action) => ({
      ...state,
      autoCompleteSearchResults: [],
      autoCompleteSearchResultsError: action.error
    })
  ),
  on(SearchActions.runProviderFacilityProcedureSearchSuccess, (state, action) => ({ ...state, searchResults: action.results })),
  on(SearchActions.runProviderFacilityProcedureSearchFailure, (state, action) => searchAdapter.setAll([], state)),
  on(SearchActions.getCommonSpecialtiesSuccess, (state, action) => ({
    ...state,
    commonSpecialties: action.commonSpecialties
  })),
  on(SearchActions.getAutoCompleteSpecialtiesSuccess, (state, action) => ({
    ...state,
    autoCompleteSpecialties: action.specialties
  })),
  on(SearchActions.getFacilityTypesSuccess, (state, action) => ({
    ...state,
    facilityTypes: action.facilityTypes
  })),
  on(SearchActions.getSearchResultDetailsSuccess, (state, action) => ({
    ...state,
    selectedProviderOrFacilityDetails: action.details
  })),
  on(SearchActions.getPlanTierOptionsForSearchSuccess, (state, action) => ({
    ...state,
    planTierFilterOptions: action.planTierFilterOptions
  })),
  on(SearchActions.clearSelectedProviderOrFacilityDetails, (state, action) => ({
    ...state,
    selectedProviderOrFacilityDetails: null
  }))
);

export const reducer = (state: State | undefined, action: Action) => searchReducer(state, action);

// Selectors
export const getSearchState = createFeatureSelector<State>('search');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = searchAdapter.getSelectors(getSearchState);

export const getSelectedResultId = (state: State) => state.selectedResultId;

export const selectCurrentResultId = createSelector(
  getSearchState,
  getSelectedResultId
);

export const selectCurrentResult = createSelector(
  selectEntities,
  selectCurrentResultId,
  (entities, id) => entities[id]
);

export const selectAutoCompleteSuggestions = createSelector(
  getSearchState,
  (state: State) => state.autoCompleteSuggestions
);

export const selectLocationResult = createSelector(
  getSearchState,
  (state: State) => state.locationSearchResult
);

export const selectLocationResultError = createSelector(
  getSearchState,
  (state: State) => state.locationSearchResultError
);

export const selectAutoCompleteSearchResults = createSelector(
  getSearchState,
  (state: State) => state.autoCompleteSearchResults
);

export const selectAutoCompleteSearchResultsError = createSelector(
  getSearchState,
  (state: State) => state.autoCompleteSearchResultsError
);

export const selectSearchResults = createSelector(
  getSearchState,
  (state: State) => state.searchResults
);

export const selectSelectedProviderOrFacilityDetails = createSelector(
  getSearchState,
  (state: State) => state.selectedProviderOrFacilityDetails
);

export const selectCommonSpecialties = createSelector(
  getSearchState,
  (state: State) => state.commonSpecialties
);

export const selectAutoCompleteSpecialties = createSelector(
  getSearchState,
  (state: State) => state.autoCompleteSpecialties
);

export const selectFacilityTypes = createSelector(
  getSearchState,
  (state: State) => state.facilityTypes
);

export const selectPlanTierFilterOptions = createSelector(
  getSearchState,
  (state: State) => state.planTierFilterOptions
);
