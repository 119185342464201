export interface IChartData {
  labels: string[];
  datasets: IChartDataSet[];
}
export interface IChartDataSet {
  label: string;
  borderColor?: string;
  lineTension?: 0;
  fillColor?: string;
  backgroundColor?: string;
  fill?: boolean;
  data: number[];
  maxBarThickness?: number;
  stack?: string;
}
