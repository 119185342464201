import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IMeMdConfigBaseModel,
  IMeMdConfigResponseModel
} from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class MeMdConfigService {

  constructor(
    private http: HttpClient
  ) { }

  public get(memdConfigId: number): Observable<IMeMdConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/memd/configuration/${memdConfigId}`;
    return this.http.get<IMeMdConfigResponseModel>(url);
  }

  public getByPlan(planId: number): Observable<IMeMdConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/memd/configuration`;
    return this.http.get<IMeMdConfigResponseModel>(url);
  }

  public post(planId: number, memdConfig: IMeMdConfigBaseModel): Observable<IMeMdConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/memd/configuration`;
    const body = memdConfig;
    return this.http.post<IMeMdConfigResponseModel>(url, body);
  }

  public put(memdConfigId: number, memdConfig: IMeMdConfigBaseModel): Observable<IMeMdConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/memd/configuration/${memdConfigId}`;
    const body = memdConfig;
    return this.http.put<IMeMdConfigResponseModel>(url, body);
  }

  public delete(memdConfigId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/memd/configuration/${memdConfigId}`;
    return this.http.delete<any>(url);
  }
}
