import { IPaymentCardRequestStatusResponseModel } from './payment-card-request-status-response.model';
import { IPaymentCardResponseModel } from './payment-card-response.model';

export interface IPaymentCardRequestDetailResponseModel {
  id: number;
  paymentCardId: number;
  partnerUserId: number;
  memberPersonId: number;
  groupId: number;
  groupNumber: string;
  memberNumber: string;
  memberFirstName: string;
  memberLastName: string;
  cardAmount: number;
  cardAvailableDate: string;
  cardExpirationDate: string;
  procedureInfo: string;
  cptCode: string;
  providerName: string;
  providerAddress1: string;
  providerAddress2: string;
  providerCity: string;
  providerState: string;
  providerPostalCode: string;
  providerCountryCode: string;
  spcId: string;
  visibleToMember: boolean;
  createdAt: string;
  updatedAt: string;
  paymentCard: IPaymentCardResponseModel;
  statuses: IPaymentCardRequestStatusResponseModel[];
  providerTIN: string;
  providerNPI: string;
}
