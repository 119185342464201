import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { IModuleConfigResponseModel, IModuleConfigPostModel, IModuleConfigPutModel, IModuleResponseModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class ModuleConfigService {

  constructor(
    private http: HttpClient
  ) { }

  public get(id: number): Observable<IModuleConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/moduleConfig/${id}`;
    return this.http.get<IModuleConfigResponseModel>(url);
  }

  public getAllModules(): Observable<IModuleResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/modules`;
    return this.http.get<IModuleResponseModel[]>(url);
  }

  public getByGroup(groupId: number): Observable<IModuleConfigResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/group/${groupId}/moduleConfigs?includeBalanceBill=true`;
    return this.http.get<IModuleConfigResponseModel[]>(url);
  }

  public post(moduleConfig: IModuleConfigPostModel): Observable<IModuleConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/group/${moduleConfig.groupId}/moduleConfig`;
    const body = moduleConfig;
    return this.http.post<IModuleConfigResponseModel>(url, body);
  }

  public put(moduleConfig: IModuleConfigPutModel): Observable<IModuleConfigResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/moduleConfig/${moduleConfig.id}`;
    const body = moduleConfig;
    return this.http.put<IModuleConfigResponseModel>(url, body);
  }

  public delete(moduleConfigId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/moduleConfig/${moduleConfigId}`;
    return this.http.delete<any>(url);
  }

}
