<div fxLayout="row" fxLayoutAlign="center center" class="full-view">
  <mat-card class="not-found-container">
    <mat-card-title>
      <h1 style="margin: 0">Page not found!</h1>
    </mat-card-title>
    <mat-card-content>
      The page or resource you requested could not be found.
    </mat-card-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
      <button mat-raised-button color="primary" routerLink="/groups">Go to Home Page</button>
    </mat-dialog-actions>
  </mat-card>
</div>
