import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'mx-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnChanges {
  @Input() rating: number;

  private maxStars = 5;
  private starsAmount: number;

  constructor() { }

  ngOnChanges() {
    if (this.rating >= 0) {
      this.starsAmount = Math.round(this.rating / 10) / 2;
    }
  }

  public get filledStarsArray() {
    return Array.from({length: Math.floor(this.starsAmount)});
  }

  public get hasHalfStar() {
    return this.starsAmount ? this.starsAmount % 1 != 0 : false;
  }

  public get outlinedStarsArray() {
    return Array.from({length: this.maxStars - Math.ceil(this.starsAmount)});
  }

}
