import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProfileActions, ProfileSelectors, ProfileState } from '../../root-store/profile-store';

@Injectable({
  providedIn: 'root'
})
export class ProfileFacade {
  public isUpdatingPassword$: Observable<boolean> = this.store.pipe(select(ProfileSelectors.selectIsUpdatingPassword));

  constructor(
    private store: Store<ProfileState>
  ) {}

  public updatePassword(oldPassword: string, newPassword: string ) {
    this.store.dispatch(ProfileActions.updatePassword({ oldPassword, newPassword }));
  }

  public resetMemberPassword(username: string) {
    this.store.dispatch(ProfileActions.resetMemberPassword({ username }));
  }

  public impersonateUser(targetUserId: number, clientToken: string) {
    this.store.dispatch(ProfileActions.impersonateUser({ targetUserId, clientToken }));
  }

}
