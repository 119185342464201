import { IPartnerResponseModel } from '@models';
import { createAction, props } from '@ngrx/store';

export const loadPartners = createAction(
  '[Partner] Load Partners'
);

export const loadPartnersSuccess = createAction(
  '[Partner] Load Partners Success',
  props<{ partners: IPartnerResponseModel[] }>()
);

export const loadPartnersFailure = createAction(
  '[Partner] Load Partners Failure',
  props<{ error: any }>()
);

export const loadGroupPartners = createAction(
  '[Partner] Load Group Partners',
  props<{ groupId: number }>()
);

export const loadGroupPartnersSuccess = createAction(
  '[Partner] Load Group Partners Success',
  props<{ groupPartners: IPartnerResponseModel[] }>()
);

export const loadGroupPartnersFailure = createAction(
  '[Partner] Load Group Partners Failure',
  props<{ error: any }>()
);

export const addGroupPartners = createAction(
  '[Partner] Add Group Partners',
  props<{ partnerIds: number[]; groupId: number }>()
);

export const addGroupPartnersSuccess = createAction(
  '[Partner] Add Group Partners Success',
  props<{ groupId: number }>()
);

export const addGroupPartnersFailure = createAction(
  '[Partner] Add Group Partners Failure',
  props<{ error: any }>()
);

export const removeGroupPartners = createAction(
  '[Partner] Remove Group Partners',
  props<{ partnerIds: number[]; groupId: number }>()
);

export const removeGroupPartnersSuccess = createAction(
  '[Partner] Remove Group Partners Success',
  props<{ groupId: number }>()
);

export const removeGroupPartnersFailure = createAction(
  '[Partner] Remove Group Partners Failure',
  props<{ error: any }>()
);

export const removeSingleGroupPartner = createAction(
  '[Partner] Remove Single Group Partner',
  props<{ partnerId: number; groupId: number }>()
);

export const removeSingleGroupPartnerSuccess = createAction(
  '[Partner] Remove Single Group Partner Success'
);

export const removeSingleGroupPartnerFailure = createAction(
  '[Partner] Remove Single Group Partner Failure',
  props<{ error: any }>()
);

export const clonePartnersFromGroup = createAction(
  '[Partner] Clone Partners From Group',
  props<{ groupId: number; groupToCloneFromId: number }>()
);

export const clonePartnersFromGroupFailure = createAction(
  '[Partner] Clone Partners From Group Failure',
  props<{ error: any }>()
);

export const cloneDetailsBrandingFromGroup = createAction(
  '[Partner] Clone Details Branding From Group',
  props<{ groupId: number; groupName: string; brandConfigId: number; groupToCloneFromId: number }>()
);

export const cloneDetailsBrandingFromGroupSuccess = createAction(
  '[Partner] Clone Details Branding From Group Success',
  props<{ groupId: number }>()
);

export const cloneDetailsBrandingFromGroupFailure = createAction(
  '[Partner] Clone Details Branding From Group Failure',
  props<{ error: any }>()
);
