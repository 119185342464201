import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTerminology from './terminology.reducer';

export const selectTerminologyState = createFeatureSelector<fromTerminology.TerminologyState>(
  fromTerminology.terminologyFeatureKey
);

export const selectTerminology = createSelector(
  selectTerminologyState,
  (state: fromTerminology.TerminologyState) => state.terminology
);

export const selectTerminologyGroupConfigs = createSelector(
  selectTerminologyState,
  (state: fromTerminology.TerminologyState) => state.terminologyGroupConfigs
);

export const selectIsLoadingRequest = createSelector(
  selectTerminologyState,
  (state: fromTerminology.TerminologyState) => state.isLoadingRequest
);
