import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentCardRequestState } from '@enums';
import { PaymentCardFacade } from '@facades';
import { IPaymentCardRequestStatusPostModel } from '@models';

@Component({
  selector: 'mx-payment-card-request-status-modal',
  templateUrl: './payment-card-request-status-modal.component.html',
  styleUrls: ['./payment-card-request-status-modal.component.scss']
})
export class PaymentCardRequestStatusModalComponent implements OnInit {
  public statusForm: FormGroup;
  public title = 'Update Status';
  public cancelButtonText = 'Cancel';
  public saveButtonText = 'Save';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
    { statusBlocked?: boolean;
      title?: string;
      saveButtonText?: string;
      cancelButtonText?: string;
      paymentCardRequestId: number;
      status: PaymentCardRequestState;
    },
    private fb: FormBuilder,
    private paymentCardFacade: PaymentCardFacade
  ) { }

  ngOnInit() {
    let statusBlocked = false;
    if (this.data.statusBlocked) {
      statusBlocked = this.data.statusBlocked;
    }

    this.statusForm = this.fb.group({
      status: new FormControl({ value: this.data.status, disabled: statusBlocked }),
      note: ''
    });

    if (this.data.title) {
      this.title = this.data.title;
    }

    if (this.data.cancelButtonText) {
      this.cancelButtonText = this.data.cancelButtonText;
    }

    if (this.data.saveButtonText) {
      this.saveButtonText = this.data.saveButtonText;
    }
  }

  clickUpdateStatus() {
    const p: IPaymentCardRequestStatusPostModel = {
      status: this.statusForm.controls.status.value as PaymentCardRequestState,
      note: this.statusForm.controls.note.value as string
    };
    this.paymentCardFacade.updatePaymentCardRequestStatus(p, this.data.paymentCardRequestId);
  }

}
