import { PermissionType } from './enums';
import { IRole } from './models/role.model';

export const AdminRole: IRole = {
  name: 'Admin',
  permissions: [
    PermissionType.CreateGroup,
    PermissionType.CreatePlan,
    PermissionType.EditBranding,
    PermissionType.EditGroup,
    PermissionType.EditModules,
    PermissionType.EditTerminology,
    PermissionType.EditPartners
  ]
};

export const StandardRole: IRole = {
  name: 'Standard',
  permissions: []
};
