import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ISuperBillDetailResponseModel,
  ISuperBillImageResponseModel,
  ISuperBillResponseModel,
  ISuperBillStatusPostModel
} from '@models';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class SuperBillService {

  constructor(
    private http: HttpClient
  ) { }

  public getSuperBill(superBillId: number): Observable<ISuperBillDetailResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/superBill/${superBillId}`;
    return this.http.get<ISuperBillDetailResponseModel>(url);
  }

  public getSuperBills(personId: number): Observable<ISuperBillResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/person/${personId}/superBills`;
    return this.http.get<ISuperBillResponseModel[]>(url);
  }

  public getSuperBillImages(superBillId: number): Observable<ISuperBillImageResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/superBill/${superBillId}/images`;
    return this.http.get<ISuperBillImageResponseModel[]>(url);
  }

  public getSuperBillImage(superBillId: number, imageId: number, contentType: string, fileName: string) {
    const url = `${environment.config.apiEndpoint}/v2/superBill/${superBillId}/image/${imageId}`;
    if (!contentType.includes('/')) {
      if (contentType.includes('pdf')) {
        contentType = 'application/'.concat(contentType);
      } else {
        contentType = 'image/'.concat(contentType);
      }
    }

    this.http.get(url, { headers: { Accept: contentType }, responseType: 'blob' })
      .subscribe(blob => {
        saveAs(blob, fileName, {
          type: contentType
        });
      });
  }

  public createStatus(model: ISuperBillStatusPostModel, superBillId: number) {
    const url = `${environment.config.apiEndpoint}/v2/superBill/${superBillId}/status`;
    const body = model;
    return this.http.post<ISuperBillDetailResponseModel>(url, body);
  }

  public getSuperBillsByPartner(userId: number): Observable<ISuperBillResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/user/${userId}/partner/superBills`;
    return this.http.get<ISuperBillResponseModel[]>(url);
  }

  public getSuperBillsByHSR(hsrId: number): Observable<ISuperBillResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/healthServiceRequest/${hsrId}/superBills`;
    return this.http.get<ISuperBillResponseModel[]>(url);
  }

}

