import { PlanType } from '@enums';

export interface IPlanPutModel {
  groupId?: number;
  name: string;
  externalId: string;
  type: PlanType;
  groupNumber: string;
  planImageUri: string;
  rxPcn: string;
  rxBin: string;
  rxGroup: string;
  claimSubmittalAddressLine1: string;
  claimSubmittalAddressLine2: string;
  claimSubmittalCity: string;
  claimSubmittalState: string;
  claimSubmittalPostalCode: string;
  ediPayerId: string;
  memberSupportPhoneNumber: string;
  providerSupportPhoneNumber: string;
  sbcUri: string;
  spdUri: string;
  familyAccumulatorsMustBeMetForFamilies: boolean;
  hidePolicyIdentifiers: boolean;
  maskMemberNumbers: boolean;
  suffixNote: string;
  prefixNote: string;
}
