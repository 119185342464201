import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceFacade } from '@facades';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'planImage'
})
export class PlanImagePipe implements PipeTransform {
  constructor(private insuranceFacade: InsuranceFacade) {}

  transform(planId: number): Observable<string> {
    return this.insuranceFacade.groupPlans$
      .pipe(
        map(plans => {
          if (plans) {
            const foundPlan = plans.find(plan => plan.id === planId);
            if (foundPlan) {
              return foundPlan.planImageUri;
            } else {
              return '';
            }
          }
        })
      );
  }

}
