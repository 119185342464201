import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProviderNetworkResponseModel } from '@models';

@Component({
  selector: 'mx-networks-list',
  templateUrl: './networks-list.component.html',
  styleUrls: ['./networks-list.component.scss']
})
export class NetworksListComponent {

  @Input() providerNetworks: IProviderNetworkResponseModel[];
  @Input() showDeleteButton: boolean = false;

  @Output() networkRemoved = new EventEmitter<number>();

  constructor() { }

  public openPortalUri(portalUri: string) {
    if (portalUri !== undefined && portalUri !== '' && portalUri != null) {
      window.open(portalUri, '_blank');
    }
  }

  public removeNetworkClicked(networkId: number) {
    this.networkRemoved.emit(networkId);
  }

}
