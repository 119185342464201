import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsActions } from '@root-store';
import { Observable } from 'rxjs';
import { selectSearchResponseStartTime } from 'src/app/root-store/analytics-store/analytics.reducer';
import { AppState } from 'src/app/root-store/root.state';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsFacade {
  public searchResponseStartTime$: Observable<number> = this.store.select(selectSearchResponseStartTime);

  constructor(
    private store: Store<AppState>
  ) {}

  logEvent(eventName: string, data?: any) {
    this.store.dispatch(AnalyticsActions.logEvent({ eventName, data }));
  }

  setSearchResponseStartTime() {
    this.store.dispatch(AnalyticsActions.setSearchResponseStartTime());
  }
}
