import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  IExternalNetworkMappingResponseModel, IPlanTierPostModel, IPlanTierPutModel,
  IPlanTierResponseModel, IExternalNetworkAutocompleteResponseModel,
  INetworkTierPutModel, INetworkTier, IProviderNetworkResponseModel
} from '@models';

import { environment } from '@environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlanTierService {

  constructor(
    private http: HttpClient
  ) { }

  public createPlanTier(planId: number, planTier: IPlanTierPostModel): Observable<IPlanTierResponseModel> {
    const url = `${environment.config.apiEndpoint}/v3/plan/${planId}/benefitTier`;
    return this.http.post<IPlanTierResponseModel>(url, planTier);
  }

  public updatePlanTier(planId: number, tier: number, planTier: IPlanTierPutModel): Observable<IPlanTierResponseModel> {
    const url = `${environment.config.apiEndpoint}/v3/plan/${planId}/benefitTier/${tier}`;
    return this.http.put<IPlanTierResponseModel>(url, planTier);
  }

  public loadPlanTiers(planId: number, isProviderSearch: boolean = false): Observable<IPlanTierResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v3/plan/${planId}/benefitTiers?isProviderSearch=${isProviderSearch}`;
    return this.http.get<IPlanTierResponseModel[]>(url);
  }

  public deletePlanTier(planId: number, tier: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v3/plan/${planId}/benefitTier/${tier}`;
    return this.http.delete(url);
  }
  
  public loadPlanTierNetworks(planId: number, tier: number): Observable<IProviderNetworkResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v3/plan/${planId}/tier/${tier}/networks`;
    return this.http.get<IProviderNetworkResponseModel[]>(url);
  }
  
  public addPlanTierNetwork(planId: number, tier: number, networkId: number): Observable<IProviderNetworkResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/tier/${tier}/network/${networkId}`;
    return this.http.post<IProviderNetworkResponseModel>(url, null);
  }
  
  public removePlanTierNetwork(planId: number, tier: number, networkId: number) {
    const url = `${environment.config.apiEndpoint}/v2/plan/${planId}/tier/${tier}/network/${networkId}`;
    return this.http.delete(url);
  }

  public getExternalNetworkSuggestions(searchTerm: string): Observable<IExternalNetworkAutocompleteResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/ribbon/provider/networks/autocomplete`;
    const body = { searchTerm, size: 1000, from: 1 };
    return this.http.post<IExternalNetworkAutocompleteResponseModel[]>(url, body);
  }

  public removePlanTierExternalNetwork(planId: number, tier: number, ribbonInsuranceId: string): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v1/plan/${planId}/tier/${tier}/ribbon/insurance/${ribbonInsuranceId}`;
    return this.http.delete(url);
  }

  public getPlanTierExternalNetworkMappings(planId: number, tier: number): Observable<IExternalNetworkMappingResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v1/plan/${planId}/tier/${tier}/ribbon/insurances`;
    return this.http.get<IExternalNetworkMappingResponseModel[]>(url);
  }

  public createPlanTierExternalNetworkMapping(planId: number, tier: number, ribbonInsuranceId: string): Observable<IExternalNetworkMappingResponseModel> {
    const url = `${environment.config.apiEndpoint}/v1/plan/${planId}/tier/${tier}/ribbon/insurance/${ribbonInsuranceId}`;
    return this.http.post<IExternalNetworkMappingResponseModel>(url, null);
  }

  public updatePlanTierBenefits(planId: number, tier: number, tierBenefits: INetworkTierPutModel): Observable<INetworkTier> {
    const url = `${environment.config.apiEndpoint}/v4/plan/${planId}/benefitTier/${tier}/benefits/summary`;
    return this.http.put<INetworkTier>(url, tierBenefits);
  }

  public getPlanTierBenefits(planId: number): Observable<INetworkTier[]> {
    const url = `${environment.config.apiEndpoint}/v4/plan/${planId}/benefits/summary`;
    return this.http.get<INetworkTier[]>(url);
  }
}