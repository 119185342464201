import { TokenService } from './../../shared/utilities/token.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService, UserService } from '@backend';
import { EventName } from '@enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsActions } from '../analytics-store';
import { selectAuthToken, selectUserId } from '../auth-store/auth.selectors';
import { AppState } from '../root.state';
import * as ProfileActions from './profile.actions';
import { environment } from '@environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ProfileEffects {

  loadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.loadProfile),
    switchMap(() =>
      this.store.select(selectAuthToken)
        .pipe(
          filter(token => token !== '' && token !== null && token !== undefined),
          take(1),
          switchMap((token) => this.tokenService.decodeJwt(token)
            .pipe(
              switchMap(decoded => {
                if (decoded) {
                  return this.profileService.getByUser(decoded.user_id as number)
                    .pipe(
                      map(profile => ProfileActions.loadProfileSuccess({ data: profile })),
                      catchError(error => of(ProfileActions.loadProfileFailure({ error })))
                    );
                } else {
                  const error = {};
                  return of(ProfileActions.loadProfileFailure({ error }));
                }
              })
            ))
        ))
  ));

  loadProfileWithProfileId$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.loadProfileWithProfileId),
    switchMap((data) => this.profileService.get(data.profileId)
      .pipe(
        map(profile => ProfileActions.loadProfileSuccess({ data: profile })),
        catchError(error => of(ProfileActions.loadProfileFailure({ error })))
      ))
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.resetPassword),
    map((action) =>
    // this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.ResetPassword }));
      this.userService.requestResetPassword(action.username)
    )
  ),
                                { dispatch: false });

  resetMemberPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.resetMemberPassword),
    map((action) => this.userService.requestResetPasswordForMember(action.username))
  ),
                                      { dispatch: false });

  updateProfile$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.updateProfile),
    switchMap(action => {
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdateProfile }));
      return this.profileService.update(action.profileId, action.updatedProfile)
        .pipe(
          map(response => {
            void this.router.navigate(['']);
            return ProfileActions.updateProfileSuccess({ updatedProfile: response });
          }),
          catchError(error => of(ProfileActions.updateProfileFailure({ error })))
        );
    })
  ));

  updatePassword$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.updatePassword),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([ action, userId ]) => this.userService.updatePassword(
      {
        id: userId,
        oldPassword: action.oldPassword,
        password: action.newPassword
      }
    )),
    map(() => {
      this.store.dispatch(ProfileActions.updatePasswordSuccess());
    })
  ), { dispatch: false });

  impersonateUser$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.impersonateUser),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([action, impersonatorUserId]) =>
      this.userService.impersonateUser(action.targetUserId, impersonatorUserId, action.clientToken)
      .pipe(
        tap(response => {
          const impersonationTokenParam = `impersonationToken=${response.impersonationToken}`;
          const clientTokenParam = `clientToken=${action.clientToken}`;
          const impersonatorUserIdParam = `impersonatorUserId=${impersonatorUserId}`;
          const queryParameters = `${impersonationTokenParam}&${clientTokenParam}&${impersonatorUserIdParam}`;
          window.open(`${environment.config.memberPortalUrl}/login?${queryParameters}`);
        }),
        catchError(() => {
          this.snackBar.open('An application error occurred and member could not be impersonated.', 'close', { duration: 10000 });
          return of(ProfileActions.impersonateUserFailure());
        }),
      ))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private userService: UserService,
    private tokenService: TokenService,
    private store: Store<AppState>,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

}
