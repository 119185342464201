import { ClaimTransactionStatus } from '@enums';

export interface IClaimTransaction {
  id?: number;
  userId?: number;
  claimId: number;
  paymentMethodId?: number;
  status: ClaimTransactionStatus;
  createdAt: Date;
  updatedAt?: Date;
}
