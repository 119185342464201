import { createSelector, on, createFeatureSelector, createReducer, Action } from '@ngrx/store';
import { AnalyticsActions } from '.';

export const analyticsFeatureKey = 'analytics';

// TODO Come back and do something with this feature store or remove it
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnalyticsState {
  searchResponseStartTime: number;
}

export const initialAnalyticsState: AnalyticsState = {
  searchResponseStartTime: null
};

export const analyticsReducer = createReducer(
  initialAnalyticsState,
  on(AnalyticsActions.setSearchResponseStartTime, (state) => ({ ...state, searchResponseStartTime: Date.now() }))
);

export function reducer(state: AnalyticsState | undefined, action: Action) {
  return analyticsReducer(state, action);
}

// Selectors
export const getAnalyticsState = createFeatureSelector<AnalyticsState>('analytics');

export const selectSearchResponseStartTime = createSelector(
  getAnalyticsState,
  (state: AnalyticsState) => state.searchResponseStartTime
);
