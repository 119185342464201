import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCostEstimator from './cost-estimator.reducer';

export const selectCostEstimatorState = createFeatureSelector<fromCostEstimator.CostEstimatorState>(
  fromCostEstimator.analyticsFeatureKey
);

export const selectProcedureCost = createSelector(
  selectCostEstimatorState,
  (state: fromCostEstimator.CostEstimatorState) => state.procedureCost
);

export const selectProcedureCostEstimate = createSelector(
  selectCostEstimatorState,
  (state: fromCostEstimator.CostEstimatorState) => state.procedureCostEstimate
);
