import { TokenService } from './../../shared/utilities/token.service';
import { StorageService } from './../../shared/utilities/storage.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, UserService } from '@backend';
import { ADMIN_PARTNER_IDS, SESSION_EXP_WARNING, TOKEN_REFRESH_BUFFER_FACTOR } from '@constants';
import { EventName } from '@enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsActions } from '../analytics-store';
import { clearInsuranceState, loadAllGroups } from '../insurance-store/insurance.actions';
import { selectGroups } from '../insurance-store/insurance.selectors';
import { clearPersonState } from '../person-store/person.actions';
import { clearProfile } from '../profile-store/profile.actions';
import { AppState } from '../root.state';
import * as AuthActions from './auth.actions';
import { decodeAuthToken } from './auth.actions';
import { selectLoggedIn } from './auth.selectors';
import { PartnerService } from '../../backend/services/partner.service';
import { StandardRole, AdminRole } from '../../shared/roles';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    switchMap((data) =>
      this.authService.login(data.loginCreds)
        .pipe(
          take(1),
          switchMap(token => {
            // this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.Login }));
            this.store.dispatch(decodeAuthToken(token));
            this.store.dispatch(AuthActions.loginSuccess({ data: token, email: data.loginCreds.username }));
            return this.storageService.storeAuthToken(token.token);
          }),
          switchMap(() => {
            this.store.dispatch(loadAllGroups());
            return this.store.pipe(select(selectGroups));
          }),
          filter(groups => !!groups),
          tap(() => {
            void this.router.navigate(['groups']);
          }),
          catchError(error => of(AuthActions.loginFailure({ error })))
        )
    )
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map(() => {
      // this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.Logout }));
      void this.router.navigate(['./login']);
      this.storageService.unstoreAuthToken();
      this.dialog.closeAll();
      this.store.dispatch(AuthActions.clearStore());
    })
  ), { dispatch: false });

  refreshAuthToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.refreshAuthToken),
    switchMap(() =>
      this.authService.refreshToken()
        .pipe(
          take(1),
          map(token => {
            this.store.dispatch(decodeAuthToken(token));
            this.storageService.storeAuthToken(token.token);
            return AuthActions.refreshAuthTokenSuccess({ data: token });
          }),
          catchError(error => of(AuthActions.refreshAuthTokenFailure({ error })))
        )
    )
  ));

  decodeAuthToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.decodeAuthToken),
    switchMap((data) => {
      if (data.token !==  null && data.token !== undefined) {
        return this.tokenService.decodeJwt(data.token)
          .pipe(
            map(decodedToken => {
              this.store.dispatch(AuthActions.setAuthExpTimer({ exp: decodedToken.exp }));
              return AuthActions.decodeAuthTokenSuccess({ data: decodedToken });
            }),
            catchError(error => of(AuthActions.decodeAuthTokenFailure({ error })))
          );
      }
    })
  ));

  getRole$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.getRole),
    switchMap(payload => this.partnerService.getPartner(payload.userId)),
    map(partner => (ADMIN_PARTNER_IDS.find(x => x === partner?.partnerId) !== undefined) ? AdminRole : StandardRole),
    map(role => AuthActions.getRoleSuccess({ role }))
  ));

  setAuthExpTimer$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.setAuthExpTimer),
    switchMap((data) => {
      const EXPIRATION_TIME = +data.exp;
      const TIME_NOW = Math.round((Date.now() / 1000));
      const TOTAL_TIME_LEFT = (EXPIRATION_TIME - TIME_NOW) * 1000;
      const INITIAL_TIMER = (TOTAL_TIME_LEFT + TOKEN_REFRESH_BUFFER_FACTOR) - (SESSION_EXP_WARNING * 1000);

      const logout$ = this.actions$.pipe(ofType(AuthActions.logout));

      return timer(INITIAL_TIMER, 1000)
        .pipe(
          map(timerOutput => SESSION_EXP_WARNING - timerOutput),
          takeUntil(logout$)
        );
    }),
    withLatestFrom(this.store.select(selectLoggedIn)),
    filter(([_, isLoggedIn]) => !!isLoggedIn),
    map(([timerRemaining, isLoggedIn]) => {
      if (timerRemaining <= 0 || !isLoggedIn) {
        this.store.dispatch(AuthActions.logout());
        this.store.dispatch(AuthActions.setSessionTimeLeft({ time: null }));
      } else {
        this.store.dispatch(AuthActions.setSessionTimeLeft({ time: timerRemaining }));
      }
    })
  ), { dispatch: false });

  loadAuthCookie$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loadAuthCookie),
    map(() => this.storageService.checkAuthToken()),
    switchMap(tokenExists => {
      if (tokenExists === true) {
        const token = this.storageService.getAuthToken();
        if (token !== 'undefined' && token) {
          // Do this so that the expiration timer will start even if they are automatically logged in due to a still-active token
          this.store.dispatch(decodeAuthToken({ token }));
          return of(AuthActions.loadAuthCookieSuccess({ token }));
        }
        return of(AuthActions.loadAuthCookieFailure());
      } else {
        return of(AuthActions.loadAuthCookieFailure());
      }
    })
  ));

  updatePassword$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.updatePassword),
    map((data) => {
      this.store.dispatch(AnalyticsActions.logEvent({ eventName: EventName.UpdatePassword }));
      this.userService.putPassword(data.updateModel);
    })), { dispatch: false });

  clearStore$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.clearStore),
    map(() => {
      this.store.dispatch(clearProfile());
      this.store.dispatch(clearInsuranceState());
      this.store.dispatch(clearPersonState());
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authService: AuthService,
    private partnerService: PartnerService,
    private tokenService: TokenService,
    private storageService: StorageService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router
  ) { }

}
