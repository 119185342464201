import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SuperBillActions } from '.';
import { ISuperBillResponseModel } from '../../../backend/entities/super-bill-response.model';

export const superbillsFeatureKey = 'superbills';
// SuperBills should be sorted by last created first
export const superbillsAdapter = createEntityAdapter<ISuperBillResponseModel>({
  sortComparer: (a: ISuperBillResponseModel, b: ISuperBillResponseModel) => b.createdAt.localeCompare(a.createdAt),
});
export interface State extends EntityState<ISuperBillResponseModel> {
  selectedSuperBillId: number | null;
}

const defaultSuperBills = {
  selectedSuperBillId: null
};

export const initialState: State = superbillsAdapter.getInitialState(defaultSuperBills);

export const superbillsReducer = createReducer(
  initialState,
  on(SuperBillActions.setSelectedSuperBill, (state, { id }) => ({ ...state, selectedRequestId: id })),
  on(SuperBillActions.getAllSuperBillsSuccess, (state, { superBills }) => superbillsAdapter.setAll(superBills, state)),
  on(SuperBillActions.clearSuperBills, state => superbillsAdapter.removeAll({ ...state, selectedResultId: null })),
  on(SuperBillActions.createSuperBillSuccess, (state, { superBill }) => superbillsAdapter.addOne(superBill, state)),
);

export const reducer = (state: State | undefined, action: Action) => superbillsReducer(state, action);

// Selectors
export const getSuperBillsState = createFeatureSelector<State>('superbills');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = superbillsAdapter.getSelectors(getSuperBillsState);

export const getSelectedSuperBillId = (state: State) => state.selectedSuperBillId;

export const selectCurrentSuperBillId = createSelector(
  getSuperBillsState,
  getSelectedSuperBillId
);

export const selectCurrentSuperBill = createSelector(
  selectEntities,
  selectCurrentSuperBillId,
  (entities, id) => entities[id]
);
