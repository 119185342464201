import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IPaymentCardNumberModel,
  IPaymentCardRequestDetailResponseModel,
  IPaymentCardRequestPostModel,
  IPaymentCardRequestResponseModel,
  IPaymentCardRequestStatusPostModel
} from '@models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentCardService {

  constructor(
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private http: HttpClient,
    private location: Location
  ) { }

  public getPaymentCardRequest(id: number): Observable<IPaymentCardRequestDetailResponseModel> {
    const url = `${environment.config.apiEndpoint}/v2/paymentCardRequest/${id}`;
    return this.http.get<IPaymentCardRequestDetailResponseModel>(url);
  }

  public getPaymentCardRequestsByPartner(userId: number): Observable<IPaymentCardRequestResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/partner/user/${userId}/paymentCardRequests`;
    return this.http.get<IPaymentCardRequestResponseModel[]>(url);
  }

  public getPaymentCardRequestsByMember(personId: number): Observable<IPaymentCardRequestResponseModel[]> {
    const url = `${environment.config.apiEndpoint}/v2/person/${personId}/paymentCardRequests`;
    return this.http.get<IPaymentCardRequestResponseModel[]>(url);
  }

  public requestPaymentCard(requestModel: IPaymentCardRequestPostModel): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/paymentCardRequest`;
    const body = requestModel;
    return this.http.post<any>(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 201) {
            this.snackBar.open(
              'Payment card requested!',
              'Close',
              { duration: 10000 }
            );
            this.location.back();
          }
        })
      );
  }

  public updatePaymentCardRequestStatus(requestModel: IPaymentCardRequestStatusPostModel, paymentCardRequestId: number): Observable<any> {
    const url = `${environment.config.apiEndpoint}/v2/paymentCardRequest/${paymentCardRequestId}/status`;
    const body = requestModel;
    return this.http.post<any>(url, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === 201) {
            this.snackBar.open(
              'Payment card status updated!',
              'Close',
              { duration: 10000 }
            );
          }
        })
      );
  }

  public getCardRequestsReportByUser(userId: number): Observable<HttpEvent<Blob>> {
    const url = `${environment.config.apiEndpoint}/v2/paymentCardRequest/${userId}/report`;
    return this.http.request(new HttpRequest('GET', url, {
      responseType: 'blob'
    })
    );
  }

  public getPaymentCardNumber(paymentCardId: number): Observable<IPaymentCardNumberModel> {
    const url = `${environment.config.apiEndpoint}/v2/paymentCard/${paymentCardId}/number`;
    return this.http.get<IPaymentCardNumberModel>(url);
  }
}
