import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SESSION_EXP_WARNING } from '@constants';
import { ThemeService } from '@utilities';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '@environment';
import { SessionTimeoutModalComponent } from './shared/components/session-timeout-modal/session-timeout-modal.component';
import { AuthFacade } from './shared/facades/auth.facade';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'mx-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {


  public isExpanded: boolean;
  public navState: string;
  public isDarkTheme$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private titleService: Title,
    private router: Router,
    private themeService: ThemeService,
    private authFacade: AuthFacade,
    private dialog: MatDialog
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        this.titleService.setTitle(title);

        this.authFacade.userId$
          .pipe(
            takeUntil(this.unsubscribe$),
            filter(user => !!user))
          .subscribe(userId => {
            gtag('config', environment.config.trackerId, {
              page_path: event.urlAfterRedirects,
              user_id: userId
            });
          });
      }
    });
  }

  ngOnInit() {
    this.authFacade.loadAuthCookie();
    this.isDarkTheme$ = this.themeService.isDarkTheme;
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getTitle(routerState, parent): Array<string> {
    const data: Array<string> = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title as string);
    }

    if (routerState && parent) {
      data.push(... this.getTitle(routerState, routerState.firstChild(parent)));
    }
    return data;
  }

  toggleDarkTheme(checked: boolean) {
    this.themeService.setDarkTheme(checked);
  }

  private initSubscriptions() {
    this.authFacade.sessionTimeLeft$
      .pipe(
        filter(time => time === SESSION_EXP_WARNING),
        takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.dialog.open(SessionTimeoutModalComponent, {
          width: '500px'
        });
      });
  }
}
