import { Injectable } from '@angular/core';
import { RegistrationDateRange } from '@enums';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TempReportFixService {

  public reportRange: BehaviorSubject<number>;

  constructor() {
    this.reportRange = new BehaviorSubject(RegistrationDateRange.Past7Days);
  }

  public setRange(range: number): void {
    this.reportRange.next(range);
  }
}
