import { ModuleType, PhysicianType, PlaceOfService } from '@enums';

export const COOKIE_EXPIRATION_DAYS = 1;
export const AUTH_COOKIE_ID = 'mx-auth-token';
export const SESSION_EXP_WARNING = 60; // ms
export const TOKEN_REFRESH_BUFFER_FACTOR = 10000;
export const IN_NETWORK_TERM = 'In-Network';
export const OUT_OF_NETWORK_TERM = 'Out-of-Network';
export const PAYER_ID = 1;
export const GROUP_LOGO_PLACEHOLDER = 'https://partner.medxoom.com/shared_assets/medxoom/medxoom.png';

export const GENERIC_ERROR_MESSAGE =
`An application error occurred.

Please try again, or contact support if the issue persists.`;

export const NO_POLICIES_FOUND_MESSAGE = `We could not find any policy information with the data you provided.

If you continue to experience this issue, please contact your benefit administrator.`;

export const APP_PORTAL_CONTEXT = 'mx.portal.partner';

export const MODULES_WITH_SETTINGS = [
  ModuleType.Spc,
  ModuleType.EZPay,
  ModuleType.BalanceBill,
  ModuleType.SuperBill,
  ModuleType.Chat,
  ModuleType.HealthServiceRequest,
  ModuleType.ProviderSearch,
  ModuleType.MeMD,
  ModuleType.ExternalLink,
  ModuleType.Magellan,
  ModuleType.ProviderSearchStandalone
];

export const ADMIN_PARTNER_IDS = [ 1, 19, 121 ];

export const THREADS_PAGING_LIMIT = 50;

export const DEFAULT_SEARCH_CONFIDENCE_LEVEL = 1;

export const DEFAULT_MEMBER_SEARCH_PAGE_LIMIT = 100;

export const PHYSICIANTYPES = [
  { name: 'Primary Care Provider', value: PhysicianType.PCP },
  { name: 'Specialist', value: PhysicianType.Specialist }
];

export const PLACESOFSERVICE = [
  { name: 'In Office', value: PlaceOfService.InOffice },
  { name: 'Urgent Care', value: PlaceOfService.UrgentCare },
  { name: 'ER', value: PlaceOfService.ER }
];
